import React, { createContext, useContext, useRef, useState } from 'react';

const ResizeContext = createContext();

const useResizable = (initialSettings) => {
  const [sizes, setSizes] = useState(new Map());
  const [ids] = useState({});
  const ref = useRef(null);
  const defaultSize = initialSettings?.defaultSize || 60;

  const size = (index) => {
    return sizes.get(index) || defaultSize;
  };

  const idIndex = (id) => {
    return ids[id] || 0;
  };

  const setSize = (payload) => {
    const index = payload.index ?? payload.id;
    const currentSize = sizes.get(index);

    if (currentSize === Math.round(payload.size)) {
      return;
    }

    setSizes((prevSizes) => {
      const newSizes = new Map(prevSizes);
      newSizes.set(index, Math.round(payload.size));
      return newSizes;
    });

    if (ref?.current && typeof ref?.current?.resetAfterIndex === 'function') {
      ref.current.resetAfterIndex(index, true);
    }
  };

  const resetAfterIndex = (index) => {
    if (ref.current) {
      ref.current.resetAfterIndex(index);
    }
  };

  const setRef = (newRef) => {
    ref.current = newRef.current;
    if (newRef?.current) {
      newRef.current.resetAfterIndex(0);
    }
  };

  return {
    size,
    idIndex,
    setSize,
    resetAfterIndex,
    setRef,
  };
};

/**
 * ResizeProvider Component
 *
 * This component serves as a context provider for managing
 * the resizing behavior of child components. It utilizes
 * the `useResizable` hook to provide resizing functionalities
 * such as setting and retrieving sizes for resizable elements.
 *
 * @param {Object} props - The props for the component.
 * @param {ReactNode} props.children - The child components
 * that will have access to the resize context.
 * @param {Object} props.defaultSettings - Default/Initial settings.
 *
 * @returns {JSX.Element} The context provider wrapping the children.
 */
export const ResizeProvider = ({ children, defaultSettings }) => {
  const resizable = useResizable(defaultSettings);
  return <ResizeContext.Provider value={resizable}>{children}</ResizeContext.Provider>;
};

/**
 * Custom hook to use the resize context.
 *
 * @returns {Object} The resize context value.
 * @throws {Error} If used outside of a ResizeProvider.
 */
export const useResize = () => {
  const context = useContext(ResizeContext);
  if (!context) {
    throw new Error('useResize must be used within a ResizeProvider');
  }
  return context;
};
