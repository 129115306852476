import { setCompanies } from './setCompanies';
import { setUserCompany } from './setUserCompany';
import { resetCompanies } from './resetCompanies';
import { setAllPeopleSearchText } from './setAllPeopleSearchText';
import { setAllPeopleSearchedValueByText } from './setAllPeopleSearchedValueByText';
import { setAllPeopleSearchedByEmptyText } from './setAllPeopleSearchedByEmptyText';

export default {
  setCompanies,
  setUserCompany,
  resetCompanies,
  setAllPeopleSearchText,
  setAllPeopleSearchedValueByText,
  setAllPeopleSearchedByEmptyText,
};
