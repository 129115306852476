import { action } from 'easy-peasy';

import { projectTeamworkFxTemplatesStore } from '../store';
import { clearStatePartByKey } from '../../../../../../utils-deprecated/easyPeasyHelpers';

export const changeSidebarCategoryChecked = action(
  (state, { categories: { list, map }, showAll }) => {
    state.sidebar.list = list;
    state.sidebar.map = map;
    state.sidebar.showAll = showAll;
    state.sidebar.isFiltered = true;
    clearStatePartByKey({ state, key: 'pagination', store: projectTeamworkFxTemplatesStore });
  }
);
