import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onUpdateMilestoneTemplate = thunk(
  async (
    { updateMilestoneTemplate },
    { values, milestoneId, onCloseForm },
    { getStoreActions }
  ) => {
    const { common } = getStoreActions();

    try {
      const res = await api.projectTemplateMilestones.patch(milestoneId, values, {
        for: 'responsibleUserWithCompany',
      });

      onCloseForm();
      updateMilestoneTemplate(res);
    } catch (e) {
      common.showError(e);
    }
  }
);
