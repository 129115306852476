import { paramsForServer } from 'feathers-hooks-common';
import api from '../../../../../../services/api';

import { templateProjectPaginationLimit } from '../../../../../../ui/constants/limits';

export const getProjectTemplates = async (
  { list, map, showAll },
  setTemplateProjectsItemsToStore,
  { $skip = 0, $limit = templateProjectPaginationLimit, nextPage = 1 } = {}
) => {
  const selectedCategories = list.filter((id) => map[id].checked);
  const data = await api.projectTemplates.find(
    showAll
      ? { query: { $skip, $limit } }
      : paramsForServer({
          query: { categoryIds: selectedCategories, $skip, $limit },
          for: 'filterByCategoryIds',
        })
  );
  setTemplateProjectsItemsToStore({ ...data, nextPage });
  return data;
};
