const jobDepartments = [
  { label: 'Customer Service', value: 'customer_service' },
  { label: 'Design', value: 'design' },
  { label: 'Education', value: 'education' },
  { label: 'Engineering', value: 'engineering' },
  { label: 'Finance', value: 'finance' },
  { label: 'Health', value: 'health' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Legal', value: 'legal' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Media', value: 'media' },
  { label: 'Operations', value: 'operations' },
  { label: 'Public Relations', value: 'public_relations' },
  { label: 'Real Estate', value: 'real_estate' },
  { label: 'Sales', value: 'sales' },
  { label: 'Trades', value: 'trades' },
];
export default jobDepartments;
