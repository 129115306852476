export const removeMilestoneHelper = ({ weeks, deletedWeek, deletedDay, deletedMilestone }) => {
  if (
    weeks.map[deletedWeek].days.list.length === 1 &&
    weeks.map[deletedWeek].days[deletedDay].list.length === 1
  ) {
    const deleteWeekIndex = weeks.list.findIndex((item) => item === deletedWeek);

    delete weeks.map[deletedWeek];
    weeks.list.splice(deleteWeekIndex, 1);
    return;
  }

  if (weeks.map[deletedWeek].days[deletedDay].list.length === 1) {
    const deleteDayIndex = weeks.map[deletedWeek].days.list.findIndex(
      (item) => item === deletedDay
    );

    delete weeks.map[deletedWeek].days[deletedDay];
    weeks.map[deletedWeek].days.list.splice(deleteDayIndex, 1);
    return;
  }

  const deleteMilestoneIndex = weeks.map[deletedWeek].days[deletedDay].list.findIndex(
    (item) => item === deletedMilestone
  );

  delete weeks.map[deletedWeek].days[deletedDay].map[deletedMilestone];
  weeks.map[deletedWeek].days[deletedDay].list.splice(deleteMilestoneIndex, 1);
};
