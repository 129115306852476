import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';
import { getFilterParams } from '../helpers/getFilterParams';
import { setTodoLists } from '../helpers/setTodoLists';

// TODO: refactor this whole fetch and store setting. Data structures and logic is far to
export const onMountProjectTodoLists = fetchWithLoaderAndRedirect({
  loaderKey: null, //'ProjectTodoListsPage',
  action: async (actions, { projectId: _projectId }, getStoreState) => {
    const { mountProjectTodoLists } = actions.project.todos.todoLists;
    const { showLoader, hideLoader } = actions.common;
    const loaderKey = 'globalLoader.onMountProjectTodoListsPage';

    const state = getStoreState();
    const { data: project } = useProject();
    const projectId = _projectId || state.router.params.projectId || project?.projectId;
    const { filters } = state.project.todos.todoLists;

    if (!projectId) {
      return;
    }

    showLoader({ key: loaderKey });

    const newTodoLists = await api.todoLists.find({
      query: {
        projectId,
        $sort: { position: 1 },
        $select: [
          'todolistId',
          'name',
          'description',
          'private',
          'completed',
          'position',
          'milestoneId',
          'encrypted',
        ],
      },
      for: 'onFindProjectTodoLists',
    });

    const checkedLists = newTodoLists
      .filter((todoList) => !toBool(todoList.completed))
      .map(({ todolistId }) => todolistId);

    const todoLists = setTodoLists(newTodoLists, checkedLists);

    mountProjectTodoLists({ todoLists, todosBag: { data: [], hasMore: true } });

    // Split todo requests to so it returns all active and narrowed scope of
    // completed items.
    //
    // TODO: redo this so "completed items" can be attached as resolver on todolists
    // reducing our requests

    Promise.all([
      api.todos.find({
        query: {
          todolistId: { $in: checkedLists.slice(0, 2) },
          completedAt: 'NULL',
          // $skip,
          $limit: -1,
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      }),
      api.todos.find({
        query: {
          todolistId: { $in: checkedLists.slice(0, 2) },
          completedAt: { $ne: 'NULL' },
          $sort: { completedAt: -1 },
          // $skip,
          $limit: 4,
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      }),
      api.todos.find({
        query: {
          todolistId: { $in: checkedLists.slice(1, 2) },
          completedAt: { $ne: 'NULL' },
          $sort: { completedAt: -1 },
          // $skip,
          $limit: 4,
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      }),
    ]).then((res) => {
      const [activeTodos, completedTodosFirst, completedTodosSecond] = res;
      const todosBag = [...activeTodos, ...completedTodosFirst.data, ...completedTodosSecond.data];

      // TODO results are not returing completed total

      hideLoader({ key: loaderKey });
      mountProjectTodoLists({ todoLists, todosBag, todolistId: checkedLists.slice(0, 2) });
    });

    api.milestones
      .find({
        query: {
          projectId,
          $sort: { title: 1 },
          $select: ['milestoneId', 'title', 'encrypted'],
        },
      })
      .then((milestones) => mountProjectTodoLists({ milestones }));

    api.projects
      .get({ projectId, params: { for: 'onGetProjectTodosAssignees' } })
      .then((res) => mountProjectTodoLists({ assignees: res.assignees }));

    // mountProjectTodoLists({ todoLists, todosBag, assignees });

    return state.router.url({ route: 'projectTodolists', projectId });
  },
});
