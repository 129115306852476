// actions
import { setActivities } from './actions/setActivities';
import { clearActivitiesState } from './actions/clearActivitiesState';
import { clearEventsMilestonesState } from './actions/clearEventsMilestonesState';
import { removeEvent } from './actions/removeEvent';
import { updateEvent } from './actions/updateEvent';
import { setSubscribeState } from './actions/setSubscribeState';
import { addMilestone } from './actions/addMilestone';
import { removeMilestone } from './actions/removeMilestone';
import { updateMilestone } from './actions/updateMilestone';
import { updateOverdueMilestone } from './actions/updateOverdueMilestone';
import { removeOverdueMilestone } from './actions/removeOverdueMilestone';
import { setSelectMilestone } from './actions/setSelectMilestone';
import { toggleMilestone } from './actions/toggleMilestone';
import { completeOverdueMilestone } from './actions/completeOverdueMilestone';
import { uncompleteOverdueMilestone } from './actions/uncompleteOverdueMilestone';
import { loadMoreOverdueMilestones } from './actions/loadMoreOverdueMilestones';
import { mountProjectOverview } from './actions/mountProjectOverview';
import { moveMilestone } from './actions/moveMilestone';
import { updateMilestones } from './actions/updateMilestones';
import { decreaseOverdueCount } from './actions/decreaseOverdueCount';
import { setOverdueMilestones } from './actions/setOverdueMilestones';

// ws-actions
import { wsAddProjectOverviewEvent } from './actions/wsAddProjectOverviewEvent';
import { wsPatchProjectOverviewEvent } from './actions/wsPatchProjectOverviewEvent';
import { wsRemoveProjectOverviewEvent } from './actions/wsRemoveProjectOverviewEvent';
import { wsAddProjectOverviewMilestone } from './actions/wsAddProjectOverviewMilestone';
import { wsPatchProjectOverviewMilestone } from './actions/wsPatchProjectOverviewMilestone';
import { wsRemoveProjectOverviewMilestone } from './actions/wsRemoveProjectOverviewMilestone';
import { wsAddProjectOverviewMilestoneComment } from './actions/wsAddProjectOverviewMilestoneComment';
import { wsRemoveMilestoneComment } from './actions/wsRemoveMilestoneComment';

// thunks
import { onMountProjectOverview } from './thunks/onMountProjectOverview';
import { onUnMountOverview } from './thunks/onUnMountOverview';
import { onGetActivitiesByPagination } from './thunks/onGetActivitiesByPagination';
import { onDeleteEvent } from './thunks/onDeleteEvent';
import { onUpdateEvent } from './thunks/onUpdateEvent';
import { onUpdateMilestone } from './thunks/onUpdateMilestone/onUpdateMilestone';
import { onDeleteMilestone } from './thunks/onDeleteMilestone';
import { onGetMilestone } from './thunks/onGetMilestone';
import { onToggleCompleteMilestone } from './thunks/onToggleCompleteMilestone';
import { onProjectSubscribe } from './thunks/onProjectSubscribe';
import { onLoadMoreOverdueMilestones } from './thunks/onLoadMoreOverdueMilestones';
import { onClickProjectActivityEventTitle } from './thunks/onClickProjectActivityEventTitle';

// ws-thunks
import { onWsCreateProjectOverviewMilestone } from './thunks/onWsCreateProjectOverviewMilestone';
import { onWsPatchProjectOverviewMilestone } from './thunks/onWsPatchProjectOverviewMilestone';
import { onWsPatchProjectOverviewEvent } from './thunks/onWsPatchProjectOverviewEvent';
import { onWsRemoveProjectOverviewMilestone } from './thunks/onWsRemoveProjectOverviewMilestone';
import { onWsDeleteMilestoneComment } from './thunks/onWsDeleteMilestoneComment';

import activitiesStore from './activitiesStore';

const overview = {
  activities: { ...activitiesStore },

  subscriberId: null,

  events: {
    items: [],
    select: null,
  },

  milestones: {
    items: [],
    select: null,
    overdue: [],
    hasMoreData: false,
    skipCount: 0,
    overdueCount: 0,
  },

  calendarDates: {
    from: null,
    to: null,
  },

  // actions
  setActivities,
  clearActivitiesState,
  clearEventsMilestonesState,
  removeEvent,
  updateEvent,
  setSubscribeState,
  setSelectMilestone,
  addMilestone,
  removeMilestone,
  updateMilestone,
  updateOverdueMilestone,
  removeOverdueMilestone,
  completeOverdueMilestone,
  uncompleteOverdueMilestone,
  toggleMilestone,
  loadMoreOverdueMilestones,
  mountProjectOverview,
  moveMilestone,
  updateMilestones,
  decreaseOverdueCount,
  setOverdueMilestones,

  // ws-actions
  wsAddProjectOverviewEvent,
  wsPatchProjectOverviewEvent,
  wsRemoveProjectOverviewEvent,
  wsAddProjectOverviewMilestone,
  wsPatchProjectOverviewMilestone,
  wsRemoveProjectOverviewMilestone,
  wsAddProjectOverviewMilestoneComment,
  wsRemoveMilestoneComment,

  // thunks
  onMountProjectOverview,
  onGetActivitiesByPagination,
  onUnMountOverview,
  onDeleteEvent,
  onUpdateEvent,
  onUpdateMilestone,
  onDeleteMilestone,
  onGetMilestone,
  onToggleCompleteMilestone,
  onProjectSubscribe,
  onLoadMoreOverdueMilestones,
  onClickProjectActivityEventTitle,

  // ws-thunks
  onWsCreateProjectOverviewMilestone,
  onWsPatchProjectOverviewMilestone,
  onWsPatchProjectOverviewEvent,
  onWsDeleteMilestoneComment,
  onWsRemoveProjectOverviewMilestone,
};

export default overview;
