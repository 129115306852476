import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';
import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';

export const onToggleCompleteMilestone = thunk(
  async (_, { milestoneId, isCompleted }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const toggleMilestone = actions.project.overview.toggleMilestone;
    const completeOverdueMilestone = actions.project.overview.completeOverdueMilestone;
    const uncompleteOverdueMilestone = actions.project.overview.uncompleteOverdueMilestone;

    const { userId, timezone } = store.auth.user();
    try {
      const body = {
        completedAt: null,
        completerUserId: null,
      };
      if (isCompleted) {
        body.completedAt = DateTime.utc().toISO();
        body.completerUserId = userId;
      }

      const milestone = await api.milestones.update(milestoneId, body, {
        for: 'milestoneComments',
      });
      toggleMilestone(milestone);

      if (isOverdueMilestone(milestone.dueAt, timezone)) {
        isCompleted ? completeOverdueMilestone(milestone) : uncompleteOverdueMilestone(milestone);
      }
    } catch (e) {
      showError(e);
    }
  }
);
