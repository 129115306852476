import { action } from 'easy-peasy';

export const addTeamworkFxTemplateMultipleTodo = action((state, todoItems) => {
  const { todolistTemplateId } = todoItems[0];

  const list = state.lists.map[todolistTemplateId];

  if (list) {
    todoItems.forEach((item) => {
      const { todolistTemplateTodoId } = item;

      list.todos.push(todolistTemplateTodoId);
      state.todoItems[todolistTemplateTodoId] = item;
    });
  }
});
