import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { moveTodoListToActive } from '../helpers/moveTodoListToActive';

export const unCompleteTodo = action((state, { todo }) => {
  const { todolistId, todoId } = todo;
  const { todos } = state.todoLists.map[todolistId];

  if (todos.map[todoId]) {
    todos.completed = todos.completed.filter((id) => id !== todoId);
    todos.active.push(todoId);
    merge(todos.map[todoId], todo);
  }

  moveTodoListToActive(state.todoLists, todo);
});
