import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { previousPath } from '../../../../../utils-deprecated/redirectHelper';

export const onCreateUserWithParams = thunk(
  async (
    _,
    { values, companyId, projectId, push, setSubmitting },
    { getStoreState, getStoreActions }
  ) => {
    const state = getStoreState();
    const actions = getStoreActions();
    const { common } = actions;
    const { onMountPeoplePermissions } = actions.ui.peopleAndPermissions;
    const { onMountChangePermissions } = actions.ui.changePermissions;

    try {
      await api.users.create(
        { ...values, company: { companyId }, timezone: 'America/New_York' },
        { projectId, for: 'peopleAndPermissions' }
      );
      const prevPath = previousPath(state.router.pathname);
      if (prevPath.indexOf('people-and-permissions') !== -1) {
        onMountPeoplePermissions({ projectId, pushParams: { push } });
      } else if (prevPath.indexOf('change-permissions') !== -1) {
        onMountChangePermissions({ projectId, pushParams: { push } });
      } else {
        push(prevPath);
      }
    } catch (error) {
      common.showError(error);
      setSubmitting(false);
    }
  }
);
