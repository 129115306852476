import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

/**
 *
 * @param ref
 */
export const getContentAsHtml = (ref) => {
  const delta = ref.current.getEditor().getContents();
  const converter = new QuillDeltaToHtmlConverter(delta.ops, { inlineStyles: true });

  converter.renderCustomWith((customOp) => {
    if (customOp.insert.type !== 'mention') {
      return '';
    }

    const { index, denotationChar, id, value, type } = customOp.insert.value;

    return `<span class="mention" data-index="${index}" data-denotation-char="${denotationChar}" data-id="${id}" data-value="${value}"  data-type="${type}"><span contenteditable="false"><span class="ql-mention-denotation-char">${denotationChar}</span>${value}</span></span>`;
  });

  let html = converter.convert();

  // quill-delta-to-html adds the unsafe: when an unknown protocol is used in links.
  html = html.replace('="unsafe:', '="http://');

  //Only proceed if the HTML string contains an ordered list.
  if (html.includes('<ol>')) {
    html = reconstructOrderedList(html);
  }

  return html;
};

const reconstructOrderedList = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const parentOrderedList = doc.querySelector('ol');

  const listStyleTypes = ['decimal', 'lower-alpha', 'lower-roman'];
  const listStylesCount = listStyleTypes.length;

  const listItems = Array.from(parentOrderedList.children);

  listItems.forEach((list) => {
    const nestedOrderedLists = list.getElementsByTagName('ol');
    [...nestedOrderedLists].forEach((ol, index) => {
      // [index + 1] because we want to start from the 'lower-alpha' list style type for nested ordered list.
      const selectedType = listStyleTypes[(index + 1) % listStylesCount];

      ol.style.listStyleType = selectedType;
    });
  });

  return doc.body.innerHTML;
};

/**
 *
 * @param str
 */
export const getTaggingPeopleIdsPrivate = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');

  const userIds = Array.from(doc.querySelectorAll('span.mention'), (node) => {
    if (node.dataset.type !== 'other') {
      return parseInt(node.dataset.id);
    }
  });

  return userIds.filter((id) => id);
};

/**
 *
 * @param str
 */
export const getTaggingPeopleIds = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');

  return Array.from(doc.querySelectorAll('span.mention'), (node) => parseInt(node.dataset.id));
};
