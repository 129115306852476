import { setTeamworkFxSearchedByCategories } from './actions/setTeamworkFxSearchedByCategories';
import { unMountTeamworkFxSearchState } from './actions/unMountTeamworkFxSearchState';
import { setTeamworkFxSearchedValueByText } from './actions/setTeamworkFxSearchedValueByText';
import { setTeamworkFxSearchedAllToStore } from './actions/setTeamworkFxSearchedAllToStore';
import { setTeamworkFxSearchText } from './actions/setTeamworkFxSearchText';
import { setMoreTeamworkFxSearchedValueToStore } from './actions/setMoreTeamworkFxSearchedValueToStore';

import { onSearchTeamworkFxByText } from './thunks/onSearchTeamworkFxByText';
import { onSearchTeamworkFxByCategory } from './thunks/onSearchTeamworkFxByCategory';
import { onClickTeamworkFxSearchEventTitle } from './thunks/onClickTeamworkFxSearchEventTitle';
import { onClickTeamworkFxSearchMilestoneTitle } from './thunks/onClickTeamworkFxSearchMilestoneTitle';
import { onSearchTeamworkFxByAllCategories } from './thunks/onSearchTeamworkFxByAllCategories';
import { onSearchTeamworkFxMore } from './thunks/onSearchTeamworkFxMore';
import { onUnMountTeamworkFxSearchState } from './thunks/onUnMountTeamworkFxSearchState';
import { onMountTeamworkFxSearchPage } from './thunks/onMountTeamworkFxSearchPage';

import initStore from './initStore';

const search = {
  // store
  ...initStore,

  // actions
  setTeamworkFxSearchedByCategories,
  setTeamworkFxSearchText,
  unMountTeamworkFxSearchState,
  setTeamworkFxSearchedValueByText,
  setTeamworkFxSearchedAllToStore,
  setMoreTeamworkFxSearchedValueToStore,

  // thunks
  onSearchTeamworkFxByText,
  onSearchTeamworkFxByCategory,
  onClickTeamworkFxSearchEventTitle,
  onClickTeamworkFxSearchMilestoneTitle,
  onSearchTeamworkFxByAllCategories,
  onSearchTeamworkFxMore,
  onUnMountTeamworkFxSearchState,
  onMountTeamworkFxSearchPage,
};

export default search;
