import { action } from 'easy-peasy';

export const addTemplateMultipleTodo = action((state, todoItems) => {
  const { projectTemplateTodoListId } = todoItems[0];

  const list = state.lists.map[projectTemplateTodoListId];

  if (list) {
    todoItems.forEach((item) => {
      const { projectTemplateTodoId } = item;

      list.projectTemplateTodos.push(projectTemplateTodoId);
      state.todoItems[projectTemplateTodoId] = item;
    });
  }
});
