import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';
import { getCheckedLists } from '../helpers/getCheckedLists';

export const onToggleSidebarTodoList = thunk(
  async (
    { toggleSidebarTodoList },
    { todolistId, checked },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { todoLists, filters } = state.project.todos.todoLists;

    const loaders = [
      `project.todos.todoLists.sidebar.${todolistId}`,
      'project.todos.todoLists.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });

    try {
      const [activeTodos, completedTodos] = await Promise.all([
        api.todos.find({
          query: {
            todolistId: { $in: getCheckedLists({ todoLists, todolistId, checked }) },
            completedAt: 'NULL',
            // $skip,
            $limit: -1,
          },
          filter: getFilterParams(filters),
          for: 'onFindProjectTodos',
        }),
        api.todos.find({
          query: {
            todolistId: { $in: getCheckedLists({ todoLists, todolistId, checked }) },
            completedAt: { $ne: 'NULL' },
            $sort: { completedAt: -1 },
            // $skip,
            $limit: 4,
          },
          filter: getFilterParams(filters),
          for: 'onFindProjectTodos',
        }),
      ]);

      const todosBag = [...activeTodos, ...completedTodos.data];

      toggleSidebarTodoList({ todosBag, todolistId, checked });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loaders });
  }
);
