import { action, computed } from 'easy-peasy';
import groupBy from 'lodash/groupBy';
import memoizerific from 'memoizerific';
import { mixins } from '@webfx/core-web';

import { set } from '../../../utils-deprecated/easyPeasyHelpers';
import projectsThunks from './thunks';

const projects = {
  ...mixins.fetchable('tw/projects-deprecated', 'projectId'),
  ...mixins.itemsAsOptions('projectId', 'name'),

  sortedItems: computed((state) => {
    if (!state.items || !state.items.length) {
      return [];
    }
    return state.items.sort((a, b) => {
      const nameA = a.name.trim().toUpperCase();
      const nameB = b.name.trim().toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }),

  itemsByCompany: computed((state) => {
    if (!state.items || !state.items.length) {
      return {};
    }
    return groupBy(state.items, (i) => i.company?.name || 'Unassigned');
  }),

  // TODO: Refactor below to use mixin data instead
  // -
  // -
  companiesWithProjects: [], // TODO: Define what this is for. should be getter off projects list
  companiesWithProjectsAndLists: [], // TODO: Define what this is for. seems unnecessary
  companyProjects: [], // TODO: Define what this is for
  assignee: [],
  privateAssignee: [],

  // actions
  removeProject: action((state, projectId) => {
    delete state.data[projectId];
    state.ids = state.ids.filter((i) => i !== projectId);
  }),
  setCompanyProjects: action((state, listOfProjects) => {
    state.companyProjects = listOfProjects;
  }),

  setAssignee: action((state, payload) => {
    state.assignee = payload;
  }),

  setPrivateAssignee: action((state, payload) => {
    state.privateAssignee = payload;
  }),

  // TODO:Refactor - remove this coz we should use companies and projects from globals
  // this action is still using in MovedMilestoneIformation.jsx component
  getProjectListsByIdFromMovingItem: computed((state) =>
    memoizerific(10)((id) => {
      const project = state.companiesWithProjects
        .map(({ projects }) => projects)
        // We are using flat because every company has an array of projects
        // so we need to convert it to one array
        .flat()
        .find(({ projectId }) => projectId === id);
      if (!project) {
        return { name: '' };
      }
      return project;
    })
  ),

  setCompaniesWithProjects: set('companiesWithProjects'),
  setCompaniesWithProjectsAndLists: set('companiesWithProjectsAndLists'),

  // thunks
  selectOptionsWithProjects: computed((state) =>
    state.companiesWithProjects.map(({ name, projects, logoUrl }) => ({
      label: {
        name,
        logoUrl,
      },
      options: projects.map(({ name: projectName, projectId }) => ({
        label: projectName,
        value: projectId,
      })),
    }))
  ),
  ...projectsThunks,
};

export default projects;
