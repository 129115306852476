import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';

import api from '../../../../../services/api';

export const onAddCompanyToProject = thunk(
  async (
    { addCompanyToProject },
    { body, params, setSubmitting, onClosePopup, resetForm },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const {
      common: { showError },
      project: { setSelectedProjectFromPermissionsPage },
    } = actions;

    const { data: project } = useProject();
    const projectId = project?.projectId;

    try {
      const newCompany = await api.projectsCompanies.create(body, params);
      const selectedProject = await api.projects.get({
        projectId,
        params: { for: 'projectCompanies' },
      });

      addCompanyToProject(newCompany);
      setSelectedProjectFromPermissionsPage(selectedProject);
      resetForm();
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
