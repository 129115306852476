import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

const service = feathersClient.service('tw/subscribers-deprecated');

/**
 *
 * @param params
 */
export const find = (params) => service.find(paramsForServer(params));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => service.create(payload, paramsForServer(params));
/**
 *
 * @param subscriberId
 */
export const remove = (subscriberId) => service.remove(subscriberId);
