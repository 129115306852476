import { action } from 'easy-peasy';

import { getTodoIndex } from '../helpers/list';

export const deleteTeamworkFxTemplateTodo = action(
  (state, { todolistTemplateId, todolistTemplateTodoId }) => {
    const list = state.lists.map[todolistTemplateId];

    if (!list) {
      return;
    }

    const todoIndex = getTodoIndex(list, todolistTemplateTodoId);
    list.todos.splice(todoIndex, 1);
    delete state.todoItems[todolistTemplateTodoId];
  }
);
