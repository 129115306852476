const getSelectedCategories = (sidebarFilters) => {
  if (sidebarFilters.showAll) {
    return undefined;
  }

  if (sidebarFilters.selected.includes('event')) {
    return [...sidebarFilters.selected, 'milestone'];
  }
  if (sidebarFilters.selected.includes('todo')) {
    return [...sidebarFilters.selected, 'todolist'];
  }

  return sidebarFilters.selected;
};

export default getSelectedCategories;
