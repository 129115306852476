import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

import api from '../../../../../services/api';

export const onGiveAccess = thunk(
  async ({ giveAccess }, { userId, projectId, accessLevel }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const giveAccessTo = [{ userId, projectId, accessLevel }];

    const loader = [
      `project.peopleAndPermissions.nonWebFxUsers.${accessLevel || 'general'}.${userId}`,
      `project.peopleAndPermissions.nonWebFxUsers.hasAnyLoading.${userId}`,
    ];
    showLoader({ key: loader });
    try {
      const res = await api.projectsUsers.create(giveAccessTo, {
        for: 'changePeopleAndPermissions',
      });
      giveAccess(res);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
    feathers().service('permissions').update(userId, { userId });
  }
);
