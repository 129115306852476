import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';

const getMilestoneIndexInStore = (state, milestoneId) => {
  const { items: milestonesList } = state.project.overview.milestones;
  return milestonesList.findIndex(({ milestoneId: id }) => id === milestoneId);
};

const handleToggleCompleteOverdueMilestone = (milestone, actions, timezone) => {
  if (!isOverdueMilestone(milestone.dueAt, timezone)) {
    return;
  }

  const completeOverdueMilestone = actions.project.overview.completeOverdueMilestone;
  const uncompleteOverdueMilestone = actions.project.overview.uncompleteOverdueMilestone;
  const isCompleted = !!milestone.completedAt;

  if (isCompleted) {
    completeOverdueMilestone(milestone);
  } else {
    uncompleteOverdueMilestone(milestone);
  }
};

const addMilestone = async (state, actions, wsMilestone) => {
  const wsAddProjectOverviewMilestone = actions.project.overview.wsAddProjectOverviewMilestone;
  const timezone = store.auth.user().timezone;
  let milestone = wsMilestone;

  // if milestone was moved to another project
  // then we need to get milestone full info (with hasNewComments prop)
  if (milestone.eventType === 'moveToAnotherProject') {
    milestone = await api.milestones.get({
      id: wsMilestone.milestoneId,
      params: { for: 'milestoneComments' },
    });
  }

  wsAddProjectOverviewMilestone({ milestone, timezone });
};

export const onWsPatchProjectOverviewMilestone = thunk(
  async (_, milestone, { getStoreState, getStoreActions }) => {
    // avoid call thunk on milestone deleting
    if (milestone.deletedAt) {
      return;
    }

    const actions = getStoreActions();
    const wsPatchProjectOverviewMilestone =
      actions.project.overview.wsPatchProjectOverviewMilestone;

    const state = getStoreState();
    const { timezone, userId } = store.auth.user();

    // if milestone was edited by current user - do nothing
    if (milestone.editorUserId === userId) {
      return;
    }

    handleToggleCompleteOverdueMilestone(milestone, actions, timezone);

    const milestoneIndex = getMilestoneIndexInStore(state, milestone.milestoneId);
    // milestone not exists in store, try to add it
    if (milestoneIndex === -1) {
      await addMilestone(state, actions, milestone);
      return;
    }

    wsPatchProjectOverviewMilestone({ milestone, milestoneIndex });
  }
);
