import { api } from '@webfx/core-web';
import { useParams } from 'react-router-dom';

/**
 * hook to fetch and manage company users data.
 * @param {string | number | null} companyId - company Id.
 * @param {object} [reactQueryParams] - Additional query parameters for the React Query call.
 * @param {object} [companyUserQuery] - Additional query parameters for the company users query.
 * @returns {object} An object containing the company users data, pass-through query properties, and a mutator function for the data.
 */
export default function useCompanyUsers(companyId, reactQueryParams = {}, companyUserQuery = {}) {
  const params = useParams();

  companyId = companyId ?? params.companyId ?? null;

  const { data, ...passThroughQuery } = api.useQuery(
    ['companies-users', { companyId: Number(companyId), ...companyUserQuery }, {}],
    {
      enabled: !!companyId,
      ...reactQueryParams,
    }
  );

  const mutator = api.useMutation(['companies-users'], null, [passThroughQuery.queryKey], {
    queryParams: (data) => ({ companyId: data.companyId }),
  });

  return {
    data,
    ...passThroughQuery,
    mutator,
  };
}
