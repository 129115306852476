import { paramsForServer } from 'feathers-hooks-common';
import api from '../../../../../../services/api';
import getQueryForFilteringListsTemplate from '../helpers/getQueryForFilteringListsTemplate';

export const filterTodoListsTemplate = async (
  { list, map, showAll, search },
  setTemplateListItemsToStore,
  singleTodoListTemplateId
) => {
  const query = getQueryForFilteringListsTemplate(
    { list, map, showAll, search },
    singleTodoListTemplateId
  );

  const listsResponse = await api.todoListsTemplate.find(
    paramsForServer({
      query: {
        ...query,
        $join: ['updater', 'lastDeployedDate', 'timesDeployedLast12Months', 'owner'],
      },
      ...(!showAll && { for: 'filterByCategoryIds' }),
    })
  );
  setTemplateListItemsToStore(listsResponse);
};
