import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onEditTodoList = thunk(
  async (
    { editTodoList },
    { todolistId, values, setSubmitting, onClosePopup },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      let todoList = await api.todoLists.patch(todolistId, values, { for: 'onEditTodoList' });
      if (Array.isArray(todoList)) {
        todoList = todoList[0];
      }

      editTodoList({ todoList });
      onClosePopup();
    } catch (e) {
      showError(e);
    }

    setSubmitting(false);
  }
);
