import { action } from 'easy-peasy';

import { moveTodoListToActive } from '../helpers/moveTodoListToActive';
import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';

export const moveSidebarList = action((state, { todo, toCompleted }) => {
  toCompleted
    ? moveTodoListToCompleted(state.todoLists, todo)
    : moveTodoListToActive(state.todoLists, todo);
});
