/* eslint-disable no-param-reassign */
import { isArray, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

/**
 * Hook to filter kanban action items.
 * @param {*} initialFilters {}
 * @returns {}
 */
export default function useKanbanFilters(initialFilters) {
  const [filters, setFilters] = useState(initialFilters);

  const setFilter = useCallback(
    (filter) => {
      if (isArray(filter.completed) && !isEmpty(filter.completed)) {
        if (filter.completed.length === 1) {
          if (filter.completed.includes('open')) {
            filter.completedAt = { $eq: 'NULL' };
          }
          if (filter.completed.includes('completed')) {
            filter.completedAt = { $ne: 'NULL' };
          }
        } else {
          filter.completedAt = {};
        }
      }
      const newFilter = { ...filter };
      delete newFilter.completed;

      // A goalId of -1 logically represents that the one time client requests filter is checked.
      // In this case, we delete the goalId filter and use an $or with the goalId filter and goalId $eq
      // null, as one time client requests are stored without a goalId.
      if (
        filter?.goalId?.$in?.includes(-1) || filter?.$or
          ? filter?.$or[0]?.goalId?.$in?.includes(-1)
          : false
      ) {
        delete filters.goalId;
        delete newFilter.goalId;
      } else {
        // one time client requests is unchecked, remove the $or and use the goalId filter
        delete filters.$or;
        delete newFilter.$or;
      }

      setFilters({ ...filters, ...newFilter });
    },
    [filters]
  );

  const resetFilters = useCallback(
    (payload) => {
      const newFilters = { ...filters };

      Object.keys(payload).forEach((key) => {
        delete newFilters[key];
      });
      setFilters(newFilters);
    },
    [filters]
  );

  return { filters, setFilters, setFilter, resetFilters };
}
