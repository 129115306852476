import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { companiesProjectsLimit } from '../../../../../../ui/constants/limits';

export const onFilterProjectsByName = thunk(
  async (actions, payload, { getStoreActions, getStoreState }) => {
    const { search } = payload;
    const {
      setCompaniesProjects,
      toggleIsFetchingCompaniesProjects,
      resetCompanyProjects,
      setNameFilter,
    } = actions;
    const { showError } = getStoreActions().common;

    const state = getStoreState();
    const { name: prevName } = state.teamworkFx.dashboard.sidebar.companiesProjects.filters.params;

    resetCompanyProjects();
    toggleIsFetchingCompaniesProjects(true);
    // Save value to store
    setNameFilter({ value: search });
    api.sidebar
      .find({
        query: {
          $skip: 0,
          $limit: companiesProjectsLimit,
          name: {
            $like: `%${search}%`,
          },
          status: 'active',
          $cache: false,
        },
        for: 'projectsSidebar',
      })
      .then(
        (companiesProjects) => {
          setCompaniesProjects({ companiesProjects, type: 'active' });
          toggleIsFetchingCompaniesProjects(false);
        },
        (error) => {
          showError(error);
          setNameFilter({ value: prevName });
          toggleIsFetchingCompaniesProjects(false);
        }
      );
  }
);
