import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const projectTemplateMilestones = feathersClient.service('tw/project-template-milestones');

/**
 *
 * @param body
 * @param params
 */
export const create = (body, params) =>
  projectTemplateMilestones.create(body, paramsForServer(params));
/**
 *
 * @param params
 */
export const find = (params) => projectTemplateMilestones.find(paramsForServer(params));
/**
 *
 * @param projectTemplateMilestoneId
 * @param body
 * @param params
 */
export const patch = (projectTemplateMilestoneId, body, params) =>
  projectTemplateMilestones.patch(projectTemplateMilestoneId, body, paramsForServer(params));
/**
 *
 * @param projectTemplateMilestoneId
 */
export const remove = (projectTemplateMilestoneId) =>
  projectTemplateMilestones.remove(projectTemplateMilestoneId);
