import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import getQueryForFiltering from '../helpers/getQueryForFiltering';

export const onChangeFilteringParams = thunk(
  async (
    { setTemplateTodoListsIntoStore, setTodoListTemplateFilteringParams },
    { name, value, templateId },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = [
      `projectTemplate.todoLists.sidebar.${name}`,
      'projectTemplate.todoLists.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      setTodoListTemplateFilteringParams({ name, value });

      const state = getStoreState();
      const query = getQueryForFiltering(state.projectTemplate.todoLists, templateId);
      const data = await api.projectTemplateTodoLists.find({
        query,
        for: 'projectTemplateTodoList',
      });
      setTemplateTodoListsIntoStore(data);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
