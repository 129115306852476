import { action } from 'easy-peasy';

export const createMilestoneTemplate = action((state, payload) => {
  const { weeks } = state.milestones;

  if (weeks.list.includes(payload.weekDue)) {
    const { list } = weeks.map[payload.weekDue].days;

    if (list.includes(payload.dowDue)) {
      weeks.map[payload.weekDue].days[payload.dowDue].list.push(payload.projectTemplateMilestoneId);
      weeks.map[payload.weekDue].days[payload.dowDue].map[payload.projectTemplateMilestoneId] =
        payload;
      return;
    }
    const newDayIndex = list.findIndex((item) => item > payload.dowDue);

    list.splice(newDayIndex === -1 ? list.length : newDayIndex, 0, payload.dowDue);
    weeks.map[payload.weekDue].days[payload.dowDue] = {
      list: [payload.projectTemplateMilestoneId],
      map: {
        [payload.projectTemplateMilestoneId]: payload,
      },
    };
    return;
  }
  const newWeekIndex = weeks.list.findIndex((item) => item > payload.weekDue);

  weeks.list.splice(newWeekIndex === -1 ? weeks.list.length : newWeekIndex, 0, payload.weekDue);
  weeks.map[payload.weekDue] = {
    days: {
      list: [payload.dowDue],
      [payload.dowDue]: {
        list: [payload.projectTemplateMilestoneId],
        map: {
          [payload.projectTemplateMilestoneId]: payload,
        },
      },
    },
  };
});
