import { thunk } from 'easy-peasy';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import { getInitializedTodoLists } from '../helpers/getInitializedTodoLists';
import { filterTodos } from '../helpers/filterTodos';

/* eslint-disable prefer-destructuring */
export const wsOnEditTodo = thunk(
  async (_, { todolistId, result, result: { todoId } }, { getStoreActions, getStoreState }) => {
    const a = getStoreActions();
    const createTodo = a.project.todos.todoLists.createTodo;
    const editTodo = a.project.todos.todoLists.editTodo;
    const deleteActiveTodo = a.project.todos.todoLists.deleteActiveTodo;

    const state = getStoreState();
    const { filters } = state.project.todos.todoLists;
    const { active, hasMore, lastLoadedTodoListId } = state.project.todos.todoLists.todoLists;
    const todo = state.project.todos.todoLists.todoLists.map[todolistId].todos.map[todoId];

    const isTodoExist = toBool(todo);
    const isFiltersPassed = filterTodos({ data: result, filters }).length > 0;
    const isListInitialized = active.includes(todolistId);

    // if (hasMore && !isListInitialized) return; Not sure why the hasMore condition was here.
    if (!isListInitialized) {
      return;
    }

    if (isFiltersPassed && isTodoExist) {
      editTodo({ todolistId, todoId, todo: { ...result, hasNewComments: todo.hasNewComments } });
      return;
    }

    if (!isFiltersPassed && isTodoExist) {
      deleteActiveTodo({ todo: result });
    }
    if (isFiltersPassed && !isTodoExist) {
      createTodo({ todolistId, todos: [result] });
    }
  }
);
