import { action } from 'easy-peasy';
import uniqBy from 'lodash/uniqBy';

import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';

export const uncompleteOverdueMilestone = action((state, updatedItem) => {
  state.milestones.overdue = uniqBy([...state.milestones.overdue, updatedItem], 'milestoneId').sort(
    sortOverdueMilestones
  );
  state.milestones.overdueCount += 1;
});
