import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { isEmpty, groupBy, uniq } from 'lodash';

import { useFlags } from '@webfx/web-hooks';
import useQuarterSetting from '@webfx/permissions-web/src/hooks/useQuarterSetting';
import { getCurrent, getQuarters } from '@webfx/web-ui/src/QuarterSelector/utils';

import useAllGoals from '../../hooks/useAllGoals';

import GoalsV1 from '../GoalsV1';
import Goals from '../Goals';
import Header from './Header';

const QuarterlyGrowthPlan = ({ companyId, companyUsers, forPlaybook = false }) => {
  const flags = useFlags();

  const companyIds = companyId
    ? [companyId]
    : uniq(companyUsers?.map(({ companyId }) => companyId));

  const quarterSetting = useQuarterSetting(companyIds?.[0]?.companyId);

  const { quarters } = getQuarters(quarterSetting);
  const { quarter: currentQuarter, year: currentYear } = getCurrent(quarters);
  const [quarter, setQuarterMonth] = useState(currentQuarter);
  const [year, setQuarterYear] = useState(currentYear);

  const setQuarter = ({ quarter }) => {
    setQuarterMonth(quarter);
    setQuarterYear(null);
  };

  useEffect(() => {
    setQuarterMonth(currentQuarter);
    setQuarterYear(currentYear);
  }, [quarterSetting]);

  // We look for if a company ever setup goals. If not, we hide the goals section
  const { data: goals, isFetching } = useAllGoals(companyIds);
  const companyGoals = groupBy(goals, 'companyId') ?? [];

  return (
    <>
      {/* We hide the Quarterly goal plan at this level when neither of the company  */}
      {!isFetching && !isEmpty(goals) && (
        <div className="container-fluid pr-0 pl-0">
          {/* Header */}
          <Header
            setQuarter={setQuarter}
            quarter={quarter}
            quarterSetting={quarterSetting}
            year={year}
            forPlaybook={forPlaybook}
            companyId={companyId}
          />

          <div className={classnames(forPlaybook ? 'bg-white shadow-sm' : '')}>
            {companyIds?.map((companyId, index) =>
              flags.gameplanfxUpdates && flags.gameplanfxUpdatesPhase2 ? (
                <Goals
                  companyId={companyId}
                  quarter={quarter}
                  key={`${companyId}-${index}`}
                  hideGoals={isEmpty(companyGoals[companyId])}
                />
              ) : (
                <GoalsV1
                  companyId={companyId}
                  quarter={quarter}
                  key={`${companyId}-${index}`}
                  hideGoals={isEmpty(companyGoals[companyId])}
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default QuarterlyGrowthPlan;
