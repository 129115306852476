import { action } from 'easy-peasy';

export const updateFile = action((state, payload) => {
  const itemsCopy = [...state.items];

  itemsCopy.forEach(({ files: currentFiles }) => {
    const index = currentFiles.findIndex(({ uploadId }) => uploadId === payload.uploadId);

    if (index !== -1) {
      currentFiles[index] = {
        ...currentFiles[index],
        ...payload,
      };
    }
  });
});
