import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  {
    name: 'pbfxIndustryOverview',
    url: '/playbookfx/:siteId?/industry-benchmarks',
  },
  {
    name: 'pbfxRevenue',
    url: '/playbookfx/:siteId?/enhanced-growth-strategies/:strategyId?/:tab?',
  },
  {
    name: 'pbfxSalesEstimator',
    url: '/playbookfx/:siteId?/sales-estimator',
  },
  {
    name: 'pbfxStrategies',
    url: '/playbookfx/:siteId?/enhanced-growth-tactics',
  },
  {
    name: 'pbfxStrategies-old',
    url: '/playbookfx/:siteId?/key-strategies',
  },
  {
    name: 'pbfxOpportunities',
    url: '/playbookfx/:siteId?/opportunities/:id?',
  },
  {
    name: 'pbfxConfiguration',
    url: '/playbookfx/:siteId?/configuration',
  },
  {
    name: 'pbfxLanding',
    url: '/playbookfx/:siteId?',
  },
  {
    name: 'pbfxGamePlan',
    url: '/playbookfx/:siteId?/current-playbook',
  },
]);
