import { thunk } from 'easy-peasy';
import { toBool } from '../../../../../../utils-deprecated/toBool';

/* eslint-disable prefer-destructuring */
export const wsOnDeleteTodo = thunk(async (_, { result: todo }, { getStoreActions }) => {
  const actions = getStoreActions();
  const deleteActiveTodo = actions.project.todos.todoLists.deleteActiveTodo;
  const deleteCompletedTodo = actions.project.todos.todoLists.deleteCompletedTodo;

  toBool(todo.completedAt) ? deleteCompletedTodo({ todo }) : deleteActiveTodo({ todo });
});
