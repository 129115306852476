import { setTemplateProjectSidebarCategories } from './actions/setTemplateProjectSidebarCategories';
import { changeSidebarCategoryChecked } from './actions/changeSidebarCategoryChecked';
import { clearProjectTemplateState } from './actions/clearProjectTemplateState';
import { clearProjectTemplateItemsState } from './actions/clearProjectTemplateItemsState';
import { setTemplateProjectsItemsToStore } from './actions/setTemplateProjectsItemsToStore';
import { setProjectTemplateCategories } from './actions/setProjectTemplateCategories';
import { updateTemplateProjectsItems } from './actions/updateTemplateProjectsItems';

import { onFilterByCategory } from './thunks/onFilterByCategory';
import { onShowAllCategories } from './thunks/onShowAllCategories';
import { onCreateProjectTemplate } from './thunks/onCreateProjectTemplate';
import { onMountTeamworkFxProjectTemplate } from './thunks/onMountTeamworkFxProjectTemplate';
import { onUnMountTeamworkFxProjectTemplate } from './thunks/onUnMountTeamworkFxProjectTemplate';
import { onGetProjectTemplates } from './thunks/onGetProjectTemplates';
import { onUpdateProjectTemplate } from './thunks/onUpdateProjectTemplate';
import { onRemoveProjectTemplate } from './thunks/onRemoveProjectTemplate';

import { projectTeamworkFxTemplatesStore } from './store';

const projects = {
  // store
  ...projectTeamworkFxTemplatesStore,

  // actions
  setTemplateProjectSidebarCategories,
  changeSidebarCategoryChecked,
  clearProjectTemplateState,
  clearProjectTemplateItemsState,
  setTemplateProjectsItemsToStore,
  setProjectTemplateCategories,
  updateTemplateProjectsItems,

  // thunks
  onFilterByCategory,
  onShowAllCategories,
  onCreateProjectTemplate,
  onMountTeamworkFxProjectTemplate,
  onUnMountTeamworkFxProjectTemplate,
  onGetProjectTemplates,
  onUpdateProjectTemplate,
  onRemoveProjectTemplate,
};

export default projects;
