import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';
import { getResponsibleInfo } from '../../../../projectTemplate/todoLists/helpers/getResponsibleInfo';

const getContent = (multipleItem, { content, id, assigneeId }) => {
  const itemInfo = getResponsibleInfo({ assigneeId });
  if (!multipleItem) {
    return {
      content,
      todolistTemplateId: id,
      ...itemInfo,
    };
  }

  const contentForAllItems = content.split('\n').filter((item) => item);

  if (!contentForAllItems.length) {
    return null;
  }

  return contentForAllItems.map((currentItem) => ({
    todolistTemplateId: id,
    content: currentItem,
    multipleItem,
    ...itemInfo,
  }));
};

export const onCreateTeamworkFxTemplateTodo = thunk(
  async (
    { addTeamworkFxTemplateTodo, addTeamworkFxTemplateMultipleTodo },
    { values, setSubmitting, setFieldValue, id, multipleItem, resetForm, queryClient },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    try {
      const itemContent = getContent(multipleItem, { ...values, id });
      if (itemContent) {
        const result = await api.todoListTemplateTodos.create(itemContent);
        queryClient?.invalidateQueries('tw/todolist-templates');
        result.length > 0
          ? addTeamworkFxTemplateMultipleTodo(result)
          : addTeamworkFxTemplateTodo(result);
        setFieldValue('content', '');
      }
    } catch (e) {
      showError(e);
    }
    resetForm();
    setSubmitting(false);
  }
);
