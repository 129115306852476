import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../services/api';

export const onDeleteProjectUser = thunk(async (_, { userId, push }, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;
  const { onMountPeoplePermissions } = actions.ui.peopleAndPermissions;

  const { data: project } = useProject();
  const projectId = project?.projectId;

  const loader = 'project.peopleAndPermissions.editUser.deleteUser';
  showLoader({ key: loader });
  try {
    await api.users.remove(userId);
    onMountPeoplePermissions({ projectId, pushParams: { push } });
  } catch (err) {
    showError(err);
  }
  hideLoader({ key: loader });
});
