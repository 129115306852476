import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { toBool } from '../../../../../../utils-deprecated/toBool';
import { transformTodoListFields } from '../helpers/transformTodoList';

export const editTodoList = action((state, { todoList }) => {
  state.todoList = merge(state.todoList, transformTodoListFields(todoList));

  const status = toBool(todoList?.completed) ? 'completed' : 'active';
  state.sidebarLists[status] = state.sidebarLists[status].map((list) =>
    list.todolistId === todoList.todolistId ? { ...list, name: todoList.name } : list
  );
});
