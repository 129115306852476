import { thunk } from 'easy-peasy';
import get from 'lodash/get';

import api from '../../../../../services/api';

export const onProjectSubscribe = thunk(
  async (
    { setSubscribeState },
    { projectId, subscriberId },
    { getStoreActions, getStoreState }
  ) => {
    const { common } = getStoreActions();
    const userId = get(getStoreState(), 'user.user.userId', null);
    try {
      if (!subscriberId) {
        const res = await api.subscribers.create(
          { projectId, userId },
          { for: 'subscribeToProject' }
        );
        const newSubscriberId = get(res, [0, 'subscriberId'], null);
        setSubscribeState(newSubscriberId);
      } else {
        await api.subscribers.remove(subscriberId);
        setSubscribeState(null);
      }
    } catch (e) {
      common.showError(e);
    }
  }
);
