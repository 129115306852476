import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';

import { store } from '@webfx/web-state';
import api from '../../../../../../../services/api';
import { isOverdueMilestone } from '../../../../../../../utils-deprecated/calendar';
import { uncompleteOverdueMilestone } from './uncompleteOverdueMilestone';

export const onToggleCompleteMilestone = thunk(
  async (_, { milestoneId, isCompleted }, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const toggleMilestone = actions.teamworkFx.dashboard.toggleMilestone;
    const completeOverdueMilestone = actions.teamworkFx.dashboard.completeOverdueMilestone;

    const state = getStoreState();
    const { userId, timezone } = store.auth.user() ?? {};

    try {
      const body = {
        completedAt: null,
        completerUserId: null,
      };
      if (isCompleted) {
        body.completedAt = DateTime.utc().toISO();
        body.completerUserId = userId;
      }
      const milestone = await api.milestones.update(milestoneId, body, {
        for: 'milestoneComments',
      });

      if (isOverdueMilestone(milestone.dueAt, timezone)) {
        isCompleted
          ? completeOverdueMilestone(milestone)
          : await uncompleteOverdueMilestone({ state, actions, milestone });
      }
      toggleMilestone(milestone);
    } catch (e) {
      showError(e);
    }
  }
);
