import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  {
    name: 'newUser',
    url: '/client-permissions/users/new',
  },
  {
    name: 'userPermissions',
    url: '/client-permissions/users',
  },
  {
    name: 'editUser',
    url: '/client-permissions/users/edit/:id',
  },
  {
    name: 'editCompany',
    url: '/client-permissions/companies/edit/:id/:page?',
  },
  {
    name: 'companyPermissions',
    url: '/client-permissions/companies',
  },
]);
