const initStore = {
  selectedMilestone: null,
  subscribePeople: [],
  comments: {
    list: [],
    map: {},
    firstCommentId: null,
    total: 0,
  },
  sidebar: {
    subscribers: [],
    userSubscribeId: null,
    commentators: [],
  },
  pastebinTemplates: [],
};

export default initStore;
