import { thunk, computed, unstable_effectOn } from 'easy-peasy';
import { mixins, feathers } from '@webfx/core-web';

export default {
  ...mixins.fetchable('tw/projects', 'projectId'),
  ...mixins.filterable(),
  ...mixins.editable('Project', 'projectId'),
  ...mixins.viewable(),

  sortedItems: computed([(state) => state.items], (items) => {
    if (!items) {
      return [];
    }
    return items
      .filter((i) => !!i.projectId && !!i.name)
      .sort((a, b) => {
        const nameA = a.name.trim().toUpperCase();
        const nameB = b.name.trim().toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }),

  itemsAsOptions: computed([(state) => state.sortedItems], (items) => {
    if (!items) {
      return [];
    }
    return items.map((item) => ({
      value: item.projectId,
      label: item.name.trim(),
    }));
  }),
};
