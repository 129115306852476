import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';
import api from '../../../../../../services/api';
import { getOverdueDate } from '../../../../../../utils-deprecated/calendar';

export const onLoadAllOverdueMilestones = thunk(
  async ({ loadMoreOverdueMilestones }, _, { getStoreState, getStoreActions }) => {
    const state = getStoreState();
    const { skipCount } = state.teamworkFx.dashboard.overdueMilestones;
    const timezone = store.auth.user().timezone;

    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const overdueMaxDate = getOverdueDate(timezone);
    const overdueDateMinDate = DateTime.fromISO(overdueMaxDate).minus({ year: 1 }).toISO();
    const query = {
      dueAt: { $lte: overdueMaxDate, $gte: overdueDateMinDate },
      $client: { for: 'overdueCalendar' },
      $sort: { dueAt: -1 },
      $limit: -1,
      $skip: skipCount,
    };

    const loader = 'teamworkFx.dashboard.overdueMilestones.getMore';
    showLoader({ key: loader });

    try {
      const milestones = await api.milestones.find({ query });

      const data = {
        data: milestones,
        skip: 0,
        hasMore: false,
        count: milestones.length,
      };

      loadMoreOverdueMilestones(data);
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
