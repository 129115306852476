import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { moveSidebarListToActive } from '../helpers/moveSidebarListToActive';

export const unCompleteTodo = action((state, { todoId, todo }) => {
  const { todos } = state.todoList;

  if (todos.map[todoId]) {
    todos.completed = todos.completed.filter((id) => id !== todoId);
    todos.active.push(todoId);
    merge(todos.map[todoId], todo);
  }

  //if (!todos.completed.length)
  state.sidebarLists = moveSidebarListToActive(state.todoList, state.sidebarLists, todo);
});
