import { action } from 'easy-peasy';

import { deleteTodosFromList } from '../helpers/deleteTodosFromList';
import { setTodosToList } from '../helpers/setTodosToList';

export const filterTodosBySearch = action((state, { todosBag, search }) => {
  const { todoList, filters } = state;

  state.todoList = setTodosToList({ todoList: deleteTodosFromList(todoList), todosBag });
  filters.search = search;
});
