import { feathers } from '@webfx/core-web';
import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';

const getPayload = (projectId, files) =>
  files.map(({ filePond, description, private: privateStatus }) => ({
    projectId: Number(projectId),
    extension: filePond.fileExtension,
    byteSize: filePond.fileSize,
    type: filePond.fileType,
    name: filePond.filename,
    uploadId: filePond.serverId,
    description,
    private: privateStatus,
  }));

export const onUploadFiles = thunk(
  async ({ clearFilesState }, { values, projectId, setSubmitting }, { getStoreActions }) => {
    const { common } = getStoreActions();
    const userId = store.auth.user().userId;
    const payload = getPayload(projectId, values.files);

    const promises = payload.map((file) => {
      if (!file.uploadId) {
        return;
      }

      return feathers()
        .service('tw/attachments')
        .patch(file.uploadId, {
          ...file,
          creatorUserId: userId,
        });
    });

    try {
      await Promise.all(promises);

      // Clear storage for getting all files again
      clearFilesState();
      common.showLoader({ key: 'showAllFilesLoader' });
    } catch (e) {
      common.showError(e);
      setSubmitting(false);
    }
  }
);
