import { action } from 'easy-peasy';
import { getListIndex } from '../helpers/list';

export const deleteTeamworkFxTemplateList = action((state, listId) => {
  const listIndex = getListIndex(state.lists.list, listId);

  if (listIndex === -1) {
    return;
  }

  const list = state.lists.map[listId];
  list.todos.forEach((id) => delete state.todoItems[id]);

  state.lists.list.splice(listIndex, 1);
  delete state.lists.map[listId];
});
