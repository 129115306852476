import { thunk } from 'easy-peasy';
import api from '../../../services/api';

export const onSetPastebinTemplateToEditor = thunk(
  async (_, { value, group, reactQuillRef }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const editor = reactQuillRef.current.getEditor();
    const emptyValue = !!value;

    if (!emptyValue) {
      editor.setText('');
      return;
    }

    try {
      const template = await api.pastebinTemplates.get({
        templateId: value,
        group,
      });
      editor.setText('');
      editor.clipboard.dangerouslyPasteHTML(0, template.replace(/\n/g, '<br />'));
    } catch (e) {
      showError(e);
    }
  }
);
