import isInternalCompany from './isInternalCompany';

const updateCompany = (companyId, subscribers) =>
  subscribers.map((company) => {
    const companySubscribed = company.subscribed;

    if (company.companyId === companyId) {
      return {
        ...company,
        subscribed: !companySubscribed,
        users: company.users.map((user) => {
          return {
            ...user,
            subscribed: !companySubscribed,
          };
        }),
      };
    }

    return company;
  });

const updateUser = (companyId, userId, subscribers) =>
  subscribers.map((company) => {
    const users = company.users.map((user) => {
      if (user.userId === userId) {
        return {
          ...user,
          subscribed: !user.subscribed,
        };
      }

      return user;
    });

    return {
      ...company,
      subscribed: users.every(({ subscribed }) => subscribed),
      users,
    };
  });

const generateSubscribersList = (subscribers) => {
  return subscribers.map(({ companyId, name: companyName, users }) => {
    let companySubscribed = false;
    let subscribedUsersCount = 0;

    const usersItems = users.map(({ userId, firstName, lastName, subscribed }) => {
      if (subscribed) {
        subscribedUsersCount += 1;
      }

      return {
        userId,
        name: `${firstName} ${lastName}`,
        subscribed: !!subscribed,
      };
    });

    if (subscribedUsersCount === users.length) {
      companySubscribed = true;
    }

    return {
      companyId,
      name: companyName,
      subscribed: companySubscribed,
      users: usersItems,
    };
  });
};

// sort subscribers (companies)
export const sortSubscribers = (subscribers) => {
  // sort in alphabetical order
  // sort by name
  subscribers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return subscribers;
};

export const moveInternalCompanyToEnd = (subscribers) => {
  const index = subscribers.findIndex(({ name }) => isInternalCompany(name));

  if (index !== -1) {
    subscribers.push(subscribers.splice(index, 1)[0]);
  }

  return subscribers;
};

export const updateValue = (companyId, userId, subscribers) => {
  let data = null;

  if (companyId && userId) {
    data = updateUser(companyId, userId, subscribers);
  } else if (companyId) {
    data = updateCompany(companyId, subscribers);
  }

  if (data) {
    return data;
  }
};

export const getSubscribers = (subscribers) => {
  if (!subscribers) {
    return [];
  }

  const processingFunctions = [generateSubscribersList, sortSubscribers, moveInternalCompanyToEnd];
  return processingFunctions.reduce((acc, func) => func(acc), subscribers);
};

// takes an initial list of subscribers and a list of changes and creates a merged list
export const applySubscriberChanges = (subscribers = [], changes = []) => {
  // convert changes to a map for quicker lookups
  const changesMap = new Map();
  changes.forEach((change) => {
    const changeKey = change.userId ? `${change.companyId}-${change.userId}` : change.companyId;
    changesMap.set(changeKey, change.selected);
  });

  return subscribers.map((subscriberCompany) => {
    const companyChangeKey = subscriberCompany.companyId;
    const companySelected = changesMap.get(companyChangeKey) ?? subscriberCompany.subscribed;
    const newUsers = subscriberCompany.users.map((subscriberUser) => {
      const userChangeKey = `${subscriberCompany.companyId}-${subscriberUser.userId}`;
      const userSelected = changesMap.get(userChangeKey) ?? subscriberUser.subscribed;
      return {
        ...subscriberUser,
        subscribed: userSelected || companySelected,
      };
    });
    return {
      ...subscriberCompany,
      subscribed: companySelected,
      users: newUsers,
    };
  });
};

export const dedupeSubscribersByUser = (subscribers) => {
  if (!subscribers) {
    return [];
  }

  const seenUserIds = {};

  return subscribers.filter((subscriber) => {
    const cacheId = subscriber.userId;
    const hasSeen = cacheId in seenUserIds;
    seenUserIds[cacheId] = 1;
    return !hasSeen;
  });
};

// get array of subscribed users ids
export const getSubscribePeopleIds = (subscribers) => {
  if (!subscribers) {
    return [];
  }

  const ids = [];

  subscribers.forEach(({ users }) =>
    users.forEach(({ userId, subscribed }) => {
      if (subscribed) {
        ids.push(userId);
      }
    })
  );

  return ids;
};
