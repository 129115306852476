import { action } from 'easy-peasy';

import { moveSidebarListToActive } from '../helpers/moveSidebarListToActive';
import { moveSidebarListToCompleted } from '../helpers/moveSidebarListToCompleted';

export const moveSidebarList = action((state, { todo, toCompleted }) => {
  state.sidebarLists = toCompleted
    ? moveSidebarListToCompleted(state.todoList, state.sidebarLists, todo)
    : moveSidebarListToActive(state.todoList, state.sidebarLists, todo);
});
