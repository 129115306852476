//import { toBool } from '../../../../../../utils/toBool';

export const moveSidebarListToActive = (todolist, sidebarLists, todo) => {
  const activeCount = todolist.todos?.active?.length || 0;
  const completedCount = todolist.todos?.completed?.length || 0;

  if (!activeCount && completedCount) {
    return sidebarLists;
  }

  const { active, completed } = sidebarLists;
  const { todolistId } = todo;
  const completedTodoList = completed.find(
    (completedList) => completedList.todolistId === todolistId
  );

  if (completedTodoList) {
    return {
      active: [completedTodoList, ...active],
      completed: completed.filter((completedList) => completedList.todolistId !== todolistId),
    };
  }
  return sidebarLists;
};
