import { thunkOn } from 'easy-peasy';
export const todoListsTemplatesStore = {
  lists: {
    list: [],
    map: {},
  },
  todoItems: {},
  categories: [],
  sidebar: {
    filters: {
      search: '',
    },
    categories: {
      showAll: true,
      list: [],
      map: {},
    },
    isFiltered: false,
  },

  updateTodoListState: thunkOn(
    (actions) => [
      actions.updateTeamworkFxTemplateTodo,
      actions.deleteTeamworkFxTemplateTodo,
      actions.addTeamworkFxTemplateMultipleTodo,
      actions.addTeamworkFxTemplateTodo,
    ],
    (actions, target) => {
      const { todoListTemplate } = Array.isArray(target.payload)
        ? target.payload[0]
        : target.payload;
      actions.updateTeamworkFxTemplateTodoList(todoListTemplate);
    }
  ),
};
