import { action } from 'easy-peasy';
import initStore from '../initStore';

export const clearTemplatePageState = action((state) => {
  const { lists, todoItems, sidebarLists, listTemplates, filters } = initStore;

  state.lists = lists;
  state.todoItems = todoItems;
  state.sidebarLists = sidebarLists;
  state.listTemplates = listTemplates;
  state.filters = filters;
});
