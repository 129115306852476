import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';
import { getContentAsHtml } from '../../../../../../../utils-deprecated/rte/utils';
import { getSubscribeUsers } from '../../../../../../../utils-deprecated/subscribers';

export const onEditComment = thunk(
  async (
    { editComment },
    { values, setSubmitting, hideEditForm, reactQuillRef },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const currentUserId = store.auth.user()?.userId;

    const body = getContentAsHtml(reactQuillRef);
    const subscribers = getSubscribeUsers(values.subscribers, body);

    try {
      const comment = await api.comments.patch(
        values.commentId,
        {
          ...values,
          body,
          subscribers,
          files: values.files.map((file) => ({
            commentId: values.commentId,
            extension: file.extension,
            byteSize: file.byteSize,
            type: file.type,
            name: file.name,
            uploadId: file.uploadId,
          })),
        },
        { for: 'usersAndFiles' }
      );

      editComment({ comment, currentUserId });
      hideEditForm();
    } catch (e) {
      showError(e);
      setSubmitting(false);
    }
  }
);
