import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const commentTemplates = feathersClient.service('tw/comment-templates');

/**
 *
 */
export const find = () => commentTemplates.find();
/**
 *
 * @param root0
 * @param root0.templateId
 * @param root0.group
 */
export const get = ({ templateId, group }) =>
  commentTemplates.get(templateId, paramsForServer({ group }));
