import get from 'lodash/get';

export const transformTodo = (todo) => {
  const { user, company } = todo;
  return {
    ...todo,
    assignee: {
      avatarUrl: get(user, 'avatarUrl', '') || get(company, 'logoUrl', ''),
      name:
        user && user.firstName !== null
          ? `${user.firstName} ${user.lastName}`
          : get(company, 'name', ''),
    },
    movedTo: null,
  };
};
