import { action } from 'easy-peasy';
import { removeMilestoneHelper } from '../helpers/removeMilestoneHelper';

const updateDueMilestone = (weeks, payload, { deletedWeek, deletedDay, deletedMilestone }) => {
  if (payload.weekDue === deletedWeek && payload.dowDue === deletedDay) {
    weeks.map[deletedWeek].days[deletedDay].map[deletedMilestone] = payload;
    return;
  }

  if (weeks.map[payload.weekDue].days.list.includes(payload.dowDue)) {
    const day = weeks.map[payload.weekDue].days[payload.dowDue];

    day.map[payload.projectTemplateMilestoneId] = payload;
    day.list.push(payload.projectTemplateMilestoneId);
  } else {
    const { list } = weeks.map[payload.weekDue].days;
    const newDayIndex = list.findIndex((item) => item > payload.dowDue);

    list.splice(newDayIndex === -1 ? list.length : newDayIndex, 0, payload.dowDue);

    weeks.map[payload.weekDue].days[payload.dowDue] = {
      list: [payload.projectTemplateMilestoneId],
      map: {
        [payload.projectTemplateMilestoneId]: payload,
      },
    };
  }

  removeMilestoneHelper({ weeks, deletedWeek, deletedDay, deletedMilestone });
};

export const updateMilestoneTemplate = action((state, payload) => {
  let deletedWeek = null;
  let deletedDay = null;
  let deletedMilestone = null;
  const { weeks } = state.milestones;

  weeks.list.forEach((week) => {
    const { days } = weeks.map[week];
    const day = days.list.find((d) => days[d].list.includes(payload.projectTemplateMilestoneId));

    if (day === undefined) {
      return;
    }

    deletedWeek = week;
    deletedDay = day;
    deletedMilestone = payload.projectTemplateMilestoneId;
  });

  if (state.milestones.weeks.list.includes(payload.weekDue)) {
    return updateDueMilestone(weeks, payload, {
      deletedWeek,
      deletedDay,
      deletedMilestone,
    });
  }

  const newWeekIndex = weeks.list.findIndex((item) => item > payload.weekDue);

  weeks.list.splice(newWeekIndex === -1 ? weeks.list.length : newWeekIndex, 0, payload.weekDue);

  const days = {
    days: {
      list: [payload.dowDue],

      [payload.dowDue]: {
        list: [payload.projectTemplateMilestoneId],
        map: {
          [payload.projectTemplateMilestoneId]: payload,
        },
      },
    },
  };

  weeks.map[payload.weekDue] = days;

  removeMilestoneHelper({ weeks, deletedWeek, deletedDay, deletedMilestone });
});
