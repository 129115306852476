import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onCreateProjectTemplate = thunk(
  async (
    _,
    { name, templateCategories, internal, setSubmitting, push, resetForm, onClosePopup },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const categoryIds = templateCategories.map((template) => template.value);

    try {
      const project = await api.projectTemplates.create({ name, categoryIds, internal });
      push(`/templates/projects/${project.projectTemplateId}/todo-lists`);

      resetForm();
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
