import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

export const wsReorderTodoLists = action((state, { todoLists }) => {
  const map = todoLists.reduce(
    (acc, todoList) => ({ ...acc, [todoList.todolistId]: todoList }),
    {}
  );

  const updatedPositions = state.sidebarLists.active.map((todoList) => {
    const _todoList = map[todoList.todolistId];
    return _todoList ? { ...todoList, position: _todoList.position } : todoList;
  });

  state.sidebarLists.active = sortBy(updatedPositions, ['position']);
});
