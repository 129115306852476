import { action } from 'easy-peasy';

import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';

export const updateOverdueMilestone = action((state, updatedItem) => {
  const isMilestoneInState = state.milestones.overdue.some(
    ({ milestoneId }) => milestoneId === updatedItem.milestoneId
  );

  if (isMilestoneInState) {
    const searchedItem = state.milestones.overdue.find(
      (milestone) => milestone.milestoneId === updatedItem.milestoneId
    );
    Object.assign(searchedItem, { responsibleCompany: null, responsibleUser: null }, updatedItem);
    state.milestones.overdue = sortOverdueMilestones(state.milestones.overdue);
  }

  if (!isMilestoneInState && !updatedItem.completedAt) {
    state.milestones.overdue.push(updatedItem);
    state.milestones.overdue = sortOverdueMilestones(state.milestones.overdue);
    state.milestones.overdueCount += 1;
  }
});
