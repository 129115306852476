import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';

const StatusFilters = ({ setFilter, filters }) => {
  const [statuses, setStatuses] = useState(filters?.completed ?? []);

  const setStatusFilters = (type) => {
    const index = statuses.indexOf(type);
    if (index !== -1) {
      statuses.splice(index, 1);
    } else {
      statuses.push(type);
    }
    setStatuses([...statuses]);
  };

  return (
    <>
      <div className="d-flex flex-column mt-3">
        <span className="font-14 bold mb-1">By Status</span>
        <div className="d-flex flex-column border rounded p-2">
          <Form.Check
            type="checkbox"
            id="open"
            name="open"
            label="Open Items"
            checked={isEmpty(statuses) || statuses.includes('open')}
            onClick={() => {
              setStatusFilters('open');
              setFilter({ completed: statuses });
            }}
            onChange={() => {}}
            className="font-14 text-dark-gray"
            custom
            data-fx-name="openItems"
          />

          <Form.Check
            type="checkbox"
            id="completed"
            name="completed"
            label="Completed Items"
            checked={isEmpty(statuses) || statuses.includes('completed')}
            onClick={() => {
              setStatusFilters('completed');
              setFilter({ completed: statuses });
            }}
            onChange={() => {}}
            className="font-14 text-dark-gray"
            custom
            data-fx-name="completedItems"
          />
        </div>
      </div>
    </>
  );
};

export default StatusFilters;
