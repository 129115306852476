import initStore from './initStore';
import archivedProjectsActions from './actions';
import archivedProjectsThunks from './thunks';

const archivedProjects = {
  ...initStore,

  // actions
  ...archivedProjectsActions,

  // thunks
  ...archivedProjectsThunks,
};

export default archivedProjects;
