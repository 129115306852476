import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { getResponsibleInfo } from './utils';

export const onUpdateTodo = thunk(
  async ({ updateTodo }, { values, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      const updatedTodo = await api.todos.patch(todoId, getResponsibleInfo(values), {
        for: 'onEditTodo',
      });
      updateTodo(updatedTodo);
    } catch (e) {
      showError(e);
    }
  }
);
