import { thunk } from 'easy-peasy';
//import api from '../../../../../services/api';
import { feathers } from '@webfx/core-web';

export const onDeleteProject = thunk(
  async (_, { projectId, projectName, push }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showNotification, showError } = actions.common;
    const { onMountTeamworkFxDashboardPage } = actions.teamworkFx.dashboard;
    const { onMountTeamworkFx } = actions.teamworkFx;
    const loader = 'project.settings.deleteProject';

    showLoader({ key: loader });

    try {
      await feathers().service('tw/projects').remove(projectId);
      showNotification({
        message: `The ${projectName} project has been deleted. You can recover this project by going to the recently deleted projects page.`,
      });
      await onMountTeamworkFx({ doNotShowLoaders: true });
      onMountTeamworkFxDashboardPage({ pushParams: { push } });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
