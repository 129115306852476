import { action } from 'easy-peasy';

import { setTodosToLists } from '../helpers/setTodosToLists';
import { toggleTodoLists } from '../helpers/toggleTodoLists';

export const toggleSidebarTodoList = action((state, { todosBag, todolistId, checked }) => {
  const todoLists = setTodosToLists({
    todoLists: state.todoLists,
    todosBag,
    todolistId,
  });

  toggleTodoLists({ todoLists, todolistId, checked });

  state.todoLists = todoLists;
});
