import { onUpdateProject } from './thunks/onUpdateProject';
import { onDeleteProject } from './thunks/onDeleteProject';

const settings = {
  // thunks
  onUpdateProject,
  onDeleteProject,
};

export default settings;
