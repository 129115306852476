import { store } from '@webfx/web-state';
import get from 'lodash/get';
import api from '../../../../../services/api';
import { allPeopleAndCompaniesLimit } from '../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxAllPeoplePage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxAllPeoplePage',
  pathname: '/client-permissions',
  action: async (actions, _, getStoreState) => {
    const state = getStoreState();
    const user = store.auth.user();
    const { skipCount } = state.ui.allPeople;
    const companyId = get(user, ['company', 'companyId'], null);
    const { setUserCompany, setCompanies } = actions.ui.allPeople;

    const [userCompany, companies] = await Promise.all([
      api.companies.get(companyId),
      api.companies.find({
        query: {
          $sort: { name: 1 },
          companyId: { $ne: companyId },
          $skip: skipCount,
          $limit: allPeopleAndCompaniesLimit,
        },
        for: 'allPeople',
      }),
    ]);
    setUserCompany(userCompany);
    setCompanies(companies);
  },
});
