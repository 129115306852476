import { Icon } from '@webfx/core-web';
import ActivityItem from '../ActivityItem';

const ActivitySection = ({ type, title, icon, items, iconOutlined }) => {
  let data = items;
  if (type === 'actionItems' && items?.length > 0) {
    // Limit action items to 8 on the UI
    data = items.slice(0, 8);
  }

  return (
    <div className="d-flex flex-column" data-fx-name="column">
      <div className="d-flex align-items-center mb-2">
        <Icon className="text-blue-vivid-200 font-16" outlined={iconOutlined}>
          {icon}
        </Icon>
        <p className="m-0 ml-1 text-gray-700 bold" data-fx-name="label">
          {title}
        </p>
      </div>
      {!items || items.length === 0 ? (
        <span className="font-12 text-gray-600">
          No {type === 'actionItems' ? 'action items' : type} available.
        </span>
      ) : (
        <>
          {(data ?? []).map((item) => (
            <div key={item.messageId ?? item.callId ?? item.historyId} className="d-flex">
              <ActivityItem item={item} sectionType={type} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ActivitySection;
