import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
// TODO: there is 3 of these move todo files that do the exact same thing.

/* eslint-disable prefer-destructuring */
export const onMountMoveTodo = thunk(async (_, { projectIdIsNot }, { getStoreActions }) => {
  const actions = getStoreActions();
  const showError = actions.common.showError;
  const showLoader = actions.common.showLoader;
  const hideLoader = actions.common.hideLoader;
  const setMoveTodoProjects = actions.project.todos.singleTodoList.setMoveTodoProjects;

  const loader = 'project.todos.singleTodoList.moveTodoForm.getProjects';
  showLoader({ key: loader });

  const query = {
    status: 'active',
    projectId: { $ne: projectIdIsNot },
    $select: ['projectId', 'name', 'encrypted'],
    $limit: -1,
  };

  try {
    const projects = await api.projects.find({
      for: 'todosMoveProjectList',
      query,
    });
    setMoveTodoProjects({ projects });
  } catch (error) {
    showError(error);
  }

  hideLoader({ key: loader });
});
