import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { moveSidebarListToCompleted } from '../helpers/moveSidebarListToCompleted';

export const completeTodo = action((state, { todoId, todo }) => {
  const { todos } = state.todoList;

  if (todos.map[todoId]) {
    todos.active = todos.active.filter((id) => id !== todoId);
    todos.completed.unshift(todoId);
    merge(todos.map[todoId], todo);
  }

  //if (!todos.active.length)
  state.sidebarLists = moveSidebarListToCompleted(state.todoList, state.sidebarLists, todo);
});
