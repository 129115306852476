import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const todoLists = feathersClient.service('tw/todo-lists-deprecated');

/**
 *
 * @param params
 */
export const find = (params) => todoLists.find(paramsForServer(params));
/**
 *
 * @param todolistId
 * @param params
 */
export const get = (todolistId, params) => todoLists.get(todolistId, paramsForServer(params));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => todoLists.create(payload, paramsForServer(params));
/**
 *
 * @param todolistId
 * @param payload
 * @param params
 */
export const patch = (todolistId, payload, params) =>
  todoLists.patch(todolistId, payload, paramsForServer(params));
/**
 *
 * @param todolistId
 * @param params
 */
export const remove = (todolistId, params) => todoLists.remove(todolistId, paramsForServer(params));
