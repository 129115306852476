import { thunk } from 'easy-peasy';
import { paramsForServer } from 'feathers-hooks-common';
import { feathers } from '@webfx/core-web';
import { companiesProjectsLimit } from '../../../../../ui/constants/limits';

/* eslint-disable prefer-destructuring -- not needed */
export const onLoadMoreCompaniesProjects = thunk(
  async ({ setCompaniesProjects }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;

    const state = getStoreState();
    const { $skipSidebarProjects, paginationProjectsType } = state.ui.archivedProjects;

    const loader = 'archivedProjects.getCompaniesProjects';
    showLoader({ key: loader });
    try {
      const companiesProjects = await feathers()
        .service('tw/sidebar')
        .find(
          paramsForServer({
            query: {
              $cache: false,
              $skip: $skipSidebarProjects,
              $limit: companiesProjectsLimit,
              status: paginationProjectsType,
            },
            for: 'projectsSidebar',
          })
        );
      setCompaniesProjects({ companiesProjects, type: paginationProjectsType });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
/* eslint-enable prefer-destructuring -- re-enable previously disabled */
