import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

// TODO: move to refactored context project store

export const onGetAssignee = thunk(async (actions, { projectId }, { getStoreActions }) => {
  const { common } = getStoreActions();
  common.showLoader({ key: 'getAssignee' });
  try {
    const res = await api.projects.get({
      projectId,
      params: { for: 'assigneesDropdown' },
    });
    actions.setAssignee(res);
  } catch (e) {
    common.showError(e);
  }
  common.hideLoader({ key: 'getAssignee' });
});
