export const deleteFromCompany = ({ state, companyId, projectId }) => {
  if (projectId in state.projects) {
    return state;
  }

  const { projects } = state.companies.map[companyId];

  if (projects.length === 1) {
    delete state.companies.map[companyId];
    const companyIndex = state.companies.list.indexOf(companyId);
    state.companies.list.splice(companyIndex, 1);
    return state;
  }

  const projectIndex = projects.indexOf(projectId);
  projects.splice(projectIndex, 1);
  return state;
};

export const deleteFromProject = ({ state, projectId, todolistId }) => {
  if (todolistId in state.todolists) {
    return state;
  }

  const { todolists } = state.projects[projectId];

  if (todolists.length === 1) {
    delete state.projects[projectId];
    return state;
  }

  const listIndex = todolists.indexOf(todolistId);
  todolists.splice(listIndex, 1);
  return state;
};

export const deleteFromTodoList = ({ state, todolistId, todoId }) => {
  const { todoItems } = state.todolists[todolistId];

  if (todoItems.length === 1) {
    delete state.todolists[todolistId];
    return state;
  }

  const itemIndex = todoItems.indexOf(todoId);
  todoItems.splice(itemIndex, 1);
  return state;
};

export const deleteItem = ({ state, todoId }) => {
  delete state.todoItems[todoId];
  state.scrollParams.skipCount -= 1;

  return state;
};
