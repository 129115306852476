import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onUnsubscribeCurrentUser = thunk(
  async ({ unsubscribeCurrentUser }, { userSubscribeId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      await api.subscribers.remove(userSubscribeId);
      unsubscribeCurrentUser({ userSubscribeId });
    } catch (e) {
      showError(e);
    }
  }
);
