import { useState, useEffect } from 'react';

import {
  getLastDayOfTheMonthByDate,
  getCurrent,
  getQuarters,
  getKanbanMonths,
} from '@webfx/web-ui/src/QuarterSelector/utils';

import { useFlags } from '@webfx/web-hooks';

import { useGamePlanKanbanView } from '@webfx/gameplan-ui';

import PlayBookFXCTABanner from '@webfx/teamwork-web/src/ui/components/PlayBookFXCTABanner';
import useQuarterSetting from '@webfx/permissions-web/src/hooks/useQuarterSetting';

import useOptimizationsHidden from '../../hooks/useOptimizationsHidden';
import useCompaniesOptimizationsHidden from '../../hooks/useCompaniesOptimizationsHidden';
import useFilterOptimizationsByCompany from '../../hooks/useFilterOptimizationsByCompany';
import useProjectsOptimizations from '../../hooks/useProjectsOptimizations';
import useKanbanFilters from '../../hooks/useKanbanFilters';

import Header from './Header';
import CalendarColumn from './CalendarColumn';

const OptimizationKanban = ({
  companyId,
  forUserProjects = false,
  forPlaybook = false,
  companyUsers,
}) => {
  let projectsOptimizationQuery = {};

  const flags = useFlags();
  const hideOptimizations = useOptimizationsHidden(companyId);
  const companiesHiddenOptimizations = useCompaniesOptimizationsHidden(companyUsers);

  const quarterSetting = useQuarterSetting(companyId);

  const { quarters } = getQuarters(quarterSetting);
  const { data: playbookKanbanMonthViewSetting } = useGamePlanKanbanView(companyId);
  const { kanbanMonthView } = playbookKanbanMonthViewSetting;
  const { quarter: currentQuarter, year: currentYear } = getCurrent(quarters);
  const [quarter, setQuarterMonth] = useState(currentQuarter);
  const [year, setYear] = useState(currentYear);

  if (companyId) {
    projectsOptimizationQuery = { companyId };
  }

  // get user projects optimization query for dashboard page
  if (forUserProjects) {
    projectsOptimizationQuery = {
      $client: {
        forUserProjects: true,
      },
    };
  }

  const {
    filters: kanbanFilters,
    setFilter,
    resetFilters,
  } = useKanbanFilters(projectsOptimizationQuery);

  if (kanbanFilters) {
    //Reassign Project Id, as the default assigned is the previous project
    projectsOptimizationQuery = { ...kanbanFilters };
  }

  // only query for assets for the current company's PlaybookFX action items kanban if the setting to do so is toggled on
  if (hideOptimizations) {
    projectsOptimizationQuery = {
      ...projectsOptimizationQuery,
      type: 'asset',
    };
  }

  useEffect(() => {
    setQuarterMonth(currentQuarter);
    setYear(currentYear);
  }, [quarterSetting]);

  const months = getKanbanMonths({
    quarter,
    monthView: flags.gameplanfxUpdates ? kanbanMonthView : 'quarterly',
  });

  const lastMonthOfTheQuarterDay = getLastDayOfTheMonthByDate(months[months.length - 1]);

  const queries = useProjectsOptimizations({
    query: {
      dueDate: { $gte: months[0], $lte: lastMonthOfTheQuarterDay },
      companyId,
      ...projectsOptimizationQuery,
    },
  });

  const dashboardFilteredQueries = useFilterOptimizationsByCompany(
    queries,
    companiesHiddenOptimizations
  );

  const setQuarter = ({ quarter, year }) => {
    setQuarterMonth(quarter);
    setYear(year);
  };

  return (
    <div className="container-fluid pr-0 pl-0">
      <div>
        <Header
          companyId={companyId}
          setQuarter={setQuarter}
          quarter={quarter}
          year={year}
          filters={kanbanFilters}
          setFilter={setFilter}
          resetFilters={resetFilters}
        />
        <div
          style={{ backgroundColor: '#fcfdff' }}
          className="row ml-0 mr-0 border-top border-bottom border-left border-right"
          data-fx-name="optimizationKanban"
        >
          <div style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
            {months?.map((month, index) => (
              <div key={index} style={{ flex: '1 1 33%', minWidth: '500px' }}>
                <CalendarColumn
                  companyId={companyId}
                  query={forUserProjects ? dashboardFilteredQueries : queries}
                  quarter={quarter}
                  month={month}
                  year={month.slice(0, 4)}
                />
              </div>
            ))}
          </div>
        </div>

        {!forPlaybook && <PlayBookFXCTABanner companyId={companyId} />}
      </div>
    </div>
  );
};

export default OptimizationKanban;
