import initStore from './initStore';
import dashboardActions from './actions';
import dashboardThunks from './thunks';

const dashboard = {
  ...initStore,

  // actions
  ...dashboardActions,

  // thunks
  ...dashboardThunks,
};

export default dashboard;
