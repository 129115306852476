import { action } from 'easy-peasy';

export const deleteTodoList = action((state, { todolistId }) => {
  const { todoLists } = state;
  const { active, completed } = state.todoLists.map[todolistId].todos;

  todoLists.$skip -= active.length + completed.length;
  todoLists.active = todoLists.active.filter((id) => id !== todolistId);
  delete todoLists.map[todolistId];
});
