import { action } from 'easy-peasy';

import { deleteTodosFromList } from '../helpers/deleteTodosFromList';
import { setTodosToList } from '../helpers/setTodosToList';

export const filterTodosByAssignee = action((state, { todosBag, assignee }) => {
  const { todoList, filters } = state;

  state.todoList = setTodosToList({ todoList: deleteTodosFromList(todoList), todosBag });
  filters.assignee = assignee;
});
