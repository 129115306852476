import { thunk } from 'easy-peasy';

import { moveMilestone } from './moveMilestone';
import { updateMilestone } from './updateMilestone';

export const onUpdateMilestone = thunk(
  async (
    _,
    { id, values, closeModal = () => null, resetForm = () => null },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;

    const state = getStoreState();

    // We need to use loader to avoid infinite scroll loadMore call while state is updating
    const loader = 'teamworkFx.dashboard.overdueMilestones.isLoading';
    showLoader({ key: loader });
    try {
      values.projectId
        ? await moveMilestone({ actions, state, id, values })
        : await updateMilestone({ actions, state, form: { id, values } });

      resetForm();
      closeModal();
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
