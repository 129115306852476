import { action } from 'easy-peasy';

import { transformTodo } from '../helpers/transformTodo';
import { sortTodosByPosition } from '../helpers/sortTodosByPosition';

export const createTodo = action((state, { todos, todolistId, colorCodes }) => {
  const todoList = state.todoLists.map[todolistId];
  const { active, map } = state.todoLists.map[todolistId].todos;

  state.todoLists.$skip += todos.length;

  todos.forEach((todo) => {
    const { todoId } = todo;
    active.push(todoId);
    map[todoId] = transformTodo(todo);
  });

  todoList.todos.active = sortTodosByPosition(active, map);

  if (colorCodes) {
    Object.entries(colorCodes.results).forEach(([todoId, value]) => {
      todoList.colorCodes[todoId] = value;
    });
  }
});
