import { thunk } from 'easy-peasy';
import { toBool } from '../../../../../../utils-deprecated/toBool';

/* eslint-disable prefer-destructuring */
export const wsOnToggleTodoCompletedStatus = thunk(
  async (_, { result: todo }, { getStoreActions }) => {
    const actions = getStoreActions();
    const completeTodo = actions.project.todos.todoLists.completeTodo;
    const unCompleteTodo = actions.project.todos.todoLists.unCompleteTodo;

    toBool(todo.completedAt) ? completeTodo({ todo }) : unCompleteTodo({ todo });
  }
);
