import { thunk } from 'easy-peasy';
import { PermissionsAbility } from '@webfx/web-state';
import { subject } from '@casl/ability';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../services/api';

export const onMountCreateTodoListForm = thunk(
  async ({ mountCreateTodoListForm }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const { data: project } = useProject();
    const projectId = project?.projectId;
    const viewPrivate = PermissionsAbility.can(
      'read',
      subject('project', { projectId, private: true })
    );

    if (!viewPrivate) {
      return;
    }

    try {
      const templates = await api.todoListsTemplate.find();
      mountCreateTodoListForm({ templates });
    } catch (e) {
      showError(e);
    }
  }
);
