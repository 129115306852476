import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

const todoListTemplateTodos = feathersClient.service('tw/todolist-template-todos-deprecated');

/**
 *
 * @param payload
 */
export const create = (payload) => todoListTemplateTodos.create(payload);

/**
 *
 * @param id
 * @param body
 * @param params
 */
export const patch = (id, body, params = {}) => todoListTemplateTodos.patch(id, body, params);

/**
 *
 * @param todoId
 */
export const remove = (todoId) => todoListTemplateTodos.remove(todoId);

/**
 *
 * @param payload
 * @param params
 */
export const updateDragAndDrop = (payload, params) => patch(null, payload, paramsForServer(params));
