import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

export const onUnCompleteTodo = thunk(
  async ({ unCompleteTodo }, { todolistId, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = `project.todos.completeTodo.${todoId}`;
    showLoader({ key: loader });

    try {
      api.todos.patch(
        todoId,
        { todolistId },
        { for: 'onToggleTodoCompletedStatus', completed: false }
      );
      unCompleteTodo({ todoId, todo: { todoId, todolistId, completedAt: null } });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
