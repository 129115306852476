import { action } from 'easy-peasy';
import { backendStartPages } from '../../../../../ui/constants/project/settings';
import isInternalCompany from '../../../../../utils-deprecated/isInternalCompany';

export const modifyStartPage = (pages) =>
  pages.map(({ projects, ...rest }) => {
    projects.sort((a, b) => {
      const aname = a.name.toLowerCase();
      const bname = b.name.toLowerCase();
      if (aname > bname) {
        return 1;
      }
      if (aname < bname) {
        return -1;
      }
      return 0;
    });

    const formatedProjects = projects.map(({ startPage, ...restProjectParams }) => ({
      ...restProjectParams,
      startPage: backendStartPages[startPage],
    }));

    return {
      ...rest,
      projects: formatedProjects,
    };
  });

export const getValuesForStoreByStatus = (items) => {
  const valuesForStore = {
    list: [],
    map: {},
    projects: {},
  };

  items.forEach((company) => {
    isInternalCompany(company.name)
      ? valuesForStore.list.unshift(company.companyId)
      : valuesForStore.list.push(company.companyId);
    valuesForStore.map[company.companyId] = { ...company, projects: [] };

    company.projects.forEach((currentProject) => {
      valuesForStore.projects[currentProject.projectId] = currentProject;
      valuesForStore.map[company.companyId].projects.push(currentProject.projectId);
    });
  });

  return valuesForStore;
};

export const setCompaniesProjects = action((state, { companiesProjects, type }) => {
  const { data, hasMore } = companiesProjects;
  const projectsType = type === 'active' ? 'active' : 'onHold';

  const modifiedStartPage = modifyStartPage(data);
  const storeValue = getValuesForStoreByStatus(modifiedStartPage);

  state.sidebar.companiesProjects[projectsType].list = [
    ...state.sidebar.companiesProjects[projectsType].list,
    ...storeValue.list,
  ];
  state.sidebar.companiesProjects[projectsType].map = {
    ...state.sidebar.companiesProjects[projectsType].map,
    ...storeValue.map,
  };

  state.sidebar.companiesProjects.projects = {
    ...state.sidebar.companiesProjects.projects,
    ...storeValue.projects,
  };

  if (state.sidebar.companiesProjects.paginationProjectsType === 'active' && !hasMore) {
    state.sidebar.companiesProjects.paginationProjectsType = 'on hold';
    state.sidebar.companiesProjects.$skip = 0;
    state.sidebar.companiesProjects.hasMoreData = true;
    return;
  }

  state.sidebar.companiesProjects.hasMoreData = hasMore;
  state.sidebar.companiesProjects.$skip += data.length;
});
