import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const messages = feathersClient.service('tw/messages');

/**
 *
 * @param messageId
 * @param params
 */
export const get = (messageId, params) => messages.get(messageId, paramsForServer(params));
/**
 *
 * @param message
 * @param params
 */
export const create = (message, params) => messages.create(message, paramsForServer(params));
/**
 *
 * @param root0
 * @param root0.params
 */
export const find = ({ params }) => messages.find(paramsForServer(params));
/**
 *
 * @param messageId
 * @param message
 * @param params
 */
export const patch = (messageId, message, params) =>
  messages.patch(messageId, message, paramsForServer(params));
/**
 *
 * @param messageId
 */
export const remove = (messageId) => messages.remove(messageId);
