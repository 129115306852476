import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const milestones = feathersClient.service('tw/milestones-deprecated');

/**
 *
 * @param payload
 */
export const find = (payload) => milestones.find(paramsForServer(payload));
/**
 *
 * @param root0
 * @param root0.id
 * @param root0.params
 */
export const get = ({ id, params }) => milestones.get(id, paramsForServer(params));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => milestones.create(payload, paramsForServer(params));
/**
 *
 * @param milestoneId
 */
export const remove = (milestoneId) => milestones.remove(milestoneId);
/**
 *
 * @param id
 * @param body
 * @param params
 */
export const update = (id, body, params) => milestones.patch(id, body, paramsForServer(params));
/**
 *
 * @param id
 * @param params
 */
export const updateCompleteSingleMilestone = (id, params) =>
  milestones.patch(id, {}, paramsForServer(params));
