import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onUpdateProjectTemplate = thunk(
  async (
    { updateTemplateProjectsItems },
    {
      name,
      templateCategories,
      internal,
      setSubmitting,
      resetForm,
      onClosePopup,
      projectTemplateId,
      updaterUserId,
    },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const categoryIds = templateCategories.map((template) => template.value);

    try {
      const result = await api.projectTemplates.patch(
        projectTemplateId,
        { name, categoryIds, internal, updaterUserId },
        {
          query: {
            $join: ['updater', 'lastDeployedDate', 'timesDeployedLast12Months'],
          },
        }
      );
      updateTemplateProjectsItems(result);
      resetForm();
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
