import { DateTime } from 'luxon';
import classnames from 'classnames';
import { useRouter } from '@webfx/web-hooks';
import CalendarIcon from '@webfx/marketingcloud-web/src/assets/svgs/reports/calendar-icon.svg';
import { Link, Svg } from '@webfx/core-web';

const ReportCard = ({ report }) => {
  const { url } = useRouter();

  return (
    <Link
      to={url({
        route: 'mcfxAnalyticsReports',
        siteId: report.siteId,
        reportId: report.reportId,
      })}
      className="d-flex col-md-4 p-0"
    >
      <Svg src={CalendarIcon} style={{ width: 60 }} />

      <div
        className={classnames('d-flex flex-column ml-2', {
          'justify-content-center': !!report.service,
        })}
      >
        <p className="bold m-0 text-gray-550 font-12 uppercase">{report.service}</p>
        <div className="d-flex flex-column">
          <p className="bold text-gray-700 m-0" data-fx-name="reportName">
            {report.name}
          </p>
          <p className="m-0 text-gray-550 semibold font-12">
            {DateTime.fromISO(report?.createdAt).toFormat('yyyy/MM/dd')}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
