import { api } from '@webfx/core-web';

function useCompanySites(companyId) {
  return api.useQuery(['companies', Number(companyId), { $join: ['sites', 'qbo'] }], {
    enabled: !!companyId,
    staleTime: 10 * 60 * 1000,
    cacheTime: 15 * 60 * 1000,
  });
}

export default useCompanySites;
