import { action } from 'easy-peasy';

export const giveAccessYourCompany = action((state, payload) => {
  if (payload.userId) {
    state.yourCompany.users[payload.userId] = {
      ...state.yourCompany.users[payload.userId],
      projectUserId: payload.projectsAccess[0].projectUserId,
      hasAccess: !!payload.projectsAccess.length,
    };
  }
  if (Array.isArray(payload) && payload.length) {
    payload.forEach((user) => {
      state.yourCompany.users[user.userId] = {
        ...state.yourCompany.users[user.userId],
        projectUserId: user.projectsAccess[0].projectUserId,
        hasAccess: !!user.projectsAccess.length,
      };
    });
  }
});
