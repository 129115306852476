import { DateTime } from 'luxon';

const checkChangesDate = (currentDate, prevDate) => {
  const firstDate = DateTime.fromISO(currentDate);
  const secondDate = DateTime.fromISO(prevDate);

  return !DateTime.fromISO(firstDate).hasSame(secondDate, 'day');
};

export default checkChangesDate;
