import { action } from 'easy-peasy';

import { moveTodoListToActive } from '../helpers/moveTodoListToActive';

export const deleteCompletedTodo = action((state, { todo, todo: { todolistId, todoId } }) => {
  const { todos } = state.todoLists.map[todolistId];

  if (todos.map[todoId]) {
    state.todoLists.$skip -= 1;
    todos.completed = todos.completed.filter((id) => id !== todoId);
    delete todos.map[todoId];
  }

  moveTodoListToActive(state.todoLists, todo);
});
