import { action } from 'easy-peasy';
import getProjectSearchParams from '../helpers/getProjectSearchParams';

export const setSearchItems = action(
  (
    state,
    {
      results: {
        body: { hits },
        statusCode,
      },
      loadMore = false,
    }
  ) => {
    if (statusCode !== 200) {
      return;
    }

    const searchedParams = getProjectSearchParams({
      state,
      searchedValue: hits,
      clearItems: !loadMore,
    });

    state.items.total = hits.total.value;
    state.items.list = searchedParams.items.list;
    state.items.map = searchedParams.items.map;
    state.scrollParams.skipCount = searchedParams.scrollParams.skipCount;
    state.scrollParams.hasMoreData = searchedParams.scrollParams.hasMoreData;

    return;
  }
);
