import { action } from 'easy-peasy';

export const moveTodo = action(
  (state, { todoId, source: { todolistId }, movedTodo: { todoList } }) => {
    const todoLists = state.todoLists;

    todoLists.$skip -= 1;

    todoLists.map[todolistId].todos.map[todoId].movedTo = {
      project: {
        newProjectId: todoList.project.projectId,
        newProjectName: todoList.project.name,
      },
      todoList: {
        newTodolistId: todoList.todolistId,
        newTodoListName: todoList.name,
      },
    };
  }
);
