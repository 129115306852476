import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

import reorderTodoListHelper from '../../todoLists/helpers/reorderTodoListHelper';

export const reorderTodo = action((state, { todoId, position }) => {
  const { todos, todolistId } = state.todoList;

  reorderTodoListHelper(todos, todoId, todolistId, todolistId, position);
  const todosMap = todos.active.map((t) => todos.map[t]);
  todos.active = sortBy(todosMap, ['position']).map(({ todoId }) => todoId);
});
