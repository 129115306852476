import api from '../../../../../services/api';
import { deletedDataLimit } from '../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onGetDeletions = fetchWithLoaderAndRedirect({
  loaderKey: 'GetDeletions',
  useGlobalLoader: false,
  action: async (actions, { projectId, skip = 0 }, getStoreState) => {
    const state = getStoreState();
    const limit = state.project.deletions.limit || deletedDataLimit;
    const { setDeletionsData } = actions.project.deletions;

    const res = await api.deletions.find({
      query: {
        projectId,
        $limit: limit,
        $skip: skip,
      },
    });
    setDeletionsData(res);
  },
});
