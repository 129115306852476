import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

export const onMountEditCreateTemplateTodoListForm = thunk(
  async ({ mountEditCreateTemplateTodoListForm }, _, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const { projectTemplateId } = state.projectTemplate.selectedProjectTemplate;

    try {
      const [todoListTemplates, milestoneTemplates] = await Promise.all([
        api.todoListsTemplate.find({
          // for: 'todoListTemplateTodos',
        }),
        api.projectTemplateMilestones.find({
          query: {
            projectTemplateId,
            $sort: { title: 1 },
            $select: ['title', 'projectTemplateMilestoneId'],
          },
        }),
      ]);
      mountEditCreateTemplateTodoListForm({ todoListTemplates, milestoneTemplates });
    } catch (e) {
      showError(e);
    }
  }
);
