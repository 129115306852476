import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const todoListsTemplate = feathersClient.service('tw/todolist-templates');

export const find = (payload) => todoListsTemplate.find(paramsForServer(payload));
export const create = (payload) => todoListsTemplate.create(payload);
export const patch = (id, body, params) => todoListsTemplate.patch(id, body, params);
export const remove = (id) => todoListsTemplate.remove(id);
