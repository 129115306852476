import _messages from './messages';
import message from './message';
import messageForm from './messageForm';

const messages = {
  messages: _messages,
  message,
  messageForm,
};

export default messages;