import { transformTodo } from './transformTodo';

const setTodoToList = ({ todos }, todo) => {
  const { todoId, completedAt } = todo;
  completedAt ? todos.completed.push(todoId) : todos.active.push(todoId);
  todos.map[todoId] = transformTodo(todo);
};

export const setTodosToList = ({ todoList, todosBag }) => {
  const todos = todosBag.data || todosBag;
  const hasMore = todosBag.hasMore || false;
  todos.forEach((todo) => {
    setTodoToList(todoList, todo);
  });

  return {
    ...todoList,
    $skip: todoList.$skip + todos.length,
    hasMore,
  };
};
