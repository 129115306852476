import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onSendProjectPasswordInstruction = thunk(async (_, email, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showNotification, showError } = actions.common;
  try {
    await api.resetPassword.create({ email });
    showNotification({ message: 'Instructions sent to email' });
  } catch (error) {
    showError(error);
  }
});
