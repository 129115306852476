// eslint-disable-next-line import/no-unresolved
import { store } from '@webfx/core-web';

import api from '../../../../../../services/api';
import { templateProjectPaginationLimit } from '../../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxProjectTemplate = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxProjectTemplate',
  pathname: store.getState().router.route('projectsTemplate'),
  action: async (actions, _, getStoreState) => {
    const {
      setTemplateProjectSidebarCategories,
      setTemplateProjectsItemsToStore,
      setProjectTemplateCategories,
    } = actions.teamworkFx.templates.projects;

    const state = getStoreState();
    const { map, showAll } = state.teamworkFx.templates.projects.sidebar;
    const selectedCategories = Object.keys(map).filter((id) => map[id].checked);
    const $skip = 0;
    const $limit = templateProjectPaginationLimit;
    const $join = ['updater', 'lastDeployedDate', 'timesDeployedLast12Months'];

    const [categories, projectTemplates] = await Promise.all([
      api.categories.find({ query: { type: 'template', $limit: -1 } }),
      api.projectTemplates.find(
        showAll
          ? { query: { $skip, $limit, $join } }
          : { query: { categoryId: { $in: selectedCategories }, $skip, $limit, $join } }
      ),
    ]);

    // set categories
    setTemplateProjectSidebarCategories(categories);
    setProjectTemplateCategories(categories);
    // set project templates
    setTemplateProjectsItemsToStore({ ...projectTemplates, nextPage: 1 });
  },
});
