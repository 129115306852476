import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

/**
 * Analytics reports web hook.
 * @param {object} params - The parameters for getting the reports.
 * @param {object} reactQueryParams - The parameters for the React Query hook.
 * @returns {object} - The result of the reports query
 */
const useReports = (params = {}, reactQueryParams = {}) => {
  const { siteId } = useActiveSite();

  const query = api.useQuery(
    [
      'mcfx/analytics/reports',
      {
        query: {
          siteId,
          $join: ['uploadedBy', 'views', 'isViewed'],
          $sort: { createdAt: -1 },
          ...params,
        },
      },
    ],
    {
      enabled: !!siteId || !!params.siteId,
      staleTime: 1000 * 60 * 15, // 15 min
      cacheTime: 1000 * 60 * 30, // 30 min
      select: (data) => data || [],
      ...reactQueryParams,
    }
  );

  const mutator = api.useMutation(['mcfx/analytics/reports']);
  return { ...query, mutator };
};

export default useReports;
