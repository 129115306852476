import { setProjectForPeopleAndPermissions } from './setProjectForPeopleAndPermissions';
import { addCompanyToProject } from './addCompanyToProject';
import { removeAccess } from './removeAccess';
import { removeCompanyFromProject } from './removeCompanyFromProject';
import { giveAccess } from './giveAccess';
import { updateUserPermissions } from './updateUserPermissions';
import { giveAccessYourCompany } from './giveAccessYourCompany';
import { removeAccessYourCompany } from './removeAccessYourCompany';

export default {
  setProjectForPeopleAndPermissions,
  addCompanyToProject,
  removeAccess,
  removeCompanyFromProject,
  giveAccess,
  updateUserPermissions,
  giveAccessYourCompany,
  removeAccessYourCompany,
};
