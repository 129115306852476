import { action, debug } from 'easy-peasy';
import { setAccessLevel } from '../../../../../utils-deprecated/changePermissions';

export const addCompanyToProject = action((state, payload) => {
  payload.projectsCompanies = payload.projectsCompaniesAlias;
  delete payload.projectsCompaniesAlias;

  const companiesNames = [payload.name];

  state.project.projectCompanies.forEach((c) => companiesNames.push(c.name));

  const index = companiesNames
    .sort((a, b) => a.localeCompare(b))
    .findIndex((name) => name === payload.name);
  state.project.projectCompanies.splice(index, 0, payload);
});
