import styles from './ListItem.module.css';

const ListItem = ({ item }) => {
  return (
    <>
      <img className={styles.avatar} src={item.avatar} alt="" loading="lazy" />
      <span className={styles.name}>{item.fullName}</span>
    </>
  );
};

export default ListItem;
