const jobTitles = [
  'accounting',
  'accounts',
  'brand_marketing',
  'broadcasting',
  'business_development',
  'compensation',
  'content_marketing',
  'customer_success',
  'data',
  'dental',
  'devops',
  'doctor',
  'editorial',
  'education_administration',
  'electrical',
  'employee_development',
  'events',
  'fitness',
  'graphic_design',
  'information_technology',
  'instructor',
  'investment',
  'journalism',
  'judicial',
  'lawyer',
  'logistics',
  'mechanical',
  'media_relations',
  'network',
  'nursing',
  'office_management',
  'paralegal',
  'pipeline',
  'product',
  'product_design',
  'product_marketing',
  'professor',
  'project_engineering',
  'project_management',
  'property_management',
  'quality_assurance',
  'realtor',
  'recruiting',
  'researcher',
  'security',
  'software',
  'support',
  'systems',
  'tax',
  'teacher',
  'therapy',
  'video',
  'web',
  'web_design',
  'wellness',
  'writing',
];

export default jobTitles.map((title) => {
  const jobTitle = title.replace(/_/g, ' ');
  return {
    label: jobTitle,
    value: jobTitle,
  };
});
