import { action } from 'easy-peasy';
import { initState } from './initState';
// actions
import { mountTodo } from './actions/mountTodo';
// actions - to-do
import { editTodo } from './actions/todo/editTodo';
import { checkConfirmation } from './actions/todo/checkConfirmation';
import { completeTodo } from './actions/todo/completeTodo';
import { uncompleteTodo } from './actions/todo/uncompleteTodo';
import { editTodoPriority } from './actions/todo/editTodoPriority';
// actions - comments
import { createComment } from './actions/comments/createComment';
import { editComment } from './actions/comments/editComment';
import { deleteComment } from './actions/comments/deleteComment';
import { loadAllComments } from './actions/comments/loadAllComments';
// actions - sidebar
import { subscribeCurrentUser } from './actions/sidebar/subscribeCurrentUser';
import { unsubscribeCurrentUser } from './actions/sidebar/unsubscribeCurrentUser';
// actions - move to-do
import { setMoveTodoProjects } from './actions/moveTodo/setMoveTodoProjects';
import { setMoveTodoTodoLists } from './actions/moveTodo/setMoveTodoTodoLists';
import { resetMoveTodoTodoLists } from './actions/moveTodo/resetMoveTodoTodoLists';
import { moveTodo } from './actions/moveTodo/moveTodo';
import { unSubscribeUser } from './actions/unSubscribeUser';

// thunks
import { onMountTodo } from './thunks/onMountTodo';
// thunks - to-do
import { onEditTodo } from './thunks/todo/onEditTodo';
import { onMoveTodo } from './thunks/moveTodo/onMoveTodo';
import { onCheckConfirmation } from './thunks/todo/onCheckConfirmation';
import { onCompleteTodo } from './thunks/todo/onCompleteTodo';
import { onUncompleteTodo } from './thunks/todo/onUncompleteTodo';
import { onEditTodoPriority } from './thunks/todo/onEditTodoPriority';
// thunks - comments
import { onCreateComment } from './thunks/comments/onCreateComment';
import { onEditComment } from './thunks/comments/onEditComment';
import { onDeleteComment } from './thunks/comments/onDeleteComment';
import { onLoadAllComments } from './thunks/comments/onLoadAllComments';
// thunks - sidebar
import { onSubscribeCurrentUser } from './thunks/sidebar/onSubscribeCurrentUser';
import { onUnsubscribeCurrentUser } from './thunks/sidebar/onUnsubscribeCurrentUser';
// thunks - move to-do
import { onMountMoveTodo } from './thunks/moveTodo/onMountMoveTodo';
import { onFindTodoListsForMoveDropdown } from './thunks/moveTodo/onFindTodoListsForMoveDropdown';
// thunks - ws
import { wsOnCreateComment } from './thunks/ws/wsOnCreateComment';
import { wsOnDeleteComment } from './thunks/ws/wsOnDeleteComment';

const todo = {
  ...initState,

  reset: action((state) => {
    Object.keys(initState).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      state[key] = initState[key];
    });
  }),
  // actions
  mountTodo,
  // actions - to-do
  editTodo,
  checkConfirmation,
  completeTodo,
  uncompleteTodo,
  editTodoPriority,
  // actions - comments
  createComment,
  editComment,
  deleteComment,
  loadAllComments,
  // actions - sidebar
  subscribeCurrentUser,
  unsubscribeCurrentUser,
  unSubscribeUser,

  // actions - move to-do
  setMoveTodoProjects,
  setMoveTodoTodoLists,
  resetMoveTodoTodoLists,
  moveTodo,

  // thunks
  onMountTodo,
  // thunks - to-do
  onEditTodo,
  onMoveTodo,
  onCheckConfirmation,
  onCompleteTodo,
  onUncompleteTodo,
  onEditTodoPriority,
  // thunks - comments
  onCreateComment,
  onEditComment,
  onDeleteComment,
  onLoadAllComments,
  // thunks - sidebar
  onSubscribeCurrentUser,
  onUnsubscribeCurrentUser,
  // thunks - moveTodo
  onMountMoveTodo,
  onFindTodoListsForMoveDropdown,
  // thunks - ws
  wsOnCreateComment,
  wsOnDeleteComment,
};

export default todo;
