import { action } from 'easy-peasy';

import { deleteTodosFromLists } from '../helpers/deleteTodosFromLists';
import { setTodosToLists } from '../helpers/setTodosToLists';

export const wsReorderTodoLists = action((state, { active, map, todosBag }) => {
  const { completed = [] } = state.todoLists || {};

  state.todoLists = setTodosToLists({
    todoLists: {
      active,
      completed,
      map: deleteTodosFromLists({ active, map }),
    },
    todosBag: { ...todosBag, hasMore: true },
  });
});
