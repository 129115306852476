import { action } from 'easy-peasy';

import { transformTodoList } from '../helpers/transformTodoList';

export const createTodoList = action((state, { todoList }) => {
  const { todoLists } = state;

  const { todolistId } = todoList;
  todoLists.active.unshift(todolistId);
  todoLists.loadedTodoLists.unshift(todolistId);
  todoLists.map[todolistId] = transformTodoList({ todoList, checked: true });
});
