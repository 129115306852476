// We map thought current todos and replace todoId for all position.
// We take position and set new to-do to this position
//
// { id: 'a', position: 0 }    { id: 'c', position: 0 }
// { id: 'b', position: 5 } => { id: 'b', position: 5 }
// { id: 'c', position: 7 }    { id: 'a', position: 7 }

const reorder = (list, startIndex, endIndex, newItem) => {
  const result = Array.from(list);
  if (startIndex !== null) {
    const [removed] = result.splice(startIndex, 1);

    if (endIndex !== null) {
      result.splice(endIndex, 0, removed);
    }
  }

  if (newItem) {
    result.splice(endIndex, 0, newItem);
  }

  return result;
};

export const getReorderedTodos = ({ todolistId, todos, startIndex, endIndex, newTodo }) => {
  const newOrder = reorder(todos.active, startIndex, endIndex, newTodo);
  return newOrder.map((todoId, index) => ({
    todoId,
    todolistId,
    position: index,
  }));
  // .filter(({ todoId }) => !todos.map[todoId].movedTo);
};
