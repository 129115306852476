/* eslint-disable no-param-reassign */
import { action, debug } from 'easy-peasy';
import castArray from 'lodash/castArray';

// import { setAccessLevel } from '../../../../utils/changePermissions';

export const updateUserPermissions = action((state, payload) => {
  // API can return both single/array object, lets cast just to avoid any issues.
  const data = castArray(payload);

  for (const user of data) {
    for (const map of state.userMap[user.userId]) {
      state.project.projectCompanies[map.company].users[map.user].projects[0].projectsUsers =
        user.projectsAccess; //setAccessLevel();
    }
  }
});
