import { thunk } from 'easy-peasy';
import omit from 'lodash/omit';
import api from '../../../../../../services/api';

const getSelectedProjects = (companies) => {
  const ids = [];

  companies.list.forEach((id) => {
    const company = companies.map[id];

    company.projects.list.forEach((projectId) => {
      const isChecked = company.projects.map[projectId].checked;
      if (isChecked) {
        ids.push({ projectId });
      }
    });
  });

  return ids;
};

export const onGiveAccessTeamworkFxUser = thunk(
  async (_, __, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError, showNotification } = actions.common;

    const state = getStoreState();
    const { companies } = state.teamworkFx.allPeople.editUser.sidebar;
    const { userId } = state.teamworkFx.allPeople.editUser.selectedUser;
    const selectedProjects = getSelectedProjects(companies);

    const loader = 'teamworkFx.allPeople.editUser.updateProjectAccess';
    showLoader({ key: loader });
    try {
      const sanitized = omit({ projects: selectedProjects }, ['companyId']);
      await api.users.patch(userId, sanitized);
      showNotification({ message: 'The settings for this person have been saved successfully' });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
