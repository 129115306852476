const jobPositions = [
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Partner',
    value: 'partner',
  },
  {
    label: 'CXO',
    value: 'cxo',
  },
  {
    label: 'VP',
    value: 'vp',
  },
  {
    label: 'Senior',
    value: 'senior',
  },
  {
    label: 'Director',
    value: 'director',
  },
  {
    label: 'Manager',
    value: 'manager',
  },
  {
    label: 'Entry',
    value: 'entry',
  },
  {
    label: 'Training',
    value: 'training',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
  },
];

export default jobPositions;
