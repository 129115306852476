import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

export const onDeleteFile = thunk(async ({ removeFile }, payload, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;

  const loader = 'project.files.deleteFile';
  showLoader({ key: loader });
  try {
    const file = await feathers().service('tw/attachments').remove(payload.uploadId);
    removeFile(file);
  } catch (e) {
    showError(e);
  }
  hideLoader({ key: loader });
});
