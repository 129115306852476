import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';

export const onGiveAccessForAll = thunk(
  async ({ giveAccess }, { users, list, projectId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    const currentUser = store.auth.user();

    const usersIds = list.reduce((acc, cur) => {
      if (currentUser.userId !== cur && users[cur].accessLevel.hasAccess === false) {
        acc.push({ userId: cur, projectId });
      }

      return acc;
    }, []);

    const loader = 'project.peopleAndPermissions.subscribe';
    showLoader({ key: loader });
    try {
      const usersWithAccess = await api.projectsUsers.create(usersIds, {
        for: 'changePeopleAndPermissions',
      });
      giveAccess(usersWithAccess);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
