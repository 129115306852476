import { api } from '@webfx/core-web';
import { useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { allMessagesLimit } from '../../ui/constants/limits';
import { getContentAsHtml } from '../../utils/rte';
import { getSubscribeUsers } from '../../utils-deprecated/subscribers';

const useMessages = (projectId, params = {}) => {
  const query = api.useQuery(
    [
      'tw/messages',
      {
        projectId,
        $join: {
          category: true,
          creator: true,
          pinnedBy: true,
          comments: {
            $join: ['creator'],
          },
        },
        $limit: allMessagesLimit,
        $sort: { createdAt: -1 },
        ...params,
      },
    ],
    {
      enabled: !!projectId,
    }
  );

  const queryClient = useQueryClient();
  const mutator = api.useMutation(['tw/messages'], {});

  // re-formats api response for the ui
  const messages = useMemo(() => {
    return (query.data?.data ?? []).map((message) => {
      const authorsMap = {};
      let newestComment = null;
      message.comments.forEach((comment) => {
        if (comment.creator) {
          authorsMap[comment.creator.userId] = comment.creator;
        }

        if (
          !newestComment ||
          new Date(comment.createdAt).getTime() > new Date(newestComment.createdAt).getTime()
        ) {
          newestComment = { ...comment };
        }
      });

      return {
        ...message,
        comments: {
          quantity: message.comments.length,
          authors: Object.values(authorsMap),
        },
        newestComment,
      };
    });
  }, [query.data]);

  const createMessage = async (projectId, updateParams, formProps = {}) => {
    const {
      onSuccess = () => null,
      onError = () => null,
      onCompleted = () => null,
      reactQuillRef = null,
    } = formProps;

    const content = getContentAsHtml(reactQuillRef);
    const subscribers = getSubscribeUsers(updateParams.subscribers, content, updateParams.private);

    mutator.mutate(
      {
        _method: 'create',
        ...updateParams,
        projectId,
        content,
        subscribers,
        attachments: undefined, // endpoint expects 'files' not 'attachments'
        files: formatAttachments(projectId, updateParams.attachments),
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries(query.queryKey);
          await queryClient.invalidateQueries('tw/subscribers');

          onSuccess(data);
          onCompleted();
        },
        onError: () => {
          onError();
          onCompleted();
        },
      }
    );
  };

  const updateMessage = async (messageId, updateParams, callbacks = {}) => {
    const {
      onSuccess = () => null,
      onError = () => null,
      onCompleted = () => null,
      reactQuillRef = null,
    } = callbacks;

    const content = getContentAsHtml(reactQuillRef);
    const subscribers = getSubscribeUsers(updateParams.subscribers, content, updateParams.private);

    mutator.mutate(
      {
        _method: 'patch',
        _id: messageId,
        ...updateParams,
        content,
        subscribers,
        attachments: undefined, // endpoint expects 'files' not 'attachments'
        files: formatAttachments(projectId, updateParams.attachments),
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries(query.queryKey);
          await queryClient.invalidateQueries('tw/subscribers');

          onSuccess(data);
          onCompleted();
        },
        onError: () => {
          onError();
          onCompleted();
        },
      }
    );
  };

  const removeMessage = async (messageId, formProps = {}) => {
    const { onSuccess = () => null, onError = () => null, onCompleted = () => null } = formProps;

    mutator.mutate(
      {
        _method: 'remove',
        _id: messageId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(query.queryKey);

          onSuccess();
          onCompleted();
        },
        onError: (error) => {
          onError(error);
          onCompleted();
        },
      }
    );
  };

  return {
    createMessage,
    isLoading: query.isLoading,
    isUpdating: mutator.isLoading,
    messages,
    mutator,
    removeMessage,
    totalMessages: query.data?.total ?? 0,
    updateMessage,
  };
};

const formatAttachments = (projectId, attachments) =>
  attachments
    .map((file) => ({
      extension: file.extension,
      byteSize: file.byteSize,
      type: file.type,
      name: file.name,
      uploadId: file.uploadId,
      projectId,
    }))
    .filter((file) => file.uploadId);

export default useMessages;
