const getIdOfSelectedCategories = ({ list, map }) =>
  list ? list.filter((id) => map[id].checked) : [];

const getQueryForFilteringListsTemplate = (
  { list, map, showAll, search },
  singleTodoListTemplateId
) => {
  const query = {
    todolistTemplateId: singleTodoListTemplateId,
    categoryIds: showAll ? undefined : getIdOfSelectedCategories({ list, map }),
  };
  if (search) {
    query.$or = [{ name: { $like: `%${search}%` } }, { description: { $like: `%${search}%` } }];
  }
  return query;
};

export default getQueryForFilteringListsTemplate;
