// TODO this is a mock data - we should get real permission from API
// roles = ['owner', 'admin', 'user_messages', 'user_todos', 'user_calendar']

const store = {
  fetched: false,
  data: {
    viewPrivate: false, // ['owner', 'admin']
    // view private milestone/messages/todos-lists/files;

    // TeamworkFx.Dashboard &
    // Project.Overview &
    // Project.Calendar:
    // - Create / Edit Milestone modal: 'Private Milestone' checkbox;

    // Project.CreateMessage &
    // Project.EditMessage - Template Dropdown, Private checkbox, upload file size;

    // Project.TodoLists - Create TodoList modal - 'User a TodoList Template' button, 'Private checkbox;
    // Project.TodoLists - Edit TodoList modal - 'Private checkbox;
    // Project.TodoComments - Create / Edit Comment - upload file size;

    // Project.MilestoneComments &
    // Project.TodoComments &
    // Project.MessageComments - Template Dropdown

    // Project.MilestoneComments &
    // Project.MessageComments &
    // Project.FilesUpload:
    // - Create / Edit Milestone Comment, Message Comment - upload file size;
    // - Create / Edit File - Private checkbox, upload file size;
    myInfo: {
      editMyCompany: false, // ['admin']
      // MyInfo - 'Company' dropdown disable
    },
    teamworkFx: {
      viewTeamworkFxPages: false, // ['owner', 'admin']
      // block access to all TeamworkFx Pages except Teamwork.Dashboard / MyInfo
      // Project.Messages - edit category
      // TeamworkFx.Dashboard - View the Full Calendar
      dashboard: {
        createNewProject: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard - 'Create New Project' button;
        recoverDeletedProjects: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard - 'Recover Your Deleted Projects' button;
        viewArchivedProjects: true, // ['owner', 'admin']
        // TeamworkFx.Dashboard - 'Your Archived Projects' button;
      },
    },
    project: {
      hasAccessToProject: false,
      // Redirecting to TeamworkFx.Dashboard page if don't have access to project

      viewSettings: false, // ['owner', 'admin']
      // ProjectTabs - Project Setting Tab / Page;

      viewPermissions: false, // ['owner', 'admin']
      // ProjectTabs - People&Permissions Tab - 'People' or 'People&Permissions' tab name;
      // Project.People&Permissions:
      // - 'Change People&Permissions' button / access to 'Change People&Permissions' Page
      // - 'Add New Person' button
      // - 'Edit' button on Company / other users card

      deleteComments: false, // ['owner', 'admin']
      // Project.MilestoneComments &
      // Project.MessageComments &
      // Project.TodoComments:
      // - 'Delete' button on comment card;
      messages: {
        editOtherMessages: false, // ['owner', 'admin']
        // Project.MessageComments - 'Edit this message' button for other users messages

        deleteOtherMessages: false, // ['owner', 'admin']
        // Project.MessageComments - 'Delete' button for other users messages
      },
      todos: {
        createTodoList: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.Overview &
        // Project.TodoLists - 'New To-do List' button

        editTodoList: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoLists - 'Reorder Lists' button;
        // Project.TodoLists &
        // Project.SingleTodoList - To-do List header hover popup - edit icon;

        moveTodoList: false, // ['owner', 'admin']
        // Project.TodoLists &
        // SingleTodoList - Edit To-do List modal - 'Move' button;

        deleteTodoList: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoLists &
        // Project.SingleTodoList - To-do List header hover popup - delete icon;
        // Project.SingleTodoList - 'Delete This List' button;

        createTodoItem: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoLists &
        // Project.SingleTodoList - 'Add An Item' button

        editTodoItem: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoComments - edit icons;
        // Project.TodoLists &
        // Project.SingleTodoList:
        // - To-do Item hover popup - edit icon
        // - reorder icons - hide
        // - due date - disable
        // - priority - disable
        // - assignee  - disable

        moveTodoItem: false, // ['owner', 'admin']
        // Project.TodoLists &
        // Project.SingleTodoList &
        // Project.TodoComments: - Edit To-do Item form - 'Move' link;

        deleteTodoItem: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoLists &
        // Project.SingleTodoList - To-do Item hover popup - delete icon;

        completeOtherTodoItems: false, // ['owner', 'admin', 'user_todos', 'user_calendar']
        // Project.TodoLists - complete / uncomplete todoItems assigned to other people
      },
      calendar: {
        createEventOrMilestone: false, // ['owner', 'admin', 'user_calendar']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: Calendar Day - onClick open 'Create Event / Milestone 'modal

        // Project.Overview - 'New Event' button
        // Project.Calendar - 'Add A New Event' Button

        editOtherEventOrMilestone: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: Calendar Day - onClick open 'Edit Event / Milestone 'modal

        completeOtherMilestone: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: complete / uncomplete milestone for other people

        moveEventOrMilestone: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: Edit Event & Milestone modal - 'Move' button

        deleteMyEventOrMilestone: false, // ['owner', 'admin', 'user_calendar']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: Edit Event & Milestone modal - Delete Event/Milestone' button

        deleteOtherEventOrMilestone: false, // ['owner', 'admin']
        // TeamworkFx.Dashboard &
        // Project.Overview &
        // Project.Calendar: Edit Event & Milestone modal - 'Delete Event/Milestone' button
      },
      files: {
        editOtherFiles: false, // ['owner', 'admin']
        // Project.Files - hover popup - 'Edit' icon for other users messages

        deleteOtherFiles: false, // ['owner', 'admin']
        // Project.Files - hover popup - 'Delete' icon for other users messages
      },
    },
  },
  projectId: null,
};

export default store;
