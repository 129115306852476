/* eslint-disable prefer-destructuring */
import { thunk } from 'easy-peasy';
import { allPeopleAndCompaniesLimit } from '../../../../../ui/constants/limits';
import api from '../../../../../services/api';

export const onSearchAllPeopleByText = thunk(
  async (_, { currentUserCompanyId, searchText }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;
    const setAllPeopleSearchedValueByText = actions.pages.allPeople.setAllPeopleSearchedValueByText;
    const setAllPeopleSearchText = actions.pages.allPeople.setAllPeopleSearchText;
    const setAllPeopleSearchedByEmptyText = actions.pages.allPeople.setAllPeopleSearchedByEmptyText;

    if (searchText.length < 2 && searchText.length !== 0) {
      setAllPeopleSearchText(searchText);
      return undefined;
    }

    const loaders = [
      'teamworkFx.allPeople.allPeople.search',
      'teamworkFx.allPeople.allPeople.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      if (searchText.length === 0) {
        const [userCompany, companies] = await Promise.all([
          api.companies.get(currentUserCompanyId),
          api.companies.find({
            query: {
              $sort: { name: 1 },
              companyId: { $ne: currentUserCompanyId },
              $skip: 0,
              $limit: allPeopleAndCompaniesLimit,
            },
            for: 'allPeople',
          }),
        ]);
        setAllPeopleSearchedByEmptyText({ userCompany, companies });
      } else {
        const foundedCompanies = await api.companies.find({
          searchName: searchText,
          for: 'onFindSearchCompanies',
        });
        setAllPeopleSearchedValueByText({
          companies: foundedCompanies,
          searchText,
          currentUserCompanyId,
        });
      }
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
