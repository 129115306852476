import { action, computed } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

import categoriesThunks from './thunks';

const categories = {
  items: [],
  // computed
  messageCategory: computed((state) => state.items.filter((cat) => cat.type === 'message')),
  templateCategory: computed((state) => state.items.filter((cat) => cat.type === 'template')),

  // actions
  set: action((state, payload) => {
    // Not sure if this is the right place, maybe create a selector ?
    state.items = sortBy(payload, ({ name }) => name.trim().toLowerCase());
  }),

  put: action((state, payload) => {
    state.items.push(payload);
  }),

  remove: action((state, payload) => {
    state.items = state.items.filter((item) => item.categoryId !== payload);
  }),

  // thunks
  ...categoriesThunks,
};

export default categories;
