import { action } from 'easy-peasy';

import { deleteTodosFromList } from '../helpers/deleteTodosFromList';
import { setTodosToList } from '../helpers/setTodosToList';

export const filterTodosByDueDate = action((state, { todosBag, dueDate }) => {
  const { todoList, filters } = state;

  state.todoList = setTodosToList({ todoList: deleteTodosFromList(todoList), todosBag });
  filters.dueDate = dueDate;
});
