import { api } from '@webfx/core-web';

function useGamePlanKanbanView(companyId) {
  const companyIds = companyId ? [].concat(companyId) : [];
  const { data, isFetching } = api.useQuery(
    [
      'company-settings',
      {
        query: {
          field: 'playbookKanbanMonthView',
          companyId: { $in: companyIds },
        },
      },
    ],
    {
      select: (data) => data.data?.[0] ?? data ?? [],
      placeholderData: [],
      enabled: !!companyIds && companyIds.length > 0,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  const kanbanMonthView = data?.value || 'quarterly'; // Default to quarterly if not found

  return {
    isFetching,
    data: { kanbanMonthViewSettingId: data?.companySettingId, kanbanMonthView },
  };
}

export default useGamePlanKanbanView;
