import { action } from 'easy-peasy';

import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';

export const moveMilestone = action((state, { milestone: { milestoneId, dueAt }, timezone }) => {
  state.milestones.items = state.milestones.items.filter(
    (item) => item.milestoneId !== milestoneId
  );
  if (isOverdueMilestone(dueAt, timezone)) {
    state.milestones.overdue = state.milestones.overdue.filter(
      (item) => item.milestoneId !== milestoneId
    );
    state.milestones.overdueCount -= 1;
  }
});
