import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

import api from '../../../../../services/api';

export const onRemoveAccess = thunk(
  async ({ removeAccess }, { projectUserId, userId, accessLevel }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = [
      `project.peopleAndPermissions.nonWebFxUsers.${accessLevel || 'general'}.${userId}`,
      `project.peopleAndPermissions.nonWebFxUsers.hasAnyLoading.${userId}`,
    ];
    showLoader({ key: loader });
    try {
      await api.projectsUsers.remove(projectUserId);
      removeAccess(userId);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
    feathers().service('permissions').update(userId, { userId });
  }
);
