const countries = [
  'afghanistan',
  'albania',
  'algeria',
  'american samoa',
  'andorra',
  'angola',
  'anguilla',
  'antarctica',
  'antigua and barbuda',
  'argentina',
  'armenia',
  'aruba',
  'australia',
  'austria',
  'azerbaijan',
  'bahamas',
  'bahrain',
  'bangladesh',
  'barbados',
  'belarus',
  'belgium',
  'belize',
  'benin',
  'bermuda',
  'bhutan',
  'bolivia',
  'bosnia and herzegovina',
  'botswana',
  'bouvet island',
  'brazil',
  'british indian ocean territory',
  'british virgin islands',
  'brunei',
  'bulgaria',
  'burkina faso',
  'burundi',
  'cambodia',
  'cameroon',
  'canada',
  'cape verde',
  'caribbean netherlands',
  'cayman islands',
  'central african republic',
  'chad',
  'chile',
  'china',
  'christmas island',
  'cocos (keeling) islands',
  'colombia',
  'comoros',
  'cook islands',
  'costa rica',
  'croatia',
  'cuba',
  'curaçao',
  'cyprus',
  'czechia',
  'côte d’ivoire',
  'democratic republic of the congo',
  'denmark',
  'djibouti',
  'dominica',
  'dominican republic',
  'ecuador',
  'egypt',
  'el salvador',
  'equatorial guinea',
  'eritrea',
  'estonia',
  'ethiopia',
  'falkland islands',
  'faroe islands',
  'fiji',
  'finland',
  'france',
  'french guiana',
  'french polynesia',
  'french southern territories',
  'gabon',
  'gambia',
  'georgia',
  'germany',
  'ghana',
  'gibraltar',
  'greece',
  'greenland',
  'grenada',
  'guadeloupe',
  'guam',
  'guatemala',
  'guernsey',
  'guinea',
  'guinea-bissau',
  'guyana',
  'haiti',
  'heard island and mcdonald islands',
  'honduras',
  'hong kong',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iran',
  'iraq',
  'ireland',
  'isle of man',
  'israel',
  'italy',
  'ivory coast',
  'jamaica',
  'japan',
  'jersey',
  'jordan',
  'kazakhstan',
  'kenya',
  'kiribati',
  'kosovo',
  'kuwait',
  'kyrgyzstan',
  'laos',
  'latvia',
  'lebanon',
  'lesotho',
  'liberia',
  'libya',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macau',
  'macedonia',
  'madagascar',
  'malawi',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'marshall islands',
  'martinique',
  'mauritania',
  'mauritius',
  'mayotte',
  'mexico',
  'micronesia',
  'moldova',
  'monaco',
  'mongolia',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'myanmar',
  'namibia',
  'nauru',
  'nepal',
  'netherlands',
  'netherlands antilles',
  'new caledonia',
  'new zealand',
  'nicaragua',
  'niger',
  'nigeria',
  'niue',
  'norfolk island',
  'north korea',
  'northern mariana islands',
  'norway',
  'oman',
  'pakistan',
  'palau',
  'palestine',
  'panama',
  'papua new guinea',
  'paraguay',
  'peru',
  'philippines',
  'pitcairn',
  'poland',
  'portugal',
  'puerto rico',
  'qatar',
  'republic of the congo',
  'romania',
  'russia',
  'rwanda',
  'réunion',
  'saint barthélemy',
  'saint helena',
  'saint kitts and nevis',
  'saint lucia',
  'saint martin',
  'saint pierre and miquelon',
  'saint vincent and the grenadines',
  'samoa',
  'san marino',
  'saudi arabia',
  'senegal',
  'serbia',
  'seychelles',
  'sierra leone',
  'singapore',
  'sint maarten',
  'slovakia',
  'slovenia',
  'solomon islands',
  'somalia',
  'south africa',
  'south georgia and the south sandwich islands',
  'south korea',
  'south sudan',
  'spain',
  'sri lanka',
  'sudan',
  'suriname',
  'svalbard and jan mayen',
  'swaziland',
  'sweden',
  'switzerland',
  'syria',
  'são tomé and príncipe',
  'taiwan',
  'tajikistan',
  'tanzania',
  'thailand',
  'timor-leste',
  'togo',
  'tokelau',
  'tonga',
  'trinidad and tobago',
  'tunisia',
  'turkey',
  'turkmenistan',
  'turks and caicos islands',
  'tuvalu',
  'u.s. virgin islands',
  'uganda',
  'ukraine',
  'united arab emirates',
  'united kingdom',
  'united states',
  'united states minor outlying islands',
  'uruguay',
  'uzbekistan',
  'vanuatu',
  'vatican city',
  'venezuela',
  'vietnam',
  'wallis and futuna',
  'western sahara',
  'yemen',
  'zambia',
  'zimbabwe',
  'Åland islands',
  'åland islands',
];

export default countries.map((country) => ({
  label: country,
  value: country,
}));
