import { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { Badge, Icon, Button } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';

import { SidePanel } from '@webfx/web-ui';

import useOptimizationsHidden from '../../hooks/useOptimizationsHidden';

import GoalFilters from './GoalFilters';
import TypeFilters from './TypeFilters';
import TypeFiltersV1 from './TypeFiltersV1';
import StatusFilters from './StatusFilters';

import styles from './OptimizationFilters.module.css';

const OptimizationFilters = ({ companyId, quarter, setFilter, resetFilters }) => {
  const flags = useFlags();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    type: { $in: ['asset', 'todo', 'milestone'] },
    completed: ['open', 'completed'],
  });

  const hideOptimizations = useOptimizationsHidden(companyId);

  const handleFilter = (value) => {
    setValues({ ...values, ...value });
  };

  const handleReset = () => {
    // Remove all filters set previously
    resetFilters(values);
    setValues({ type: { $in: ['asset', 'todo', 'milestone'] }, completed: ['open', 'completed'] });
    setOpen(false);
  };

  const filterCount = useMemo(() => {
    let count = 0;

    // One of the filters has been unchecked
    if (values?.type?.$in.length === 1) {
      count++;
    }
    // One of the filters has been unchecked
    if (values?.completed?.length === 1) {
      count++;
    }
    if (!isEmpty(values?.goalId?.$in)) {
      count++;
    }
    return count;
  }, [values]);

  return (
    <>
      {/* Filter button */}
      <div
        className={classnames(
          'd-flex align-items-center justify-content-center bg-white text-blue-vivid-300 text-hover-blue-vivid-400 rounded font-14 bold cursor-pointer',
          styles.filterButton
        )}
        onClick={() => setOpen(true)}
      >
        <div className="d-flex justify-content-center px-4" data-fx-name="filterButton">
          <span>Filters</span>
          {filterCount !== 0 && (
            <Badge
              pill
              variant="primary"
              className="d-flex align-items-center ml-2 bg-blue-vivid-400"
            >
              <span>{filterCount}</span>
            </Badge>
          )}
        </div>
        <div className={classnames('h-100', styles.separator)}></div>
        <div className="d-flex align-items-center px-2">
          <Icon className="text-blue-300">clear_all</Icon>
        </div>
      </div>

      <SidePanel
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className={classnames('d-flex flex-column', styles.filterPanel)}
      >
        <h4 className="mb-4">Filters</h4>

        <div className="flex-fill overflow-auto">
          <GoalFilters
            quarter={quarter}
            companyId={companyId}
            setFilter={handleFilter}
            filters={values}
          />
          {!hideOptimizations &&
            (flags.gameplanfxUpdates ? (
              <TypeFilters setFilter={handleFilter} filters={values} />
            ) : (
              <TypeFiltersV1 setFilter={handleFilter} filters={values} />
            ))}
          <StatusFilters setFilter={handleFilter} filters={values} />
        </div>
        <div className="d-flex justify-content-end border-top pt-4">
          <Button
            className="text-blue-vivid-300 border-blue-vivid-300 text-hover-blue-vivid-500 border-hover-color-blue-vivid-500 bg-hover-white"
            variant="outline-primary"
            text="Reset All"
            onClick={handleReset}
          />
          <Button
            variant="primary"
            className="ml-2 bg-blue-vivid-300 border-blue-vivid-300 bg-hover-blue-vivid-500 border-hover-color-blue-vivid-500"
            icon="arrow_forward"
            text="Apply Filters"
            iconPosition="right"
            iconOutlined="true"
            iconTrailing="true"
            onClick={() => {
              setFilter(values);
              setOpen(false);
            }}
          />
        </div>
      </SidePanel>
    </>
  );
};

export default OptimizationFilters;
