import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

const query = (search) => {
  if (typeof search !== 'string') {
    return null;
  }

  return {
    query: {
      $or: [
        {
          firstName: {
            $like: `%${search}%`,
          },
        },
        {
          lastName: {
            $like: `%${search}%`,
          },
        },
      ],
    },
  };
};

const users = feathersClient.service('users');

export const find = (search) => users.find(query(search));

export const get = (id, params) => users.get(id, paramsForServer(params));

export const create = (payload, params) => users.create(payload, paramsForServer(params));

export const patch = (id, payload) => users.patch(id, payload);

export const remove = (id) => users.remove(id);
