import { thunk } from 'easy-peasy';
import { getInitializedTodoLists } from '../helpers/getInitializedTodoLists';
import { getTodosColorCodes } from '../helpers/getTodosColorCodes';
import { filterTodos } from '../helpers/filterTodos';

/* eslint-disable prefer-destructuring */
export const wsOnCreateTodo = thunk(
  async (_, { todolistId, result }, { getStoreActions, getStoreState }) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const createTodo = a.project.todos.todoLists.createTodo;
    const moveSidebarList = a.project.todos.todoLists.moveSidebarList;

    const state = getStoreState();
    const { filters } = state.project.todos.todoLists;
    const { active, hasMore, lastLoadedTodoListId } = state.project.todos.todoLists.todoLists;
    const { isColorCoding } = state.project.todos.todoLists.todoLists.map[todolistId];

    moveSidebarList({ todo: { todoList: { todolistId, completed: false } }, toCompleted: false });

    const isListInitialized = active.includes(todolistId);
    if (!isListInitialized) {
      return;
    }

    const todos = filterTodos({ data: result, filters });
    if (todos.length === 0) {
      return;
    }

    try {
      const colorCodes = await getTodosColorCodes(todos, isColorCoding);
      createTodo({ todolistId, todos, colorCodes });
    } catch (e) {
      showError(e);
    }
  }
);
