import { action } from 'easy-peasy';
import get from 'lodash/get';

import { formatingActivity } from '../../../../../utils-deprecated/formatingActivity';

export const setActivities = action(
  (state, res) =>
    (state.activities = res.map((company) => {
      const formatedActivities = company.activities.map((activity) => formatingActivity(activity));

      const { projectCompanies, companyId } = company;
      const currentCompany = projectCompanies.find((item) => item.companyId === companyId);

      return {
        projectId: company.projectId,
        companyName: get(currentCompany, 'name', 'No Name'), // TODO Seems we have some bug here
        projectName: company.name,
        activities: formatedActivities,
      };
    }))
);
