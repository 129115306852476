import { action } from 'easy-peasy';
import { todoListsTemplatesStore } from '../store';

export const setTemplateListItemsToStore = action((state, payload) => {
  const {
    lists: { map },
    todoItems,
  } = todoListsTemplatesStore;

  state.lists.list = [];
  state.lists.map = map;
  state.todoItems = todoItems;

  payload.forEach((currentList) => {
    const { todolistTemplateId } = currentList;
    const idsOfTodos = currentList.todos
      ? currentList.todos.map((item) => {
          const { todolistTemplateTodoId } = item;

          state.todoItems[todolistTemplateTodoId] = item;
          return todolistTemplateTodoId;
        })
      : [];

    state.lists.list.push(todolistTemplateId);
    state.lists.map[todolistTemplateId] = { ...currentList, todos: idsOfTodos };
  });
});
