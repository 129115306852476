import { action } from 'easy-peasy';

export const setTodoListTemplatesTodos = action((state, { todolistTemplateId, todos }) => {
  const idsOfTodos = todos.map((item) => {
    const { todolistTemplateTodoId } = item;

    state.todoItems[todolistTemplateTodoId] = item;
    return todolistTemplateTodoId;
  });

  state.lists.map[todolistTemplateId].todos = idsOfTodos;
});
