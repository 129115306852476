export const moveTodoListToCompleted = (todoLists, todo) => {
  const todolistId = todo.todolistId || todo.todoList.todolistId;
  if (!todolistId) {
    return;
  }

  // if any active todos, return
  if (todoLists.map[todolistId].todos?.active?.length) {
    return;
  }

  // if no todos at all (empty list), return
  if (!todoLists.map[todolistId].todos?.completed?.length) {
    return;
  }

  todoLists.active = todoLists.active.filter((id) => id !== Number(todolistId));

  todoLists.completed.push(todolistId);
  if (todoLists.map[todolistId]) {
    todoLists.map[todolistId].completed = true;
  }
};
