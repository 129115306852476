import { action, debug } from 'easy-peasy';
import { setAccessLevel } from '../../../../../utils-deprecated/changePermissions';

export const removeAccess = action((state, payload) => {
  if (!Array.isArray(payload)) {
    for (const map of state.userMap[payload]) {
      state.project.projectCompanies[map.company].users[map.user].projects[0].projectsUsers = {
        ...(state.project.projectCompanies[map.company].users[map.user].projects[0]
          ?.projectsUsers ?? {}),
        projectUserId: null,
        accessLevel: null,
      };
    }
    return;
  }

  payload.forEach((id) => {
    for (const map of state.userMap[id]) {
      state.project.projectCompanies[map.company].users[map.user].projects = {
        ...(state.project.projectCompanies[map.company].users[map.user].projects[0]
          ?.projectsUsers ?? {}),
        projectUserId: null,
        accessLevel: null,
      };
    }
  });
});
