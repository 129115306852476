const getSelectedCategories = (selectedCategories) => {
  if (!selectedCategories.length) {
    return undefined;
  }

  if (selectedCategories.includes('event')) {
    return [...selectedCategories, 'milestone'];
  }
  if (selectedCategories.includes('todo')) {
    return [...selectedCategories, 'todolist'];
  }

  return selectedCategories;
};

export default getSelectedCategories;
