import { action } from 'easy-peasy';
import assign from 'lodash/assign';

import { getUserSubscribeId } from '../../../../helpers/getUserSubscribeId';
import { setSubscribersToPeople } from '../../helpers/setSubscribersToPeople';
import { transformTodo } from '../../helpers/transformTodo';

export const editTodo = action((state, { todo, currentUserId }) => {
  const { currentSubscribers } = todo;

  assign(state.todo, transformTodo(todo));

  setSubscribersToPeople(state.people, currentSubscribers);
  state.sidebar.subscribers = currentSubscribers;
  state.sidebar.userSubscribeId = getUserSubscribeId(currentSubscribers, currentUserId);
});
