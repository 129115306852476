import { thunk } from 'easy-peasy';

export const onUnMountProjectTemplate = thunk(
  async (
    { clearProjectTemplateState, todoLists: { clearTemplatePageState } },
    _,
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;

    try {
      clearProjectTemplateState();
      clearTemplatePageState();
    } catch (e) {
      showError(e);
    }
  }
);
