import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onRemoveAccessForAllYourCompany = thunk(
  async ({ removeAccessYourCompany }, { users, list, curentUserId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const newListIds = list.filter((id) => {
      return users[id].type !== 'owner' && id !== curentUserId;
    });

    const usersIds = newListIds.reduce((acc, cur) => {
      acc.push({ projectUserId: users[cur].projectUserId });
      return acc;
    }, []);

    const loader = 'project.peopleAndPermissions.unSubscribe';
    showLoader({ key: loader });
    try {
      await api.projectsUsers.remove(null, {
        query: { $multi: true, $or: usersIds },
        for: 'changePeopleAndPermissions',
      });
      removeAccessYourCompany(newListIds);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
