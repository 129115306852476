import { store } from '@webfx/web-state';
import api from '../../../../../../services/api';

export const moveMilestone = async ({ actions, form: { id, values } }) => {
  const _moveMilestone = actions.project.overview.moveMilestone;

  const timezone = store.auth.user().timezone;

  const milestone = await api.milestones.update(id, values, { for: 'moveToAnotherProject' });
  _moveMilestone({ milestone, timezone });
};
