import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';
import {
  getCustomQuarterMonths,
  getLastDayOfTheMonthByDate,
} from '@webfx/web-ui/src/QuarterSelector/utils';

import useGoals from '../../../hooks/useGoals';

const GoalFilters = ({ companyId, quarter, setFilter, filters }) => {
  const [goalFilters, setGoalFilters] = useState(
    filters?.goalId?.$in ?? (filters?.$or ? filters?.$or[0]?.goalId?.$in : [])
  );
  const months = getCustomQuarterMonths(quarter);
  const lastMonthOfTheQuarterDay = getLastDayOfTheMonthByDate(months[2]);

  const setGoalsToFilter = (goal) => {
    const newFilters = goalFilters;
    const index = newFilters.indexOf(goal);

    if (index !== -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(goal);
    }
    setGoalFilters(newFilters);
  };

  const { data: goals } = useGoals({
    query: {
      companyId,
      date: { $gte: months[0], $lte: lastMonthOfTheQuarterDay },
      $limit: -1,
    },
  });

  return (
    <>
      <div className="d-flex flex-column">
        <span className="font-14 bold mb-1">By Objective</span>
        <div className="d-flex flex-column border rounded p-2">
          <Form.Check
            key={`goal-otcr`}
            type="checkbox"
            id={`goal-otcr`}
            label={'One-Time Client Request'}
            checked={isEmpty(goalFilters) || goalFilters.includes(-1)}
            onClick={() => {
              setGoalsToFilter(-1);
              setFilter({ $or: [{ goalId: { $in: goalFilters } }, { goalId: { $eq: null } }] });
            }}
            onChange={() => {}}
            className="font-14 text-dark-gray"
            custom
            data-fx-name="oneTimeClientRequest"
          />
          {goals?.map((goal, index) => (
            <Form.Check
              key={`goal-${index}`}
              type="checkbox"
              id={`goal-${index}`}
              label={goal.name}
              checked={isEmpty(goalFilters) || goalFilters.includes(goal.goalId)}
              onClick={() => {
                setGoalsToFilter(goal.goalId);
                setFilter({ goalId: { $in: goalFilters } });
              }}
              onChange={() => {}}
              className="font-14 text-dark-gray"
              custom
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GoalFilters;
