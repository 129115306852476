// TODO move it to utils
export const getResponsibleInfo = (userInfo) => {
  const { assigneeId } = userInfo;

  if (!assigneeId) {
    return userInfo;
  }

  const newUserInfo = { ...userInfo, nobody: false };

  const foundAssigneeId = assigneeId.split(' ')[1];
  const formattedAssigneeId = foundAssigneeId === 'null' ? null : foundAssigneeId;

  // Find property 'nobody'
  if (formattedAssigneeId === 'nobody') {
    return { ...newUserInfo, responsibleUserId: null, nobody: true };
  }

  // Set user
  if (assigneeId.indexOf('userId') !== -1) {
    return {
      ...newUserInfo,
      responsibleCompanyId: null,
      responsibleUserId: formattedAssigneeId,
    };
  }

  // Set company
  return {
    ...newUserInfo,
    responsibleUserId: null,
    responsibleCompanyId: formattedAssigneeId,
  };
};

const getQueryForFiltering = (
  { sidebarLists, filters },
  projectTemplateId,
  singlePageTodoListId
) => {
  const { responsibleCompanyId, responsibleUserId, nobody } = getResponsibleInfo({
    assigneeId: filters.assigneeId,
  });
  const list = Object.keys(sidebarLists.map);

  const selectedTodoLists = list ? list.filter((id) => sidebarLists.map[id].checked) : [];
  const requestInfo = {
    projectTemplateId,
    search: filters.search,
    nobody,
    responsibleCompanyId,
    responsibleUserId,
  };

  if (selectedTodoLists.length === 1) {
    const [id] = selectedTodoLists;
    requestInfo.projectTemplateTodoListId = id;
  }

  if (selectedTodoLists.length >= 2 || selectedTodoLists.length === 0) {
    requestInfo.projectTemplateTodoListId = { $in: selectedTodoLists };
  }

  if (singlePageTodoListId) {
    requestInfo.projectTemplateTodoListId = singlePageTodoListId;
  }

  return requestInfo;
};

export default getQueryForFiltering;
