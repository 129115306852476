import { action } from 'easy-peasy';

import { sortTodosByPosition } from '../../todoLists/helpers/sortTodosByPosition';
import { moveSidebarListToActive } from '../helpers/moveSidebarListToActive';
import { transformTodo } from '../helpers/transformTodo';

export const createTodo = action((state, { todolistId, todos, colorCodes }) => {
  const { todoList } = state;
  const { active, map } = state.todoList.todos;

  todoList.$skip += todos.length;

  todos.forEach((todo) => {
    const { todoId } = todo;
    active.push(todoId);
    map[todoId] = transformTodo(todo);
  });

  todoList.todos.active = sortTodosByPosition(active, map);

  if (colorCodes) {
    Object.entries(colorCodes.results).forEach(([todoId, value]) => {
      todoList.colorCodes[todoId] = value;
    });
  }

  state.sidebarLists = moveSidebarListToActive(state.todoList, state.sidebarLists, { todolistId });
});
