import { api } from '@webfx/core-web';

const useReports = (params = {}) => {
  return api.useQuery(
    [
      'mcfx/analytics/reports',
      {
        query: {
          $sort: { createdAt: -1 },
          ...params,
        },
        paginate: false,
      },
    ],
    {
      enabled: !!params?.siteId,
      staleTime: 1000 * 60 * 15, // 15 min
      cacheTime: 1000 * 60 * 30, // 30 min
      select: (data) => data.data ?? data ?? [],
    }
  );
};

export default useReports;
