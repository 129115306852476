import { action } from 'easy-peasy';

import lodashSet from 'lodash/set';
import get from 'lodash/get';

export const patch = (key) =>
  action((state, payload) => (state[key] = { ...state[key], ...payload }));

export const set = (path) => action((state, payload) => lodashSet(state, path, payload));

export const reset = (key) => action((state) => (state[key] = {}));

export const clearState = (store) =>
  action((state) => Object.keys(store).forEach((key) => (state[key] = store[key])));

// TODO think how we can implement here go deep in store and clear value which is not in exclude=[]
// In the future probably we can remove this function
export const clearStatePart = ({ state, store }) =>
  Object.keys(store).forEach((key) => (state[key] = store[key]));

export const clearStatePartByKey = ({ state, key, store }) =>
  lodashSet(state, key, get(store, key));
