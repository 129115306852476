import { DateTime } from 'luxon';
import groupBy from 'lodash/groupBy';

// eslint-disable-next-line jsdoc/require-jsdoc
const groupByOptimizationDueDate = (data) => {
  let optimizations = [];
  for (const optimization of data) {
    if (optimization.dueDate) {
      /**
       * optimization.dueDate value is `year-month-01`
       * Since we're setting the date to the first day of the month, there is a chance that we'll get the prior month for timezones with a minus 12-hour time difference from UTC.
       *
       * To prevent this, we've applied the following:
       * 1. Set timezone to UTC
       * 2. Setting first day of the month to the due date's day to ensure that we are getting the proper "month" regardless of the timezone.
       */
      const date = DateTime.fromFormat(optimization.dueDate, 'yyyy-MM-dd')
        .set({ day: 1 })
        .toUTC()
        .toJSDate();

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, 0);
      const day = date.getDate().toString().padStart(2, 0);

      //Update dueDate based on our Kanban grouping of items
      optimization.dueDate = `${year}-${month}-${day}`;
    }
    optimizations.push(optimization);
  }

  //Group optimizations by dueDate
  optimizations = groupBy(data, 'dueDate');

  return optimizations;
};

export default groupByOptimizationDueDate;
