import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onEditTodoPriority = thunk(
  async ({ editTodoPriority }, { values, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      editTodoPriority({ priority: values.priority });
      await api.todos.patch(todoId, values, { for: 'onEditTodo' });
    } catch (e) {
      showError(e);
    }
  }
);
