import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onShowTodosColorCodes = thunk(
  async ({ showTodosColorCodes }, { todolistId }, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = 'project.todos.singleTodoList.colorCoding';
    showLoader({ key: loader });

    // TODO: Refactor as this endpoint is for turnaround status.
    try {
      // TODO: Why is this querying? we should already have todos in store.
      const todos = await api.todos.find({
        query: {
          todolistId,
          $limit: -1,
        },
      });

      const todoIds = todos.map(({ todoId }) => todoId);
      const colorCodes = await api.colorCodes.colorCodes(todoIds);

      showTodosColorCodes({ todolistId, colorCodes });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
