import { thunk } from 'easy-peasy';

import { filterTodoListsTemplate } from './filterTodoListsTemplate';

export const onShowAllTemplateTodoListsCategories = thunk(
  async (
    { changeTemplateListCategoryChecked, setTemplateListItemsToStore },
    _,
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = [
      'teamworkFx.templates.todoLists.sidebar.showAll',
      'teamworkFx.templates.todoLists.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      changeTemplateListCategoryChecked({ showAll: true });

      const state = getStoreState();
      const { categories, filters } = state.teamworkFx.templates.todoLists.sidebar;
      await filterTodoListsTemplate({ ...categories, ...filters }, setTemplateListItemsToStore);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
