import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { companiesProjectsLimit } from '../../../../../ui/constants/limits';

export const onLoadMoreCompaniesProjects = thunk(
  async ({ setCompaniesProjects }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const {
      $skip,
      paginationProjectsType,
      filters: {
        params: { name },
      },
    } = state.teamworkFx.dashboard.sidebar.companiesProjects;

    const query = {
      $cache: false,
      $skip,
      $limit: companiesProjectsLimit,
      status: paginationProjectsType,
    };

    // Only filter active projects by name
    if (name) {
      query.name = {
        $like: `%${name}%`,
      };
    }

    try {
      const companiesProjects = await api.sidebar.find({
        query,
        for: 'projectsSidebar',
      });
      setCompaniesProjects({ companiesProjects, type: paginationProjectsType });
    } catch (e) {
      showError(e);
    }
  }
);
