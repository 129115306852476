import { action } from 'easy-peasy';

export const clearSearchItems = action((state, _) => {
  state.items.list = [];
  state.items.map = {};
  state.scrollParams.skipCount = 0;
  state.scrollParams.hasMoreData = true;

  return;
});
