import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const projectTemplateTodos = feathersClient.service('tw/project-template-todos');

/**
 *
 * @param payload
 */
export const create = (payload) => projectTemplateTodos.create(payload);
/**
 *
 * @param id
 */
export const remove = (id) => projectTemplateTodos.remove(id);
/**
 *
 * @param id
 * @param body
 * @param params
 */
export const patch = (id, body, params) =>
  projectTemplateTodos.patch(id, body, paramsForServer(params));
