export const initState = {
  todo: {
    author: {},
    files: [],
    category: {},
    movedTo: null,
  },
  firstComment: {
    hasWarning: false,
    body: null,
  },
  comments: {
    list: [],
    map: {},
    firstCommentId: null,
    total: 0,
  },
  pastebinTemplates: [],
  people: [],
  assignees: [],
  sidebar: {
    subscribers: [],
    userSubscribeId: null,
    commentators: [],
  },
  moveTodoForm: {
    projects: [],
    todoLists: [],
  },
};
