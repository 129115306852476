import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onLoadAllComments = thunk(
  async ({ loadAllComments }, { todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, showError, hideLoader } = actions.common;

    const loader = 'project.todos.todo.loadAllComments';
    showLoader({ key: loader });

    try {
      const comments = await api.comments.find({
        query: { todoId, $limit: -1 },
        for: 'usersAndFiles',
      });
      loadAllComments({ comments });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
