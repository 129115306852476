import { DateTime } from 'luxon';
import { Button } from '@webfx/core-web';
import { useCompany, useActiveSite, useRouter } from '@webfx/web-hooks';
import useArrangeReports from '@webfx/marketingcloud-web/src/hooks/analytics/useArrangeReports';

import useReports from '../../hooks/useReports';
import ComingSoonCard from './ComingSoonCard';

import ReportCard from './ReportCard';

const LatestReports = ({ companyId }) => {
  const { url } = useRouter();
  const { data: company } = useCompany(companyId, { $join: 'sites' });
  const site = company?.sites?.[0];
  const { siteId: activeSiteId } = useActiveSite();

  const { reportCategories } = useArrangeReports();

  const { data: monthlyReports, isFetching } = useReports({
    siteId: activeSiteId || site?.siteId,
    isCustom: 0,
    createdAt: {
      $gte: DateTime.utc().startOf('month').toFormat('yyyy-MM-dd'),
    },
  });

  if (isFetching) {
    return null;
  }

  /**
   * Get available reports based on service mappings.
   * @returns {Array} Returns an array of reports
   */
  const getFilteredReports = () => {
    const filteredReports = [];

    reportCategories.forEach((category) => {
      const matchingReports = monthlyReports?.filter((report) => report.service === category);

      if (matchingReports?.length > 0) {
        filteredReports.push(
          ...matchingReports.map((report) => ({
            type: 'report',
            data: report,
          }))
        );
      } else if (category === 'Executive Summary') {
        filteredReports.push({
          type: 'comingSoon',
          data: { reportCategory: category },
        });
      }
    });

    const unmatchedReports = monthlyReports
      ?.filter((report) => !report.service || !reportCategories.includes(report.service))
      .map((report) => ({
        type: 'report',
        data: report,
      }));

    const result = [...filteredReports, ...(unmatchedReports ?? [])];

    return result.slice(0, 3) ?? [];
  };

  const reports = getFilteredReports();

  return (
    <div className="d-flex flex-column my-3 border-bottom py-4">
      <div className="d-flex justify-content-between">
        <p className="bold font-20 text-gray-700" data-fx-name="label">
          Latest Account Reports
        </p>
        <div>
          <Button
            variant="link"
            className="font-13 p-0 border-none shadow-none semibold text-blue-vivid-300 mt-2"
            to={url({
              route: 'mcfxAnalyticsReportsCategories',
              siteId: activeSiteId || site?.siteId,
              categoryId: 'monthly-reports',
            })}
            text="View All Reports"
            icon="arrow_forward"
            iconPosition="right"
            iconTrailing
          />
        </div>
      </div>

      <div className="container-fluid">
        <div className="row mtb-2" data-fx-name="latestReports">
          {reports.map((item) => {
            if (item.type === 'report') {
              return <ReportCard key={item.data.reportId} report={item.data} />;
            } else if (item.type === 'comingSoon') {
              return (
                <ComingSoonCard
                  key={item.data.reportCategory}
                  reportCategory={item.data.reportCategory}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default LatestReports;
