import jwtDecode from 'jwt-decode';
import { setItem, getItem, removeItem } from '../../utils-deprecated/localStorage';

export const getAccessToken = () => getItem('accessToken');
export const setAccessToken = (token) => setItem('accessToken', token);
export const deleteTokens = () => removeItem('accessToken');

export const extractTokenInfo = () => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return jwtDecode(token);
};
export const setUser = (user) => setItem('user', JSON.stringify(user));

export const getUser = () => JSON.parse(getItem('user'));

export const isAccessTokenAlive = () => {
  const token = getAccessToken();
  if (!token) {
    return false;
  }
  const currentTime = Math.floor(new Date().getTime() / 1000);
  return currentTime < extractTokenInfo(token).exp;
};

// Time in sec.
export const accessTokenRemainingLifeTimeSec = () => {
  const token = getAccessToken();
  if (!token) {
    return -1;
  }
  const currentTime = Math.floor(new Date().getTime() / 1000);
  return extractTokenInfo().exp - currentTime;
};
