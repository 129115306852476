import api from '../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountPeoplePermissions = fetchWithLoaderAndRedirect({
  loaderKey: 'PeoplePermissions',
  action: async (actions, { projectId }) => {
    const { setProjectForPeopleAndPermissions } = actions.ui.peopleAndPermissions;

    if (!projectId) {
      return;
    }

    const project = await api.projects.get({ projectId, params: { for: 'peopleAndPermissions' } });
    setProjectForPeopleAndPermissions(project);

    return `/projects/${projectId}/people-and-permissions`;
  },
});
