import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';

import api from '../../../../../../services/api';

export const onUpdateTeamworkFxTemplateTodoList = thunk(
  async (
    { updateTeamworkFxTemplateTodoList },
    { values, setSubmitting, onClosePopup, id, updaterUserId },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const { categoryIds } = values;

    if (values.owner) {
      values.ownerUserId = values.owner?.value?.responsibleUserId;
    }
    if (values.owner) {
      delete values.owner;
    }

    const lastEdited = `${DateTime.now().toSQLDate()}`;
    const payload = {
      ...values,
      updaterUserId,
      categoryIds,
      lastEdited,
    };
    try {
      const data = await api.todoListsTemplate.patch(id, payload, {
        query: {
          $join: ['updater', 'lastDeployedDate', 'timesDeployedLast12Months', 'owner'],
        },
      });

      updateTeamworkFxTemplateTodoList(data);
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
