import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import getSelectedCategories from '../helpers/getSelectedCategories';

export const onSearchTeamworkFxByText = thunk(
  async ({ setTeamworkFxSearchedValueByText }, _, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { search, sidebar } = state.teamworkFx.search.filters;
    const checkedCategories = getSelectedCategories(sidebar);

    if (!search) {
      return setTeamworkFxSearchedValueByText({
        searchedValue: {
          body: {
            hits: { total: 0, hits: [] },
          },
        },
        search,
      });
    }

    const loaders = ['teamworkFx.search.teamworkFxSearch', 'teamworkFx.search.hasAnyLoading'];
    showLoader({ key: loaders });
    try {
      const searchedValue = await api.search.find({
        query: { searchTerm: search, resourceType: checkedCategories },
      });

      setTeamworkFxSearchedValueByText({ searchedValue, search });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
