import { action } from 'easy-peasy';

export const deleteComment = action((state, { comment: { commentId, users } }) => {
  state.comments.total -= 1;
  state.comments.list = state.comments.list.filter((id) => id !== commentId);
  if (state.comments.firstCommentId === commentId) {
    state.comments.firstCommentId = null;
  }
  delete state.comments.map[commentId];

  state.sidebar.commentators = users;
});
