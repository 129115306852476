import { action } from 'easy-peasy';
import deletionsThunks from './thunks';

const deletions = {
  list: [],
  total: 0,
  limit: null,
  skip: null,

  // actions
  setDeletionsData: action((state, { data, total, limit, skip }) => {
    state.list = data;
    state.total = total;
    state.limit = limit;
    state.skip = skip;
  }),

  // thunks
  ...deletionsThunks,
};

export default deletions;
