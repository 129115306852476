import { store } from '@webfx/core-web';

import { store as webState } from '@webfx/web-state';
import api from '../../../../../services/api';
import { companiesProjectsLimit } from '../../../../../ui/constants/limits';
import { getFromTo, getSelectedMonthDate } from '../../../../../utils-deprecated/calendar';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxDashboardPage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxDashboardPage',
  pathname: store.getState().router.route('dashboard'),
  useGlobalLoader: false,
  action: async (actions) => {
    const isAdmin = webState.auth.isAdmin();
    const user = webState.auth.user();

    const { timezone } = user;
    const setCompaniesProjects = actions.teamworkFx.dashboard.setCompaniesProjects;
    const setActivities = actions.teamworkFx.dashboard.setActivities;
    const setEvent = actions.teamworkFx.dashboard.setEvent;
    const setMilestones = actions.teamworkFx.dashboard.setMilestones;

    const currDate = getSelectedMonthDate(null, timezone);
    const { from, to } = getFromTo(currDate, true, 2);

    api.sidebar
      .find({
        query: { $skip: 0, $limit: companiesProjectsLimit, status: 'active', $cache: false },
        for: 'projectsSidebar',
      })
      .then((companiesProjects) => setCompaniesProjects({ companiesProjects, type: 'active' }));

    api.projects
      .find({ for: 'dashboardActivity', ...(!isAdmin && { restrict: ['isPrivate'] }) })
      .then((dashboardActivities) => {
        setActivities(dashboardActivities);
      });

    api.calendar
      .find({ query: { from, to, dayLimit: 2 } })
      .then((calendarData) => {
        const { milestones, events } = calendarData;
        setMilestones(milestones);
        setEvent(events);
      })
      .catch((err) => {
        console.error(err);
      });
  },
});
