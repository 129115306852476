import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { Svg } from '@webfx/core-web';

import GoalProgress from '../GoalProgress';
import Box from '../../assets/images/box.svg';

import styles from './GoalList.module.css';

const GoalList = ({ goals }) => {
  return (
    <div className={classnames('d-flex flex-column', styles.goalList)}>
      {goals?.map((goal, index) => (
        <GoalProgress
          index={index}
          goal={goal}
          key={`${goal.goalId}-${index}`}
          companyId={goal?.companyId}
        />
      ))}
      {isEmpty(goals) && (
        <>
          <div className="row h-100 d-flex align-items-center justify-content-center">
            <Svg svgStyle={{ height: '130px' }} svgClassName="pt-5" src={Box} />
          </div>

          <div className="row h-100 d-flex align-items-center justify-content-center mt-4 p-4">
            <p className="font-16">No goals configured yet</p>
          </div>
        </>
      )}
    </div>
  );
};

export default GoalList;
