import { store } from '@webfx/teamwork-web/src/contexts/ProjectContext';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectSearch = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectSearch',
  action: (actions, { projectSearchPage = true, projectId }) => {
    const { onSetAssigneeList, setSearchAllProjects } = actions.project.search;

    if (!projectSearchPage) {
      // Store expects a string to cope with html props for radio buttons
      setSearchAllProjects({ value: projectSearchPage ? 'false' : 'true' });
      onSetAssigneeList();
      return;
    }

    const viewingId = store.get.projectId();
    onSetAssigneeList();
    return `/projects/${projectId ?? viewingId}/search`;
  },
});
