import { thunk } from 'easy-peasy';

/* eslint-disable prefer-destructuring */
export const onUnMountOverview = thunk(
  async ({ clearActivitiesState, clearEventsMilestonesState }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    // const resetCompaniesWithProjects = actions.companies.resetCompaniesWithProjects;

    try {
      clearActivitiesState();
      clearEventsMilestonesState();
      // resetCompaniesWithProjects();
    } catch (e) {
      showError(e);
    }
  }
);
