import { action } from 'easy-peasy';

export const moveTodoList = action((state, { movedTodoList: { project } }) => {
  const { todoList, sidebarLists } = state;
  const id = state.todoList.todolistId;

  todoList.movedTo = { project };
  todoList.$skip = 0;
  todoList.hasMore = false;

  sidebarLists.active = sidebarLists.active.filter(({ todolistId }) => todolistId !== id);
  sidebarLists.completed = sidebarLists.completed.filter(({ todolistId }) => todolistId !== id);
});
