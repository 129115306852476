const initStore = {
  calendarAssignee: [],
  assignee: [],
  privateAssignee: [],
  assignedTo: null,
  dates: {
    from: null,
    to: null,
  },

  events: {
    items: [],
    select: null,
  },

  milestones: {
    items: [],
    select: null,
  },

  overdueMilestones: {
    map: [],
    list: {},
    skipCount: 0,
    hasMoreData: false,
    isLoading: false,
    overdueCount: 0,
  },
};

export default initStore;
