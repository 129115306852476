export const setSubscribersToPeople = (people, subscribers) => {
  people.forEach(({ users }) => {
    users.forEach((user) => {
      user.subscribed = subscribers.some(({ userId }) => userId === user.userId);
    });
  });

  people.forEach((company) => {
    company.subscribed = company.users.every(({ subscribed }) => subscribed);
  });
};
