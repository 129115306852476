import api from '../../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';

export const onMountSingleTodoListsTemplate = fetchWithLoaderAndRedirect({
  loaderKey: 'SingleTodoListsTemplate',
  action: async (actions, { todolistId }, getStoreState) => {
    const { setTemplateListItemsToStore, setTeamworkFxTodoCategories } =
      actions.teamworkFx.templates.todoLists;

    const data = await api.categories.find({ query: { type: 'template', $limit: -1 } });
    setTeamworkFxTodoCategories(data);

    const stateAfterSettingSidebar = getStoreState();
    const { filters } = stateAfterSettingSidebar.teamworkFx.templates.todoLists.sidebar;

    const listsResponse = await api.todoListsTemplate.find({
      query: {
        todolistTemplateId: todolistId,
        $join: ['owner', 'updater', 'lastDeployedDate', 'timesDeployedLast12Months'],
      },
      for: 'todoListTemplateTodos',
      search: filters.search || '',
    });

    setTemplateListItemsToStore(listsResponse);

    return `/templates/todos/${todolistId}`;
  },
});
