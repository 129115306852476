export const initState = {
  todoList: {
    $skip: 0,
    hasMore: false,
    isColorCoding: false,
    colorCodes: null,
    movedTo: null,
    todos: {
      active: [],
      completed: [],
      map: {},
    },
  },
  todoListForm: {
    templates: [],
    milestones: [],
  },
  sidebarLists: {
    active: [],
    completed: [],
  },
  filters: {
    assignee: {
      label: 'Anyone',
      selectedValueLabel: 'Anyone',
      value: {
        responsibleUserId: undefined,
        responsibleCompanyId: undefined,
      },
      avatarUrl: null,
    },
    dueDate: {
      label: 'Anytime',
      value: 'anytime',
    },
    search: '',
  },
  assignees: [],
  moveTodoForm: {
    projects: [],
    todoLists: [],
  },
  completedTodosLimit: 3,
};
