export const projectTeamworkFxTemplatesStore = {
  items: {
    list: [],
    map: {},
  },
  categories: [],
  sidebar: {
    showAll: true,
    list: [],
    map: {},
    isFiltered: false,
  },
  pagination: {
    currentPage: 1,
    hasMore: false,
  },
};
