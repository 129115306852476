import { action } from 'easy-peasy';

import { getSelectedMonthDate } from '../../../../../utils-deprecated/calendar';
import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';

export const updateMilestones = action((state, { milestones, timezone }) => {
  // update existed milestones
  const existedMilestones = state.milestones.items.map((milestone) => {
    const updatedMilestone = milestones.find(
      ({ milestoneId }) => milestoneId === milestone.milestoneId
    );
    if (updatedMilestone) {
      return updatedMilestone;
    }
    return milestone;
  });
  // add another milestones
  const anotherMilestones = milestones.filter(
    (milestone) =>
      !state.milestones.items.some(({ milestoneId }) => milestoneId === milestone.milestoneId)
  );

  const currentDate = getSelectedMonthDate(null, timezone).toFormat('yyyy-LL-dd');
  milestones.forEach((milestone) => {
    if (milestone.dueAt < currentDate) {
      const searchedItem = state.milestones.overdue.find(
        ({ milestoneId }) => milestoneId === milestone.milestoneId
      );

      if (searchedItem) {
        Object.assign(searchedItem, { responsibleCompany: null, responsibleUser: null }, milestone);
      } else {
        state.milestones.overdue.push(milestone);
      }
    } else {
      state.milestones.overdue = state.milestones.overdue.filter(
        (item) => item.milestoneId !== milestone.milestoneId
      );
    }
  });

  state.milestones.overdue = sortOverdueMilestones(state.milestones.overdue);
  state.milestones.items = [...existedMilestones, ...anotherMilestones];
});
