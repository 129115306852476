import { action } from 'easy-peasy';

import { toggleTodoLists } from '../helpers/toggleTodoLists';

export const showSidebarAllTodoLists = action((state) => {
  const todoLists = state.todoLists;
  toggleTodoLists({ todoLists, checked: true });

  state.todoLists = todoLists;
});
