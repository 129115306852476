import { action } from 'easy-peasy';

import { deleteTodosFromLists } from '../helpers/deleteTodosFromLists';
import { setTodosToLists } from '../helpers/setTodosToLists';

export const filterTodosByAssignee = action((state, { todosBag, assignee }) => {
  const { filters } = state;
  const { active, completed, map } = state.todoLists;

  state.todoLists = setTodosToLists({
    todoLists: {
      active,
      completed,
      map: deleteTodosFromLists({ active, map }),
    },
    todosBag,
  });

  filters.assignee = assignee;
});
