import { computed } from 'easy-peasy';
const initStore = {
  loading: false,
  items: {
    list: [],
    map: {},
    total: 0,
  },
  filters: {
    sort: {
      options: [
        { label: 'Relevance', value: 'relevance' },
        { label: 'Date Asc', value: 'dateAsc' },
        { label: 'Date Desc', value: 'dateDesc' },
        { label: 'User', value: 'user' },
      ],
      selected: { label: 'Relevance', value: 'relevance' },
    },
    searchAllProjectsRadio: 'false',
    searchAllProjects: computed(
      [(state) => state.searchAllProjectsRadio],
      (searchAllProjectsRadio) => searchAllProjectsRadio === 'true'
    ),
    search: '',
    lastSearch: '',
    assignee: '',
    assigneeList: [],
    date: {
      from: '',
      to: '',
    },
    searchAllCategories: computed([(state) => state.categories], (categories) =>
      categories.every((c) => !c.checked)
    ),
    selectedCategories: computed([(state) => state.categories], (categories) =>
      categories.filter((c) => c.checked).map((c) => c.value)
    ),
    categories: [
      {
        title: 'Messages',
        value: 'message',
        checked: false,
      },
      { title: 'Comments', value: 'comment', checked: false },
      { title: 'To-dos', value: 'todo', checked: false },
      { title: 'Files', value: 'attachment', checked: false },
      { title: 'Milestones and Events', value: 'event', checked: false },
      { title: 'Projects', value: 'project', checked: false, onlyForSearchAll: true },
    ],
  },
  scrollParams: {
    skipCount: 0,
    hasMoreData: true,
    loading: false,
  },
};

export default initStore;
