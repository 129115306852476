import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../ui/constants/limits';
import { getOverdueDate } from '../../../../../utils-deprecated/calendar';

export const onMountOverdueMilestones = thunk(
  async ({ setOverdueMilestones }, _, { getStoreActions }) => {
    const { timezone } = store.auth.user() ?? {};

    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const overdueDate = getOverdueDate(timezone);
    const query = {
      dueAt: { $lte: overdueDate },
      $client: { for: 'overdueCalendar' },
      $sort: { dueAt: -1 },
      $limit: overdueMilestonesLimit,
    };

    const key = 'teamworkFx.dashboard.mountDashboardOverdueMilestones';
    showLoader({ key });

    try {
      const overdueMilestones = await api.milestones.find({ query });
      setOverdueMilestones({
        milestones: overdueMilestones.data,
        hasMoreData: overdueMilestones.hasMore,
        overdueCount: overdueMilestones.count,
      });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key });
  }
);
