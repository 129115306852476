import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onDeleteTeamworkFxUser = thunk(async (_, { userId, push }, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;
  const { onMountTeamworkFxAllPeoplePage } = actions.pages.allPeople;

  const loader = 'teamworkFx.allPeople.editUser.deleteUser';
  showLoader({ key: loader });
  try {
    await api.users.remove(userId);
    onMountTeamworkFxAllPeoplePage({ pushParams: { push } });
  } catch (err) {
    showError(err);
  }
  hideLoader({ key: loader });
});
