import allPeopleActions from './actions';
import allPeopleThunks from './thunks';

const allPeople = {
  skipCount: 0,
  hasMoreData: false,
  companies: [],
  userCompany: {},
  searchText: '',

  // actions
  ...allPeopleActions,
  // thunks
  ...allPeopleThunks,
};

export default allPeople;
