import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';
// TODO:Todos - move this to general todo helpers
import { getRequestInfo } from '../../../todoLists/helpers/getTodoData';

export const onEditTodo = thunk(
  async ({ editTodo }, { todoId, values, setSubmitting, onCloseForm }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const currentUserId = store.auth.user()?.userId;

    try {
      const todo = await api.todos.patch(todoId, getRequestInfo(values), { for: 'onEditTodo' });
      editTodo({ todo, currentUserId });
      onCloseForm();
    } catch (e) {
      showError(e);
    }

    setSubmitting(false);
  }
);
