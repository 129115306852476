import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

import reorderTodoListHelper from '../helpers/reorderTodoListHelper';

export const reorderTodo = action(
  (state, { sourceTodolistId, destinationTodolistId, todoId, position }) => {
    const targetTodos = state.todoLists.map[destinationTodolistId].todos;
    reorderTodoListHelper(targetTodos, todoId, sourceTodolistId, destinationTodolistId, position);
    const todos = targetTodos.active.map((t) => targetTodos.map[t]);
    targetTodos.active = sortBy(todos, ['position']).map(({ todoId }) => todoId);
  }
);
