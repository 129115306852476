import { action } from 'easy-peasy';
import uniqBy from 'lodash/uniqBy';

export const mountProjectMilestonesTemplate = action((state, { milestones = [] }) => {
  const weekList = milestones
    .reduce((acc, cur) => {
      return uniqBy([...acc, cur.weekDue]);
    }, [])
    .sort((a, b) => a - b);

  const milestonesList = weekList.reduce(
    (accumulator, curent, _, arr) => {
      const daysList = milestones
        .reduce((acc, cur) => {
          if (cur.weekDue === curent) {
            acc.push(cur.dowDue);
          }
          return uniqBy([...acc]);
        }, [])
        .sort((a, b) => a - b);
      const days = daysList.reduce((acc, cur) => {
        const milstonesList = milestones.reduce((accum, cure) => {
          if (cure.dowDue === cur && cure.weekDue === curent) {
            accum.push(cure.projectTemplateMilestoneId);
          }
          return [...accum];
        }, []);
        const milestonesMap = milstonesList.reduce((accumul, curen) => {
          return {
            ...accumul,
            [curen]: milestones.filter((x) => x.projectTemplateMilestoneId === curen)[0],
          };
        }, {});
        return {
          ...acc,
          [cur]: {
            list: milstonesList,
            map: {
              ...milestonesMap,
            },
          },
        };
      }, {});
      accumulator.weeks.list = arr;
      accumulator.weeks.map = {
        ...accumulator.weeks.map,
        [curent]: {
          days: {
            list: daysList,
            ...days,
          },
        },
      };
      return { ...accumulator };
    },
    { weeks: { list: [], map: {} } }
  );

  state.milestones = milestonesList;
});
