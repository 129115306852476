import { action, actionOn } from 'easy-peasy';
import set from 'lodash/set';
import { onSetPastebinTemplateToEditor } from './thunks/onSetPastebinTemplateToEditor';
const common = {
  modal: {},
  loader: {
    globalLoader: {},
    project: {
      messages: {
        categories: {
          hasAnyLoading: false,
          showAll: false,
        },
        message: {
          deleteMessage: false,
        },
      },
      todos: {
        completeTodo: {},
        dragTodos: false,
        deleteTodo: false,
        reorderTodoLists: false,
        todoLists: {
          colorCoding: {},
          sidebar: {
            hasAnyLoading: false,
            assignee: false,
            dueDate: false,
            showAll: false,
          },
          moveTodoList: false,
          moveTodoForm: {
            getProjects: false,
            getTodoLists: false,
            isTodoMoving: false,
          },
        },
        singleTodoList: {
          colorCoding: false,
          deleteList: false,
          sidebar: {
            hasAnyLoading: false,
            assignee: false,
            dueDate: false,
            showAll: false,
          },
          moveTodoList: false,
          moveTodoForm: {
            getProjects: false,
            getTodoLists: false,
            isTodoMoving: false,
          },
        },
        todo: {
          loadAllComments: false,
          completeTodo: false,
          moveTodoForm: {
            getProjects: false,
            getTodoLists: false,
            isTodoMoving: false,
          },
        },
      },
      overview: {
        calendar: {
          deleteMilestone: false,
          deleteEvent: false,
          editMilestone: false,
        },
        activities: {
          getOverviewPaginationActivities: {
            next: false,
            previous: false,
          },
        },
        overdueMilestones: {
          getMore: false,
        },
      },
      search: {
        hasAnyLoading: false,
        projectSearch: false,
        projectSearchGetMore: false,
        toggleSearchAcross: false,
        categories: {
          showAll: false,
        },
      },
      calendar: {
        deleteMilestone: false,
        deleteEvent: false,
        editMilestone: false,
        editSelectedMilestone: false,
        filters: {
          assignee: false,
        },
        overdueMilestones: {
          getMore: false,
        },
      },
      settings: {
        deleteProject: false,
      },
      files: {
        deleteFile: false,
      },
      peopleAndPermissions: {
        editUser: {
          updateProjectAccess: false,
          deleteUser: false,
        },
        unSubscribe: false,
        subscribe: false,
        removeCompanyFromProject: false,
        users: {},
        nonWebFxUsers: {
          hasAnyLoading: {},
          general: {},
          // TODO in the next phase should rename to messagesAndFiles
          'messages and files': {},
          todos: {},
          calendar: {},
        },
      },
      // we use comments here as a global loader for all comments in project (e.g. message, todo, milestone comments)
      comments: {
        deleteComment: false,
      },
    },
    teamworkFx: {
      dashboard: {
        deleteMilestone: false,
        deleteEvent: false,
        editingMilestone: false,
        overdueMilestones: {
          getMore: false,
          isLoading: false,
        },
        findCompanies: false,
        mountDashboardCalendar: false,
        mountDashboardOverdueMilestones: false,
      },
      calendar: {
        deleteMilestone: false,
        deleteEvent: false,
        filters: {
          hasAnyLoading: false,
          assignee: false,
          nextMonth: false,
          prevMonth: false,
        },
        overdueMilestones: {
          getMore: false,
          isLoading: false,
        },
      },
      templates: {
        projects: {
          categories: {
            showAll: false,
          },
          deleteProjectTemplate: false,
        },
        todoLists: {
          sidebar: {
            hasAnyLoading: false,
            showAll: false,
            byText: false,
          },
          list: {
            deleteList: false,
            deleteTodo: false,
          },
        },
      },
      search: {
        hasAnyLoading: false,
        teamworkFxSearch: false,
        teamworkFxSearchGetMore: false,
        categories: {
          showAll: false,
        },
      },
      allPeople: {
        allPeople: {
          hasAnyLoading: false,
          search: false,
        },
        editUser: {
          updateProjectAccess: false,
          deleteUser: false,
        },
      },
      todos: {
        getAllTodos: true,
        completed: {},
      },
    },
    projectTemplate: {
      todoLists: {
        hasAnyLoading: false,
        sidebar: {
          assigneeId: false,
          search: false,
          categories: {
            showAll: false,
          },
        },
        todo: {
          deleteTodo: false,
        },
        deleteProjectTemplateTodoList: false,
        changingTemplateTodoListsOrder: false,
      },
      milestones: {
        deleteMilestone: false,
      },
    },
    archivedProjects: {
      getCompaniesProjects: false,
    },

    // These using for global spinner
    // through different pages
    companies: {
      findCompanies: false,
      findCompaniesWithoutAccess: false,
    },

    getCompaniesWithProjects: false,
    getCategories: true,
    editCompanyLogo: false,
    deleteCompany: false,
    getUsers: true,
    getTodoLists: true,
    getMilestones: true,
    getMilestoneSingle: true,
    getTemplates: true,
    // getTodos: true,  // delete
    getProjectTemplates: true,
    switchProject: false,
    // for single page - delete after refactoring
    // getTodoList: true,  // delete
    // filterTodoLists: true, // delete
    getAllFiles: true,
    getPrivateAssignee: true,
    getCreateCategories: true,
    getUsersOfFiles: true,
    restoreDeletedProject: false,
    getDashboard: true,
    // TODO should move to different loader state part (project & teamworkFx)
    // after splitting global users store to a separate
    deleteUser: false,

    // This values are using for checking do we can show loader again in some pages
    showAllFilesLoader: true,
    showAllPeopleCompaniesLoader: true,
  },
  error: {},
  appError: null,
  isMobile: false,
  isTablet: false,
  isLaptop: true,
  screenSize: 0,
  reloadPageMessage: null,

  onSetPastebinTemplateToEditor,

  // actions
  showModal: action((state, payload) => {
    state.modal[payload.key] = true;
  }),

  hideModal: action((state, payload) => {
    state.modal[payload.key] = false;
  }),

  showLoader: action((state, { key }) => {
    const paths = Array.isArray(key) ? key : [key];
    paths.forEach((path) => {
      set(state.loader, path, true);
    });
  }),

  hideLoader: action((state, { key }) => {
    const paths = Array.isArray(key) ? key : [key];
    paths.forEach((path) => {
      set(state.loader, path, false);
    });
  }),

  showError: action((state, error) => {
    state.error = error;
  }),

  hideError: action((state) => {
    state.error = {};
  }),

  setAppError: action((state, payload) => {
    state.appError = payload;
  }),

  clearAppError: actionOn(
    (actions, storeActions) => storeActions.router.setLocation,
    (state, target) => {
      state.appError = null;
    }
  ),

  showNotification: action((state, payload) => {
    state.error = payload;
  }),

  setScreenSize: action((state, payload) => {
    state.isMobile = payload === 'mobile';
    state.isTablet = payload === 'tablet';
    state.isLaptop = payload === 'laptop';
  }),

  setScreenSizeInPx: action((state, payload) => {
    state.screenSize = payload;
  }),

  clearLoadersState: action((state, payload) => {
    payload.keys.forEach((key) => (state.loader[key] = true));
  }),

  showChunkLoader: action((state, payload) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!state.loader.globalLoader.hasOwnProperty(`chunk${payload.key}`)) {
      state.loader.globalLoader[`chunk${payload.key}`] = true;
    }
  }),

  showReloadPageMessage: action(
    (
      state,
      {
        message = 'There are new changes on this page.',
        reloadHandler = () => window.location.reload,
      }
    ) => {
      state.reloadPageMessage = { message, reloadHandler };
    }
  ),

  hideReloadPageMessage: action((state) => {
    state.reloadPageMessage = null;
  }),

  hideReloadPageMessageOnRouteChange: actionOn(
    (actions, storeActions) => storeActions.router.setLocation,
    (state, target) => {
      state.reloadPageMessage = null;
    }
  ),
};

export default common;
