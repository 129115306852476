import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

export const onGetTemplates = thunk(async ({ setProjectTemplates }, _, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;

  showLoader({ key: 'getProjectTemplates' });
  try {
    const data = await api.projectTemplates.find({ query: { $limit: -1 } });
    setProjectTemplates(data);
  } catch (e) {
    showError(e);
  }
  hideLoader({ key: 'getProjectTemplates' });
});
