import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const projectsUsers = feathersClient.service('tw/projects-users');

/**
 *
 * @param body
 * @param params
 */
export const create = (body, params) => projectsUsers.create(body, paramsForServer(params));

/**
 *
 * @param id
 */
export const find = (id) => projectsUsers.find(id);

/**
 *
 * @param projectUserId
 * @param accessLevel
 * @param params
 */
export const patch = (projectUserId, accessLevel, params) =>
  projectsUsers.patch(projectUserId, accessLevel, paramsForServer(params));

/**
 *
 * @param id
 * @param params
 */
export const remove = (id, params) => projectsUsers.remove(id, paramsForServer(params));
