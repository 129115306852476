import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const comments = feathersClient.service('tw/comments-deprecated');

/**
 *
 * @param payload
 */
export const create = (payload) =>
  comments.create(payload, paramsForServer({ for: 'usersAndFiles' }));
/**
 *
 * @param params
 */
export const find = (params) => comments.find(paramsForServer(params));
/**
 *
 * @param commentId
 * @param payload
 */
export const patch = (commentId, payload) =>
  comments.patch(commentId, payload, paramsForServer({ for: 'usersAndFiles' }));
/**
 *
 * @param commentId
 */
export const remove = (commentId) => comments.remove(commentId);
