import { action } from 'easy-peasy';

export const reorderTodoList = action((state, { reorderedTodoLists }) => {
  // here we use the map to modify both props in a single loop
  state.todoLists.active = reorderedTodoLists.map(({ todolistId, position }) => {
    // update map positions
    state.todoLists.map[todolistId].position = position;
    // return for reordering active
    return todolistId;
  });
});
