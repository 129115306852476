const getOrderList = ({ sidebarLists: { list, map }, todoListsOrder }) => {
  const lists = list.map((projectTemplateTodoListId) => ({
    projectTemplateTodoListId,
    checked: map[projectTemplateTodoListId].checked,
    position: map[projectTemplateTodoListId].position,
  }));

  const newPositions = lists
    .filter(({ checked }) => checked)
    .map(({ position }, index) => ({
      projectTemplateTodoListId: Number(todoListsOrder[index]),
      position,
    }));

  return lists
    .map(({ projectTemplateTodoListId, position, checked }) => {
      const newPosition = newPositions.find(
        ({ projectTemplateTodoListId: id }) => id === projectTemplateTodoListId
      );
      return newPosition
        ? {
            projectTemplateTodoListId,
            position: newPosition.position,
            checked,
          }
        : {
            projectTemplateTodoListId,
            position,
            checked,
          };
    })
    .sort((a, b) => a.position - b.position);
};

export default getOrderList;
