import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onCreateProjectTemplateTodoList = thunk(
  async (
    { addProjectTemplateList },
    { values, setSubmitting, id, onClosePopup, singlePage, push },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;
    const { onMountProjectTemplateTodoLists } = actions.projectTemplate.todoLists;

    try {
      const createdList = await api.projectTemplateTodoLists.create(
        { ...values, projectTemplateId: Number(id) },
        { for: 'projectTemplateTodoList' }
      );
      addProjectTemplateList(createdList);

      if (singlePage && push) {
        onMountProjectTemplateTodoLists({ projectTemplateId: Number(id), pushParams: { push } });
      }
    } catch (e) {
      showError(e);
    }
    onClosePopup();
    setSubmitting(false);
  }
);
