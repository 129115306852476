export const toBool = (value) => {
  if (value === null) {
    return false;
  }
  if (typeof value === 'undefined') {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'number') {
    return value !== 0;
  }
  if (typeof value === 'string') {
    return value !== '';
  }
  if (typeof value === 'object') {
    return true;
  }

  throw new Error(
    `The arg ${value} is a ${typeof value} type. You should implement the check logic for this type`
  );
};
