import superagent from 'superagent';
import { API_COLOR_CODES } from '../../config/app';

export const colorCodes = (todoIds) => {
  const data = {
    auth: 'ee06cace-eccc-4683-b968-167503400ae3',
    version: 2,
    todo_ids: todoIds,
  };
  return superagent
    .post(API_COLOR_CODES)
    .send(data)
    .then((res) => res.body)
    .catch((err) => {
      throw new Error(err);
    });
};
