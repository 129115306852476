import api from '../../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxEditUserPage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxEditUserPage',
  action: async (actions, { userId }) => {
    const { mountTeamworkFxEditUserPage } = actions.teamworkFx.allPeople.editUser;

    const [selectedUser, companies] = await Promise.all([
      api.users.get(userId, { for: 'projectsAccess' }),
      api.companies.find({
        query: { $sort: { name: 1 }, $limit: -1 },
        for: 'users',
      }),
    ]);

    mountTeamworkFxEditUserPage({ selectedUser, companies });
  },
});
