import useCompanySettings from './useCompanySettings';

export default function useQuarterSetting(companyId) {
  const query = {
    field: 'quarters',
    $sort: { createdAt: -1 },
    $limit: 1,
  };

  const { data: companySettings } = useCompanySettings(companyId, {
    query,
  });

  return Number(companySettings?.data[0]?.value);
}
