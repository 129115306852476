import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import { Svg } from '@webfx/core-web';
import useSiteSetting from '@webfx/marketingcloud-web/src/hooks/useSiteSetting';
import { useAuth, useActiveSite } from '@webfx/web-hooks';
import useSiteSettings from '@webfx/playbook-web/src/hooks/useSiteSettings';

import NewIcon from '../../../assets/svgs/new-strategy-icon.svg';

const NewStrategyIcon = ({ className, iconSize, onMenu = false }) => {
  const activeSite = useActiveSite();
  const { isAdmin, user } = useAuth();

  const { data: siteSettings } = useSiteSettings({
    siteId: user?.sites ? { $in: user.sites.map((res) => res.siteId) } : -1, //ensures fetching of data does not happen when no user sites are available
    field: 'playbookfx',
    $limit: -1,
  });
  const { data: selectedSiteSetting, isLoading } = useSiteSetting(activeSite?.siteId, 'playbookfx');

  /**
   * Show new badge only when new strategy is added and 30 days has not pass.
   * After 30 days, the badge should not show anymore.
   *
   * @returns boolean
   */
  const isNewStrategy = useCallback((creationDate, updateDate) => {
    const today = DateTime.now();
    const { days: daysFromCreation } = DateTime.fromISO(creationDate)
      .until(today)
      .toDuration(['days'])
      .toObject();
    const { days: daysFromUpdate } = DateTime.fromISO(updateDate)
      .until(today)
      .toDuration(['days'])
      .toObject();

    return daysFromCreation < 30 || daysFromUpdate < 30;
  }, []);

  const newStrategyFound = useMemo(() => {
    if (isAdmin && selectedSiteSetting) {
      // When user is admin, show the icon only when a site is selected
      return isNewStrategy(selectedSiteSetting.createdAt, selectedSiteSetting.updatedAt);
    } else {
      if (siteSettings && onMenu) {
        // When user is not an admin, we show the icon on the top menu always
        for (const setting of siteSettings) {
          if (isNewStrategy(setting.createdAt, setting.updatedAt)) {
            return true;
          }
        }
      }
      // When user is not an admin, we show the icon elsewhere only once a site is selected
      if (selectedSiteSetting && !onMenu) {
        return isNewStrategy(selectedSiteSetting.createdAt, selectedSiteSetting.updatedAt);
      }
    }

    return false;
  }, [siteSettings, isNewStrategy, selectedSiteSetting, isAdmin, onMenu]);

  return (
    <>
      {!isLoading && newStrategyFound && (
        <div className={className}>
          <Svg src={NewIcon} svgStyle={{ width: iconSize }} />
        </div>
      )}
    </>
  );
};

export default NewStrategyIcon;
