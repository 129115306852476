const initStore = {
  message: {
    messageId: null,
    title: '',
    content: '',
    categoryId: null,
    private: false,
    milestoneId: null,
    files: [],
    subscribers: [],
  },
  categories: [],
  milestones: [],
  pastebinTemplates: [],
  undo: false,
};

export default initStore;