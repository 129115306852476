import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';
import { getContentAsHtml } from '../../../../../../../utils-deprecated/rte/utils';

// update first comment -> update comment body (if we use Before sending to the POC)

const updateFirstComment = async ({ actions, state, firstCommentId, reactQuillRef }) => {
  if (!firstCommentId) {
    return;
  }
  const firstComment = state.project.todos.todo.comments.map[firstCommentId];
  if (!firstComment) {
    return;
  }

  const editComment = actions.project.todos.todo.editComment;
  const currentUserId = store.auth.user()?.userId;

  // user cannot update comments of other users
  if (firstComment.creatorUserId !== currentUserId) {
    return;
  }

  // we don't change subscribers in `before sending to the POC` popup
  // so we take them from sidebar store directly
  const subscribers = state.project.todos.todo.sidebar.subscribers;
  const body = getContentAsHtml(reactQuillRef);

  const comment = await api.comments.patch(
    firstComment.commentId,
    {
      ...firstComment,
      body,
      subscribers: subscribers.map(({ userId }) => ({ userId })),
      files: firstComment.files.map((file) => ({
        commentId: firstComment.commentId,
        extension: file.fileExtension,
        byteSize: file.fileSize,
        type: file.fileType,
        name: file.filename,
        uploadId: file.serverId,
      })),
    },
    { for: 'usersAndFiles', editCommentAnyway: true }
  );

  editComment({ comment, currentUserId });
};

export const onCompleteTodo = thunk(
  async (
    { completeTodo },
    { todolistId, todoId, toggleShowConfirmation, firstCommentId, reactQuillRef },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;
    const state = getStoreState();

    const loader = 'project.todos.todo.completeTodo';
    showLoader({ key: loader });

    try {
      const todo = await api.todos.patch(
        todoId,
        { todolistId },
        { for: 'onToggleTodoCompletedStatus', completed: true }
      );
      completeTodo({ todo });
      await updateFirstComment({ actions, state, firstCommentId, reactQuillRef });
      if (toggleShowConfirmation) {
        toggleShowConfirmation(false);
      }
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
