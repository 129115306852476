import { thunk } from 'easy-peasy';

/* eslint-disable prefer-destructuring */
export const onUnMountTeamworkFxDashboardPage = thunk(async (_, __, { getStoreActions }) => {
  const actions = getStoreActions();
  const showError = actions.common.showError;
  const clearDashboardState = actions.teamworkFx.dashboard.clearDashboardState;

  try {
    clearDashboardState();
  } catch (e) {
    showError(e);
  }
});
