import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';
import api from '../../../../../../services/api';

export const onCompleteTodo = thunk(
  async ({ completeTodo, unCompleteTodo }, { todolistId, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = `project.todos.completeTodo.${todoId}`;
    showLoader({ key: loader });

    try {
      api.todos.patch(
        todoId,
        { todolistId },
        { for: 'onToggleTodoCompletedStatus', completed: true }
      );
      completeTodo({
        todo: {
          todoId,
          todolistId,
          completedAt: DateTime.local().toISO(),
        },
      });
    } catch (e) {
      showError(e);
      unCompleteTodo({
        todo: {
          todoId,
          todolistId,
          completedAt: null,
        },
      });
    }

    hideLoader({ key: loader });
  }
);
