import { useState } from 'react';

import { Dialog } from '@webfx/web-ui';
import { Icon, Button, SelectInput, Spinner, api } from '@webfx/core-web';
import { useActiveSite, useNotifications } from '@webfx/web-hooks';
import { useGamePlanKanbanView } from '@webfx/gameplan-ui';

import useProjectsOptimizationsMutator from '@webfx/gameplan-ui/src/hooks/useProjectsOptimizationsMutator';

import { getMonth, getKanbanMonths } from '@webfx/web-ui/src/QuarterSelector/utils';

const MoveOptimization = ({ projectOptimization, companyId, quarter, open, onClose }) => {
  const { objectId, type, strategyBuilderId } = projectOptimization;

  const site = useActiveSite();
  const queryClient = api.useQueryClient();
  const { toast } = useNotifications();
  const { mutator } = useProjectsOptimizationsMutator({
    optimizationId: objectId,
  });

  const { data: playbookKanbanMonthViewSetting } = useGamePlanKanbanView(companyId);
  const { kanbanMonthView } = playbookKanbanMonthViewSetting;

  const months = getKanbanMonths({
    quarter,
    monthView: kanbanMonthView,
  });

  const monthsInWords = months.map((month) => {
    const year = new Date(month).getFullYear();
    return {
      label: `${getMonth(month, 'short')} ${year}`,
      value: month,
    };
  });

  const [selectedMonth, setSelectedMonth] = useState(projectOptimization?.dueDate);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = (selectedMonth) => {
    setIsSaving(true);
    const year = new Date(selectedMonth).getFullYear();
    mutator.mutate(
      {
        _method: 'patch',
        dueDate: selectedMonth,
        strategyBuilderId,
        type,
        siteId: site.siteId,
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries('tw/optimizations');

          toast.success(
            `Successfully moved optimization item to ${getMonth(selectedMonth, 'long')} ${year}`,
            {
              autoClose: 3000,
              pauseOnHover: true,
            }
          );

          setIsSaving(false);
          onClose();
        },
        onError: (err) => {
          const { message } = err;
          toast.error(`Failed to move optimization item. Please try again. Error: ${message}`);
          setIsSaving(false);
          onClose();
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Panel className="font-14" style={{ width: '450px' }}>
        <Dialog.Title className="d-flex align-items-center font-14 mb-3 border-bottom pb-2">
          <Icon className="font-20 mr-2">calendar_month</Icon>Move item to...
        </Dialog.Title>
        <SelectInput
          defaultValue={selectedMonth}
          field={{ value: selectedMonth, name: 'selectedMonth' }}
          menuPlacement="auto"
          options={monthsInWords}
          onChange={(month) => setSelectedMonth(month)}
        />
        <div className="d-flex justify-content-end align-items-center mt-4">
          {!isSaving ? (
            <>
              <Button
                text="Cancel"
                id="cancelBtn"
                outline
                className="btn border-blue-vivid-400 text-blue-vivid-400 mr-1"
                onClick={onClose}
              />
              <Button
                text="Move"
                className="btn btn-primary ml-2"
                onClick={() => handleSave(selectedMonth.value)}
              />
            </>
          ) : (
            <Spinner size="sm" />
          )}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default MoveOptimization;
