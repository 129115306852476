import api from '../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountChangePermissions = fetchWithLoaderAndRedirect({
  loaderKey: 'ChangePermissions',
  action: async (actions, { projectId }, getStoreState) => {
    const state = getStoreState();

    const { setProjectForPeopleAndPermissions } = actions.ui.changePermissions;

    const [project] = await Promise.all([
      api.projects.get({ projectId, params: { for: 'peopleAndPermissions' } }),
    ]);
    setProjectForPeopleAndPermissions({ project });

    return state.router.url({ route: 'changePermissions', projectId });
  },
});
