import { action } from 'easy-peasy';

export const wsAddProjectOverviewMilestoneComment = action((state, { comment, currentUserId }) => {
  const { milestoneId, creatorUserId } = comment;
  // Handle the create event only for milestone comments
  if (!milestoneId) {
    return;
  }

  const index = state.milestones.items.findIndex(({ milestoneId: id }) => id === milestoneId);
  state.milestones.items[index].commentsCount += 1;

  // if comment was added by non-current user, set to true indicator of new comments
  if (creatorUserId !== currentUserId) {
    state.milestones.items[index].hasNewComments = true;
  }
});
