import { action } from 'easy-peasy';
import getTeamworkFxSearchParams from '../helpers/getTeamworkFxSearchParams';

export const setTeamworkFxSearchedByCategories = action(
  (
    state,
    {
      categoryId,
      checked,
      searchedValue: {
        body: { hits },
      },
      search,
    }
  ) => {
    const changedCategories = state.filters.sidebar.categories.map((category) => {
      if (category.value === categoryId) {
        const index = state.filters.sidebar.selected.indexOf(categoryId);
        if (index !== -1) {
          state.filters.sidebar.selected.splice(index, 1);
        } else {
          state.filters.sidebar.selected.push(categoryId);
        }
        return { ...category, checked };
      }
      return category;
    });

    state.filters.lastSearched = search;
    state.filters.sidebar.categories = changedCategories;
    state.filters.sidebar.showAll = !state.filters.sidebar.selected.length;
    state.filters.isFiltered = true;
    state.filters.pageIndex = 0;

    const searchedParams = getTeamworkFxSearchParams({
      state,
      searchedValue: hits,
      clearItems: true,
    });
    state.items.list = searchedParams.items.list;
    state.items.map = searchedParams.items.map;
    state.items.total = hits.total.value;

    state.scrollParams.skipCount = searchedParams.scrollParams.skipCount;
    state.scrollParams.hasMoreData = searchedParams.scrollParams.hasMoreData;
  }
);
