import classnames from 'classnames';
import { Spinner, Icon } from '@webfx/core-web';

import styles from './Checkbox.module.css';

const Checkbox = ({
  field: { name, value, onBlur = () => {} },
  form: { setFieldValue },
  disabled,
  small = false,
  readOnly,
  isLoading,
  uncheckedGrayColor = false,
  iconClassName,
  iconName = 'check',
  hasChangeColorWhenDisabled = true,
}) => (
  <div
    className={classnames([
      small ? styles.smallCheckbox : '',
      'custom-checkbox',
      styles.checkbox,
      {
        [styles.checkboxDisabled]: hasChangeColorWhenDisabled && disabled,
        [styles.checkboxDisabledChecked]: hasChangeColorWhenDisabled && disabled && value,
        [styles.checkboxReadOnly]: readOnly,
        [styles.checkboxReadOnlyChecked]: readOnly && value,
        [styles.checkboxChecked]: value,
      },
    ])}
  >
    <div
      className={classnames(styles.iconWrap, {
        [styles.iconWrapDisabled]: hasChangeColorWhenDisabled && disabled,
        [styles.iconWrapDisabledChecked]: hasChangeColorWhenDisabled && disabled && value,
        [styles.iconWrapReadOnly]: readOnly,
        [styles.iconWrapReadOnlyChecked]: readOnly && value,
        [styles.iconWrapChecked]: value,
      })}
    >
      {isLoading ? (
        <Spinner animation="border" size="sm" className={styles.spinner} />
      ) : (
        <Icon
          className={classnames(
            styles.icon,
            {
              [styles.iconDisabled]: hasChangeColorWhenDisabled && disabled,
              [styles.iconDisabledChecked]: hasChangeColorWhenDisabled && disabled && value,
              [styles.iconReadOnly]: readOnly,
              [styles.iconReadOnlyChecked]: readOnly && value,
              [styles.iconChecked]: value,
            },
            iconClassName
          )}
          outlined={uncheckedGrayColor}
        >
          {iconName}
        </Icon>
      )}
    </div>
    <input
      type="checkbox"
      className={classnames(styles.input, { [styles.inputDisabled]: disabled || readOnly })}
      id={name}
      name={name}
      onChange={() => setFieldValue(name, !value)}
      onBlur={onBlur}
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      checked={value}
      data-fx-name="checkbox"
    />
  </div>
);

export default Checkbox;
