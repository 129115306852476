import { action, debug } from 'easy-peasy';
import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';
import { formatingActivity } from '../../../../../utils-deprecated/formatingActivity';
import groupDate from '../helpers/groupDate';

export const mountProjectOverview = action((state, payload) => {
  const {
    subscriberId,
    milestones,
    events,
    overdueMilestones,
    calendarDates,
    activities,
    userTimezone,
  } = payload;

  if (subscriberId) {
    state.subscriberId = subscriberId;
  }

  if (milestones) {
    state.milestones.items = milestones;
  }
  if (events) {
    state.events.items = events;
  }
  if (overdueMilestones) {
    state.milestones.overdue = sortOverdueMilestones(overdueMilestones.data);
    state.milestones.skipCount = overdueMilestones.data.length;
    state.milestones.hasMoreData = overdueMilestones.hasMore;
    state.milestones.overdueCount = overdueMilestones.count;
  }
  if (calendarDates) {
    state.calendarDates = calendarDates;
  }

  const formattedActivities = [];

  if (!activities || !activities.data.length) {
    return;
  }

  activities.data.forEach((currentActivity) => {
    const formattedActivity = formatingActivity(currentActivity);
    const labelName = groupDate(formattedActivity.actionDate, userTimezone);

    const lastItem = formattedActivities[formattedActivities.length - 1];

    if (lastItem && lastItem.dateLabel === labelName) {
      lastItem.activities.push(formattedActivity);
      return;
    }

    formattedActivities.push({
      dateLabel: labelName,
      activities: [formattedActivity],
    });
  });

  state.activities.items = formattedActivities;
  state.activities.skipCount += activities.data.length;
  state.activities.hasMoreData = activities.total > state.activities.skipCount;
  state.activities.itemsOnPage = activities.data.length;
  state.activities.total = activities.total;
});
