import React, { useMemo } from 'react';
import { api } from '@webfx/core-web';

export default function useSiteSetting(siteId, setting) {
  const { data, ...query } = api.useQuery(['site-settings', siteId, { $cache: false }], {
    enabled: !!siteId,
  });

  const out = useMemo(() => {
    if (setting && data) {
      const item = data.find((s) => s.field === setting);
      return item !== -1 ? item : null;
    }
    return data;
  }, [siteId, setting, data]);

  return {
    data: out,
    ...query,
  };
}
