import { thunk, useStoreActions } from 'easy-peasy';

const onWSPatchedProjectUser = thunk(
  (actions, projectUser, { getState, getStoreState, getStoreActions }) => {
    const { data } = getState();
    const { project, router } = getStoreState();
    const {
      teamworkFx: {
        dashboard: { onUnMountTeamworkFxDashboardPage, onMountTeamworkFxDashboardPage },
      },
      permissions: { onFindPermissions },
    } = getStoreActions();

    const projectId = projectUser.projectId || projectUser?.projectsAccess?.projectId;

    actions.fetchOne({
      projectId,
      $select: ['projectId', 'name', 'startPage', 'status'],
      $client: { for: 'switchProjectsDropdown' },
    });

    if (project?.selectedProject?.projectId === projectId) {
      onFindPermissions({ projectId });
    }

    if (router.active === 'dashboard') {
      onUnMountTeamworkFxDashboardPage();
      onMountTeamworkFxDashboardPage({});
    }
  }
);

export default onWSPatchedProjectUser;
