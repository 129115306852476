import { action } from 'easy-peasy';

export const addTeamworkFxTemplateTodo = action((state, item) => {
  const { todolistTemplateId, todolistTemplateTodoId } = item;

  const list = state.lists.map[todolistTemplateId];

  if (list) {
    list.todos.push(todolistTemplateTodoId);
    state.todoItems[todolistTemplateTodoId] = item;
  }
});
