import { api } from '@webfx/core-web';
import { useFlags, useAuth } from '@webfx/web-hooks';

function useProjectsOptimizations({ query = {} }) {
  const flags = useFlags();
  const { isAdmin } = useAuth();

  const baseJoinFields = ['creator', 'goal', 'asset'];

  // Removed the 'project' field from the $join array as it is only used with the 'legacy' GamePlan and FXers only
  const $join =
    flags.gameplanfxUpdates || !isAdmin ? baseJoinFields : [...baseJoinFields, 'projects'];

  return api.useQuery(
    [
      'tw/optimizations',
      {
        ...query,
        $join,
        $sort: { completedAt: 1, createdAt: -1 },
      },
    ],
    {
      staleTime: 1000 * 60 * 15, // 15 min
      cacheTime: 1000 * 60 * 30, // 30 min
      placeholderData: [],
      select: (data) => data ?? [],
    }
  );
}

export default useProjectsOptimizations;
