import { DateTime } from 'luxon';

import {
  daysInWeek,
  weeksInCalendar,
  monthNames,
  startDate,
  fullMonthNames,
} from '../ui/constants/calendar';

export const isToday = (date, timezone) => {
  let today = DateTime.utc().setZone(timezone);
  if (!timezone) {
    today = DateTime.local();
  }
  return date.toFormat('yyyy-LL-dd') === today.toFormat('yyyy-LL-dd');
};

export const isWeekend = (date) => date.get('weekday') > 5;

export const getSelectedMonthDate = (dateISO, timezone, isUTC) => {
  let date = DateTime.fromISO(dateISO, isUTC ? { zone: 'utc' } : {});
  if (!date.isValid) {
    date = DateTime.utc();
  }
  if (timezone) {
    return date.setZone(timezone);
  }
  return date;
};

export const getOverdueDate = (timezone) => {
  const date = DateTime.utc().setZone(timezone).minus({ days: 1 }).toFormat('yyyy-LL-dd');
  return DateTime.fromISO(date, { zone: 'utc' }).toISO();
};

export const setTimeToZero = (date) => {
  let dateObj = DateTime.fromJSDate(date);
  if (!dateObj.isValid) {
    dateObj = DateTime.fromISO(date, { zone: 'utc' });
  }
  return dateObj.toFormat('yyyy-LL-dd');
};

export const setUTCTimeToZero = (date) => {
  let dateObj = DateTime.fromJSDate(date);
  if (!dateObj.isValid) {
    dateObj = DateTime.fromISO(date, { zone: 'utc' });
  }
  return dateObj.toUTC().toFormat('yyyy-LL-dd');
};

const generateEventsMatrix = (events, firstDay) => {
  const daysCount = daysInWeek * weeksInCalendar;
  const sortedEvents = events.sort((firstEvent, secondEvent) => {
    if (
      firstEvent.startAt > secondEvent.startAt ||
      (firstEvent.startAt === secondEvent.startAt && firstEvent.endAt < secondEvent.endAt) ||
      (firstEvent.startAt === secondEvent.startAt &&
        firstEvent.endAt === secondEvent.endAt &&
        firstEvent.createdAt > secondEvent.createdAt)
    ) {
      return 1;
    }
    return -1;
  });
  const matrix = Array(daysCount)
    .fill()
    .map(() => []);

  sortedEvents.forEach((event) => {
    const start = DateTime.fromISO(event.startAt);
    const end = DateTime.fromISO(event.endAt);
    let index1 = Math.floor(start.diff(firstDay, 'day').toObject().days);
    let index2 = Math.floor(end.diff(firstDay, 'day').toObject().days);
    if (index1 < 0) {
      index1 = 0;
    }
    if (index1 >= daysCount) {
      return false;
    }
    if (index2 >= daysCount) {
      index2 = daysCount - 1;
    }

    let row = 0;
    const setEventToMatrix = () => {
      if (!matrix[index1][row]) {
        for (let i = index1; i <= index2; i += 1) {
          if (i === index1 || !(i % daysInWeek)) {
            let daysEventOnWeek = index2 - i + 1;
            const n2 = Math.floor(index2 / daysInWeek);
            const n1 = Math.floor(i / daysInWeek);
            if (n1 < n2) {
              daysEventOnWeek = i !== index1 ? daysInWeek : Math.ceil((i + 1) / daysInWeek) * 7 - i;
            }
            matrix[i][row] = {
              ...event,
              isFirstDay: true,
              daysEventOnWeek,
            };
          } else {
            matrix[i][row] = event;
          }
        }
      } else {
        row += 1;
        setEventToMatrix();
      }
    };

    setEventToMatrix();
  });
  return matrix.map((day) => Array.from(day, (item, i) => item || { id: i, isEmptyCell: true }));
};

export const getCalendarData = (date, milestones = [], events = [], timezone, weeksIn) => {
  if (!date) {
    return [];
  }
  const selectedDate = DateTime.fromISO(date.toFormat('yyyy-LL-dd'));
  let firstDay = selectedDate;
  const weekday = selectedDate.get('weekday');
  if (weekday !== 7) {
    firstDay = selectedDate.startOf('week').minus({ days: 1 });
  }
  const eventsMatrix = generateEventsMatrix(events, firstDay);
  if (!eventsMatrix) {
    return [];
  }

  return Array(weeksIn)
    .fill()
    .map((week, w) => {
      const weekData = { week: w };
      weekData.days = Array(daysInWeek)
        .fill()
        .map((day, d) => {
          const dayAfterFirst = w * daysInWeek + d;
          const currentDay = firstDay.plus({ days: dayAfterFirst });
          const milestonesAtDay = milestones.filter(
            ({ dueAt }) =>
              currentDay.toFormat('yyyy-LL-dd') ===
              DateTime.fromISO(dueAt, { zone: 'utc' }).toUTC().toFormat('yyyy-LL-dd')
          );
          const eventsAtDay = eventsMatrix[dayAfterFirst];
          const currentDate = currentDay.get('day');

          const dayData = {
            date: currentDate,
            currentDay: currentDay.toISODate(),
            isDayToday: isToday(currentDay, timezone),
            isWeekendDay: isWeekend(currentDay),
            milestones: milestonesAtDay,
            events: eventsAtDay,
          };
          if (currentDate === 1) {
            dayData.monthName = monthNames[currentDay.get('month') - 1];
          }
          return dayData;
        });
      return weekData;
    });
};

export const getFromTo = (date, fromCurrentWeek = false, weeksIn) => {
  let firstLineDate = date;
  if (!fromCurrentWeek) {
    firstLineDate = date.startOf('month');
  }
  let from = firstLineDate;
  const weekday = firstLineDate.get('weekday');
  if (weekday !== 7) {
    from = firstLineDate.startOf('week').minus({ days: 1 });
  }
  const to = from.plus({ days: weeksIn * daysInWeek });
  return {
    startOfMonth: firstLineDate.toFormat('yyyy-LL-dd'),
    from: from.toFormat('yyyy-LL-dd'),
    to: to.toFormat('yyyy-LL-dd'),
  };
};

const formatOverdueTime = (time, title) => `${time} ${title}${time > 1 ? 's' : ''}`;

export const getOverdueTime = (date, timezone) => {
  const formattedCurrentDate = getSelectedMonthDate(null, timezone).toFormat('yyyy-LL-dd');
  const currentDate = DateTime.fromISO(formattedCurrentDate, { zone: 'utc' });
  const formattedDueAt = getSelectedMonthDate(date, null, true).toFormat('yyyy-LL-dd');
  const dueAt = DateTime.fromISO(formattedDueAt, { zone: 'utc' });
  const { days } = currentDate.diff(dueAt, ['days']).toObject();

  return formatOverdueTime(days, 'Day');
  // maybe we will use it in future
  // if (days) return formatOverdueTime(days, 'Day');
  // if (hours) return formatOverdueTime(hours, 'Hour');
  //
  // const minutesCount = Math.floor(minutes);
  // return formatOverdueTime(minutesCount, 'Minute');
};

export const getResponsible = (responsibleCompany, responsibleUser) => {
  let responsible;
  let url;

  if (responsibleCompany) {
    responsible = responsibleCompany.name;
  }
  if (responsibleUser) {
    responsible = `${responsibleUser.firstName} ${responsibleUser.lastName}`;
  }
  if (responsibleCompany && responsibleCompany.logoUrl) {
    url = responsibleCompany.logoUrl;
  }
  if (responsibleUser && responsibleUser.avatarUrl) {
    url = responsibleUser.avatarUrl;
  }

  return { responsible, url };
};

export const isOverdueMilestone = (milestoneDueDate, timezone) => {
  const currentDate = getSelectedMonthDate(null, timezone).toFormat('yyyy-LL-dd');
  return milestoneDueDate < currentDate;
};

export const getUserTimezone = (timezones, userTimezone) => {
  let timezone;

  // timezones.forEach(arr => {
  const neededTimezone = timezones.find(({ tzCode }) => tzCode === userTimezone);
  if (neededTimezone) {
    timezone = neededTimezone.label;
  }
  // });
  return timezone;
};

export const getIsLessThanStartDate = (time) => time < startDate;

export const getSwitcherText = (date) => {
  const currentYear = getSelectedMonthDate().get('year');
  const year = date.get('year');
  const month = date.get('month');
  let newText = fullMonthNames[month - 1];
  if (currentYear !== year) {
    newText += ` ${year}`;
  }
  return newText;
};

export const isFirstWeekOfMonth = (selectedDate) => {
  const { weekNumber } = selectedDate.plus({ days: 1 });
  const { weekNumber: startWeekNumber } = selectedDate.startOf('month').plus({ days: 1 });
  return weekNumber === startWeekNumber;
};
