import { Button } from '@webfx/core-web';
import classnames from 'classnames';

import useQuarterSetting from '@webfx/permissions-web/src/hooks/useQuarterSetting';
import {
  getPreviousOrNextQuarter,
  getCustomQuarterMonths,
} from '@webfx/web-ui/src/QuarterSelector/utils';

import styles from './KanbanQuarterSelector.module.css';

const KanbanQuarterSelector = ({ setQuarter, quarter, year, companyId }) => {
  const quarterSetting = useQuarterSetting(companyId);

  const handlePreviousAndNextQuarter = (changeBy) => {
    const { newQuarter, newYear } = getPreviousOrNextQuarter({
      changeBy,
      currentQuarter: quarter,
      currentYear: year,
      quarterSetting,
    });
    setQuarter({ quarter: newQuarter, year: newYear });
  };

  return (
    <>
      <Button
        className={classnames(
          styles.arrow,
          'bold bg-white text-primary-light-gray text-hover-blue-vivid-300'
        )}
        icon="arrow_back"
        round
        onClick={() => {
          handlePreviousAndNextQuarter(-1);
        }}
        disabled={getCustomQuarterMonths(quarter).includes('2022-10-01')}
      />

      <Button
        round
        className={classnames(
          styles.arrow,
          'ml-2 bold bg-white text-primary-light-gray text-hover-blue-vivid-300'
        )}
        icon="arrow_forward"
        onClick={() => {
          handlePreviousAndNextQuarter(1);
        }}
      />
    </>
  );
};

export default KanbanQuarterSelector;
