import { renderToStaticMarkup } from 'react-dom/server';
import ReactQuill from 'react-quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { queryClient } from '@webfx/core-web';

import Icon from '../../ui/components/TextEditor/Icon/Icon';
import ListItem from '../../ui/components/TextEditor/ListItem/ListItem';

const generateBtn = (icon) => renderToStaticMarkup(<Icon>{icon}</Icon>);

// Setup custom icons
/**
 *
 */
export const setupIcons = () => {
  const icons = ReactQuill.Quill.import('ui/icons');

  icons.bold = generateBtn('format_bold');
  icons.italic = generateBtn('format_italic');
  icons.underline = generateBtn('format_underlined');
  icons.align[''] = generateBtn('format_align_left');
  icons.align.left = generateBtn('format_align_left');
  icons.align.center = generateBtn('format_align_center');
  icons.align.right = generateBtn('format_align_right');
  icons.align.justify = generateBtn('format_align_justify');
  icons.list.bullet = generateBtn('format_list_bulleted');
  icons.list.check = generateBtn('playlist_add_check');
  icons.list.ordered = generateBtn('format_list_numbered');
  icons.indent['-1'] = generateBtn('format_indent_decrease');
  icons.indent['+1'] = generateBtn('format_indent_increase');
  icons.link = generateBtn('link');
};

/**
 *
 * @param item
 */
export const generateListItem = (item) => renderToStaticMarkup(<ListItem item={item} />);

/**
 * Searches for users based on the provided search criteria.
 *
 * @param {Object} options - The search options.
 * @param {string} options.searchTerm - The search term to filter users by first name or last name.
 * @param {string} options.projectId - The ID of the project to search users in.
 * @param {boolean} options.searchOnlyPrivate - Indicates whether to search only private users.
 * @returns {Array} - An array of user objects that match the search criteria.
 */
export const searchUsers = async ({ searchTerm, projectId, searchOnlyPrivate }) => {
  const users = await queryClient.fetchQuery([`tw/projects/${projectId}/assignees`], {
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60 * 5,
    enabled: !!projectId,
  });
  const assignees = users?.assignees?.flatMap((a) => a.users) || [];
  return assignees
    .filter((u) => {
      const { firstName = '', lastName = '' } = u;

      if (!u.firstName || ['Unknown', 'Action', 'Adtech', '-', '----'].includes(u.firstName)) {
        return false;
      }

      const searchOnlyPrivateCondition = u.type === 'owner' || u.type === 'admin';

      // if we have a search term, we need to check if the first name or last name includes the search term
      if (searchTerm) {
        const searchTermCondition =
          firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
          lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        // if searching only private, add the private condition
        if (searchOnlyPrivate) {
          return searchTermCondition && searchOnlyPrivateCondition;
        }
        // just search names
        return searchTermCondition;
      }

      //no search term but still need to validate searchOnly
      if (searchOnlyPrivate) {
        return searchOnlyPrivateCondition;
      }

      // otherwise do not filter
      return true;
    })
    .map((user) => ({
      id: user.userId,
      value: `${user.firstName}${user.lastName}`,
      fullName: `${user.firstName} ${user.lastName}`,
      avatar: user.avatarUrl || 'https://a.webfx.com/assets/images/avatar.png',
      type: `${user.type}`,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
};

/**
 *
 * @param ref
 * @param start
 * @param end
 */
export const getContentAsJsonUsingPosition = (ref, start, end) =>
  JSON.stringify(ref.current.getEditor().getContents(start, end - start));

/**
 *
 * @param ref
 */
export const getContentAsJson = (ref) => JSON.stringify(ref.current.getEditor().getContents());
/**
 *
 * @param ref
 */
export const getContentAsHtml = (ref) => {
  const delta = ref.current.getEditor().getContents();
  const converter = new QuillDeltaToHtmlConverter(delta.ops, { inlineStyles: true });

  converter.renderCustomWith((customOp) => {
    if (customOp.insert.type !== 'mention') {
      return '';
    }

    const { index, denotationChar, id, value, type } = customOp.insert.value;

    return `<span class="mention" data-index="${index}" data-denotation-char="${denotationChar}" data-id="${id}" data-value="${value}" data-type="${type}" ><span contenteditable="false"><span class="ql-mention-denotation-char">${denotationChar}</span>${value}</span></span>`;
  });

  let html = converter.convert();

  // quill-delta-to-html adds the unsafe: when an unknown protocol is used in links.
  html = html.replace('="unsafe:', '="http://');

  return html;
};

/**
 *
 * @param data
 */
export const getContentFromJson = (data) => {
  try {
    /* TODO: simple guard to prevent a crash later should be removed
     because we shouldn't give an ability to create an empty message.
    */
    if (data === null) {
      return '';
    }
    return JSON.parse(data);
  } catch (e) {
    return e;
  }
};
