import { action } from 'easy-peasy';

import { mergeUsersSidebarIntoExisted, setNewUsersAfterDeleting } from '../helpers';

export const setSidebarUsers = action((state, { data, total, afterDeletingFile, clearState }) => {
  if (afterDeletingFile) {
    const newUsersList = setNewUsersAfterDeleting(state.sidebarUsers, { data, total });
    if (state.sidebarUsers.listId.length !== newUsersList.listId.length) {
      state.skipCountUsers -= 1;
    }
    state.sidebarUsers = newUsersList;
    return;
  }

  const newUsersList = mergeUsersSidebarIntoExisted(state.sidebarUsers, {
    data,
    total,
    clearState,
  });
  state.sidebarUsers = newUsersList;
  if (state.skipCountUsers !== total) {
    state.skipCountUsers += data.length;
  }
  state.hasMoreUsers = total > state.skipCountUsers;
});
