import { thunk } from 'easy-peasy';

export const onUnMountTeamworkFxProjectTemplate = thunk(
  async ({ clearProjectTemplateState }, _, { getStoreActions }) => {
    const { showError } = getStoreActions().common;

    try {
      clearProjectTemplateState();
    } catch (e) {
      showError(e);
    }
  }
);
