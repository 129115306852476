import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onCreateTodoListByTemplate = thunk(
  async (_, { values, setSubmitting, onClosePopup }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const createTodoListByTemplate = actions.project.todos.todoLists.createTodoListByTemplate;

    const onLoadMoreTodos = actions.project.todos.todoLists.onLoadMoreTodos;

    try {
      const todoList = await api.todoLists.create(values, { for: 'onCreateTodoListByTemplate' });
      createTodoListByTemplate({ todoList });
      onClosePopup();

      await onLoadMoreTodos();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
