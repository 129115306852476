import { set } from './set';
import { setSidebarUsers } from './setSidebarUsers';
import { setSortingParam } from './setSortingParam';
import { changeSidebarUserChecked } from './changeSidebarUserChecked';
import { removeFile } from './removeFile';
import { updateFile } from './updateFile';
import { clearFilesState } from './clearFilesState';
import { clearFilesStateWithoutUsers } from './clearFilesStateWithoutUsers';
import { increaseSkipCount } from './increaseSkipCount';
import { decreaseSkipCount } from './decreaseSkipCount';

export default {
  set,
  setSidebarUsers,
  setSortingParam,
  changeSidebarUserChecked,
  removeFile,
  updateFile,
  clearFilesState,
  clearFilesStateWithoutUsers,
  increaseSkipCount,
  decreaseSkipCount,
};
