export const initState = {
  selectedUser: null,
  companies: [],
  sidebar: {
    companies: {
      list: [],
      map: {},
    },
  },
};
