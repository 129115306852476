import { initState } from './initState';
// actions - general
import { mountProjectSingleTodoList } from './actions/mountProjectSingleTodoList';
import { unMountProjectSingleTodoList } from './actions/unMountProjectSingleTodoList';
import { loadMoreTodos } from './actions/loadMoreTodos';
// actions - todolist
import { mountCreateTodoListForm } from './actions/mountCreateTodoListForm';
import { editTodoList } from './actions/editTodoList';
import { moveTodoList } from './actions/moveTodoList';
import { showTodosColorCodes } from './actions/showTodosColorCodes';
import { hideTodosColorCodes } from './actions/hideTodosColorCodes';
import { setCompletedTodosLimit } from './actions/setCompletedTodosLimit';
// actions - todos
import { createTodo } from './actions/createTodo';
import { editTodo } from './actions/editTodo';
import { editTodoAssignee } from './actions/editTodoAssignee';
import { editTodoPriority } from './actions/editTodoPriority';
import { completeTodo } from './actions/completeTodo';
import { unCompleteTodo } from './actions/unCompleteTodo';
import { reorderTodo } from './actions/reorderTodo';
import { deleteActiveTodo } from './actions/deleteActiveTodo';
import { deleteCompletedTodo } from './actions/deleteCompletedTodo';
// actions - sidebar
import { filterTodosByAssignee } from './actions/filterTodosByAssignee';
import { filterTodosByDueDate } from './actions/filterTodosByDueDate';
import { filterTodosBySearch } from './actions/filterTodosBySearch';
import { moveSidebarList } from './actions/moveSidebarList';
// actions - move todo
import { setMoveTodoProjects } from './actions/setMoveTodoProjects';
import { setMoveTodoTodoLists } from './actions/setMoveTodoTodoLists';
import { resetMoveTodoTodoLists } from './actions/resetMoveTodoTodoLists';
import { moveTodo } from './actions/moveTodo';
import { deleteMovedTodo } from './actions/deleteMovedTodo';
// actions - ws - to-do list
import { wsCreateTodoList } from './ws/wsCreateTodoList';
import { wsCreateTodoListByTemplate } from './ws/wsCreateTodoListByTemplate';
import { wsEditTodoList } from './ws/wsEditTodoList';
import { wsReorderTodoLists } from './ws/wsReorderTodoLists';
import { wsDeleteTodoList } from './ws/wsDeleteTodoList';
// actions - ws - todos
import { wsReorderTodo } from './ws/wsReorderTodo';
import { wsDragTodoToAnotherList } from './ws/wsDragTodoToAnotherList';
import { wsCreateComment } from './ws/wsCreateComment';
import { wsDeleteComment } from './ws/wsDeleteComment';
import { wsCreateTodoView } from './ws/wsCreateTodoView';

/* ************************************************** THUNKS ****************************************************** */

// thunks - general
import { onMountProjectSingleTodoList } from './thunks/onMountProjectSingleTodoList';
import { onLoadMoreTodos } from './thunks/onLoadMoreTodos';
// thunks - todolist
import { onMountCreateTodoListForm } from './thunks/onMountCreateTodoListForm';
import { onUnMountProjectSingleTodoList } from './thunks/onUnMountProjectSingleTodoList';
import { onCreateTodoList } from './thunks/onCreateTodoList';
import { onCreateTodoListByTemplate } from './thunks/onCreateTodoListByTemplate';
import { onEditTodoList } from './thunks/onEditTodoList';
import { onMoveTodoList } from './thunks/onMoveTodoList';
import { onDeleteTodoList } from './thunks/onDeleteTodoList';
import { onShowTodosColorCodes } from './thunks/onShowTodosColorCodes';
// thunks - todos
import { onCreateTodo } from './onCreateTodo';
import { onEditTodo } from './onEditTodo';
import { onEditTodoAssignee } from './onEditTodoAssignee';
import { onEditTodoPriority } from './onEditTodoPriority';
import { onCompleteTodo } from './onCompleteTodo';
import { onUnCompleteTodo } from './onUnCompleteTodo';
import { onReorderTodo } from './onReorderTodo';
import { onMoveTodo } from './thunks/onMoveTodo';
import { onDeleteTodo } from './onDeleteTodo';
import { onPingTodoCreator } from './onPingTodoCreator';
// thunks - sidebar
import { onFilterTodosByAssignee } from './thunks/onFilterTodosByAssignee';
import { onFilterTodosByDueDate } from './thunks/onFilterTodosByDueDate';
import { onFilterTodosBySearch } from './thunks/onFilterTodosBySearch';
// thunks - move todo
import { onMountMoveTodo } from './thunks/onMountMoveTodo';
import { onFindTodoListsForMoveDropdown } from './thunks/onFindTodoListsForMoveDropdown';
// thunks - ws - to-do lists
import { wsOnEditTodoList } from './ws/wsOnEditTodoList';
import { wsOnDeleteTodoList } from './ws/wsOnDeleteTodoList';
// thunks - ws - to-dos
import { wsOnCreateTodo } from './ws/wsOnCreateTodo';
import { wsOnEditTodo } from './ws/wsOnEditTodo';
import { wsOnToggleTodoCompletedStatus } from './ws/wsOnToggleTodoCompletedStatus';
import { wsOnDeleteTodo } from './ws/wsOnDeleteTodo';
import { wsOnDeleteComment } from './ws/wsOnDeleteComment';
import { wsOnDragTodoToAnotherList } from './ws/wsOnDragTodoToAnotherList';

// TODO: Remove this whole store. Querying and managing a single todolist
// should be no different then multiples

const singleTodoList = {
  ...initState,
  // actions - general
  mountProjectSingleTodoList,
  unMountProjectSingleTodoList,
  loadMoreTodos,
  // actions - todolist
  mountCreateTodoListForm,
  editTodoList,
  moveTodoList,
  showTodosColorCodes,
  hideTodosColorCodes,
  setCompletedTodosLimit,
  // actions - todos
  createTodo,
  editTodo,
  editTodoAssignee,
  editTodoPriority,
  completeTodo,
  unCompleteTodo,
  reorderTodo,
  deleteActiveTodo,
  deleteCompletedTodo,
  // actions - sidebar
  filterTodosByAssignee,
  filterTodosByDueDate,
  filterTodosBySearch,
  moveSidebarList,
  // actions - move todo
  setMoveTodoProjects,
  setMoveTodoTodoLists,
  resetMoveTodoTodoLists,
  moveTodo,
  deleteMovedTodo,
  // actions - ws - to-do list
  wsCreateTodoList,
  wsCreateTodoListByTemplate,
  wsEditTodoList,
  wsReorderTodoLists,
  wsDeleteTodoList,
  // actions - ws - todos
  wsReorderTodo,
  wsDragTodoToAnotherList,
  wsCreateComment,
  wsDeleteComment,
  wsCreateTodoView,

  /* ************************************************** THUNKS ****************************************************** */
  // thunks - general
  onMountProjectSingleTodoList,
  onUnMountProjectSingleTodoList,
  onLoadMoreTodos,
  // thunks - todolist
  onMountCreateTodoListForm,
  onCreateTodoList,
  onCreateTodoListByTemplate,
  onEditTodoList,
  onMoveTodoList,
  onDeleteTodoList,
  onShowTodosColorCodes,
  // thunks - todos
  onCreateTodo,
  onEditTodo,
  onEditTodoAssignee,
  onEditTodoPriority,
  onCompleteTodo,
  onUnCompleteTodo,
  onReorderTodo,
  onMoveTodo,
  onDeleteTodo,
  onPingTodoCreator,
  // thunks - sidebar
  onFilterTodosByAssignee,
  onFilterTodosByDueDate,
  onFilterTodosBySearch,
  // thunks - moveTodo
  onMountMoveTodo,
  onFindTodoListsForMoveDropdown,
  // thunks - ws - to-do lists
  wsOnEditTodoList,
  wsOnDeleteTodoList,
  // thunks - ws - to-dos
  wsOnCreateTodo,
  wsOnEditTodo,
  wsOnToggleTodoCompletedStatus,
  wsOnDeleteTodo,
  wsOnDeleteComment,
  wsOnDragTodoToAnotherList,
};

export default singleTodoList;
