import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';
import { getProjectTemplates } from './getProjectTemplates';

export const onRemoveProjectTemplate = thunk(
  async (
    { setTemplateProjectsItemsToStore },
    { projectTemplateId },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'teamworkFx.templates.projects.deleteProjectTemplate';
    showLoader({ key: loader });
    try {
      await api.projectTemplates.remove(projectTemplateId);

      const state = getStoreState();
      const { sidebar } = state.teamworkFx.templates.projects;
      await getProjectTemplates(sidebar, setTemplateProjectsItemsToStore);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
