import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const projects = feathersClient.service('tw/projects-deprecated');

/**
 *
 * @param payload
 */
export const find = (payload) => projects.find(paramsForServer(payload));
/**
 *
 * @param root0
 * @param root0.projectId
 * @param root0.params
 */
export const get = ({ projectId, params }) => projects.get(projectId, paramsForServer(params));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => projects.create(payload, paramsForServer(params));
/**
 *
 * @param projectId
 * @param payload
 */
export const patch = (projectId, payload) =>
  projects.patch(projectId, payload, paramsForServer({ for: 'projectCompanies' }));
/**
 *
 * @param id
 */
export const remove = (id) => projects.remove(id);
