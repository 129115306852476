import { PermissionsAbility, store } from '@webfx/web-state';
import get from 'lodash/get';
import api from '../../../../../../services/api';
import { todoCommentsLimit } from '../../../../../../ui/constants/project/todos';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';

export const onMountTodo = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectTodo',
  action: async (actions, { todoId, projectId }, getStoreState) => {
    const { mountTodo } = actions.project.todos.todo;
    const { setSearchUsersByTagPrivacy } = actions.project;

    const state = getStoreState();

    if (!projectId) {
      return;
    }

    const currentUserId = store.auth.user()?.userId;
    const canManageTWFX = PermissionsAbility.can('manage', 'twfx');

    let todo;
    try {
      todo = await api.todos.get(todoId, {
        query: {
          $join: ['todoList'],
        },
        for: 'todoItemComments',
      });
    } catch (error) {
      const { code } = error;
      if (code === 404) {
        todo = await api.todos.get(todoId, {
          query: {
            $paranoid: false,
            $join: ['todoList'],
          },
          for: 'todoItemComments',
        });
      }
    }
    setSearchUsersByTagPrivacy(toBool(todo.todoList.private));
    mountTodo({ todo, currentUserId });

    Promise.all([
      api.projects.get({ projectId, params: { for: 'onGetProjectTodosAssignees' } }),
      api.subscribers.find({
        query: { todoId, $select: ['subscriberId', 'userId'] },
        for: 'users',
      }),
    ]).then(([assignees, subscribers]) => mountTodo({ assignees, subscribers, currentUserId }));

    if (canManageTWFX) {
      api.pastebinTemplates
        .find()
        .then((pastebinTemplates) => mountTodo({ pastebinTemplates, currentUserId }));
    }

    api.views.create({ todoId });

    const comments = await api.comments.find({
      // set limit to `todoCommentsLimit + 1` because
      // we need to check whether to show first (old) comment
      query: {
        todoId,
        $limit: todoCommentsLimit + 1,
        // $join: ['user', 'files']
      },
      for: 'usersAndFiles',
    });

    let firstCommentId = null;

    if (comments.total > todoCommentsLimit + 1) {
      // get first (old) comment
      const firstComment = api.comments.find({
        query: {
          todoId,
          // $join: ['user', 'files'],
          $limit: 1,
          $sort: { createdAt: 1 },
        },
        for: 'usersAndFiles',
      });

      firstCommentId = get(firstComment, ['data', 0, 'commentId'], null);
      if (firstCommentId) {
        comments.data.shift(); // remove excess element
        comments.data.unshift(firstComment.data[0]); // add first comment to comments data array
      }
    }

    mountTodo({ comments, currentUserId });

    // mountTodo({
    //   todo,
    //   firstCommentId,
    //   comments,
    //   assignees,
    //   subscribers,
    //   currentUserId,
    //   pastebinTemplates,
    // });

    return state.router.url({ route: 'todo', todoId });
  },
});
