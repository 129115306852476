import { api } from '@webfx/core-web';
import { useParams } from 'react-router-dom';

export default function useCompanySettings(companyId, queryParams = {}, reactQueryParams = {}) {
  const params = useParams();

  /* eslint-disable no-param-reassign */
  companyId = companyId ?? params.id ?? null;
  queryParams = !queryParams.query ? { query: queryParams } : queryParams;

  const { data, ...passThroughQuery } = api.useQuery(
    [
      'company-settings',
      {
        query: {
          companyId: Number(companyId),
          ...queryParams.query,
        },
      },
    ],
    {
      enabled: !!companyId,
      select: (data) => data ?? [],
      ...reactQueryParams,
    }
  );

  const queryClient = api.useQueryClient();
  const mutator = api.useMutation([`company-settings/${companyId}`], {
    onSuccess: () => {
      queryClient.invalidateQueries('company-settings');
    },
  });

  return {
    data,
    ...passThroughQuery,
    mutator,
  };
}
