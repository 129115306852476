import React, { createContext, useState } from 'react';

const BulkActionsContext = createContext({});

// Moved to React context due to conditional use
const BulkActionsProvider = ({ children, values }) => {
  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  return (
    <BulkActionsContext.Provider
      value={values || { selected, setSelected, action, setAction, showModal, setShowModal }}
    >
      {children}
    </BulkActionsContext.Provider>
  );
};

export { BulkActionsProvider, BulkActionsContext };

export default BulkActionsContext;
