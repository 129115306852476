'use strict';

const setProjectIdForUpload =
  ({ mutator, setFile }) =>
  ({ formObject, uploadId, file }) => {
    const params = {
      _method: 'PATCH',
      _id: uploadId,
      ...formObject,
    };
    mutator.mutate(params, {
      onSuccess: (response) => {
        setFile({
          ...response,
          preview: file.preview,
        });
      },
    });
  };

module.exports = setProjectIdForUpload;
