import { action, debug } from 'easy-peasy';
import deepmerge from 'deepmerge';
import uniqBy from 'lodash/uniqBy';

export const setProjectForPeopleAndPermissions = action((state, payload) => {
  if (state.project?.projectId === payload.project.projectId) {
    state.project = deepmerge.all([state.project, payload.project]);
  } else {
    state.project = payload.project;
  }
  state.project.projectCompanies = uniqBy(state.project.projectCompanies, (c) => c.companyId);
});
