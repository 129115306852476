import { action } from 'easy-peasy';

export const completeOverdueMilestone = action((state, updatedItem) => {
  const { milestoneId } = updatedItem;
  state.overdueMilestones.map = state.overdueMilestones.map.filter(
    (milestone) => milestone.milestoneId !== milestoneId
  );

  if (milestoneId in state.overdueMilestones.list) {
    delete state.overdueMilestones.list[milestoneId];
    state.overdueMilestones.skipCount -= 1;
    state.overdueMilestones.overdueCount -= 1;
  }
});
