import { action } from 'easy-peasy';

import { setTodosToLists } from '../helpers/setTodosToLists';

export const loadMoreTodos = action((state, payload) => {
  const { todosBag, todolistId } = payload;

  state.todoLists = setTodosToLists({
    todoLists: state.todoLists,
    todosBag,
    todolistId,
  });
});
