import { store } from '@webfx/web-state';
import api from '../../../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../../../ui/constants/limits';
import { getOverdueDate, isOverdueMilestone } from '../../../../../../../utils-deprecated/calendar';
import checkChangesDate from '../../../helpers/checkChangesDate';

export const updateMilestone = async ({ actions, state, form: { id, values } }) => {
  const updateOverdueMilestone = actions.teamworkFx.dashboard.updateOverdueMilestone;
  const removeOverdueMilestone = actions.teamworkFx.dashboard.removeOverdueMilestone;
  const setOverdueMilestones = actions.teamworkFx.dashboard.setOverdueMilestones;
  const setTeamworkFxDashboardMilestone =
    actions.teamworkFx.dashboard.setTeamworkFxDashboardMilestone;
  const updateMilestones = actions.teamworkFx.dashboard.updateMilestones;

  const timezone = store.auth.user().timezone;
  const overdueMilestoneItems = state.teamworkFx.dashboard.overdueMilestones.map;
  const selectedMilestone = state.teamworkFx.dashboard.milestones.select;

  const milestoneChanged = await api.milestones.update(id, values, {
    for: 'milestoneComments',
  });

  if (Array.isArray(milestoneChanged)) {
    updateMilestones({ timezone, milestones: milestoneChanged });
  } else {
    // update milestone in calendar
    setTeamworkFxDashboardMilestone(milestoneChanged);

    const isMilestoneOverdue = isOverdueMilestone(milestoneChanged.dueAt, timezone);
    const isChangedDate = checkChangesDate(values.dueAt, selectedMilestone.dueAt);

    // updated milestone is non-overdue
    if (!isChangedDate && !isMilestoneOverdue) {
      return;
    }

    // if overdue milestone date was not changed, then just update overdue milestone and stop additional proceed
    if (!isChangedDate && isMilestoneOverdue) {
      updateOverdueMilestone(milestoneChanged);
      return;
    }

    // all overdue milestones are fetched by 1 request
    if (overdueMilestoneItems.length < overdueMilestonesLimit) {
      isMilestoneOverdue
        ? updateOverdueMilestone(milestoneChanged)
        : removeOverdueMilestone(milestoneChanged);
      return;
    }
  }

  // need to make new request to get overdue milestones
  const overdueDate = getOverdueDate(timezone);
  const query = {
    dueAt: { $lte: overdueDate },
    $client: { for: 'overdueCalendar' },
    $sort: { dueAt: -1 },
    $limit: overdueMilestonesLimit,
  };

  const overdueMilestones = await api.milestones.find({ query });

  setOverdueMilestones({
    milestones: overdueMilestones.data,
    hasMoreData: overdueMilestones.hasMore,
    overdueCount: overdueMilestones.count,
  });
};
