import { action } from 'easy-peasy';

export const changeSidebarUserChecked = action((state, { userId, status }) => {
  const { items } = state.sidebarUsers;

  const user = items[userId];
  user.checked = status;

  // Clear data for files
  state.skipCount = 0;
  state.hasMoreData = true;
  state.items = [];
  state.itemsIds = [];
  state.startAttachmentId = null;
});
