import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { getResponsibleInfo } from '../helpers/getResponsibleInfo';

const getContent = (multipleItem, values) => {
  const itemInfo = getResponsibleInfo(values);

  if (!multipleItem) {
    return itemInfo;
  }

  const contentForAllItems = values.content.split('\n').filter((item) => item);

  if (!contentForAllItems.length) {
    return null;
  }

  return contentForAllItems.map((currentItem) => ({
    ...itemInfo,
    content: currentItem,
    multipleItem,
  }));
};

export const onCreateTemplateTodo = thunk(
  async (
    { addTemplateTodo, addTemplateMultipleTodo },
    { values, setSubmitting, setFieldValue, id, multipleItem, resetForm },
    { getStoreActions }
  ) => {
    const { common } = getStoreActions();
    try {
      const itemContent = getContent(multipleItem, { ...values, projectTemplateTodoListId: id });

      if (itemContent) {
        const result = await api.projectTemplateTodos.create(itemContent);
        result.length > 1 ? addTemplateMultipleTodo(result) : addTemplateTodo(result);
        setFieldValue('content', '');
      }
    } catch (e) {
      common.showError(e);
    }
    resetForm();
    setSubmitting(false);
  }
);
