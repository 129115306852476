import { action } from 'easy-peasy';

const updateSingleList = (lists, showAll) => {
  lists.list.forEach((categoryId) => {
    lists.map[categoryId].checked = showAll;
  });
  return lists.map;
};

export const changeSidebarAllChecked = action((state, { showAll }) => {
  state.sidebarLists.map = updateSingleList(state.sidebarLists, showAll);
  state.sidebarLists.showAll = showAll;
});
