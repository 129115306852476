import { action } from 'easy-peasy';

import { getTodoIndex } from '../helpers/list';

export const deleteTemplateTodo = action(
  (state, { projectTemplateTodoListId, projectTemplateTodoId }) => {
    const list = state.lists.map[projectTemplateTodoListId];

    if (!list) {
      return;
    }

    const todoIndex = getTodoIndex(list, projectTemplateTodoId);
    list.projectTemplateTodos.splice(todoIndex, 1);
    delete state.todoItems[projectTemplateTodoId];
  }
);
