import { Avatar, Link } from '@webfx/core-web';

const DataArchitect = ({ dataArchitect }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center ml-3">
      <Avatar
        className="rounded-circle"
        src={dataArchitect?.avatarUrl}
        style={{ width: '250px', height: '250px' }}
        alt={dataArchitect?.title || 'DataTech Architect'}
      />
      <div className="d-flex flex-column align-items-center mt-3">
        <p className="font-28 bold text-gray-700 m-0">
          {dataArchitect?.firstName + ' ' + dataArchitect?.lastName}
        </p>
        <p className="font-20 bold text-gray-700 m-0">
          {dataArchitect?.title || 'DataTech Architect'}
        </p>

        <Link className="font-18 bold" to={`mailto:${dataArchitect?.email}`}>
          {dataArchitect?.email}
        </Link>
      </div>
    </div>
  );
};

export default DataArchitect;
