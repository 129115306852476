// TODO: Deprecate file, all paths should come from router store.

export default {
  SIGNIN: '/signin',
  MY_INFO: '/my-info',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_PASSWORD: '/confirm-password',
  DASHBOARD: '/dashboard',
  TODOS: '/todos',
  CALENDAR: '/calendar',
  MESSAGES: '/posts',
  CALENDAR_MILESTONE: '/project/:projectId/milestone/:milestoneId',
  SETTINGS: '/settings',
  ALL_PEOPLE: '/all-people',
  PEOPLE_AND_PERMISSIONS: '/project/:projectId/people-and-permissions',
  PEOPLE_AND_PERMISSIONS_ADD_USER: '/project/:projectId/people-and-permissions/add-person/:id',
  PEOPLE_AND_PERMISSIONS_EDIT_USER: '/project/:projectId/people-and-permissions/edit-person/:id',
  PEOPLE_AND_PERMISSIONS_EDIT_COMPANY:
    '/project/:projectId/people-and-permissions/edit-company/:id',
  CHANGE_PERMISSIONS: '/project/:projectId/change-permissions',
  CHANGE_PERMISSIONS_ADD_USER: '/project/:projectId/change-permissions/add-person/:id',
  ALL_PEOPLE_EDIT_COMPANY: '/all-people/edit-company/:id',
  ALL_PEOPLE_ADD_USER: '/all-people/add-person/:id',
  ALL_PEOPLE_EDIT_USER: '/all-people/edit-person/:id',
  ARCHIVED_PROJECTS: '/archived-projects',
  TODOS_TEMPLATES: '/templates/todos',
  SINGLE_TODO_TEMPLATES: '/templates/todos/:todoListId',
  PROJECTS_TEMPLATES: '/templates/projects',
  PROJECTS_TEMPLATES_TODO_LISTS: '/templates/projects/:templateId/todo-lists',
  PROJECTS_TEMPLATES_SINGLE_TODO_LIST: '/templates/projects/:templateId/todo-lists/:todoListId',
  PROJECTS_TEMPLATES_MILESTONES: '/templates/projects/:templateId/milestones',

  SEARCH: '/search',
  FILES: '/files',
  FILE_DOWNLOAD: '/uploads/:uploadId',
  OVERVIEW: '/log',
  CONVERSATIONS: '/posts',
  TODO_LISTS: '/todo-lists',
  MILESTONES: '/milestones',
  DELETED_PROJECTS: '/deleted-projects',
  PROJECT_PAGE: '/project/:projectId',
  PROJECT: '/project/:projectId/:tab',
  PROJECT_OVERVIEW: '/project/:projectId/log',
  PROJECT_MESSAGES: '/project/:projectId/posts',
  PROJECT_MESSAGE: '/project/:projectId/post/:messageId',
  PROJECT_CREATE_MESSAGE: '/project/:projectId/create-post',
  PROJECT_EDIT_MESSAGE: '/project/:projectId/edit-post/:messageId',
  PROJECT_MESSAGES_ARCHIVE: '/project/:projectId/posts-archive',
  PROJECT_TODOS: '/project/:projectId/todo-lists',
  PROJECT_SINGLE_TODO_LIST: '/project/:projectId/todo_lists/:todolistId',
  PROJECT_TODO_ITEM: '/project/:projectId/todo/:todoId',
  PROJECT_FILES: '/project/:projectId/files',
  PROJECT_FILES_UPLOAD: '/project/:projectId/files-upload',
  PROJECT_SETTINGS: '/project/:projectId/settings',
  PROJECT_CALENDAR: '/project/:projectId/milestones',
  PROJECT_MILESTONE: '/project/:projectId/milestone/:milestoneId',
  PROJECT_DELETIONS: '/project/:projectId/deletions',
  PROJECT_SEARCH: '/project/:projectId/search',
};
