import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

/**
 * Web hook for integrations requests.
 * @param params
 */
const useIntegrationRequests = (params = {}) => {
  const { siteId } = useActiveSite();

  const query = api.useQuery(
    ['mcfx/integrations-requests', { query: { siteId, $limit: -1, ...params } }],
    {
      enabled: !!siteId,
      staleTime: 1000 * 60 * 15, // 15 min
      cacheTime: 1000 * 60 * 30, // 30 min
      select: (data) => data.data ?? data ?? [],
      placeholderData: [],
    }
  );
  const mutator = api.useMutation(['mcfx/integrations-requests']);

  return { ...query, mutator };
};

export default useIntegrationRequests;
