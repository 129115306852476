export const mergeUsersSidebarIntoExisted = (state, { data, total, clearState }) => {
  const { listId, items } = state;

  if (!total) {
    return { listId: [], items: [] };
  }

  if (listId.length === total) {
    return state;
  }

  const newListId = clearState ? [] : [...listId];
  const newItems = { ...items };

  data.forEach((currentUser) => {
    const { userId } = currentUser;

    // Insert id into new list
    newListId.push(userId);

    newItems[userId] = { ...currentUser, checked: userId in items ? items[userId].checked : false };
  });

  return { listId: newListId, items: newItems };
};

export const setNewUsersAfterDeleting = (state, { data, total }) => {
  const { listId, items } = state;

  if (!total) {
    return { listId: [], items: [] };
  }

  if (listId.length === total) {
    return state;
  }

  const selectedUsers = listId.length ? listId.filter((id) => items[id].checked) : [];

  const newListId = [];
  const newItems = {};
  let userWasDeleted = false;

  // Check do we have selected user
  if (data.length < listId.length && selectedUsers.length) {
    userWasDeleted = true;
  }

  data.forEach((currentUser) => {
    const { userId } = currentUser;

    newItems[userId] = {
      ...currentUser,
      checked: listId.includes(userId) ? items[userId].checked : false,
    };

    if (!listId.includes(userId) && selectedUsers.length) {
      userWasDeleted = true;
    }

    // Insert id into new list
    newListId.push(userId);
  });

  return { listId: newListId, items: newItems, userWasDeleted };
};
