import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onMoveTodo = thunk(
  async (_, { todoId, source, target, onClose }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;
    const moveTodo = actions.project.todos.todoLists.moveTodo;

    const loader = 'project.todos.todoLists.moveTodoForm.isTodoMoving';
    showLoader({ key: loader });

    try {
      const movedTodo = await api.todos.patch(
        todoId,
        { todolistId: target.todolistId },
        { for: 'onMoveTodo' }
      );
      moveTodo({ todoId, source, movedTodo });
      onClose();
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
