import { api } from '@webfx/core-web';
import { useNotifications, useStartPage } from '@webfx/web-hooks';
import { useHistory } from 'react-router-dom';

/**
 * Gets a milestone
 * @param {number} milestoneId
 * @param {object} reactQueryParams
 * @returns {object}
 */
const useMilestone = (milestoneId, reactQueryParams = {}) => {
  const { toast } = useNotifications();
  const startPage = useStartPage();
  const history = useHistory();

  const query = api.useQuery(
    [
      'tw/milestones',
      Number(milestoneId),
      {
        $client: {
          for: 'milestoneComments', // NOTE: Not sure this is even needed any more
        },
        $join: ['responsibleCompany', 'creatorUser', 'responsibleUser'],
      },
    ],
    {
      enabled: !!milestoneId,
      onError: (error) => {
        if (error.code === 405) {
          toast.error('You do not have access to that project');
          history.push(startPage);
        }
      },
      ...reactQueryParams,
    }
  );

  return {
    milestone: query.data,
    isLoading: query.isLoading,
  };
};

export default useMilestone;
