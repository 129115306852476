/**
 * The 'label' values map to the filter values used in Looker
 * The 'value' values map to the filter values used in the API
 * */
const createdByOptions = [
  { label: 'IntegrationsFX Data (Synced from your CRM)', value: 'integrations' },
  { label: 'MarketingCloudFX Tracked', value: 'ingestion' },
  { label: 'One-Time Data Import', value: 'import' },
];

module.exports = {
  createdByOptions,
};
