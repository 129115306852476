import { action } from 'easy-peasy';

const updateCategories = ({ categories, categoryId, checked }) => {
  categories.map[categoryId].checked = checked;
  return categories;
};

const getSelectedCategories = (updatedCategories) => {
  const selected = updatedCategories.list.filter(
    (categoryId) => updatedCategories.map[categoryId].checked
  );

  return selected.length ? selected : undefined;
};

export const changeTemplateListCategoryChecked = action((state, { showAll, category }) => {
  state.sidebar.isFiltered = true;

  if (showAll) {
    state.sidebar.categories.showAll = true;

    state.sidebar.categories.list.forEach((categoryId) => {
      state.sidebar.categories.map[categoryId].checked = false;
    });
    return;
  }

  const updatedCategories = updateCategories({
    categories: state.sidebar.categories,
    categoryId: category.categoryId,
    checked: category.checked,
  });
  const selectedCategories = getSelectedCategories(updatedCategories);
  state.sidebar.categories.showAll = !selectedCategories;
});
