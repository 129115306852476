import { thunk } from 'easy-peasy';

import { getProjectTemplates } from './getProjectTemplates';

export const onGetProjectTemplates = thunk(
  async ({ setTemplateProjectsItemsToStore }, values, { getStoreActions, getStoreState }) => {
    const { showLoader, hideLoader, showError } = getStoreActions().common;

    const state = getStoreState();
    const { sidebar } = state.teamworkFx.templates.projects;

    showLoader({
      key: 'teamworkFx.templates.projects.paginationTeamworkFxProjectTemplates',
    });

    try {
      await getProjectTemplates(sidebar, setTemplateProjectsItemsToStore, values);
    } catch (e) {
      showError(e);
    }

    hideLoader({
      key: 'teamworkFx.templates.projects.paginationTeamworkFxProjectTemplates',
    });
  }
);
