import { action } from 'easy-peasy';

export const updateEvent = action((state, updatedItem) => {
  const index = state.events.items.findIndex((event) => event.eventId === updatedItem.eventId);

  if (index !== -1) {
    state.events.items.splice(index, 1, {
      ...state.events.items[index],
      ...updatedItem,
    });
  }
});
