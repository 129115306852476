import { action } from 'easy-peasy';

export const wsPatchProjectOverviewEvent = action((state, { event, eventIndex }) => {
  // Update event start/end date
  const updatedEvent = {
    startAt: event.startAt,
    endAt: event.endAt,
  };

  state.events.items[eventIndex] = {
    ...state.events.items[eventIndex],
    ...updatedEvent,
  };
});
