import { setUTCTimeToZero } from '../../../../../utils-deprecated/getFormattedDate';

export const getResponsibleInfo = (userInfo) => {
  const { assigneeId } = userInfo;

  if (userInfo.dueDate) {
    userInfo.dueDate = setUTCTimeToZero(userInfo.dueDate);
  }

  if (!assigneeId) {
    return userInfo;
  }

  const newUserInfo = { ...userInfo, nobody: false };

  const foundAssigneeId = assigneeId.split(' ')[1];
  const formattedAssigneeId = foundAssigneeId === 'null' ? null : foundAssigneeId;

  // Find property 'nobody'
  if (formattedAssigneeId === 'nobody') {
    return { ...newUserInfo, responsibleUserId: null, nobody: true };
  }

  // Set user
  if (assigneeId.indexOf('userId') !== -1) {
    return {
      ...newUserInfo,
      responsibleCompanyId: null,
      responsibleUserId: formattedAssigneeId,
    };
  }

  // Set company
  return {
    ...newUserInfo,
    responsibleUserId: null,
    responsibleCompanyId: formattedAssigneeId,
  };
};
