import { api } from '@webfx/core-web';
import { isEmpty } from 'lodash';

function useOptimizationsHidden(companyId) {
  const { data: companySettings, isFetching } = api.useQuery(
    [
      'company-settings',
      {
        query: {
          field: 'hideOptimizations',
          companyId: Number(companyId),
          $limit: 1,
        },
      },
    ],
    {
      enabled: !!companyId,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  if (!isFetching && !isEmpty(companySettings)) {
    const enabled = companySettings?.data.filter((s) => Number(s.value) === 1);
    return !isEmpty(enabled);
  }

  return false;
}

export default useOptimizationsHidden;
