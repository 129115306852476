import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import getSelectedCategoriesWithInsert from '../helpers/getSelectedCategoriesWithInsert';

export const onSearchTeamworkFxByCategory = thunk(
  async (
    { setTeamworkFxSearchedByCategories },
    { categoryId, checked },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { search, sidebar } = state.teamworkFx.search.filters;
    const checkedCategories = getSelectedCategoriesWithInsert({
      selected: sidebar.selected,
      categoryId,
      checked,
    });

    if (!search) {
      return setTeamworkFxSearchedByCategories({
        categoryId,
        checked,
        searchedValue: {
          body: {
            hits: { total: 0, hits: [] },
          },
        },
        search,
      });
    }

    const loaders = [
      `teamworkFx.search.categories.${categoryId}`,
      'teamworkFx.search.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      const searchedValue = await api.search.find({
        query: { searchTerm: search, resourceType: checkedCategories },
      });

      setTeamworkFxSearchedByCategories({ categoryId, checked, searchedValue, search });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
