import { action } from 'easy-peasy';

export const removeOverdueMilestone = action((state, updatedItem) => {
  const { milestoneId } = updatedItem;

  state.overdueMilestones.map = state.overdueMilestones.map.filter(
    (item) => item.milestoneId !== milestoneId
  );
  delete state.overdueMilestones.list[milestoneId];

  const milestoneInStateIndex = state.milestones.items.findIndex(
    (item) => item.milestoneId === milestoneId
  );
  milestoneInStateIndex !== -1
    ? (state.milestones.items[milestoneInStateIndex] = { ...updatedItem })
    : state.milestones.items.push(updatedItem);
});
