import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onCreateMilestoneTemplate = thunk(
  async (
    { createMilestoneTemplate },
    { values, setSubmitting, id, resetForm },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;

    try {
      const result = await api.projectTemplateMilestones.create(
        { ...values, id },
        { for: 'responsibleUserWithCompany' }
      );

      createMilestoneTemplate(result);
      resetForm();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
