import { useState, useEffect, useRef } from 'react';
import { map, sortBy, isEqual } from 'lodash';
import { reportServiceData } from '@webfx/constants';

const useArrangeReports = (reports = []) => {
  const [arrangedReports, setArrangedReports] = useState([]);
  const reportsRef = useRef(reports);

  if (!isEqual(reportsRef.current, reports)) {
    reportsRef.current = reports;
  }

  const reportCategories = map(
    sortBy(reportServiceData, (s) => s.displayOrder),
    'name'
  );

  // Always include the Executive Summary as the first category
  reportCategories.unshift('Executive Summary');

  useEffect(() => {
    // Sort reports based on their order in the component
    const sortedReports = [];

    reportCategories.forEach((name) => {
      const matchingReports = reportsRef.current.filter((report) => report.service === name);
      sortedReports.push(...matchingReports);
    });

    // Add reports with no service (or service doesn't match any of our mappings)
    const unmatchedReports = reportsRef.current.filter(
      (report) => !report.service || !reportCategories.includes(report.service)
    );
    sortedReports.push(...unmatchedReports);

    setArrangedReports(sortedReports);
  }, [reportsRef.current]);

  return { arrangedReports, reportCategories };
};

export default useArrangeReports;
