import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onUncompleteTodo = thunk(
  async ({ uncompleteTodo }, { todolistId, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = 'project.todos.todo.completeTodo';
    showLoader({ key: loader });

    try {
      const todo = await api.todos.patch(
        todoId,
        { todolistId },
        { for: 'onToggleTodoCompletedStatus', completed: false }
      );
      uncompleteTodo({ todo });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
