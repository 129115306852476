import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const wsOnDragTodoToAnotherList = thunk(
  async (_, { result }, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { active } = getStoreState().project.todos.todoLists.todoLists;
    const showReloadPageMessage = actions.common.showReloadPageMessage;
    const onMountProjectTodoLists = actions.project.todos.todoLists.onMountProjectTodoLists;

    if (
      active.includes(result.sourceList.todolistId) ||
      active.includes(result.targetList.todolistId)
    ) {
      showReloadPageMessage({
        reloadHandler: onMountProjectTodoLists,
      });
    }

    // const showError = actions.common.showError;
    // const wsDragTodoToAnotherList = actions.project.todos.todoLists.wsDragTodoToAnotherList;
    // const { todoId } = result;
    //
    // try {
    //   const todo = await api.todos.get(todoId, { for: 'todoItemComments' });
    //   wsDragTodoToAnotherList({ result, todo });
    // } catch (e) {
    //   showError(e);
    // }
  }
);
