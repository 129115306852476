import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../services/api';
import { getTodoData } from './helpers/getTodoData';
import { getTodosColorCodes } from './helpers/getTodosColorCodes';

export const onCreateTodo = thunk(
  async (
    { createTodo },
    { values, setSubmitting, setFieldValue, todolistId, multipleItem: isMultiple, resetForm },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const { isColorCoding } = state.project.todos.singleTodoList.todoList;
    const { data: project } = useProject();
    const projectId = project?.projectId;

    const data = getTodoData(isMultiple, { ...values, todolistId, projectId });

    try {
      const todos = await api.todos.create(data, { for: 'onCreateTodo' });
      const colorCodes = await getTodosColorCodes(todos, isColorCoding);

      createTodo({ todolistId, todos, colorCodes });
      resetForm();
      setFieldValue('content', '');
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
