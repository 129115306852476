import { thunk } from 'easy-peasy';

import { getProjectTemplates } from './getProjectTemplates';

const updateCategories = ({ categories, categoryId, checked }) => {
  categories.map[categoryId].checked = checked;
  return categories;
};

const getSelectedCategories = (updatedCategories) => {
  const selected = updatedCategories.list.filter(
    (categoryId) => updatedCategories.map[categoryId].checked
  );

  return selected.length ? selected : undefined;
};

export const onFilterByCategory = thunk(
  async (
    { changeSidebarCategoryChecked, setTemplateProjectsItemsToStore },
    { categoryId, checked },
    { getStoreState, getStoreActions }
  ) => {
    const { showLoader, hideLoader, showError } = getStoreActions().common;
    showLoader({ key: `teamworkFx.templates.projects.categories.${categoryId}` });

    const state = getStoreState();
    const categories = state.teamworkFx.templates.projects.sidebar;

    const updatedCategories = updateCategories({ categories, categoryId, checked });
    const selectedCategories = getSelectedCategories(updatedCategories);

    try {
      changeSidebarCategoryChecked({ categories: updatedCategories, showAll: !selectedCategories });

      const stateAfterSettingSidebar = getStoreState();
      const { sidebar } = stateAfterSettingSidebar.teamworkFx.templates.projects;
      await getProjectTemplates(sidebar, setTemplateProjectsItemsToStore);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: `teamworkFx.templates.projects.categories.${categoryId}` });
  }
);
