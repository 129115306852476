import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onDeleteTodo = thunk(async (_, { todoId, isActive }, { getStoreActions }) => {
  const a = getStoreActions();
  const showError = a.common.showError;
  const showLoader = a.common.showLoader;
  const hideLoader = a.common.hideLoader;
  const deleteActiveTodo = a.project.todos.singleTodoList.deleteActiveTodo;
  const deleteCompletedTodo = a.project.todos.singleTodoList.deleteCompletedTodo;

  const loader = 'project.todos.deleteTodo';
  showLoader({ key: loader });

  try {
    const todo = await api.todos.remove(todoId, { for: 'onDeleteTodo' });
    isActive ? deleteActiveTodo({ todo }) : deleteCompletedTodo({ todo });
  } catch (e) {
    showError(e);
  }

  hideLoader({ key: loader });
});
