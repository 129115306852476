import { getFilterParams } from './getFilterParams';

const testUserAssignee = (responsibleUserId, todo) => {
  if (responsibleUserId === undefined) {
    return true;
  }
  return responsibleUserId === todo.responsibleUserId;
};

const testCompanyAssignee = (responsibleCompanyId, todo) => {
  if (responsibleCompanyId === undefined) {
    return true;
  }
  return responsibleCompanyId === todo.responsibleCompanyId;
};

const getTime = (date) => new Date(date).getTime();

const testDueDate = (date, { dueDate }) => {
  if (date === undefined) {
    return true;
  }
  if (date === null) {
    return dueDate === null;
  }
  if (typeof date === 'string') {
    return getTime(date) === getTime(dueDate);
  }

  return getTime(dueDate) >= getTime(date.start) && getTime(dueDate) <= getTime(date.end);
};

const testSearch = (search, { content }) => {
  if (search === undefined) {
    return true;
  }
  return content.toLowerCase().includes(search.toLowerCase());
};

export const filterTodos = ({ data, filters }) => {
  const todos = Array.isArray(data) ? data : [data];
  const { responsibleUserId, responsibleCompanyId, search } = getFilterParams(filters);

  return todos.filter(
    (todo) =>
      testUserAssignee(responsibleUserId, todo) &&
      testCompanyAssignee(responsibleCompanyId, todo) &&
      testDueDate(filters.dueDate.dueDate, todo) &&
      testSearch(search, todo)
  );
};
