export const startPagesDropdown = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Conversations',
    value: 'messages',
  },
  {
    label: 'To-Do’s',
    value: 'todos',
  },
  {
    label: 'Calendar',
    value: 'calendar',
  },
];

// We get from a server startPage, but the name of this start page is different than URL to it
export const backendStartPages = {
  overview: 'overview',
  messages: 'posts',
  todos: 'todo-lists',
  calendar: 'milestones',
};
