import get from 'lodash/get';
import { DateTime } from 'luxon';

import { store } from '@webfx/web-state';

const format = "ccc LLL d yyyy 'at' t";

// we need to check with `||` because if timeZone = '', then lodash will return '', instead of return default `null` value
// can't use timeZone = '', because it throws error `Invalid DateTime`
// can use only correct timezone name or null (local time)
export const getUserTimeZone = () => get(store.auth.user(), 'timezone', null) || null;

const getFormattedDate = (date, customFormat) => {
  const timeZone = getUserTimeZone();

  const parsedDate = DateTime.fromISO(date);

  if (parsedDate.isValid) {
    return parsedDate
      .setLocale('en')
      .setZone(timeZone)
      .toFormat(customFormat || format);
  }

  return undefined;
};

export const getTodayForDatepicker = () => {
  const timeZone = getUserTimeZone();

  const today = DateTime.utc().setLocale('en').setZone(timeZone);
  const todayJSDate = today.toJSDate();
  const { year, month, day } = today.toObject();
  todayJSDate.setFullYear(year);
  todayJSDate.setMonth(month - 1);
  todayJSDate.setDate(day);
  return todayJSDate;
};

export const getDateWithLocalZone = (date) => {
  const timeZone = getUserTimeZone();

  const parsedDate = DateTime.fromISO(date);

  if (parsedDate.isValid) {
    return parsedDate.setLocale('en').setZone(timeZone);
  }

  return undefined;
};

export const formatDateWithoutTimezone = (dateObject) => {
  const parsedDate = DateTime.fromISO(dateObject, { zone: 'utc' });

  if (parsedDate.isValid) {
    return parsedDate.toUTC().toFormat('dd LLL y' || format);
  }

  const parsedDateFromJs = DateTime.fromJSDate(dateObject);

  if (parsedDateFromJs.isValid) {
    return parsedDateFromJs.toFormat('dd LLL y');
  }

  return undefined;
};

export const getDateDiff = (date, modifier) => {
  const timeZone = getUserTimeZone();
  const currentDay = DateTime.local().setLocale('en').setZone(timeZone);

  const parsedDate = Math.floor(currentDay.diff(date, modifier).toObject()[modifier]);

  if (date === 1) {
    return { date: parsedDate, modifier: modifier.slice(0, -1) };
  }

  return { date: parsedDate, modifier };
};

export const getLastActivity = (date) => {
  const dateWithLocal = getDateWithLocalZone(date);

  const diffInDays = getDateDiff(dateWithLocal, 'days');
  const diffInHours = getDateDiff(dateWithLocal, 'hours');

  let lastActivityDate = getDateDiff(dateWithLocal, 'minutes');

  if (diffInHours.date >= 1 && diffInHours.date < 24) {
    lastActivityDate = diffInHours;
  }

  if (diffInDays.date >= 1) {
    lastActivityDate = diffInDays;
  }

  return lastActivityDate;
};

export const getCurrentDateWithTimezone = () => {
  const timeZone = getUserTimeZone();

  const parsedDate = DateTime.local();

  if (parsedDate.isValid) {
    return parsedDate.setLocale('en').setZone(timeZone).toISODate();
  }

  return undefined;
};

export const checkTodayByISODate = ({ currentDay, activityDay }) =>
  currentDay.toISODate() === activityDay.toISODate() ? 'Today' : undefined;

export const checkYesterday = ({ currentDay, activityDay }) =>
  currentDay.hasSame(activityDay.plus({ days: 1 }), 'day') ? 'Yesterday' : undefined;

export const getDateForActivity = (date) => {
  const timeZone = getUserTimeZone();

  const currentDay = DateTime.local().setLocale('en').setZone(timeZone);
  const activityDay = DateTime.fromISO(date).setLocale('en').setZone(timeZone);

  const today = checkTodayByISODate({ currentDay, activityDay });
  if (today) {
    return today;
  }

  const yesterday = checkYesterday({ currentDay, activityDay });
  if (yesterday) {
    return yesterday;
  }

  return activityDay.toFormat('LLL dd');
};

export const normalizeToCurrentTimezone = (date) => {
  const timeZone = store.auth.user()?.timezone || 'America/New_York';

  const todayDate = DateTime.local().setZone(timeZone).toFormat('LLL dd yyyy');
  const yesterday = DateTime.local().setZone(timeZone).plus({ days: -1 }).toFormat('LLL dd yyyy');
  const parsedDate = DateTime.fromISO(date).setZone(timeZone);

  const dateFormat =
    (todayDate === parsedDate.toFormat('LLL dd yyyy') && 'today') ||
    (yesterday === parsedDate.toFormat('LLL dd yyyy') && 'yesterday') ||
    parsedDate.toFormat('LLL dd');

  const timeFormat = parsedDate.toFormat('h:mm a');

  return {
    date: dateFormat,
    time: timeFormat,
  };
};

export const setUTCTimeToZero = (date) => {
  let dateObj = DateTime.fromJSDate(date);
  if (!dateObj.isValid) {
    dateObj = DateTime.fromISO(date);
  }
  return dateObj.toUTC().toFormat('yyyy-LL-dd');
};

export default getFormattedDate;
