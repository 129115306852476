import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onGetMilestone = thunk(
  async ({ setSelectMilestone }, { milestoneId, params }, { getStoreActions }) => {
    const { common } = getStoreActions();
    common.showLoader({ key: 'getMilestoneSingle' });
    try {
      const milestone = await api.milestones.get({ id: milestoneId, params });
      setSelectMilestone(milestone);
    } catch (e) {
      common.showError(e);
    }
    common.hideLoader({ key: 'getMilestoneSingle' });
  }
);
