import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const wsOnDeleteComment = thunk(async (_, { todoId }, { getStoreActions, getState }) => {
  const a = getStoreActions();
  const showError = a.common.showError;
  const wsDeleteComment = a.project.todos.singleTodoList.wsDeleteComment;

  const localState = getState();
  if (!localState.todoList.todos.map[todoId]) {
    return;
  }

  try {
    const todo = await api.todos.get(todoId, { for: 'todoItemComments' });
    wsDeleteComment({ todo });
  } catch (e) {
    showError(e);
  }
});
