import { action } from 'easy-peasy';

export const setSortingParam = action((state, payload) => {
  state.sortingParam = payload;
  state.skipCount = 0;
  state.hasMoreData = true;
  state.items = [];
  state.itemsIds = [];
  state.startAttachmentId = null;
});
