import React, { createContext, useContext, useState } from 'react';

const RowSelectionContext = createContext();

export const useRowSelectionContext = () => {
  const context = useContext(RowSelectionContext);
  if (!context) {
    return null;
  }
  return context;
};

export const RowSelectionProvider = ({ children }) => {
  const [rows, setRows] = useState({});
  const [table, setTable] = useState();
  const updateStateByKey = (key, value) => {
    setRows((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <RowSelectionContext.Provider value={{ rows, updateStateByKey, table, setTable, setRows }}>
      {children}
    </RowSelectionContext.Provider>
  );
};
