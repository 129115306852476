import React from 'react';
import { Input, Modal } from '@webfx/core-web';

const ShareReportModal = ({ show, onClose, link }) => {
  return (
    <Modal show={show} onClose={onClose} showFooter={false}>
      <Modal.Header>Share Report</Modal.Header>

      <Modal.Body>
        <h5 data-fx-name="modalBody">Here&apos;s your URL</h5>
        <Input data-fx-name="reportUrl" value={link} readOnly />
        <div className="text-center">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
            data-fx-name="copyUrlButton"
          >
            Copy URL To Clipboard
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareReportModal;
