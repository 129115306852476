import { DateTime } from 'luxon';

import {
  checkTodayByISODate,
  checkYesterday,
} from '../../../../../utils-deprecated/getFormattedDate';

const groupDate = (date, timeZone) => {
  const currentDay = DateTime.local().setLocale('en').setZone(timeZone);
  const activityDay = DateTime.fromISO(date).setLocale('en').setZone(timeZone);

  const today = checkTodayByISODate({ currentDay, activityDay });
  if (today) {
    return today;
  }

  const yesterday = checkYesterday({ currentDay, activityDay });
  if (yesterday) {
    return yesterday;
  }

  const formatedDate = activityDay.toFormat('cccc dd LLLL y');
  const indexOfFirstSpace = formatedDate.indexOf(' ');

  return `${formatedDate.substr(0, indexOfFirstSpace)}, ${formatedDate.substr(
    indexOfFirstSpace + 1
  )}`;
};

export default groupDate;
