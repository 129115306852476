import api from '../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectMilestonesTemplate = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectMilestonesTemplate',
  action: async (actions, { projectTemplateId }) => {
    const { mountProjectMilestonesTemplate } = actions.projectTemplate.milestonesList;

    const result = await api.projectTemplates.get(projectTemplateId, { for: 'milestones' });
    mountProjectMilestonesTemplate(result);

    return `/templates/projects/${projectTemplateId}/milestones`;
  },
});
