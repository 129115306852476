import { action } from 'easy-peasy';

import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';

export const deleteActiveTodo = action((state, { todo, todo: { todolistId, todoId } }) => {
  const { todos } = state.todoLists.map[todolistId];

  if (todos.map[todoId]) {
    state.todoLists.$skip -= 1;
    todos.active = todos.active.filter((id) => id !== todoId);
    delete todos.map[todoId];
  }
  // TODO: FIX ME
  moveTodoListToCompleted(state.todoLists, todo);
});
