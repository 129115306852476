import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';

export const wsReorderTodo = thunk((_, { todolistId }, { getState, getStoreActions }) => {
  const actions = getStoreActions();
  const state = getState();

  if (state.todoList.todolistId !== todolistId) {
    return;
  }

  const showReloadPageMessage = actions.common.showReloadPageMessage;
  const onMountProjectSingleTodoList =
    actions.project.todos.singleTodoList.onMountProjectSingleTodoList;

  const { data: project } = useProject();
  const { projectId } = project || { projectId: null };

  if (todolistId && projectId) {
    showReloadPageMessage({
      reloadHandler: () => {
        onMountProjectSingleTodoList({
          projectId,
          todolistId,
        });
      },
    });
  }
});
