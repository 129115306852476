const reorderTodoListHelper = (
  todos,
  todoId,
  sourceTodolistId,
  destinationTodolistId,
  position
) => {
  const todosArray = todos.active.map((t) => todos.map[t]);
  let moveDown = position > todos.map[todoId].position;

  if (destinationTodolistId !== sourceTodolistId) {
    moveDown = position > todosArray[todosArray.length - 1].position;
  }

  todosArray.forEach((todo) => {
    if (moveDown && todo.position <= position) {
      todo.position = todo.position - 1;
    } else if (!moveDown && todo.position >= position) {
      todo.position = todo.position + 1;
    }
  });

  todos.map[todoId].position = position;
};

export default reorderTodoListHelper;
