import { getTaggingPeopleIdsPrivate } from '@webfx/teamwork-web/src/utils/rte';
import { getSubscribePeopleIds } from './subscribePeople';
const unique = (value, index, self) => self.indexOf(value) === index;

const foundUserId = (str) => {
  let match = str.match(/"id":"(.)+"/);
  if (!match) {
    return null;
  }
  match = match[0].match(/(\d)+/);
  return match ? match[0] : null;
};

// TODO: think whether we can simplify regex search, to use 1 regex instead of 2 functions with regex

// get tagged people id from quill delta content (use regex for this)
export const getTaggingPeopleIds = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');

  return Array.from(doc.querySelectorAll('span.mention'), (node) =>
    parseInt(node.dataset.id, 10)
  ).filter(unique);
};

export const getSubscribeUsers = (subscribers, content, privateStatus = false) => {
  let taggingIds = [];
  if (privateStatus) {
    taggingIds = getTaggingPeopleIdsPrivate(content);
  } else {
    taggingIds = getTaggingPeopleIds(content);
  }
  const ids = [...getSubscribePeopleIds(subscribers), ...taggingIds];
  return ids.filter(unique).map((userId) => ({ userId }));
};

export const getMessageCommentSubscribers = (subscribers, content) => {
  const subscribed = subscribers.map(({ userId }) => userId);
  const ids = [...subscribed, ...getTaggingPeopleIds(content)];
  return ids.filter(unique).map((userId) => ({ userId }));
};
