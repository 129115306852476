import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Icon } from '@webfx/core-web';

const Goal = ({ goal }) => {
  if (!goal.value) {
    return null;
  }

  return (
    <OverlayTrigger
      key={goal.id}
      placement="top"
      overlay={
        <Popover className="p-3">
          <div className="d-flex flex-column">
            <span className="bold font-12">{goal.name}</span>
            <div className="my-1 d-flex align-items-center">
              <Icon className="text-primary">text_snippet</Icon>
              <span className="pl-1">{goal.value ?? 'No'} Action Items Planned</span>
            </div>
            <div className="my-1 d-flex align-items-center">
              <Icon className="text-success">check_circle</Icon>
              <span className="pl-1">{goal.completed} Action Items Completed</span>
            </div>
          </div>
        </Popover>
      }
    >
      <div className="d-flex flex-column">
        <span className="font-28 semibold" style={{ color: goal.color }}>
          {goal.percentage}
        </span>
        <span className="semibold font-16 text-gray-550">{goal.name}</span>
      </div>
    </OverlayTrigger>
  );
};

export default Goal;
