import { Icon } from '@webfx/core-web';
import {
  CARD_PROPERTIES,
  BADGE_PROPERTIES,
} from '@webfx/gameplan-ui/src/constants/optimization-card';

const EXCLUDED_BADGE_TYPES = [
  'todo',
  'milestone',
  'asset',
  'cro',
  'email',
  'amazon',
  'adtechfx',
  'local seo',
  'otherServices',
];

const Badge = ({ type, pickedBy = null, serviceType = null }) => {
  if (EXCLUDED_BADGE_TYPES.includes(type)) {
    return null;
  }

  const badgeType = pickedBy || serviceType;
  const { icon, label } = BADGE_PROPERTIES[badgeType];
  const { badgeBackground, textColor } = CARD_PROPERTIES[type] || CARD_PROPERTIES.otherServices;

  return (
    <div
      className={`d-flex align-items-center badge-pill px-2 py-1 font-12 ${badgeBackground} ${textColor}`}
    >
      <Icon className="mr-2">{icon}</Icon>
      <span>{label}</span>
    </div>
  );
};

export default Badge;
