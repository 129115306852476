import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

export const onSearchTeamworkFxByAllCategories = thunk(
  async ({ setTeamworkFxSearchedAllToStore }, _, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { search } = state.teamworkFx.search.filters;

    if (!search) {
      return setTeamworkFxSearchedAllToStore({
        searchedValue: {
          body: {
            hits: { total: 0, hits: [] },
          },
        },
        search,
      });
    }

    const loaders = ['teamworkFx.search.categories.showAll', 'teamworkFx.search.hasAnyLoading'];
    showLoader({ key: loaders });
    try {
      const searchedValue = await api.search.find({
        query: { searchTerm: search, resourceType: undefined },
      });

      setTeamworkFxSearchedAllToStore({ searchedValue, search });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
