import { onUploadFiles } from './onUploadFiles';
import { onGetFiles } from './onGetFiles';
import { onGetFilesSidebarUsers } from './onGetFilesSidebarUsers';
import { onDeleteFile } from './onDeleteFile';
import { onUpdateFile } from './onUpdateFile';
import { onMountProjectFiles } from './onMountProjectFiles';

export default {
  onUploadFiles,
  onGetFiles,
  onGetFilesSidebarUsers,
  onDeleteFile,
  onUpdateFile,
  onMountProjectFiles,
};
