import get from 'lodash/get';
import { toBool } from '../../../../../../utils-deprecated/toBool';

export const transformTodo = (todo) => {
  const _todo = {
    ...todo,
    completed: toBool(todo.completedAt),
    assignee: {
      avatar: get(todo, 'user.avatarUrl') || get(todo, 'company.logoUrl'),
      name:
        (todo.user && `${todo.user.firstName} ${todo.user.lastName}`) ||
        get(todo, 'company.name', ''),
    },
  };
  if (todo.todoList) {
    _todo.todolistName = todo.todoList.name;
    _todo._private = toBool(todo.todoList.private);
  }
  return _todo;
};
