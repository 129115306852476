import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import getQueryForFiltering from '../helpers/getQueryForFiltering';

export const onShowAllTodoLists = thunk(
  async (
    { setTemplateTodoListsIntoStore, changeSidebarAllChecked },
    { templateId, showAll },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = [
      'projectTemplate.todoLists.sidebar.categories.showAll',
      'projectTemplate.todoLists.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      changeSidebarAllChecked({ showAll: !showAll });

      const state = getStoreState();
      const stateTodoListsCopy = { ...state.projectTemplate.todoLists };
      const query = getQueryForFiltering(stateTodoListsCopy, templateId);
      const filteredLists = await api.projectTemplateTodoLists.find({
        query,
        for: 'projectTemplateTodoList',
      });
      setTemplateTodoListsIntoStore(filteredLists);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
