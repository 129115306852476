import OverlayTooltip from './OverlayTooltip/OverlayTooltip';

const getUserTooltip = (user) => {
  const { firstName, lastName, title, officeNumber, mobileNumber, pronouns } = user;

  return (
    <>
      <div>
        <span className="font-weight-bold">
          {firstName} {lastName}
        </span>{' '}
        {pronouns && <small className="text-secondary">({pronouns})</small>}
      </div>
      {title && <div>{title}</div>}
      {(officeNumber || mobileNumber) && <div>{officeNumber || mobileNumber}</div>}
    </>
  );
};

const UserOverlayTooltip = ({ user = {}, placement = 'top', children, ...rest }) => {
  // do not add tooltip if firstName does not exist
  if (!user || !user.firstName) {
    return children;
  }

  const tooltip = getUserTooltip(user);

  return (
    <OverlayTooltip text={tooltip} placement={placement} {...rest}>
      {children}
    </OverlayTooltip>
  );
};

export default UserOverlayTooltip;
export { getUserTooltip };
