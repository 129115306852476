import uniq from 'lodash/uniq';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import { transformTodo } from './transformTodo';

// TODO: Replacing embedded strucuture state like this is highly inefficient
// TODO: structures need refined and flattened
// TODO: Use computed state to generate active/completed
export const setTodoToList = (todoList, todo) => {
  const { todos } = todoList;
  const { todoId, completedAt } = todo;

  if (!todos.active.includes(todoId) && !todos.completed.includes(todoId)) {
    toBool(completedAt) ? todos.completed.push(todoId) : todos.active.push(todoId);
  }

  todos.map[todoId] = transformTodo(todo); // TODO remove api transforming and move to computed props
};

const getLoadedTodoListIds = (todos) => todos.map((t) => t.todolistId);

export const setTodosToLists = (payload) => {
  const { todoLists, todosBag, todolistId } = payload;
  const { active, completed, map, loadedTodoLists = [] } = todoLists;
  const todos = todosBag.data || todosBag;

  todos.forEach((todo) => {
    if (map[todo.todolistId]) {
      setTodoToList(map[todo.todolistId], todo);
    }
  });

  return {
    active,
    completed,
    map,
    loadedTodoLists: uniq([
      ...loadedTodoLists,
      ...(Array.isArray(todolistId) ? todolistId : [todolistId]),
      ...getLoadedTodoListIds(todos),
    ]),
  };
};
