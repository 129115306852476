import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

export const mergeSidebarIntoExisted = (sidebar, payload) => {
  payload.forEach((category) => {
    const checked =
      category.categoryId in sidebar.map ? sidebar.map[category.categoryId].checked : false;

    if (!sidebar.list.includes(category.categoryId)) {
      sidebar.list.push(category.categoryId);
    }
    sidebar.map[category.categoryId] = category;
    sidebar.map[category.categoryId].checked = checked;
  });

  return sidebar;
};

export const setTemplateProjectSidebarCategories = action(({ sidebar }, payload) => {
  // Not sure if this is the right place, maybe create a selector ?
  const sortedCategories = sortBy(payload, ({ name }) => name.trim().toLowerCase());

  mergeSidebarIntoExisted(sidebar, sortedCategories);
});
