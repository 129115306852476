import { setTemplateListSidebarCategories } from './actions/setTemplateListSidebarCategories';
import { setTemplateListItemsToStore } from './actions/setTemplateListItemsToStore';
import { changeTemplateListCategoryChecked } from './actions/changeTemplateListCategoryChecked';
import { setTemplateListFilteringParams } from './actions/setTemplateListFilteringParams';
import { updateTeamworkFxTemplateTodo } from './actions/updateTeamworkFxTemplateTodo';
import { deleteTeamworkFxTemplateTodo } from './actions/deleteTeamworkFxTemplateTodo';
import { addTeamworkFxTemplateTodo } from './actions/addTeamworkFxTemplateTodo';
import { addTeamworkFxTemplateMultipleTodo } from './actions/addTeamworkFxTemplateMultipleTodo';
import { deleteTeamworkFxTemplateList } from './actions/deleteTeamworkFxTemplateList';
import { updateTeamworkFxTemplateTodoList } from './actions/updateTeamworkFxTemplateTodoList';
import { teamworkFxDragAndDropTemplateTodo } from './actions/teamworkFxDragAndDropTemplateTodo';
import { setTeamworkFxTodoCategories } from './actions/setTeamworkFxTodoCategories';
import { setTodoListTemplatesTodos } from './actions/setTodoListTemplatesTodos';

import { onMountTodoListsTemplate } from './thunks/onMountTodoListsTemplate';
import { onMountSingleTodoListsTemplate } from './thunks/onMountSingleTodoListsTemplate';
import { onShowAllTemplateTodoListsCategories } from './thunks/onShowAllTemplateTodoListsCategories';
import { onFilterTodoListsTemplateByCategory } from './thunks/onFilterTodoListsTemplateByCategory';
import { onChangeTodoListTemplateFilteringParams } from './thunks/onChangeTodoListTemplateFilteringParams';
import { onUpdateTeamworkFxTemplateTodo } from './thunks/onUpdateTeamworkFxTemplateTodo';
import { onDeleteTeamworkFxTemplateTodo } from './thunks/onDeleteTeamworkFxTemplateTodo';
import { onCreateTeamworkFxTemplateTodo } from './thunks/onCreateTeamworkFxTemplateTodo';
import { onDeleteTeamworkFxTemplateTodoList } from './thunks/onDeleteTeamworkFxTemplateTodoList';
import { onUpdateTeamworkFxTemplateTodoList } from './thunks/onUpdateTeamworkFxTemplateTodoList';
import { onUpdateTeamworkFxTemplateDragAndDropTodo } from './thunks/onUpdateTeamworkFxTemplateDragAndDropTodo';
import { onCreateTeamworkFxTemplateTodoList } from './thunks/onCreateTeamworkFxTemplateTodoList';
import { onChangeSingleTemplateSearch } from './thunks/onChangeSingleTemplateSearch';

import { todoListsTemplatesStore } from './store';

const todoLists = {
  // store
  ...todoListsTemplatesStore,

  // actions
  setTemplateListSidebarCategories,
  setTemplateListItemsToStore,
  changeTemplateListCategoryChecked,
  setTemplateListFilteringParams,
  updateTeamworkFxTemplateTodo,
  deleteTeamworkFxTemplateTodo,
  addTeamworkFxTemplateTodo,
  addTeamworkFxTemplateMultipleTodo,
  deleteTeamworkFxTemplateList,
  updateTeamworkFxTemplateTodoList,
  teamworkFxDragAndDropTemplateTodo,
  setTeamworkFxTodoCategories,
  setTodoListTemplatesTodos,

  // thunks
  onMountTodoListsTemplate,
  onMountSingleTodoListsTemplate,
  onShowAllTemplateTodoListsCategories,
  onFilterTodoListsTemplateByCategory,
  onChangeTodoListTemplateFilteringParams,
  onUpdateTeamworkFxTemplateTodo,
  onDeleteTeamworkFxTemplateTodo,
  onCreateTeamworkFxTemplateTodo,
  onDeleteTeamworkFxTemplateTodoList,
  onUpdateTeamworkFxTemplateTodoList,
  onUpdateTeamworkFxTemplateDragAndDropTodo,
  onCreateTeamworkFxTemplateTodoList,
  onChangeSingleTemplateSearch,
};

export default todoLists;
