import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { allPeopleAndCompaniesLimit } from '../../../../../ui/constants/limits';

export const onFindCompanies = thunk(
  async ({ setCompanies }, { excludeCompanyId, skipCount }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = ['onFindCompanies', 'teamworkFx.allPeople.allPeople.hasAnyLoading'];
    showLoader({ key: loaders });
    try {
      // always sort companies alphabetically
      // if `excludeCompanyId` parameter is available - then exclude company with this id from result
      const foundedCompanies = await api.companies.find({
        query: {
          $sort: { name: 1 },
          companyId: { $ne: excludeCompanyId },
          $skip: skipCount,
          $limit: allPeopleAndCompaniesLimit,
        },
        for: 'allPeople',
      });
      setCompanies(foundedCompanies);
    } catch (error) {
      showError(error);
    }
    hideLoader({ key: loaders });
  }
);
