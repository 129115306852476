import { action } from 'easy-peasy';

import { setTodoToList } from '../helpers/setTodosToLists';
import { transformTodoList } from '../helpers/transformTodoList';

export const createTodoListByTemplate = action((state, { todoList }) => {
  const { todoLists } = state;
  const list = Array.isArray(todoList) ? todoList[0] : todoList;
  const { todolistId, todos } = list;

  const _todoList = transformTodoList({ todoList: list, checked: true });
  todos.forEach((todo) => {
    setTodoToList(_todoList, todo);
  });

  todoLists.active.unshift(todolistId);
  todoLists.map[todolistId] = _todoList;
  todoLists.$skip += todos.length;
});
