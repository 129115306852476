'use strict';

const updateProjectPrivateStatus =
  ({ mutator, setPrivateFileLoading }) =>
  ({ privateStatus, uploadId }) => {
    const params = {
      _method: 'PATCH',
      _id: uploadId,
      private: privateStatus,
    };
    mutator.mutate(params, {
      onSuccess: (response) => {
        setPrivateFileLoading(false);
      },
    });
  };

module.exports = updateProjectPrivateStatus;
