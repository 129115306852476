import { action } from 'easy-peasy';

import { projectTeamworkFxTemplatesStore } from '../store';
import { clearStatePartByKey } from '../../../../../../utils-deprecated/easyPeasyHelpers';

export const clearProjectTemplateState = action((state) => {
  clearStatePartByKey({ state, key: 'items', store: projectTeamworkFxTemplatesStore });
  clearStatePartByKey({ state, key: 'categories', store: projectTeamworkFxTemplatesStore });
  clearStatePartByKey({ state, key: 'sidebar.list', store: projectTeamworkFxTemplatesStore });
  clearStatePartByKey({ state, key: 'pagination', store: projectTeamworkFxTemplatesStore });
});
