import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

const channels = feathersClient.service('tw/channels-deprecated');

/**
 *
 * @param data
 * @param params
 */
export const patch = (data, params) => channels.patch(null, data, paramsForServer(params));
