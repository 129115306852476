import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

import api from '../../../../../services/api';

export const onUpdateUserPermission = thunk(
  async (
    { updateUserPermissions },
    { projectUserId, userId, accessLevel },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = [
      `project.peopleAndPermissions.nonWebFxUsers.${accessLevel.accessLevel}.${userId}`,
      `project.peopleAndPermissions.nonWebFxUsers.hasAnyLoading.${userId}`,
    ];
    showLoader({ key: loader });
    try {
      const res = await api.projectsUsers.patch(projectUserId, accessLevel, {
        for: 'changePeopleAndPermissions',
      });
      updateUserPermissions(res);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
    feathers().service('permissions').update(userId, { userId });
  }
);
