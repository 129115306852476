import { onCreateCompany } from './onCreateCompany';
import { onFindCompanies } from './onFindCompanies';
import { onMountTeamworkFxAllPeoplePage } from './onMountTeamworkFxAllPeoplePage';
import { onSearchAllPeopleByText } from './onSearchAllPeopleByText';

export default {
  onCreateCompany,
  onFindCompanies,
  onMountTeamworkFxAllPeoplePage,
  onSearchAllPeopleByText,
};
