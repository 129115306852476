import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';
import { filterTodoListsTemplate } from './filterTodoListsTemplate';

export const onCreateTeamworkFxTemplateTodoList = thunk(
  async (
    { setTemplateListItemsToStore, onMountSingleTodoListsTemplate },
    { values, setSubmitting, onClosePopup, singlePage = false, push = () => null },
    { getStoreActions, getStoreState }
  ) => {
    const { showError } = getStoreActions().common;
    const { templateCategories } = values;
    delete values.templateCategories;
    const categoryIds = templateCategories.map((template) => template.value);

    try {
      const result = await api.todoListsTemplate.create({ ...values, categoryIds });

      const state = getStoreState();
      const { categories, filters } = state.teamworkFx.templates.todoLists.sidebar;

      if (singlePage) {
        onMountSingleTodoListsTemplate({
          todolistId: result.todolistTemplateId,
          pushParams: { push },
        });
      }

      await filterTodoListsTemplate(
        { ...categories, ...filters },
        setTemplateListItemsToStore,
        singlePage ? result.todolistTemplateId : undefined
      );

      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
