import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { getResponsibleInfo } from '../helpers/getResponsibleInfo';

export const onUpdateTemplateTodoAssignee = thunk(
  async ({ updateTemplateTodoAssignee }, { values, id }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;
    try {
      const res = await api.projectTemplateTodos.patch(id, getResponsibleInfo(values));
      updateTemplateTodoAssignee(res[0]);
    } catch (e) {
      showError(e);
    }
  }
);
