import { api } from '@webfx/core-web';

export function useActivity({ projectId, skip = 0 }) {
  return api.useQuery(
    [
      'tw/activities',
      {
        projectId,
        $skip: skip,
        $limit: 50,
        $client: {
          for: 'additionalInfo',
        },
      },
    ],
    {
      enabled: !!projectId,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
    }
  );
}
