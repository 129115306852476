import superagent from 'superagent';
import { getAccessToken } from '../services/auth';
import { API_AVATAR_URL, DEFAULT_AVATAR_URL } from '../config/app';

//import api from '../services/api';
export const getFileUrl = (uploadId, apiUpload) => {
  let baseUrl;
  baseUrl = process.env.POI_APP_URL;
  return uploadId ? `${baseUrl}/uploads/${uploadId}` : '';
};

export const getAvatarUrl = (avatarId) =>
  avatarId && avatarId.length ? `${API_AVATAR_URL}/${avatarId}` : '';

export const getBlobFile = async (url, type) => {
  const res = await superagent.get(url).set('Authorization', getAccessToken()).responseType('blob');
  return new Blob([res.body], { ...(type && { type }) });
};

export const getFileLinkWithUploadsToken = async (uploadId, apiKey, apiUpload) => {
  return `${getFileUrl(uploadId, apiUpload)}`;
};

export const getAuthorizedFileLink = async (uploadId, type) => {
  const url = getFileUrl(uploadId);
  const blob = await getBlobFile(url, type);
  return URL.createObjectURL(blob);
};

export const getAvatarLink = (fileUrl) => {
  if (!fileUrl || !fileUrl.length) {
    return DEFAULT_AVATAR_URL;
  }
  // link is external http/https link
  if (/^http(.)*$/.test(fileUrl)) {
    return fileUrl.replace("'", "\\'");
  }
  return getAvatarUrl(fileUrl);
};
