const toggleAllLists = (active, map, checked) => {
  active.forEach((todolistId) => {
    map[todolistId].checked = checked;
  });
};

const toggleList = (map, todolistId, checked) => {
  map[todolistId].checked = checked;
};

export const toggleTodoLists = ({ todoLists: { active, map }, todolistId, checked }) => {
  todolistId ? toggleList(map, todolistId, checked) : toggleAllLists(active, map, checked);
};
