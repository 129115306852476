import { action } from 'easy-peasy';
import cloneDeep from 'lodash/cloneDeep';

import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';

export const moveTodoToList = action((state, payload) => {
  const { todoId, sourceTodolistId, destinationTodolistId } = payload;

  state.todoLists.map[destinationTodolistId].todos.map[todoId] = {
    ...cloneDeep(state.todoLists.map[sourceTodolistId].todos.map[todoId]),
    todolistId: destinationTodolistId,
  };

  if (!state.todoLists.map[destinationTodolistId].todos.active.includes(todoId)) {
    state.todoLists.map[destinationTodolistId].todos.active.unshift(todoId);
  }

  delete state.todoLists.map[sourceTodolistId].todos.map[todoId];
  state.todoLists.map[sourceTodolistId].todos.active = state.todoLists.map[
    sourceTodolistId
  ].todos.active.filter((t) => t !== todoId);

  // TODO: do we need these? Alex: if you move all items out of a list this will mark it as complete
  // moveSidebarList({ todo: { todoList: sourceList }, toCompleted: true });
  moveTodoListToCompleted(state.todoLists, { todolistId: sourceTodolistId });
});
