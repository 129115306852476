import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onDeleteEvent = thunk(
  async ({ removeEvent }, { eventId, closeModal }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'teamworkFx.dashboard.deleteEvent';
    showLoader({ key: loader });
    try {
      const data = await api.events.remove(eventId);
      removeEvent(data);
      closeModal();
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
