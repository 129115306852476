import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../../../services/api';
import { isOverdueMilestone } from '../../../../../../utils-deprecated/calendar';

export const onDeleteMilestone = thunk(
  async ({ removeMilestone }, { milestoneId, closeModal }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const { timezone } = store.auth.user();

    const loader = 'teamworkFx.dashboard.deleteMilestone';
    showLoader({ key: loader });
    try {
      const milestone = await api.milestones.remove(milestoneId);
      const isOverdue = isOverdueMilestone(milestone.dueAt, timezone);
      removeMilestone({ milestone, isOverdue });
      closeModal();
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
