import { thunk } from 'easy-peasy';
// import sortBy from 'lodash/sortBy';
// import api from '../../../../../services/api';
// import { getFilterParams } from '../helpers/getFilterParams';
// import { getInitializedTodoLists } from '../helpers/getInitializedTodoLists';

/* eslint-disable prefer-destructuring */
export const wsOnReorderTodoLists = thunk(
  async (_, { todoLists }, { getState, getStoreActions }) => {
    const actions = getStoreActions();

    const showReloadPageMessage = actions.common.showReloadPageMessage;
    const onMountProjectTodoLists = actions.project.todos.todoLists.onMountProjectTodoLists;

    showReloadPageMessage({
      reloadHandler: () => {
        onMountProjectTodoLists();
      },
    });

    //
    // const showError = a.common.showError;
    // const wsReorderTodoLists = a.project.todos.todoLists.wsReorderTodoLists;
    //
    // const localState = getState();
    // const { filters } = localState;
    // const { active, map, lastLoadedTodoListId } = localState.todoLists;
    //
    // const _map = todoLists.reduce((acc, { todolistId, position }) => {
    //   const todoList = acc[todolistId];
    //   if (todoList) todoList.position = position;
    //   return acc;
    // }, map);
    //
    // const _active = sortBy(active.map(todolistId => map[todolistId]), ['position']).map(todoList => todoList.todolistId);
    // const initializedLists = getInitializedTodoLists(active, lastLoadedTodoListId);
    // const sliceOfLists = _active.slice(0, initializedLists.length);
    //
    // try {
    //   const todosBag = await api.todos.find({
    //     query: {
    //       todolistId: { $in: sliceOfLists },
    //       $limit: 1000000, // load all todos and don't break response structure
    //     },
    //     filter: getFilterParams(filters),
    //     for: 'onFindProjectTodos',
    //   });
    //
    //   wsReorderTodoLists({ active: _active, map: _map, todosBag });
    // } catch (e) {
    //   showError(e);
    // }
  }
);
