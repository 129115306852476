import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

import api from '../../../../../services/api';

export const onRestoreDeletions = thunk(
  async ({ setDeletionsData }, { values, param, setRestore }, { getStoreActions }) => {
    const { showError } = getStoreActions().common;
    try {
      await feathers().service('deletions').patch(Number(param.query.projectId), values);
      const res = await api.deletions.find(param);
      setRestore({});
      setDeletionsData(res);
    } catch (e) {
      showError(e);
    }
  }
);
