import { action } from 'easy-peasy';

import { getSelectedMonthDate } from '../../../../../../utils-deprecated/calendar';
import { sortOverdueMilestones } from '../../../../helpers/sortOverdueMilestones';

export const updateMilestones = action((state, { milestones, timezone }) => {
  // update existed milestones
  const existedMilestones = state.milestones.items.map((milestone) => {
    const updatedMilestone = milestones.find(
      ({ milestoneId }) => milestoneId === milestone.milestoneId
    );
    if (updatedMilestone) {
      return updatedMilestone;
    }
    return milestone;
  });
  // add another milestones
  const anotherMilestones = milestones.filter(
    (milestone) =>
      !state.milestones.items.some(({ milestoneId }) => milestoneId === milestone.milestoneId)
  );

  const currentDate = getSelectedMonthDate(null, timezone).toFormat('yyyy-LL-dd');
  milestones.forEach((milestone) => {
    if (milestone.dueAt < currentDate) {
      const searchedItem = state.overdueMilestones.map.find(
        ({ milestoneId }) => milestoneId === milestone.milestoneId
      );

      if (searchedItem) {
        state.overdueMilestones.list[searchedItem.milestoneId] = {
          ...searchedItem,
          ...{ responsibleCompany: null, responsibleUser: null },
          ...milestone,
        };
      } else {
        state.overdueMilestones.map.push(milestone);
        state.overdueMilestones.map = sortOverdueMilestones(state.overdueMilestones.map);
        state.overdueMilestones.list[milestone.milestoneId] = milestone;
      }
    } else {
      state.overdueMilestones.map = state.overdueMilestones.map.filter(
        ({ milestoneId }) => milestoneId !== milestone.milestoneId
      );
      if (state.overdueMilestones.list[milestone.milestoneId]) {
        delete state.overdueMilestones.list[milestone.milestoneId];
      }
    }
  });

  state.milestones.items = [...existedMilestones, ...anotherMilestones];
});
