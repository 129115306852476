import { thunk } from 'easy-peasy';

export const onUpdateProject = thunk(
  async (_, { projectId, values, setSubmitting }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;
    try {
      await actions.tw.projects.save({
        projectId,
        ...values,
      });
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
