import { store } from '@webfx/web-state';
import api from '../../../../services/api';
import fetchWithLoaderAndRedirect from '../../fetchWithLoaderAndRedirect';

export const onMountProjectFiles = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectFiles',
  action: async (actions, { projectId, limit = 10 }, getStoreState) => {
    const state = getStoreState();
    const userTimezone = store.auth.user().timezone;
    const { sidebarUsers, sortingParam: sort } = state.files;
    const { hideLoader } = actions.common;
    const { set, setSidebarUsers } = actions.files;

    projectId = projectId || state.router.params.projectId;

    const creatorUserId =
      Object.keys(sidebarUsers.items).filter((id) => sidebarUsers.items[id].checked) || [];
    const query = {
      projectId,
      $skip: 0,
      $limit: limit,
      $sort: sort === 'name' ? { [sort]: 1 } : { [sort]: -1 },
    };

    if (creatorUserId.length) {
      query.creatorUserId = { $in: creatorUserId };
    }

    const [item, users] = await Promise.all([
      api.attachments.find({ query, for: 'projectFiles' }),
      api.attachments.find({
        query: {
          projectId,
          $skip: 0,
          $limit: limit,
        },
        for: 'uploadedBy',
      }),
    ]);

    if (item.total) {
      set({
        item,
        sort,
        creatorUserId,
        userTimezone,
        clearState: true,
        setStartAttachmentId: true,
      });
    }
    setSidebarUsers({ ...users, afterDeletingFile: false, clearState: true });

    hideLoader({ key: 'getAllFiles' });
    hideLoader({ key: 'getUsersOfFiles' });

    return `/projects/${projectId}/files`;
  },
});
