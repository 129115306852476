// TODO: need to check to use this helper function everywhere in code where we need

import internalCompanyName from '../ui/constants/internalCompanyName';

const isInternalCompany = (companyName) => {
  if (typeof companyName !== 'string') {
    return false;
  }
  return companyName.toLowerCase() === internalCompanyName;
};

export default isInternalCompany;
