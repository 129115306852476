import React from 'react';

import { Spinner, Icon } from '@webfx/core-web';
import thumbStyle from './thumbnail.style';
import style from './DropzoneFilesUpload.module.css';

/**
 *
 * @param root0
 * @param root0.avatar
 * @param root0.handleRemoveAvatar
 * @param root0.file
 * @param root0.handleRemove
 * @param root0.handleRetry
 */
const AvatarThumbnail = ({ avatar, handleRemoveAvatar, file, handleRetry, disabled }) => {
  return (
    <div style={{ position: 'absolute', marginTop: '-25px' }}>
      {avatar && <img src={avatar} className={style.avatar} />}
      {avatar && !disabled && (
        <div onClick={handleRemoveAvatar} className={style.overlayRemoveDivAvatar}>
          <div className={style.overlayRemoveDivIcon}>
            <Icon style={thumbStyle.removeIcon}>highlight_off</Icon>
          </div>
        </div>
      )}

      {!avatar && <img src={file.preview} className={style.avatar} />}

      {(!file.uploadId || file.status == 'deleting') && file.status != 'failed' && avatar == null && (
        <div className={style.overlayDivAvatar}>
          <div className={style.spinnerDiv}>
            <Spinner className={style.spinnerDropzone} />
          </div>
        </div>
      )}

      {file.uploadId && file.status != 'deleting' && (
        <div onClick={handleRemoveAvatar} className={style.overlayRemoveDivAvatar}>
          <div className={style.overlayRemoveDivIcon}>
            <Icon style={thumbStyle.removeIcon}>highlight_off</Icon>
          </div>
        </div>
      )}

      {file.status == 'failed' && (
        <div onClick={handleRetry} className={style.overlayRemoveDivAvatar}>
          <div className={style.overlayRemoveDivIcon}>
            <Icon style={thumbStyle.retryIcon}>restart_alt</Icon>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarThumbnail;
