import { action } from 'easy-peasy';

import { sortOverdueMilestones } from '../../../../helpers/sortOverdueMilestones';

export const updateOverdueMilestone = action((state, updatedItem) => {
  // don't proceed completed milestone
  if (updatedItem.completedAt) {
    return;
  }

  let updatedOverdueMilestones;
  const isMilestoneInState = state.overdueMilestones.map.some(
    ({ milestoneId }) => milestoneId === updatedItem.milestoneId
  );
  let sortMilestones = false;

  if (isMilestoneInState) {
    updatedOverdueMilestones = state.overdueMilestones.map.map((milestone) => {
      if (milestone.milestoneId === updatedItem.milestoneId) {
        sortMilestones = milestone.dueAt !== updatedItem.dueAt;
      }
      return milestone.milestoneId === updatedItem.milestoneId ? updatedItem : milestone;
    });
  } else {
    updatedOverdueMilestones = [...state.overdueMilestones.map, updatedItem];
    sortMilestones = true;
  }

  state.overdueMilestones.map = sortMilestones
    ? sortOverdueMilestones(updatedOverdueMilestones)
    : updatedOverdueMilestones;
  state.overdueMilestones.list[updatedItem.milestoneId] = updatedItem;
});
