export const templateProjectPaginationLimit = 50;
// messages -> messages list
export const allMessagesLimit = 25;
// messages -> message comments
export const messageCommentsLimit = 30;
// project -> todos
export const projectCurrentTodosLimit = -1;
// milestones -> milestone comments
export const milestoneCommentsLimit = 30;
// project overview -> deleted data
export const deletedDataLimit = 50;
export const overdueMilestonesLimit = 200;
export const allPeopleAndCompaniesLimit = 10;
export const deletedProjectsLimit = 50;
export const todosLimit = 100;
export const companiesProjectsLimit = 20;
