export const getTemplatesOptions = (categories) =>
  categories.reduce(
    (acc, { todolistTemplateId, name }) => [...acc, { label: name, value: todolistTemplateId }],
    []
  );

export const getMilestonesOptions = (milestones) =>
  milestones.reduce(
    (acc, { milestoneId, title }) => [...acc, { label: title, value: milestoneId }],
    [{ label: 'No Milestone', value: null }]
  );
