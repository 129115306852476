import { useMemo } from 'react';
import classnames from 'classnames';

import { Svg } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';
import groupByOptimizationDueDate from '@webfx/gameplan-ui/src/utils/groupByOptimizationDueDate';

import Box from '@webfx/teamwork-web/src/assets/Box.svg';

import OptimizationCard from '../OptimizationCard';
import OptimizationCardV1 from '../OptimizationCardV1';
import CalendarColumnHeader from './CalendarColumnHeader';
import CalendarColumnHeaderV1 from './CalendarColumnHeaderV1';

import styles from './CalendarColumn.module.css';

const CalendarColumn = ({ companyId, quarter, month, query, year }) => {
  const flags = useFlags();
  const { data, isFetching } = query;

  const optimizations = useMemo(() => groupByOptimizationDueDate(data), [data]);
  const isGameplanfxUpdatesPhase2 = flags.gameplanfxUpdates && flags.gameplanfxUpdatesPhase2;

  return (
    <div className={classnames('col', styles.column)} style={{ width: '100%' }}>
      <div className="h-100">
        {isGameplanfxUpdatesPhase2 ? (
          <CalendarColumnHeader
            companyId={companyId}
            isFetching={isFetching}
            month={month}
            year={year}
            optimizations={optimizations[month]}
          />
        ) : (
          <CalendarColumnHeaderV1 isFetching={isFetching} month={month} year={year} />
        )}

        <div
          className="pl-1"
          style={{
            minHeight: 'calc(50vh - 205px)',
            maxHeight: '800px',
            overflowY: 'auto',
            scrollbarColor: '#c7c7c7 white',
            scrollbarWidth: 'thin',
          }}
          data-fx-name="calendarColumn"
        >
          {(optimizations[month] ?? []).map((projectOptimization, index) =>
            isGameplanfxUpdatesPhase2 ? (
              <OptimizationCard
                companyId={companyId}
                projectOptimization={projectOptimization}
                quarter={quarter}
                key={`${index}-${projectOptimization.objectId}`}
              />
            ) : (
              <OptimizationCardV1
                projectOptimization={projectOptimization}
                key={`${index}-${projectOptimization.objectId}`}
              />
            )
          )}

          {!(optimizations[month] ?? []).length && (
            <div className="h-100 w-100 text-center p-1 pr-4">
              <Svg svgClassName="w-lg-25 pt-5" src={Box} />
              <p className="font-14 text-secondary">
                To be determined based on objective progression, further data collection, and AI
                Machine Learning
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarColumn;
