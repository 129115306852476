const getUserInfo = (assigneeInfo) => {
  const userId = assigneeInfo.value.responsibleUserId;
  const userAssignee = {
    user: {
      userId,
      firstName: assigneeInfo.firstName,
      lastName: assigneeInfo.lastName,
      avatarUrl: assigneeInfo.avatarUrl,
    },
    company: undefined,
    responsibleCompanyId: undefined,
    responsibleUserId: userId,
    assignee: {
      avatarUrl: assigneeInfo.avatarUrl,
      name: `${assigneeInfo.firstName} ${assigneeInfo.lastName}`,
    },
  };
  return userAssignee;
};

const getCompanyInfo = (assigneeInfo) => {
  const companyId = assigneeInfo.value.responsibleCompanyId;
  const company = {
    user: undefined,
    company: {
      companyId,
      name: assigneeInfo.label,
      logoUrl: assigneeInfo.avatarUrl,
    },
    responsibleCompanyId: companyId,
    responsibleUserId: null,
    assignee: {
      avatarUrl: assigneeInfo.avatarUrl,
      name: assigneeInfo.label,
    },
  };

  return company;
};

const getDefaultInfo = () => ({
  user: undefined,
  company: undefined,
  responsibleCompanyId: undefined,
  responsibleUserId: undefined,
  assignee: undefined,
});

export const getAssigneeInfo = (assigneeInfo) => {
  if (assigneeInfo.value.responsibleCompanyId !== undefined) {
    return getCompanyInfo(assigneeInfo);
  }

  if (assigneeInfo.value.responsibleUserId !== undefined) {
    return getUserInfo(assigneeInfo);
  }

  return getDefaultInfo();
};
