import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';

export const onWsRemoveProjectOverviewMilestone = thunk(
  async (_, milestone, { getStoreActions }) => {
    const actions = getStoreActions();
    const wsRemoveProjectOverviewMilestone =
      actions.project.overview.wsRemoveProjectOverviewMilestone;

    const timezone = store.auth.user().timezone;

    const isOverdue = isOverdueMilestone(milestone.dueAt, timezone);

    wsRemoveProjectOverviewMilestone({ milestone, isOverdue });
  }
);
