import { action } from 'easy-peasy';

export const resetCompanyProjects = action((state) => {
  state.sidebar.companiesProjects = {
    active: {
      list: [],
      map: {},
    },
    onHold: {
      list: [],
      map: {},
    },
    projects: {},
    $skip: 0,
    hasMoreData: false,
    paginationProjectsType: 'active',
    filters: state.sidebar.companiesProjects.filters,
    isFetching: false,
  };
});
