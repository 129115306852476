import { actionOn, debug } from 'easy-peasy';
import { selectProject } from './project/actions/selectProject';
import { removeSelectedProject } from './project/actions/removeSelectedProject';
import { setProjectsForSwitch } from './project/actions/setProjectsForSwitch';
import { setSelectedProjectFromPermissionsPage } from './project/actions/setSelectedProjectFromPermissionsPage';
import { setSelectedProjectAfterDelete } from './project/actions/setSelectedProjectAfterDelete';
import { setSearchUsersByTagPrivacy } from './project/actions/setSearchUsersByTagPrivacy';

import { loadProject } from './project/thunks/loadProject';
import { onMountProject } from './project/thunks/onMountProject';
import { onUnMountProject } from './project/thunks/onUnMountProject';
import { onSwitchProject } from './project/thunks/onSwitchProject';
import { onMountProjectSettings } from './project/thunks/onMountProjectSettings';

import overview from './overview';
import messages from './messages';
import todos from './todos';
import calendar from './calendar';
import settings from './settings';
import deletions from './deletions';
import search from './search';
import peopleAndPermissions from './peopleAndPermissions';

// TODO: refactor selected project into viewable mixin on project
// individual project thunks refactor into context store https://easy-peasy.now.sh/docs/api/create-context-store.html
// TODO: reduce complexity and refactor view based structuring and for api requests

const project = {
  selectedProject: null,
  projectsForSwitch: [],
  searchUsersByTag: {
    searchOnlyPrivate: false,
  },
  // actions
  selectProject,
  removeSelectedProject,
  setProjectsForSwitch,
  setSelectedProjectFromPermissionsPage,
  setSelectedProjectAfterDelete,
  setSearchUsersByTagPrivacy,
  // thunks
  loadProject,
  onMountProject,
  onUnMountProject,
  onSwitchProject,
  onMountProjectSettings,
  // tabs
  overview,
  messages,
  todos,
  calendar,
  settings,
  deletions,
  search,
  peopleAndPermissions,

  onTodoListCreated: actionOn(
    (actions) => actions.todos.todoLists.onCreateTodoList,
    (state, target) => {
      if (state.selectedProject === null) {
        return;
      }

      state.selectedProject = {
        ...state.selectedProject,
        totalTodolists: state.selectProject.totalTodolists + 1,
      };
    }
  ),
};

export default project;
