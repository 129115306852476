import classnames from 'classnames';
import { Svg } from '@webfx/core-web';

import CalendarIcon from '@webfx/marketingcloud-web/src/assets/svgs/reports/calendar-icon.svg';

const ReportCard = ({ reportCategory }) => {
  return (
    <div className="d-flex align-item-center col-md-4" style={{ opacity: 0.6 }}>
      <Svg className="ml-2" src={CalendarIcon} style={{ width: 70 }} />
      <div className="ml-3">
        <p className="bold m-0 text-gray-550 font-12 uppercase" data-fx-name="reportCategory">
          {reportCategory}
        </p>
        <span
          className={classnames('uppercase text-white bg-blue-vivid-300 rounded semibold p-1')}
          style={{ fontSize: 10 }}
          data-fx-name="comingSoon"
        >
          Coming Soon
        </span>
      </div>
    </div>
  );
};

export default ReportCard;
