import { api } from '@webfx/core-web';
import { isEmpty } from 'lodash';

function useGamePlanIsEnabled(companyId) {
  const companyIds = companyId ? [].concat(companyId) : [];
  const { data: companySettings, isFetching } = api.useQuery(
    [
      'company-settings',
      {
        query: {
          field: 'gameplan',
          companyId: { $in: companyIds },
        },
      },
    ],
    {
      enabled: !!companyIds && companyIds.length > 0,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  let gamePlanSetting = false;
  if (!isEmpty(companySettings)) {
    gamePlanSetting = companySettings?.data.filter((s) => Number(s.value) === 1);
  }

  return { isFetching, enabled: !isEmpty(gamePlanSetting) };
}

export default useGamePlanIsEnabled;
