import { useMemo } from 'react';
import classnames from 'classnames';

import { FusionChart } from '@webfx/core-web';
import { colors } from '../../constants/goals';

import styles from './GoalsChart.module.css';

const GoalsChartV1 = ({ goals }) => {
  const getOptimizations = (optimizations) => {
    const result = optimizations[0];
    return result ?? {};
  };
  const data = useMemo(() => {
    if (!goals) {
      return [];
    }

    return goals.map((goal, index) => ({
      label: `${goal.name} (${getOptimizations(goal?.optimizations)['assets']} FuelFX Assets, ${
        getOptimizations(goal.optimizations)['optimizations']
      } FuelFX Optimizations)`,
      asset: goal.assets,
      opt: goal.optimizations,
      value: getOptimizations(goal.optimizations)['total'],
      color: !goal.isClientRequest ? colors[index] : '#CED4DA',
    }));
  }, [goals]);

  const chartConfig = {
    chart: {
      plottooltext: `
        <div class='text-left'>
          <b>$label</b>
        </div>
      `,
      use3DLighting: 0,
      showPercentValues: 1,
      showLabels: 0,
      showValues: 1,
      decimals: 0,
      useDataPlotColorForLabels: 0,
      theme: 'fusion',
      legendNumColumns: 1,
      showLegend: 0,
      alignLegendWithCanvas: 1,
      legendItemFontColor: '#fff',
      legendCaptionFontSize: '14',
      doughnutRadius: '60%',
    },
  };

  return (
    <div className="d-flex row justify-content-between align-items-center">
      <div className="col-lg-3 col-12">
        <span className={styles.chartDesc}>
          Action Items Contributing to Digital Marketing Objectives
        </span>
      </div>
      <div className={classnames('col-lg-9 col-11 p-0', styles.chart)}>
        <FusionChart
          type="doughnut2d"
          width="100%"
          height="400"
          dataFormat="JSON"
          {...chartConfig}
          data={data}
        />
      </div>
    </div>
  );
};

export default GoalsChartV1;
