import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onDeleteTodoList = thunk(async (_, { push, todolistId }, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;
  const { onMountProjectTodoLists } = actions.project.todos.todoLists;

  const loader = 'project.todos.singleTodoList.deleteList';
  showLoader({ key: loader });
  try {
    await api.todoLists.remove(todolistId, { for: 'onDeleteTodoList' });
    onMountProjectTodoLists({ pushParams: { push } });
  } catch (e) {
    showError(e);
  }
  hideLoader({ key: loader });
});
