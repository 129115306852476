import { action } from 'easy-peasy';

import groupDate from '../helpers/groupDate';
import { formatingActivity } from '../../../../../utils-deprecated/formatingActivity';

export const setActivities = action(
  ({ activities }, { res, userTimezone, previousPage = false }) => {
    const formattedActivities = [];

    if (!res.data.length) {
      return formattedActivities;
    }

    res.data.forEach((currentActivity) => {
      const formattedActivity = formatingActivity(currentActivity);
      const labelName = groupDate(formattedActivity.actionDate, userTimezone);

      const lastItem = formattedActivities[formattedActivities.length - 1];

      if (lastItem && lastItem.dateLabel === labelName) {
        lastItem.activities.push(formattedActivity);
        return null;
      }

      formattedActivities.push({
        dateLabel: labelName,
        activities: [formattedActivity],
      });
    });

    activities.items = formattedActivities;
    previousPage
      ? (activities.skipCount -= res.data.length)
      : (activities.skipCount += res.data.length);
    activities.hasMoreData = res.total > activities.skipCount;
    activities.itemsOnPage = res.data.length;
    activities.total = res.total;
  }
);
