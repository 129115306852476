import { api } from '@webfx/core-web';

const useAllGoals = (companyIds) => {
  return api.useQuery(
    [
      'goals',
      {
        query: {
          companyId: { $in: companyIds },
          $limit: -1,
          $select: ['goalId', 'companyId'],
        },
      },
    ],
    {
      select: (data) => data.data ?? data ?? [],
      enabled: !!companyIds?.length,
    }
  );
};

export default useAllGoals;
