/* eslint-disable no-param-reassign */
import { action, debug } from 'easy-peasy';
import { setAccessLevel } from '../../../../../utils-deprecated/changePermissions';

export const giveAccess = action((state, payload) => {
  if (!Array.isArray(payload)) {
    for (const map of state.userMap[payload]) {
      if (!state.project.projectCompanies[map.company].users[map.user]?.projects) {
        state.project.projectCompanies[map.company].users[map.user].projects =
          state.project.projectCompanies[map.company].users[map.user]?.projectAccess || [];
      }

      if (!state.project.projectCompanies[map.company].users[map.user].projects[0]) {
        state.project.projectCompanies[map.company].users[map.user].projects[0] = {};
      }

      if (!Array.isArray(state.project.projectCompanies[map.company].users[map.user].projects)) {
        state.project.projectCompanies[map.company].users[map.user].projects = [
          {
            ...state.project.projectCompanies[map.company].users[map.user].projects,
          },
        ];
      }

      state.project.projectCompanies[map.company].users[map.user].projects[0].projectsUsers =
        payload.projectsAccess;
    }
    return;
  }

  payload.forEach((user) => {
    for (const map of state.userMap[user.userId]) {
      if (!state.project.projectCompanies[map.company].users[map.user]?.projects) {
        state.project.projectCompanies[map.company].users[map.user].projects =
          state.project.projectCompanies[map.company].users[map.user]?.projectAccess || [];
      }

      if (!state.project.projectCompanies[map.company].users[map.user]?.projects[0]) {
        state.project.projectCompanies[map.company].users[map.user].projects[0] = {};
      }

      if (!Array.isArray(state.project.projectCompanies[map.company].users[map.user].projects)) {
        state.project.projectCompanies[map.company].users[map.user].projects = [
          {
            ...state.project.projectCompanies[map.company].users[map.user].projects,
          },
        ];
      }

      state.project.projectCompanies[map.company].users[map.user].projects[0].projectsUsers =
        user.projectsAccess;
    }
  });
});
