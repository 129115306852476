import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onFindTodoListsForMoveDropdown = thunk(
  async (_, { projectId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;
    const setMoveTodoTodoLists = actions.project.todos.todoLists.setMoveTodoTodoLists;
    const loader = 'project.todos.todoLists.moveTodoForm.getTodoLists';
    showLoader({ key: loader });

    try {
      const res = await api.todoLists.find({
        for: 'onFindTodoListsForMoveDropdown',
        query: { projectId },
      });
      setMoveTodoTodoLists(res);
    } catch (error) {
      showError(error);
    }

    hideLoader({ key: loader });
  }
);
