import { action, debug } from 'easy-peasy';
import merge from 'lodash/merge';
import defaultPermissions from '../store';

export const setUserPermissions = action((state, { data, projectId }) => {
  state.fetched = true;
  state.data = merge(defaultPermissions.data, data);

  const permissionsProjectId = data.project?.projectId || null;
  state.projectId = permissionsProjectId || projectId;
});
