import { CARD_PROPERTIES } from '@webfx/gameplan-ui/src/constants/optimization-card';

export const getOptimizationType = ({ name, type }) => {
  const prefixToTypeMap = {
    'PPC >': 'ppc',
    'SEO >': 'seo',
    'Social >': 'social',
  };

  const matchedPrefix = Object.keys(prefixToTypeMap).find((prefix) => name.startsWith(prefix));
  const derivedType = matchedPrefix ? prefixToTypeMap[matchedPrefix] : type;

  return derivedType || 'otherServices';
};

export const getCardProperties = (type) => {
  return CARD_PROPERTIES[type] || CARD_PROPERTIES.otherServices;
};

export const getPickedBy = (projectOptimization) => {
  if (projectOptimization.smePick) {
    return 'sme';
  }
  if (projectOptimization.directorPick) {
    return 'director';
  }
  return '';
};

export const buildUrl = ({ siteId, type, objectId }) => {
  const urls = {
    asset: {
      route: 'cgfxContentPlanner',
      page: 'content-planner',
      siteId,
      assetId: objectId,
    },
    milestone: {
      route: 'milestone',
      milestoneId: `${objectId}`,
    },
    todo: {
      route: 'todo',
      todoId: `${objectId}`,
    },
  };

  return urls[type];
};
