import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { getRequestInfo } from './helpers/getTodoData';

export const onEditTodoPriority = thunk(
  async ({ editTodoPriority }, { values, todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      editTodoPriority({ todoId, priority: values.priority });
      await api.todos.patch(todoId, getRequestInfo(values), { for: 'onEditTodo' });
    } catch (e) {
      showError(e);
    }
  }
);
