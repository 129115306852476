import { action, computed } from 'easy-peasy';

import projectTemplatesThunks from './thunks';

const projectTemplates = {
  items: [],

  // computed
  selectOptions: computed((state) => {
    const options = state.items.map(({ projectTemplateId, name }) => ({
      label: name,
      value: projectTemplateId,
    }));
    options.push({ label: 'No Template', value: null });
    return options;
  }),

  // actions
  setProjectTemplates: action((state, payload) => {
    state.items = payload;
  }),

  // thunks
  ...projectTemplatesThunks,
};

export default projectTemplates;
