import { action } from 'easy-peasy';

import { getUserSubscribeId } from '../../../../helpers/getUserSubscribeId';
import { setSubscribersToPeople } from '../../helpers/setSubscribersToPeople';

export const createComment = action((state, { comment, currentUserId }) => {
  const { commentId, subscribers, users } = comment;

  state.comments.list.push(commentId);
  state.comments.map[commentId] = comment;
  state.comments.total += 1;

  setSubscribersToPeople(state.people, subscribers);

  state.sidebar = {
    subscribers,
    userSubscribeId: getUserSubscribeId(subscribers, currentUserId),
    commentators: users,
  };
});
