import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';

// TODO deprecate all sidebar data querying.
// Options should work as filters vs deleting and requerying data
export const onShowSidebarAllTodoLists = thunk(
  async ({ showSidebarAllTodoLists }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { active } = state.project.todos.todoLists.todoLists;
    const { filters } = state.project.todos.todoLists;

    const loaders = [
      'project.todos.todoLists.sidebar.showAll',
      'project.todos.todoLists.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });

    try {
      showSidebarAllTodoLists({});
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loaders });
  }
);
