import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import classnames from 'classnames';

import styles from './OverlayTooltip.module.css';

const OverlayTooltip = ({
  text,
  strategy = 'absolute',
  placement = 'top',
  clampLines = 1,
  className,
  children,
  inline = false,
}) => {
  const component = (
    <div className={classnames(styles.title, className)} style={{ WebkitLineClamp: clampLines }}>
      {children}
    </div>
  );

  const popper = {
    strategy,
  };

  return (
    <OverlayTrigger
      popperConfig={popper}
      placement={placement}
      overlay={<BootstrapTooltip id="tooltip-text">{text}</BootstrapTooltip>}
    >
      <div className={`${styles.title} ${inline ? styles.inline : ''}`}>{component}</div>
    </OverlayTrigger>
  );
};

export default OverlayTooltip;
