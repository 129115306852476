import { useMemo } from 'react';

import { Icon } from '@webfx/core-web';
import classnames from 'classnames';
// import useGoalOptimizations from '../../hooks/useGoalOptimizations';
import { colors } from '../../constants/goals';
import GoalProgressBar from '../GoalProgressBar/GoalProgressBar';

import styles from './GoalProgress.module.css';

const GoalProgress = ({ goal, index }) => {
  const { optimizations } = goal;
  const { total, completed } = optimizations[0] ?? {};

  const percentage = useMemo(() => {
    return total && total !== 0 ? (100 * completed) / total : 0;
  }, [total, completed]);

  return (
    <>
      <div className={classnames(styles.goalSection, 'd-flex flex-column border-bottom p-3')}>
        <div className="d-flex">
          <Icon
            className={classnames(styles.squareIcon, 'mr-1')}
            style={{ color: !goal.isClientRequest ? colors[index] : '#CED4DA' }}
          >
            square
          </Icon>

          <div className="d-flex flex-column w-100">
            <span className={classnames(styles.goalName, 'mb-2 mt-n1 ml-1')}>{goal.name}</span>
            {!goal.isClientRequest && (
              <div className="d-flex align-items-center pr-4">
                {optimizations.total === 0 ? (
                  <>
                    <Icon className="mr-1 text-blue-200">fiber_manual_record</Icon>
                    <span className={classnames(styles.progressBarLabel, 'flex-shrink-0 mr-2')}>
                      Planned
                    </span>
                  </>
                ) : (
                  <>
                    <Icon className="mr-1 text-purple-03">fiber_manual_record</Icon>
                    <span className={classnames(styles.progressBarLabel, 'flex-shrink-0 mr-2')}>
                      In Progress
                    </span>
                  </>
                )}
                <GoalProgressBar
                  total={total}
                  completed={completed}
                  percentage={percentage}
                  isLoading={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalProgress;
