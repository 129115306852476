import { useMemo } from 'react';
import classnames from 'classnames';

import { FusionChart } from '@webfx/core-web';
import { colors } from '../../constants/goals';

import Goal from './Goal';

import styles from './GoalsChart.module.css';

const GoalsChart = ({ goals }) => {
  const getOptimizations = (optimizations) => {
    const result = optimizations[0];
    return result ?? {};
  };

  const data = useMemo(() => {
    // If goals are not defined, return an empty array
    if (!goals) {
      return [];
    }

    // Calculate the total value of all goals
    const totalValue = goals.reduce((total, goal) => {
      const { total: goalValue = 0 } = getOptimizations(goal.optimizations);
      return total + goalValue;
    }, 0);

    // Map each goal to the desired structure
    return (
      goals
        .map((goal, index) => {
          const {
            total: goalTotal = 0,
            assets = 0,
            optimizations = 0,
            completed = 0,
          } = getOptimizations(goal.optimizations);

          // Calculate the percentage of the total value for this goal
          const percentage = (goalTotal / totalValue) * 100;

          // Return the transformed goal object
          return {
            name: goal.name,
            label: `${goal.name} (${assets} FuelFX Assets, ${optimizations} FuelFX Optimizations)`,
            asset: assets,
            opt: goal.optimizations,
            value: goalTotal,
            completed,
            percentage: `${Math.round(percentage)}%`,
            color: !goal.isClientRequest ? colors[index] : '#CED4DA',
          };
        })
        // Sort goals by their total value in descending order
        .sort((a, b) => b.value - a.value)
    );
  }, [goals]);

  const chartConfig = {
    chart: {
      plottooltext: `
        <div class='text-left'>
          <b>$label</b>
        </div>
      `,
      use3DLighting: 0,
      showPercentValues: 0,
      showLabels: 0,
      showValues: 0,
      decimals: 0,
      useDataPlotColorForLabels: 0,
      theme: 'fusion',
      legendNumColumns: 1,
      showLegend: 0,
      alignLegendWithCanvas: 1,
      legendItemFontColor: '#fff',
      legendCaptionFontSize: '14',
      doughnutRadius: '60%',
    },
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className={classnames('col-md-5', styles.chart)}>
        <FusionChart
          type="doughnut2d"
          width="100%"
          height="400"
          dataFormat="JSON"
          {...chartConfig}
          data={data}
        />
      </div>
      <div className="col-md-7">
        <div className={styles.gridContainer}>
          {data.map((goal) => (
            <Goal key={goal.name} goal={goal} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoalsChart;
