import { thunk } from 'easy-peasy';
import { toBool } from '../../../../../../utils-deprecated/toBool';

/* eslint-disable prefer-destructuring */
export const wsOnToggleTodoCompletedStatus = thunk(
  async (_, { result: todo }, { getStoreActions }) => {
    const actions = getStoreActions();
    const completeTodo = actions.project.todos.singleTodoList.completeTodo;
    const unCompleteTodo = actions.project.todos.singleTodoList.unCompleteTodo;

    const { todoId, completedAt } = todo;
    toBool(completedAt) ? completeTodo({ todoId, todo }) : unCompleteTodo({ todoId, todo });
  }
);
