import { thunk } from 'easy-peasy';
import api from '../../../../services/api';

export const onCreateProject = thunk(
  async (
    _,
    { values, createCompany, webFxCompanyId, setSubmitting, onClosePopup, resetForm },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { common } = actions;

    try {
      const res = await api.projects.create(
        {
          ...values,
          companyId: values.private === 'privateStatus' ? webFxCompanyId : values.companyId,
          companyName: createCompany ? values.companyName : '',
          private: false,
        },
        {
          companyName: createCompany ? values.companyName : '',
          giveAccess: values.giveAccess,
        }
      );

      // await AuthStore.set.refreshPermissions();
      // TODO: this needs to use query client to invalidate permission cache

      resetForm();
      onClosePopup();

      return res;
    } catch (e) {
      console.error(e);
      common.showError(e);
    }
    setSubmitting(false);
  }
);
