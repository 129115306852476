import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';
import { getResponsibleInfo } from '../../../../projectTemplate/todoLists/helpers/getResponsibleInfo';

export const onUpdateTeamworkFxTemplateTodo = thunk(
  async (
    { updateTeamworkFxTemplateTodo },
    { values, id, setSubmitting, onCloseForm, queryClient },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const payload = {
      ...values,
      ...getResponsibleInfo(values),
    };
    delete payload.assigneeId;
    try {
      const res = await api.todoListTemplateTodos.patch(id, payload);
      queryClient?.invalidateQueries('tw/todolist-templates');
      updateTeamworkFxTemplateTodo(res);
      onCloseForm && onCloseForm();
    } catch (e) {
      showError(e);
    }
    setSubmitting && setSubmitting(false);
  }
);
