import { action } from 'easy-peasy';

export const unsubscribeCurrentUser = action((state, { userSubscribeId }) => {
  const { sidebar } = state;

  sidebar.subscribers = sidebar.subscribers.filter(
    ({ subscriberId }) => subscriberId !== userSubscribeId
  );
  sidebar.userSubscribeId = null;
});
