import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { getActualTodosPositions } from '../helpers/getActualTodosPositions';

// TODO: Deprecated remove this file

/* eslint-disable prefer-destructuring */
export const onDragTodoToAnotherList = thunk(
  async (_, { targetId, sourceId, itemId, items }, { getStoreActions }) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const showLoader = a.common.showLoader;
    const hideLoader = a.common.hideLoader;
    const dragTodoToAnotherList = a.project.todos.todoLists.dragTodoToAnotherList;
    const updateTodosPositions = a.project.todos.todoLists.updateTodosPositions;
    const moveSidebarList = a.project.todos.todoLists.moveSidebarList;

    const loader = 'project.todos.dragTodos';
    showLoader({ key: loader });

    const reorderedTodoIds = items.map((todoId) => Number(todoId));
    const todoId = Number(itemId);

    try {
      dragTodoToAnotherList({ targetId, sourceId, todoId, reorderedTodoIds });

      const allTodos = await api.todos.find({
        query: {
          todolistId: { $in: [targetId] },
          $select: ['todoId', 'content', 'position'],
          $sort: { position: 1 },
          $limit: -1,
        },
      });

      const actualTodoPositions = getActualTodosPositions(
        allTodos,
        reorderedTodoIds,
        targetId,
        todoId
      );

      updateTodosPositions({ actualTodoPositions, targetId });

      const { sourceList } = await api.todos.patch(null, actualTodoPositions, {
        for: 'onDragTodoToAnotherList',
        $skipSafeMulti: true,
      });
      moveSidebarList({ todo: { todoList: sourceList }, toCompleted: true });

      hideLoader({ key: loader });
    } catch (e) {
      showError(e);
    }
  }
);
