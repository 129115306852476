import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';

export const completeTodo = action((state, { todo }) => {
  const { todolistId, todoId } = todo;
  const { todos } = state.todoLists.map[todolistId];

  if (todos.map[todoId]) {
    todos.active = todos.active.filter((id) => id !== todoId);
    todos.completed.unshift(todoId);
    merge(todos.map[todoId], todo);
  }

  moveTodoListToCompleted(state.todoLists, todo);
});
