// eslint-disable-next-line import/no-unresolved
import { store } from '@webfx/core-web';
import api from '../../../../services/api';
import fetchWithLoaderAndRedirect from '../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxSettings = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxSettings',
  pathname: store.getState().router.url('settings'),
  action: async (actions) => {
    const { set } = actions.categories;
    const res = await api.categories.find({ query: { projectId: null, $limit: -1 } });
    set(res);
  },
});
