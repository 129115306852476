import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onChangeSingleTemplateSearch = thunk(
  async (
    { setTemplateListFilteringParams, setTemplateListItemsToStore },
    { name, value, todolistId },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = [
      'teamworkFx.templates.todoLists.sidebar.byText',
      'teamworkFx.templates.todoLists.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      setTemplateListFilteringParams({ name, value });

      const stateAfterSettingSidebar = getStoreState();
      const { filters } = stateAfterSettingSidebar.teamworkFx.templates.todoLists.sidebar;

      const listsResponse = await api.todoListsTemplate.find({
        query: {
          todolistTemplateId: todolistId,
        },
        for: 'todoListTemplateTodos',
        search: filters.search || '',
      });

      setTemplateListItemsToStore(listsResponse);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
