import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onDeleteTemplateTodoList = thunk(
  async (
    { deleteTemplateList, onMountProjectTemplateTodoLists },
    { projectTemplateTodoListId, push, pathname },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'projectTemplate.todoLists.deleteProjectTemplateTodoList';
    showLoader({ key: loader });
    try {
      const { projectTemplateId } = await api.projectTemplateTodoLists.remove(
        projectTemplateTodoListId
      );

      if (
        pathname ===
        `/templates/projects/${projectTemplateId}/todo-lists/${projectTemplateTodoListId}`
      ) {
        onMountProjectTemplateTodoLists({ projectTemplateId, pushParams: { push } });
      }

      deleteTemplateList(projectTemplateTodoListId);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
