import { action } from 'easy-peasy';

export const dragTodoToAnotherList = action(
  (state, { targetId, sourceId, todoId, reorderedTodoIds }) => {
    const sourceTodos = state.todoLists.map[sourceId].todos;
    const targetTodos = state.todoLists.map[targetId].todos;
    const todo = { ...sourceTodos.map[todoId], todolistId: targetId };

    sourceTodos.active = sourceTodos.active.filter((id) => id !== todoId);
    delete sourceTodos.map[todoId];

    targetTodos.active = reorderedTodoIds;
    targetTodos.map[todoId] = todo;

    reorderedTodoIds.forEach((id, index) => {
      targetTodos.map[id].position = index;
    });
  }
);
