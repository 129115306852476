export const setSidebarForMultiples = (state, payload) => {
  state.sidebarLists.list = [];
  state.sidebarLists.map = {};

  payload.forEach((currentList) => {
    state.sidebarLists.list.push(currentList.projectTemplateTodoListId);
    state.sidebarLists.map[currentList.projectTemplateTodoListId] = {
      ...currentList,
      checked: true,
    };
  });
  state.sidebarLists.showAll = true;
};

export const setSidebarForSingle = (state, payload, currentTodoListId) => {
  state.sidebarLists.notActiveList = [];
  state.sidebarLists.list = [];
  state.sidebarLists.map = {};

  payload.forEach((currentList) => {
    if (currentList.projectTemplateTodoListId === currentTodoListId) {
      state.sidebarLists.list.push(currentList.projectTemplateTodoListId);
      state.sidebarLists.map[currentList.projectTemplateTodoListId] = {
        ...currentList,
        checked: true,
      };
    } else {
      state.sidebarLists.notActiveList.push(currentList.projectTemplateTodoListId);
      state.sidebarLists.map[currentList.projectTemplateTodoListId] = currentList;
    }
  });
  state.sidebarLists.showAll = true;
};
