import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const calendar = feathersClient.service('tw/calendar');

/**
 *
 * @param payload
 */
export const find = (payload) => calendar.find(paramsForServer(payload));
