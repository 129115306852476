import { onMountTeamworkFxDashboardPage } from './onMountTeamworkFxDashboardPage';
import { onUnMountTeamworkFxDashboardPage } from './onUnMountTeamworkFxDashboardPage';
import { onLoadMoreCompaniesProjects } from './onLoadMoreCompaniesProjects';
import { onMountCalendar } from './onMountCalendar';
import { onMountOverdueMilestones } from './onMountOverdueMilestones';

// dashboard
import { onFindCreateProjectCompanies, onFilterProjectsByName } from './dashboard';

// milestone
import { onGetMilestone } from './milestone/onGetMilestone';
import { onUpdateMilestone } from './milestone/onUpdateMilestone/onUpdateMilestone';
import { onDeleteMilestone } from './milestone/onDeleteMilestone';
import { onToggleCompleteMilestone } from './milestone/onToggleCompleteMilestone/onToggleCompleteMilestone';
import { onLoadMoreOverdueMilestones } from './milestone/onLoadMoreOverdueMilestones';
import { onLoadAllOverdueMilestones } from './milestone/onLoadAllOverDueMilestones';

// events
import { onDeleteEvent } from './event/onDeleteEvent';
import { onUpdateEvent } from './event/onUpdateEvent';

export default {
  onGetMilestone,
  onUpdateMilestone,
  onDeleteMilestone,
  onToggleCompleteMilestone,
  onLoadMoreOverdueMilestones,
  onDeleteEvent,
  onUpdateEvent,
  onMountTeamworkFxDashboardPage,
  onFindCreateProjectCompanies,
  onUnMountTeamworkFxDashboardPage,
  onLoadMoreCompaniesProjects,
  onMountCalendar,
  onMountOverdueMilestones,
  onFilterProjectsByName,
  onLoadAllOverdueMilestones,
};
