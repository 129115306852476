import sortBy from 'lodash/sortBy';
//import { toBool } from '../../../../../../utils/toBool';

export const moveSidebarListToCompleted = (todolist, sidebarLists, todo) => {
  const activeCount = todolist.todos?.active?.length || 0;
  const completedCount = todolist.todos?.completed?.length || 0;

  if (activeCount) {
    return sidebarLists;
  }
  if (!activeCount && !completedCount) {
    return sidebarLists;
  }

  const { active, completed } = sidebarLists;
  const { todolistId } = todo;
  const activeTodoList = active.find((activeList) => activeList.todolistId === todolistId);

  if (activeTodoList) {
    return {
      active: active.filter((activeList) => activeList.todolistId !== todolistId),
      completed: sortBy([activeTodoList, ...completed], ['name']),
    };
  }
  return sidebarLists;
};
