// eslint-disable-next-line import/no-unresolved
import { store } from '@webfx/core-web';
import api from '../../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';
import { filterTodoListsTemplate } from './filterTodoListsTemplate';

export const onMountTodoListsTemplate = fetchWithLoaderAndRedirect({
  loaderKey: 'TodoListsTemplate',
  pathname: store.getState().router.route('todosTemplate').replace('/:todolistId?', ''),
  action: async (actions, { todolistId }, getStoreState) => {
    const {
      setTemplateListItemsToStore,
      setTemplateListSidebarCategories,
      setTeamworkFxTodoCategories,
    } = actions.teamworkFx.templates.todoLists;

    const data = await api.categories.find({ query: { type: 'template', $limit: -1 } });
    setTeamworkFxTodoCategories(data);
    setTemplateListSidebarCategories(data);

    const stateAfterSettingSidebar = getStoreState();
    const { categories, filters } = stateAfterSettingSidebar.teamworkFx.templates.todoLists.sidebar;
    await filterTodoListsTemplate(
      { ...categories, ...filters },
      setTemplateListItemsToStore,
      todolistId
    );
  },
});
