import { backendStartPages } from '../../../../../ui/constants/project/settings';

const modifyStartPage = (pages) =>
  pages.map(({ projects, ...rest }) => {
    const formatedProjects = projects.map(({ startPage, ...restProjectParams }) => ({
      ...restProjectParams,
      startPage: backendStartPages[startPage],
    }));

    return {
      ...rest,
      projects: formatedProjects,
    };
  });

export default modifyStartPage;
