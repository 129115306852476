import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

import { getMilestonesOptions } from '../helpers/createTodoListDropdownOptions';
import { transformTodoList } from '../helpers/transformTodoList';
import { setTodosToList } from '../helpers/setTodosToList';
import { toBool } from '../../../../../../utils-deprecated/toBool';

export const mountProjectSingleTodoList = action(
  (state, { todoList, sidebarLists, todosBag, assignees, milestones }) => {
    state.todoList = setTodosToList({
      todoList: transformTodoList(todoList),
      todosBag,
    });
    state.assignees = assignees;
    state.todoListForm.milestones = getMilestonesOptions(milestones);

    state.sidebarLists.active = sidebarLists.filter(({ completed }) => !toBool(completed));
    state.sidebarLists.completed = sortBy(
      sidebarLists.filter(({ completed }) => toBool(completed)),
      ['name']
    );
  }
);
