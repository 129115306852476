import initStore from './initStore';

import { createMilestoneTemplate } from './actions/createMilestoneTemplate';
import { deleteMilestoneTemplate } from './actions/deleteMilestoneTemplate';
import { mountProjectMilestonesTemplate } from './actions/mountProjectMilestonesTemplate';
import { updateMilestoneTemplate } from './actions/updateMilestoneTemplate';

import { onCreateMilestoneTemplate } from './thunks/onCreateMilestoneTemplate';
import { onDeleteMilestoneTemplate } from './thunks/onDeleteMilestoneTemplate';
import { onMountProjectMilestonesTemplate } from './thunks/onMountProjectMilestonesTemplate';
import { onUpdateMilestoneTemplate } from './thunks/onUpdateMilestoneTemplate';

const milestonesList = {
  // store
  ...initStore,

  // actions
  createMilestoneTemplate,
  deleteMilestoneTemplate,
  mountProjectMilestonesTemplate,
  updateMilestoneTemplate,

  // thunks
  onCreateMilestoneTemplate,
  onDeleteMilestoneTemplate,
  onMountProjectMilestonesTemplate,
  onUpdateMilestoneTemplate,
};

export default milestonesList;
