import { thunk, useStoreActions } from 'easy-peasy';

const onWSRemovedProjectUser = thunk(
  (actions, projectUser, { getState, getStoreState, getStoreActions }) => {
    const { project, router } = getStoreState();
    const {
      teamworkFx: {
        dashboard: { onUnMountTeamworkFxDashboardPage, onMountTeamworkFxDashboardPage },
      },
      permissions: { onFindPermissions },
    } = getStoreActions();

    const projectId = projectUser.projectId || projectUser.projectsAccess.projectId;

    actions.removeProject(projectId);

    if (project?.selectedProject?.projectId === projectId) {
      onFindPermissions({ projectId });
    }

    if (router.active === 'dashboard') {
      onUnMountTeamworkFxDashboardPage();
      onMountTeamworkFxDashboardPage({});
    }
  }
);

export default onWSRemovedProjectUser;
