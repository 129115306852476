import { thunk } from 'easy-peasy';
import { filterTodos } from '../helpers/filterTodos';

/* eslint-disable prefer-destructuring */
export const wsOnCreateTodoListByTemplate = thunk(
  async (_, { result }, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const createTodoListByTemplate = actions.project.todos.todoLists.createTodoListByTemplate;

    const state = getStoreState();
    const { filters } = state.project.todos.todoLists;

    createTodoListByTemplate({
      todoList: {
        ...result,
        todos: filterTodos({ data: result.todos, filters }),
      },
    });
  }
);
