import classnames from 'classnames';
import { Button, Icon } from '@webfx/core-web';
import { useCompany, useRouter } from '@webfx/web-hooks';

import { useContributors } from '@webfx/web-hooks';
import { UserAvatar } from '@webfx/web-ui-connected/src/Contributors';

import styles from './ExpertTeam.module.css';

const ExpertTeam = ({ companyId }) => {
  const { url, params } = useRouter();
  const { data: company } = useCompany(companyId, { $join: 'sites' });
  const siteId = params?.siteId ?? company?.sites[0]?.siteId;

  const { data: contributors } = useContributors(companyId);

  if (!contributors || !contributors.teams) {
    return null;
  }

  // Extract up to 10 users from contributors teams
  const teamUsers = contributors.teams.flatMap((team) => team.users).slice(0, 10);

  return (
    <div className="d-flex flex-column my-3" data-fx-name="contributors">
      <div className="d-flex flex-column">
        <p className="bold font-20 text-gray-700 m-0" data-fx-name="label">
          Expert Team
        </p>
        <span className="font-13 text-gray-500" data-fx-name="label">
          Planning and completing deliverables
        </span>

        {teamUsers.length ? (
          <div className={classnames('my-3', styles.teamRow)}>
            {teamUsers.map((user) => (
              <UserAvatar key={user.user_id} user={user} variant="sm" showSMEIcon={false} />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center text-center">
              <Icon className="text-gray-500 mb-2 font-32">groups</Icon>Details to be populated soon
            </div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center">
        <Button
          className="font-13 semibold text-blue-vivid-300 mt-3 border-blue-vivid-200"
          style={{
            width: 200,
          }}
          to={url({ route: 'mcfxAnalyticsContributors', siteId })}
          text="View all contributors"
          icon="arrow_forward"
          iconPosition="right"
          iconTrailing
        />
      </div>
    </div>
  );
};

export default ExpertTeam;
