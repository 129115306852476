import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

export const loadProject = thunk(async (actions, projectId, { getStoreActions, getStoreState }) => {
  const storeActions = getStoreActions();
  const storeState = getStoreState();

  const { hideLoader } = storeActions.common;
  const { selectProject } = actions; // setProjectsForSwitch

  // hideLoader({ key: 'globalLoader.onMountTeamworkFx' });

  const project = await api.projects.get({ projectId, params: { for: 'projectCompanies' } });

  // api.channels.patch({ projectId, subscribe: true }, { for: 'subscribeToProject' });

  selectProject(project);
});
