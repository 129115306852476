import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import getOrderList from '../helpers/getOrderList';

export const onUpdateTemplateListOrder = thunk(
  async ({ setReorderedListIntoStore }, payload, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { sidebarLists } = state.projectTemplate.todoLists;

    const loader = 'projectTemplate.todoLists.changingTemplateTodoListsOrder';
    showLoader({ key: loader });

    const orderList = getOrderList({ sidebarLists, todoListsOrder: payload });
    try {
      const reorderedLists = await api.projectTemplateTodoLists.patch(null, orderList, {
        for: 'projectTemplateTodoListsReorder',
        $skipSafeMulti: true,
      });
      setReorderedListIntoStore({ checkedList: payload, reorderedLists });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
