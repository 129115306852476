import get from 'lodash/get';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../ui/constants/limits';
import { overviewActivityItemsPerPage } from '../../../../../ui/constants/project/overview';
import {
  getFromTo,
  getOverdueDate,
  getSelectedMonthDate,
} from '../../../../../utils-deprecated/calendar';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectOverview = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectOverview',
  action: async (actions, { projectId }, getStoreState) => {
    const state = getStoreState();
    const { userId, timezone: userTimezone } = store.auth.user();
    const { mountProjectOverview } = actions.project.overview;
    const { setAssignee, setPrivateAssignee } = actions.projects;

    const currDate = getSelectedMonthDate(null, userTimezone);
    const { from, to } = getFromTo(currDate, true, 2);
    const overdueDate = getOverdueDate(userTimezone);

    const project = state.tw.projects.item;
    const _projectId = projectId || state.router.params.projectId || project?.projectId;

    api.activities
      .find({
        query: { projectId: _projectId, $skip: 0, $limit: overviewActivityItemsPerPage },
        for: 'additionalInfo',
      })
      .then((activities) => mountProjectOverview({ activities }));

    api.subscribers
      .find({ query: { projectId: _projectId, userId } })
      .then((subscribers) =>
        mountProjectOverview({ subscriberId: get(subscribers, [0, 'subscriberId'], null) })
      );

    api.calendar.find({ query: { projectId: _projectId, from, to } }).then((calendarData) => {
      const { milestones, events } = calendarData;
      mountProjectOverview({
        milestones,
        events,
        calendarDates: { from, to },
      });
    });

    api.milestones
      .find({
        query: {
          projectId: _projectId,
          dueAt: { $lte: overdueDate },
          $client: { for: 'overdueCalendar' },
          $sort: { dueAt: -1 },
          $limit: overdueMilestonesLimit,
        },
      })
      .then((overdueMilestones) => mountProjectOverview({ overdueMilestones }));

    api.projects
      .get({ projectId: _projectId, params: { for: 'assigneesDropdown' } })
      .then(({ assignees }) => setAssignee(assignees));

    api.projects
      .get({
        projectId: _projectId,
        params: { for: 'assigneesDropdown', private: true },
      })
      .then(({ assignees }) => setPrivateAssignee(assignees));

    return `/projects/${_projectId}`;
  },
});
