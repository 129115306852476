import { action } from 'easy-peasy';
import filesStore from '../store';

export const clearFilesStateWithoutUsers = action((state) => {
  Object.keys(filesStore).forEach((key) => {
    if (key === 'sidebarUsers') {
      state.sidebarUsers.listId = [];
      state.sidebarUsers.userWasDeleted = false;
    }
    if (key !== 'sidebarUsers' && key !== 'sortingParam') {
      state[key] = filesStore[key];
    }
  });
});
