import { api } from '@webfx/core-web';
import { useNotifications, useStartPage } from '@webfx/web-hooks';
import { useHistory } from 'react-router-dom';
export default function useTodolist(todolistId, reactQueryParams = {}) {
  const { toast } = useNotifications();
  const startPage = useStartPage();
  const history = useHistory();

  return api.useQuery([`tw/todolists/${todolistId}`], {
    enabled: !!todolistId,
    staleTime: 1000 * 60 * 10,

    onError: (error) => {
      if (error.code === 405) {
        toast.error('You do not have access to that project');
        history.push(startPage);
      }
    },
    ...reactQueryParams,
  });
}
