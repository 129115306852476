import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onDeleteEvent = thunk(async (_, { eventId, closeModal }, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showLoader, hideLoader, showError } = actions.common;

  const loader = 'project.overview.calendar.deleteEvent';
  showLoader({ key: loader });
  try {
    await api.events.remove(eventId);
    closeModal();
  } catch (e) {
    showError(e);
  }
  hideLoader({ key: loader });
});
