import { updateTodo } from './actions/updateTodo';
import { completeTodo } from './actions/completeTodo';
import { setFilteringParams } from './actions/setFilteringParams';

import { onUpdateTodo } from './thunks/onUpdateTodo';
import { onCompleteTodo } from './thunks/onCompleteTodo';

const todos = {
  companies: {
    list: [],
    map: {},
  },
  projects: {},
  todolists: {},
  todoItems: {},
  filters: {
    params: { assigneeId: null, dueDate: 'anytime', priority: 'any' },
    assignees: [],
  },

  scrollParams: {
    skipCount: 0,
    hasMoreData: true,
  },

  // actions
  updateTodo,
  completeTodo,
  setFilteringParams,

  // thunks
  onUpdateTodo,
  onCompleteTodo,
};

export default todos;
