import { isEmpty } from 'lodash';

import { Spinner, Svg } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';

import { getLastDayOfTheMonthByDate } from '@webfx/web-ui/src/QuarterSelector/utils';

import addOneTimeClientRequestGoals from '../../utils/addOneTimeClientRequestGoals.js';
import useGoals from '../../hooks/useGoals';
import useProjectsOptimizations from '../../hooks/useProjectsOptimizations';
import useGamePlanIsEnabled from '../../hooks/useGamePlanIsEnabled';

import Box from '../../assets/images/box.svg';

import GoalsChart from '../GoalsChart';
import GoalsChartV1 from '../GoalsChartV1';
import GoalsHeader from '../GoalsHeader';
import ActionsNeeded from '../ActionsNeeded';

const Goals = ({ companyId, quarter, hideGoals = false }) => {
  const flags = useFlags();
  const months = quarter?.monthRange;
  const lastMonthOfTheQuarterDay = getLastDayOfTheMonthByDate(months[1]);

  const { data: goals, isLoading } = useGoals({
    query: {
      companyId,
      date: { $gte: months[0], $lte: months[1] },
      $join: ['optimizations'],
      $limit: -1,
    },
  });

  const { enabled: isGamePlanEnabled } = useGamePlanIsEnabled(companyId);

  const { data, isFetching } = useProjectsOptimizations({
    query: {
      dueDate: { $gte: months[0], $lte: lastMonthOfTheQuarterDay },
      companyId,
    },
  });

  addOneTimeClientRequestGoals(goals, data, months);

  if (isFetching) {
    return <Spinner className="my-5" />;
  }

  return (
    <>
      {!hideGoals && isGamePlanEnabled && (
        <>
          {/* Sub Header */}
          {!isLoading && isEmpty(goals) ? (
            <div className="d-flex flex-column align-items-center justify-content-center p-5">
              <Svg svgStyle={{ width: '130px' }} src={Box} />

              <p className="pt-3">No objectives configured in this quarter</p>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <div className="col-md-9">
                {flags.gameplanfxUpdates && flags.gameplanfxUpdatesPhase2 ? (
                  <>
                    <GoalsHeader companyId={companyId} />
                    <GoalsChart goals={goals} />
                  </>
                ) : (
                  <GoalsChartV1 goals={goals} />
                )}
              </div>
              <div className="col-md-3 border-left bg-orange-01">
                <ActionsNeeded months={months} companyId={companyId} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Goals;
