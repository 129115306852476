import { action } from 'easy-peasy';

export const loadAllComments = action((state, { comments }) => {
  state.comments = {
    total: comments.length,
    list: comments.map(({ commentId }) => commentId),
    map: comments.reduce((acc, comment) => ({ ...acc, [comment.commentId]: comment }), {}),
    firstCommentId: null,
  };
});
