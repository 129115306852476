import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

const activities = feathersClient.service('tw/activities');

/**
 *
 * @param payload
 */
export const find = (payload) => activities.find(paramsForServer(payload));
