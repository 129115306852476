import { action } from 'easy-peasy';
import { getSelectedProjects } from '../helpers/getSelectedProjects';

export const mountTeamworkFxEditUserPage = action((state, { selectedUser, companies }) => {
  state.selectedUser = selectedUser;
  state.companies = companies;
  state.sidebar.companies.list = [];
  state.sidebar.companies.map = {};

  const allCheckedProjectId = selectedUser.projects.map(({ projectId }) => projectId);

  selectedUser.companies.forEach((company) => {
    state.sidebar.companies.list.push(company.companyId);
    state.sidebar.companies.map[company.companyId] = {
      ...company,
      projects: {
        list: [],
        map: {},
      },
      checked: false,
    };

    company.projects.forEach((project) => {
      state.sidebar.companies.map[company.companyId].projects.list.push(project.projectId);
      state.sidebar.companies.map[company.companyId].projects.map[project.projectId] = {
        ...project,
        checked: !!allCheckedProjectId.includes(project.projectId),
      };
    });
  });

  state.sidebar.companies.list.forEach((companyId) => {
    const company = state.sidebar.companies.map[companyId];
    const selectedProjects = getSelectedProjects(company);
    if (selectedProjects.length === company.projects.list.length) {
      company.checked = true;
    }
  });
});
