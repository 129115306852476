import { thunk } from 'easy-peasy';

/* eslint-disable prefer-destructuring */
export const wsOnDeleteTodoList = thunk(
  async (_, { push, result }, { getStoreActions, getState }) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const onMountProjectTodoLists = a.project.todos.todoLists.onMountProjectTodoLists;
    const wsDeleteTodoList = a.project.todos.singleTodoList.wsDeleteTodoList;

    const localState = getState();
    const { todolistId } = localState.todoList;

    if (result.todolistId !== todolistId) {
      return wsDeleteTodoList({ result });
    }

    try {
      onMountProjectTodoLists({ pushParams: { push } });
    } catch (e) {
      showError(e);
    }
  }
);
