import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onDeleteTemplateTodo = thunk(
  async ({ deleteTemplateTodo }, { projectTemplateId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'projectTemplate.todoLists.todo.deleteTodo';
    showLoader({ key: loader });
    try {
      const data = await api.projectTemplateTodos.remove(projectTemplateId);
      deleteTemplateTodo(data);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
