import { store } from '@webfx/web-state';
import api from '../../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../../ui/constants/limits';
import { getOverdueDate, isOverdueMilestone } from '../../../../../../utils-deprecated/calendar';

const getMilestoneIndexInStore = (milestonesList, milestoneId) => {
  return milestonesList.findIndex(({ milestoneId: id }) => id === milestoneId);
};

export const updateMilestone = async ({ actions, state, form: { id, values } }) => {
  const addMilestone = actions.project.overview.addMilestone;
  const _updateMilestone = actions.project.overview.updateMilestone;
  const updateMilestones = actions.project.overview.updateMilestones;
  const updateOverdueMilestone = actions.project.overview.updateOverdueMilestone;
  const removeOverdueMilestone = actions.project.overview.removeOverdueMilestone;
  const decreaseOverdueCount = actions.project.overview.decreaseOverdueCount;
  const setOverdueMilestones = actions.project.overview.setOverdueMilestones;

  const timezone = store.auth.user().timezone;

  const milestonesBeforeUpdate = [
    ...state.project.overview.milestones.items,
    ...state.project.overview.milestones.overdue,
  ];
  const milestoneBeforeUpdate = milestonesBeforeUpdate.find(
    ({ milestoneId }) => milestoneId === id
  );
  const milestone = await api.milestones.update(id, values, { for: 'milestoneComments' });
  const milestoneIndex = getMilestoneIndexInStore(milestonesBeforeUpdate, milestone.milestoneId);

  if (Array.isArray(milestone)) {
    const overdueDate = getOverdueDate(timezone);

    const overdueMilestones = await api.milestones.find({
      query: {
        projectId: milestone[0].projectId,
        dueAt: { $lte: overdueDate },
        $client: { for: 'overdueCalendar' },
        $sort: { dueAt: -1 },
        $limit: overdueMilestonesLimit,
      },
    });

    updateMilestones({ timezone, milestones: milestone });
    setOverdueMilestones({
      milestones: overdueMilestones.data,
      hasMoreData: overdueMilestones.hasMore,
      overdueCount: overdueMilestones.count,
    });
  } else {
    if (milestoneIndex === -1) {
      // milestone not exists in store, try to add it
      addMilestone({ milestone, timezone });
    } else {
      _updateMilestone(milestone);
    }

    if (isOverdueMilestone(milestone.dueAt, timezone)) {
      updateOverdueMilestone(milestone);
    } else {
      if (isOverdueMilestone(milestoneBeforeUpdate?.dueAt, timezone)) {
        decreaseOverdueCount();
      }
      removeOverdueMilestone(milestone);
    }
  }
};
