export const colors = [
  '#F87476',
  '#41D58C',
  '#6ADFD7',
  '#4C74B9',
  '#FFA232',
  '#00316A',
  '#AF3FAC',
  '#FFDF6F',
  '#80BB41',
  '#84B9F5',
  '#B6E684',
  '#D0760A',
];
