import { action } from 'easy-peasy';

export const setReorderedListIntoStore = action((state, { checkedList, reorderedLists }) => {
  state.sidebarLists.list = [];
  reorderedLists.forEach(({ projectTemplateTodoListId, position }) => {
    state.sidebarLists.list.push(projectTemplateTodoListId);
    state.sidebarLists.map[projectTemplateTodoListId].position = position;
  });

  state.lists.list = [];
  state.lists.map = {};
  state.todoItems = {};
  checkedList.forEach((id) => {
    const _id = Number(id);

    const currentList = reorderedLists.find(
      ({ projectTemplateTodoListId }) => projectTemplateTodoListId === _id
    );

    const idsOfTodos = currentList.projectTemplateTodos.map((item) => {
      const { projectTemplateTodoId } = item;

      state.todoItems[projectTemplateTodoId] = item;
      return projectTemplateTodoId;
    });

    state.lists.list.push(currentList.projectTemplateTodoListId);
    state.lists.map[currentList.projectTemplateTodoListId] = {
      ...currentList,
      projectTemplateTodos: idsOfTodos,
    };
  });
});
