import { action } from 'easy-peasy';

export const updateTemplateTodoList = action((state, item) => {
  const { projectTemplateTodoListId } = item;

  state.lists.map[projectTemplateTodoListId] = {
    ...state.lists.map[projectTemplateTodoListId],
    ...item,
  };
  state.sidebarLists.map[projectTemplateTodoListId] = {
    ...state.sidebarLists.map[projectTemplateTodoListId],
    ...item,
  };
});
