import { store } from '@webfx/web-state';
import api from '../../../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../../../ui/constants/limits';
import { getOverdueDate, isOverdueMilestone } from '../../../../../../../utils-deprecated/calendar';

export const moveMilestone = async ({ actions, id, values }) => {
  const setTeamworkFxDashboardMilestone =
    actions.teamworkFx.dashboard.setTeamworkFxDashboardMilestone;
  const setOverdueMilestones = actions.teamworkFx.dashboard.setOverdueMilestones;

  const timezone = store.auth.user().timezone;

  const milestoneChanged = await api.milestones.update(id, values, { for: 'moveToAnotherProject' });

  setTeamworkFxDashboardMilestone(milestoneChanged);

  const isMilestoneOverdue = isOverdueMilestone(milestoneChanged.dueAt, timezone);

  if (!isMilestoneOverdue) {
    return;
  }

  const overdueDate = getOverdueDate(timezone);
  const query = {
    dueAt: { $lte: overdueDate },
    $client: { for: 'overdueCalendar' },
    $sort: { dueAt: -1 },
    $limit: overdueMilestonesLimit,
  };

  const overdueMilestones = await api.milestones.find({ query });
  setOverdueMilestones({
    milestones: overdueMilestones.data,
    hasMoreData: overdueMilestones.hasMore,
    overdueCount: overdueMilestones.count,
  });
};
