// eslint-disable-next-line import/no-unresolved
import { store } from '@webfx/core-web';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxSearchPage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxSearchPage',
  pathname: store.getState().router.route('search'),
  // eslint-disable-next-line no-empty-function
  action: () => {},
});
