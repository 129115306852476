import { action } from 'easy-peasy';

import { getUserSubscribeId } from '../../../../helpers/getUserSubscribeId';
import { setSubscribersToPeople } from '../../helpers/setSubscribersToPeople';

export const editComment = action((state, { comment, currentUserId }) => {
  const { commentId, subscribers } = comment;

  state.comments.map[commentId] = comment;

  setSubscribersToPeople(state.people, subscribers);
  state.sidebar.subscribers = subscribers;
  state.sidebar.userSubscribeId = getUserSubscribeId(subscribers, currentUserId);
});
