import { store } from '@webfx/web-state';
import api from '../../../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../../../ui/constants/limits';
import { getOverdueDate } from '../../../../../../../utils-deprecated/calendar';

export const uncompleteOverdueMilestone = async ({ actions, state, milestone }) => {
  const timezone = store.auth.user().timezone;
  const hasMoreOverdueMilestones = state.teamworkFx.dashboard.overdueMilestones.hasMoreData;

  const _uncompleteOverdueMilestone = actions.teamworkFx.dashboard.uncompleteOverdueMilestone;
  const setOverdueMilestones = actions.teamworkFx.dashboard.setOverdueMilestones;

  // all overdue milestones are fetched
  if (!hasMoreOverdueMilestones) {
    _uncompleteOverdueMilestone(milestone);
    return;
  }

  const overdueDate = getOverdueDate(timezone);
  const query = {
    dueAt: { $lte: overdueDate },
    $client: { for: 'overdueCalendar' },
    $sort: { dueAt: -1 },
    $limit: overdueMilestonesLimit,
  };
  const overdueMilestones = await api.milestones.find({ query });

  setOverdueMilestones({
    milestones: overdueMilestones.data,
    hasMoreData: overdueMilestones.hasMore,
    overdueCount: overdueMilestones.count,
  });
};
