import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const wsOnDragTodoToAnotherList = thunk(
  async (_, { result }, { getStoreActions, getState }) => {
    const actions = getStoreActions();
    const state = getState();

    const showReloadPageMessage = actions.common.showReloadPageMessage;
    const onMountProjectSingleTodoList =
      actions.project.todos.singleTodoList.onMountProjectSingleTodoList;

    const { data: project } = useProject();
    const { projectId } = project || { projectId: null };

    let todolistId;
    if (result.sourceList.todolistId === state.todoList.todolistId) {
      todolistId = result.sourceList.todolistId;
    } else if (result.targetList.todolistId === state.todoList.todolistId) {
      todolistId = result.targetList.todolistId;
    }

    if (todolistId && projectId) {
      showReloadPageMessage({
        reloadHandler: () => {
          onMountProjectSingleTodoList({
            projectId,
            todolistId,
          });
        },
      });
    }

    // const actions = getStoreActions();
    // const showError = actions.common.showError;
    // const wsDragTodoToAnotherList = actions.project.todos.singleTodoList.wsDragTodoToAnotherList;
    // const { todoId } = result;
    //
    // try {
    //   const todo = await api.todos.get(todoId, { for: 'todoItemComments' });
    //   wsDragTodoToAnotherList({ result, todo });
    // } catch (e) {
    //   showError(e);
    // }
  }
);
