import { action } from 'easy-peasy';
import modifyStartPage from '../helpers/modifyStartPage';
import getValuesForStoreByStatus from '../helpers/getValuesForStoreByStatus';

export const setCompaniesProjects = action((state, { companiesProjects, type }) => {
  const { data, hasMore } = companiesProjects;
  const projectsType = type === 'on hold' ? 'onHold' : type;

  const modifiedStartPage = modifyStartPage(data);
  const storeValue = getValuesForStoreByStatus(modifiedStartPage, type);

  state[projectsType].list = [...state[projectsType].list, ...storeValue.list];
  state[projectsType].map = {
    ...state[projectsType].map,
    ...storeValue.map,
  };

  state.projects = {
    ...state.projects,
    ...storeValue.projects,
  };

  if (projectsType === 'archived') {
    state.$skip += data.length;
    state.hasMoreData = hasMore;
    return;
  }

  if (state.paginationProjectsType === 'active' && !hasMore) {
    state.paginationProjectsType = 'on hold';
    state.$skipSidebarProjects = 0;
    state.hasSidebarMoreData = true;
    return;
  }

  state.hasSidebarMoreData = hasMore;
  state.$skipSidebarProjects += data.length;
});
