import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { getRequestInfo } from '../helpers/getTodoData';

/* eslint-disable prefer-destructuring */
export const onEditTodoAssignee = thunk(
  async (_, { values, todoId, todolistId }, { getStoreActions }) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const editTodoAssignee = a.project.todos.todoLists.editTodoAssignee;

    try {
      editTodoAssignee({ todoId, todolistId, assignee: values.assignee });
      await api.todos.patch(todoId, getRequestInfo(values), { for: 'onEditTodo' });
    } catch (e) {
      showError(e);
    }
  }
);
