import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const pingTodoCreator = feathersClient.service('tw/ping-todo-creator');

/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => pingTodoCreator.create(payload, paramsForServer(params));
