import { thunkOn } from 'easy-peasy';
import { selectProjectTemplate } from './projectTemplate/actions/selectProjectTemplate';
import { clearProjectTemplateState } from './projectTemplate/actions/clearProjectTemplateState';
import { setTemplateAssignee } from './projectTemplate/actions/setTemplateAssignee';

import { onMountProjectTemplate } from './projectTemplate/thunks/onMountProjectTemplate';
import { onUnMountProjectTemplate } from './projectTemplate/thunks/onUnMountProjectTemplate';

import todoLists from './todoLists';
import milestonesList from './milestonesList';

const projectTemplate = {
  selectedProjectTemplate: null,
  assignee: [],
  // actions
  selectProjectTemplate,
  clearProjectTemplateState,
  setTemplateAssignee,
  // thunks
  onMountProjectTemplate,
  onUnMountProjectTemplate,
  // tabs
  todoLists,
  milestonesList,
  updateSelectedProjectTemplate: thunkOn(
    (actions) => [
      actions.todoLists.addProjectTemplateList,
      actions.todoLists.addTemplateMultipleTodo,
      actions.todoLists.addTemplateTodo,
      actions.todoLists.deleteTemplateList,
      actions.todoLists.deleteTemplateTodo,
      actions.todoLists.updateTemplateTodo,
      actions.todoLists.updateTemplateTodoAssignee,
      actions.todoLists.updateTemplateTodoList,
      actions.milestonesList.createMilestoneTemplate,
      actions.milestonesList.deleteMilestoneTemplate,
      actions.milestonesList.updateMilestoneTemplate,
    ],
    (actions, target) => {
      const { projectTemplate } = Array.isArray(target.payload)
        ? target.payload[0]
        : target.payload;
      if (projectTemplate) {
        actions.selectProjectTemplate(projectTemplate);
      }
    }
  ),
};

export default projectTemplate;
