import { toBool } from '../../../../../../utils-deprecated/toBool';

export const transformTodoListFields = ({ completed, private: _private, ...todoList }) => ({
  ...todoList,
  _private: toBool(_private),
  completed: toBool(completed),
});

export const transformTodoList = ({ todoList, checked = false }) => ({
  ...transformTodoListFields(todoList),
  checked,
  isColorCoding: false,
  movedTo: null,
  todos: {
    active: [],
    completed: [],
    map: {},
    totalCompleted: 0,
  },
});
