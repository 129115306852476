import filesActions from './actions';
import filesThunks from './thunks';
import filesStore from './store';

const files = {
  // store
  ...filesStore,

  // actions
  ...filesActions,

  // thunks
  ...filesThunks,
};

export default files;
