import { action } from 'easy-peasy';

import { moveSidebarListToCompleted } from '../helpers/moveSidebarListToCompleted';

export const deleteActiveTodo = action((state, { todo, todo: { todoId } }) => {
  const { todos } = state.todoList;

  if (todos.map[todoId]) {
    todos.active = todos.active.filter((id) => id !== todoId);
    delete todos.map[todoId];
  }

  // TODO: FIX ME
  state.sidebarLists = moveSidebarListToCompleted(state.todoList, state.sidebarLists, todo);
});
