import { Link } from '@webfx/core-web';
import { useRouter, useAuth } from '@webfx/web-hooks';

const ActionItemsList = ({ actionItems, siteId }) => {
  const { isGuest } = useAuth();

  const { url } = useRouter();

  const { contentPendingApprovals, newReportsCount, integrationFxConfigured } = actionItems;

  // Transform action items for display data
  const actionItemsData = [
    {
      label: `Review and Approve Overdue Content`,
      subText: `${contentPendingApprovals.over30Days} items awaiting approval for over 30 days`,
      url: url({ route: 'cgfxKanban', siteId }),
      isVisible: contentPendingApprovals.over30Days > 0,
    },
    {
      label: `Review and Approve Content`,
      subText: `${contentPendingApprovals.totalCount} items awaiting approval`,
      url: url({ route: 'cgfxKanban', siteId }),
      isVisible: contentPendingApprovals.totalCount > 0,
    },
    {
      label: `Review Reports`,
      subText: `${newReportsCount} new reports available in AnalyticsFX`,
      url: url({
        route: 'mcfxAnalyticsReportsCategories',
        siteId,
        categoryId: 'monthly-reports',
      }),
      isVisible: newReportsCount > 0,
    },
    {
      label: `Set Up IntegrationsFX`,
      subText: 'Enable access to closed loop reporting',
      url: url({
        route: 'mcfxAnalyticsReports',
        siteId,
        reportId: 'closed-revenue',
      }),
      isVisible: integrationFxConfigured,
    },
  ];

  return (
    <div className="d-flex flex-column p-2">
      {actionItemsData.map(
        (item, index) =>
          item.isVisible && (
            <div key={index} className="d-flex flex-column p-2 border-bottom">
              {!isGuest ? (
                <Link to={item.url} plain={true}>
                  <span className="bold font-16">{item.label}</span>
                </Link>
              ) : (
                <span className="bold font-16">{item.label}</span>
              )}
              <span>{item.subText}</span>
            </div>
          )
      )}
    </div>
  );
};

export default ActionItemsList;
