import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onCreateTodoList = thunk(
  async (_, { values, setSubmitting, onClosePopup }, { getStoreActions }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const createTodoList = actions.project.todos.todoLists.createTodoList;

    try {
      let todoList = await api.todoLists.create(values, { for: 'onCreateTodoList' });

      if (Array.isArray(todoList)) {
        todoList = todoList[0];
      }
      createTodoList({ todoList });
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
