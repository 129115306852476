import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { getReorderedTodos } from './helpers/getReorderedTodos';

export const onReorderTodo = thunk(
  async ({ reorderTodo }, payload, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const { source, destination, draggableId } = payload;

    const draggedTodoId = Number(draggableId.replace('todo-', ''));
    const destinationTodolistId = Number(destination.droppableId.replace('todolist-', ''));

    const state = getStoreState();
    const { todos } = state.project.todos.singleTodoList.todoList;
    const position = todos.map[todos.active[destination.index]].position;
    try {
      reorderTodo({
        todoId: draggedTodoId,
        position,
      });

      api.todos.patch(draggedTodoId, {
        position,
        todolistId: destinationTodolistId,
      });
    } catch (e) {
      showError(e);
    }
  }
);
