import { Svg, Spinner } from '@webfx/core-web';

import Calendar from '@webfx/teamwork-web/src/assets/images/calendar.svg';
import { getMonth } from '@webfx/web-ui/src/QuarterSelector/utils';

const CalendarColumnHeaderV1 = ({ month, isFetching = false, year }) => {
  const monthInWords = getMonth(month, 'short');
  return (
    <div className="d-flex border-bottom mb-3 pl-2">
      <div className="d-flex align-items-center">
        <Svg
          svgClassName="pt-sm-2 font-24 pb-2"
          svgStyle={{ width: '19px' }}
          component={'span'}
          src={Calendar}
        />
        <div className="d-flex align-items-center ml-2">
          <span className="font-weight-bolder">{monthInWords && monthInWords.toUpperCase()}</span>
          <span className="font-12 ml-1 text-gray-550">{`${year}`.substring(2)}</span>
        </div>
      </div>
      <div className="ml-2">{isFetching ? <Spinner /> : null}</div>
    </div>
  );
};

export default CalendarColumnHeaderV1;
