export const getPastebinTemplatesOptions = (templates) =>
  Object.keys(templates).reduce(
    (acc, key) => {
      const options = templates[key].map(({ id, title, group }) => ({
        value: id,
        label: title,
        template: key,
        group,
      }));
      acc.push({ label: key, options });
      return acc;
    },
    [
      {
        label: '',
        options: [
          {
            value: '',
            label: 'No Template - Select to Clear',
            group: 'interactive',
          },
        ],
      },
    ]
  );
