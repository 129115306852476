import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onDeleteTodoList = thunk(async (_, { todolistId }, { getStoreActions }) => {
  const actions = getStoreActions();
  const showError = actions.common.showError;
  const deleteTodoList = actions.project.todos.todoLists.deleteTodoList;

  try {
    deleteTodoList({ todolistId });
    await api.todoLists.remove(todolistId, { for: 'onDeleteTodoList' });
  } catch (e) {
    showError(e);
  }
});
