import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

const getTodosList = (parentId, items) =>
  items.map((projectTemplateTodoId, index) => ({
    projectTemplateTodoId,
    projectTemplateTodoListId: parentId,
    position: index,
  }));

export const onUpdateTemplateTodoDragAndDrop = thunk(
  async (
    { dragAndDropTemplateTodo },
    { targetId, sourceId, itemId, items },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const todosList = getTodosList(targetId, items);
    try {
      dragAndDropTemplateTodo({ targetId, sourceId, itemId, items });
      await api.projectTemplateTodos.patch(null, todosList, {
        for: 'reorder',
        $skipSafeMulti: true,
      });
    } catch (e) {
      console.error(e);
      showError(e);
    }
  }
);
