import { action } from 'easy-peasy';

export const wsCreateTodoView = action((state, { todoId }) => {
  if (!todoId) {
    return;
  }
  const todo = state.todoList.todos.map[todoId];

  if (todo) {
    todo.hasNewComments = false;
  }
});
