const getSelectedCategoriesWithInsert = ({ selected, categoryId, checked }) => {
  let selectedCategory = [...selected];

  const index = selected.indexOf(categoryId);
  index !== -1 && !checked
    ? selectedCategory.splice(index, 1)
    : (selectedCategory = [...selectedCategory, categoryId]);

  if (selectedCategory.includes('event')) {
    return [...selectedCategory, 'milestone'];
  }
  if (selectedCategory.includes('todo')) {
    return [...selectedCategory, 'todolist'];
  }

  return !selectedCategory.length ? undefined : selectedCategory;
};

export default getSelectedCategoriesWithInsert;
