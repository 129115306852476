import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';

export const onFilterTodosBySearch = thunk(
  async ({ filterTodosBySearch }, { search }, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { filters } = state.project.todos.singleTodoList;
    const { todolistId } = state.project.todos.singleTodoList.todoList;

    const loaders = [
      'project.todos.singleTodoList.sidebar.search',
      'project.todos.singleTodoList.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });

    try {
      const todosBag = await api.todos.find({
        query: {
          todolistId: { $in: [todolistId] },
          $limit: projectCurrentTodosLimit,
        },
        filter: getFilterParams({ ...filters, search }),
        for: 'onFindProjectTodos',
      });

      filterTodosBySearch({ todosBag, search });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loaders });
  }
);
