import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const todos = feathersClient.service('tw/todos-deprecated');

/**
 *
 * @param params
 */
export const find = (params) => todos.find(paramsForServer(params));
/**
 *
 * @param todoId
 * @param params
 */
export const get = (todoId, params) => todos.get(todoId, paramsForServer(params));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) => todos.create(payload, paramsForServer(params));
/**
 *
 * @param todoId
 * @param payload
 * @param params
 */
export const patch = (todoId, payload, params) =>
  todos.patch(todoId, payload, paramsForServer(params));
/**
 *
 * @param todoId
 * @param params
 */
export const remove = (todoId, params) => todos.remove(todoId, paramsForServer(params));
