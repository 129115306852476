import { action } from 'easy-peasy';

import { moveSidebarListToCompleted } from '../helpers/moveSidebarListToCompleted';

// We need this action for auto hide moved to-do after some time
export const deleteMovedTodo = action((state, { todoId, todolistId }) => {
  const { todos } = state.todoList;
  todos.active = todos.active.filter((id) => id !== todoId);
  delete todos.map[todoId];

  state.sidebarLists = moveSidebarListToCompleted(state.todoList, state.sidebarLists, {
    todolistId,
  });
});
