import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';
import { getFilterParams } from '../helpers/getFilterParams';

export const onMountProjectSingleTodoList = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectSingleTodoList',
  action: async (actions, { projectId, todolistId, hasToResetTodosState }, getStoreState) => {
    const { resetProjectTodos } = actions.project.todos;
    const { mountProjectSingleTodoList, loadMoreTodos } = actions.project.todos.singleTodoList;

    const state = getStoreState();
    const { filters } = state.project.todos.singleTodoList;

    const todoList = await api.todoLists.get(todolistId, { for: 'onGetProjectTodoLists' });

    if (!projectId) {
      await actions.project.loadProject(todoList.projectId);
      // eslint-disable-next-line no-param-reassign
      projectId = todoList.projectId;
    }

    const [sidebarLists, todosBag, projectAssignees, milestones] = await Promise.all([
      api.todoLists.find({
        query: {
          projectId,
          $sort: { position: 1 },
          $select: ['todolistId', 'name', 'completed', 'position'],
        },
      }),
      api.todos.find({
        query: {
          todolistId: { $in: [todolistId] },
          $limit: projectCurrentTodosLimit,
          completedAt: 'NULL',
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      }),
      api.projects.get({ projectId, params: { for: 'onGetProjectTodosAssignees' } }),
      api.milestones.find({
        query: {
          projectId,
          $limit: -1,
          $sort: { title: 1 },
          $select: ['milestoneId', 'title', 'encrypted'],
        },
      }),
    ]);

    // Offset Loading in completed todos
    // TODO: add a loader for completed todos
    // TODO: Lazy load these
    api.todos
      .find({
        query: {
          todolistId: { $in: [todolistId] },
          $limit: projectCurrentTodosLimit,
          completedAt: { $ne: 'NULL' },
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      })
      .then((todosBag) => {
        loadMoreTodos({ todosBag });
      });

    if (hasToResetTodosState) {
      resetProjectTodos();
    }

    const { assignees = [] } = projectAssignees;

    mountProjectSingleTodoList({ todoList, sidebarLists, todosBag, assignees, milestones });

    return state.router.url({
      route: 'todolist',
      todolistId,
    });
  },
});
