import { action } from 'easy-peasy';

export const setOverdueMilestones = action((state, { milestones, overdueCount, hasMoreData }) => {
  state.overdueMilestones = {
    map: milestones,
    list: milestones.reduce(
      (acc, milestone) => ({ ...acc, [milestone.milestoneId]: milestone }),
      {}
    ),
    skipCount: milestones.length,
    overdueCount,
    hasMoreData,
  };
});
