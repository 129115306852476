import { thunk } from 'easy-peasy';
import { store } from '@webfx/web-state';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../../services/api';
import { getContentAsHtml } from '../../../../../../../utils-deprecated/rte/utils';
import { getSubscribeUsers } from '../../../../../../../utils-deprecated/subscribers';

export const onCreateComment = thunk(
  async (
    { createComment },
    { values, setSubmitting, resetForm, reactQuillRef, filePondRef },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const currentUserId = store.auth.user()?.userId;

    const content = getContentAsHtml(reactQuillRef);
    const subscribers = getSubscribeUsers(values.subscribers, content);
    const { data: project } = useProject();
    const projectId = project?.projectId;
    const todo = state.tw.todos.item;

    try {
      const comment = await api.comments.create({
        projectId,
        ...values,
        body: content,
        subscribers,
        files: values.files
          .map((file) => ({
            extension: file.fileExtension,
            byteSize: file.fileSize,
            type: file.fileType,
            name: file.filename,
            uploadId: file.serverId,
            private: todo ? todo.private : 0,
          }))
          .filter((file) => file.uploadId),
      });
      createComment({ comment, currentUserId });
      resetForm();
      if (filePondRef.current) {
        filePondRef.current.removeFiles();
      }
      await api.views.create({ todoId: comment.todoId });
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
