import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { getReorderedTodos } from '../helpers/getReorderedTodos';

export const onReorderTodo = thunk(
  async ({ reorderTodo, moveTodoToList }, payload, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const state = getStoreState();

    const { showError } = actions.common;
    const { source, destination, draggableId } = payload;

    const draggedTodoId = Number(draggableId.replace('todo-', ''));
    const sourceTodolistId = Number(source.droppableId.replace('todolist-', ''));
    const destinationTodolistId = Number(destination.droppableId.replace('todolist-', ''));
    const startIndex = source.index;
    const endIndex = destination.index;

    const { todos: sourceTodos } = state.project.todos.todoLists.todoLists.map[sourceTodolistId];
    const { todos: destinationTodos } =
      state.project.todos.todoLists.todoLists.map[destinationTodolistId];

    let position;
    try {
      if (sourceTodolistId === destinationTodolistId) {
        position = sourceTodos.map[sourceTodos.active[destination.index]].position;
        reorderTodo({
          sourceTodolistId,
          destinationTodolistId,
          todoId: draggedTodoId,
          position,
        });
      } else {
        position = destinationTodos.map[destinationTodos.active[destination.index]]?.position;
        if (!position) {
          // means that todo was added to the end destination todolist.
          position =
            (destinationTodos.map[destinationTodos.active[destination.index - 1]]?.position || -1) +
            1;
        }

        moveTodoToList({ todoId: draggedTodoId, sourceTodolistId, destinationTodolistId });
        reorderTodo({
          sourceTodolistId,
          destinationTodolistId,
          todoId: draggedTodoId,
          position,
        });
      }

      // await
      api.todos.patch(draggedTodoId, {
        position,
        todolistId: destinationTodolistId,
      });
    } catch (e) {
      // TODO: undo sort on api failure.
      console.error(e);
      showError(e);
    }
  }
);
