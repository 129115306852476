import { action } from 'easy-peasy';
import { DateTime } from 'luxon';
import get from 'lodash/get';

const filterDuplicates = (state, data) =>
  data.filter(({ uploadId }) => !state.itemsIds.includes(uploadId));

export const set = action(
  (
    state,
    { item: { data, total }, sort, creatorUserId, userTimezone, clearState, setStartAttachmentId }
  ) => {
    const formattedFiles = clearState ? [] : [...state.items];

    if (clearState) {
      state.sidebarUsers.userWasDeleted = false;
    }
    if (setStartAttachmentId && data.length) {
      state.startAttachmentId = get(data, [0, 'uploadId'], null);
    }
    if (!data.length && !creatorUserId.length) {
      return formattedFiles;
    }

    // check on duplicates
    const filteredData = filterDuplicates(state, data);
    const itemsIds = [...state.itemsIds, ...filteredData.map(({ uploadId }) => uploadId)];

    filteredData.forEach((currentFile) => {
      let labelName = '';

      switch (sort) {
        case 'createdAt': {
          labelName = DateTime.fromISO(currentFile.createdAt)
            .setLocale('en')
            .setZone(userTimezone)
            .toFormat('cccc dd LLLL y');
          break;
        }

        case 'name': {
          const [currentName] = currentFile.name.toLowerCase();
          labelName = currentName;
          break;
        }

        default: {
          labelName = '';
        }
      }

      const lastItem = formattedFiles[formattedFiles.length - 1];

      if (lastItem && lastItem.dateLabel === labelName) {
        lastItem.files.push(currentFile);
        return null;
      }

      formattedFiles.push({
        dateLabel: labelName,
        files: [currentFile],
      });
    });

    state.items = formattedFiles;
    state.itemsIds = itemsIds;
    state.skipCount = clearState ? data.length : state.skipCount + data.length;
    state.hasMoreData = total > state.skipCount;
  }
);
