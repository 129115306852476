import { action } from 'easy-peasy';

import { getAssigneeInfo } from '../helpers/getAssigneeInfo';

export const editTodoAssignee = action((state, { todoId, assignee }) => {
  const formattedAssignee = getAssigneeInfo(assignee);

  state.todoList.todos.map[todoId] = {
    ...state.todoList.todos.map[todoId],
    ...formattedAssignee,
  };
});
