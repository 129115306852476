import { thunk } from 'easy-peasy';
import { getTodosColorCodes } from '../helpers/getTodosColorCodes';
import { filterTodos } from '../helpers/filterTodos';

/* eslint-disable prefer-destructuring */
export const wsOnCreateTodo = thunk(
  async (_, { todolistId, result }, { getStoreActions, getState }) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const moveSidebarList = a.project.todos.singleTodoList.moveSidebarList;
    const createTodo = a.project.todos.singleTodoList.createTodo;

    const localState = getState();
    const { filters } = localState;
    const { todolistId: _todolistId, isColorCoding } = localState.todoList;

    moveSidebarList({ todo: { todoList: { todolistId } }, toCompleted: false });

    if (todolistId !== _todolistId) {
      return;
    }

    const todos = filterTodos({ data: result, filters });
    if (todos.length === 0) {
      return;
    }

    try {
      const colorCodes = await getTodosColorCodes(todos, isColorCoding);
      createTodo({ todolistId, todos, colorCodes });
    } catch (e) {
      showError(e);
    }
  }
);
