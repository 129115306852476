import { useEffect } from 'react';
import { createStore } from 'zustand-x';

import { useParams, useHistory } from 'react-router-dom';
import { useAuth, useNotifications, useStartPage } from '@webfx/web-hooks';

import useProject from '../hooks/useProject';
import useTodolist from '../hooks/useTodolist';
import useMilestone from '../hooks/milestones/useMilestone';
import { useTodo } from '../hooks/todos/useTodo';
import useMessage from '../hooks/messages/useMessage';
export const store = createStore('teamwork')({
  projectId: null,
});

/**
 * Provides the context for the project.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export function ProjectProvider({ children }) {
  const { userId } = useAuth();
  const { toast } = useNotifications();
  const startPage = useStartPage();
  const history = useHistory();
  const {
    projectId: projectIdParam = null,
    todolistId = null,
    todoId = null,
    milestoneId = null,
    messageId = null,
  } = useParams();

  const projectId = store.useTracked.projectId();

  const { data: todoData, isLoading: todoLoading } = useTodo(todoId, { retry: 1 });
  const { data: todolistData, isLoading: todolistLoading } = useTodolist(todolistId, { retry: 1 });
  const { milestone: milestoneData, isLoading: milestoneLoading } = useMilestone(milestoneId, {
    retry: 1,
  });
  const { message: messageData, isLoading: messageLoading } = useMessage(messageId, { retry: 1 });
  useProject(projectId, true);

  useEffect(() => {
    if (!todoData && !todolistData && !milestoneData && !messageData) {
      if (
        !projectIdParam &&
        !todoLoading &&
        !todolistLoading &&
        !milestoneLoading &&
        !messageLoading
      ) {
        toast.error('The requested resource was not found.');
        history.push(startPage);
      }
      return;
    }
    const data = todoData || todolistData || milestoneData || messageData;
    if (!data.projectId === projectId) {
      return;
    }
    store.set.projectId(data.projectId);
  }, [
    todoData,
    todolistData,
    milestoneData,
    messageData,
    projectId,
    projectIdParam,
    history,
    startPage,
    toast,
    todoLoading,
    todolistLoading,
    milestoneLoading,
    messageLoading,
  ]);

  useEffect(() => {
    if (!userId) {
      return null;
    }
    const styleElement = document.createElement('style');
    const style = `.mention[data-id="${userId}"] { background-color: var(--color-dandelion) }`;

    styleElement.innerHTML = style;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [userId]);

  useEffect(() => {
    if (projectIdParam && Number(projectIdParam) !== projectId) {
      store.set.projectId(Number(projectIdParam));
    }
  }, [projectIdParam, projectId]);

  return <>{children}</>;
}
