import api from '../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectTemplate = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectTemplate',
  useGlobalLoader: false,
  action: async (actions, { templateId }) => {
    const { setTemplateAssignee, selectProjectTemplate } = actions.projectTemplate;
    const { hideLoader } = actions.common;

    hideLoader({ key: 'globalLoader.onMountTeamworkFx' });

    const res = await api.companies.find({
      query: { $sort: { name: 1 }, name: 'WebFx', $limit: -1 },
      for: 'users',
    });

    setTemplateAssignee(res);

    const template = await api.projectTemplates.get(templateId, {
      query: {
        $join: ['updater', 'lastDeployedDate', 'timesDeployedLast12Months'],
      },
    });
    selectProjectTemplate(template);
  },
});
