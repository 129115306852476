import { api } from '@webfx/core-web';

/**
 * Web hook for action template service
 */
const useActionTemplate = () => {
  return api.useMutation(['/actions']);
};

export default useActionTemplate;
