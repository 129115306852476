export const deleteTodosFromList = (todoList) => ({
  ...todoList,
  $skip: 0,
  hasMore: false,
  todos: {
    active: [],
    completed: [],
    map: {},
  },
});
