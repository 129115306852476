import { action } from 'easy-peasy';
import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';
import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';

export const wsAddProjectOverviewMilestone = action((state, { milestone, timezone }) => {
  state.milestones.items.push(milestone);

  if (!isOverdueMilestone(milestone.dueAt, timezone)) {
    return;
  }

  state.milestones.overdue.push(milestone);
  state.milestones.overdue = sortOverdueMilestones(state.milestones.overdue);
  state.milestones.overdueCount += 1;
});
