import { action } from 'easy-peasy';
import modifyStartPage from '../helpers/modifyStartPage';
import getValuesForStoreByStatus from '../helpers/getValuesForStoreByStatus';

export const mountTeamworkFxArchivedProjectsPage = action(
  (state, { companiesProjects, companiesArchivedProjects }) => {
    const { data, hasMore } = companiesProjects;
    const { data: archivedProjectsData, hasMore: hasMoreArchivedProjects } =
      companiesArchivedProjects;

    const modifiedData = modifyStartPage(data);
    const activeProjects = getValuesForStoreByStatus(modifiedData);

    const modifiedArchivedProjectsData = modifyStartPage(archivedProjectsData);
    const archivedProjects = getValuesForStoreByStatus(modifiedArchivedProjectsData, 'archived');

    state.active.list = [...state.active.list, ...activeProjects.list];
    state.active.map = {
      ...state.active.map,
      ...activeProjects.map,
    };

    state.archived.list = [...state.archived.list, ...archivedProjects.list];
    state.archived.map = {
      ...state.archived.map,
      ...archivedProjects.map,
    };

    state.projects = {
      ...state.projects,
      ...activeProjects.projects,
      ...archivedProjects.projects,
    };

    state.hasMoreData = hasMoreArchivedProjects;
    state.$skip += archivedProjectsData.length;

    if (state.paginationProjectsType === 'active' && !hasMore) {
      state.paginationProjectsType = 'on hold';
      state.$skipSidebarProjects = 0;
      state.hasSidebarMoreData = true;
      return;
    }

    state.hasSidebarMoreData = hasMore;
    state.$skipSidebarProjects += data.length;
  }
);
