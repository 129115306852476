import { thunk } from 'easy-peasy';

const onWSCreatedProjectUser = thunk(
  (actions, projectUser, { getState, getStoreState, getStoreActions }) => {
    const { data } = getState();
    const { router } = getStoreState();
    const {
      teamworkFx: {
        dashboard: { onUnMountTeamworkFxDashboardPage, onMountTeamworkFxDashboardPage },
      },
    } = getStoreActions();

    const projectId = projectUser.projectId || projectUser.projectsAccess.projectId;

    if (data[projectId]) {
      return;
    }

    actions.fetchOne({
      projectId,
      query: {
        $select: ['projectId', 'name', 'startPage', 'status', 'company'],
        $client: { for: 'switchProjectsDropdown' },
      },
    });

    if (router.active === 'dashboard') {
      onUnMountTeamworkFxDashboardPage();
      onMountTeamworkFxDashboardPage({});
    }
  }
);

export default onWSCreatedProjectUser;
