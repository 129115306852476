import { thunk } from 'easy-peasy';
import filterByDates from '../../helpers/filterCalendarEventByDates';

const getEventIndexInStore = (state, eventId) => {
  const { items: eventsList } = state.project.overview.events;
  return eventsList.findIndex(({ eventId: id }) => id === eventId);
};

export const onWsPatchProjectOverviewEvent = thunk(async (actions, event, { getStoreState }) => {
  const state = getStoreState();
  const { calendarDates } = state.project.overview;
  const { wsAddProjectOverviewEvent, wsPatchProjectOverviewEvent, wsRemoveProjectOverviewEvent } =
    actions;

  const eventIndex = getEventIndexInStore(state, event.eventId);
  if (eventIndex === -1) {
    // event not exists in store, try to add it
    wsAddProjectOverviewEvent({ event });
    return;
  }

  const isUpdatedEventInDateRange = filterByDates(event, calendarDates);

  if (isUpdatedEventInDateRange) {
    wsPatchProjectOverviewEvent({ event, eventIndex });
  } else {
    wsRemoveProjectOverviewEvent(event);
  }
});
