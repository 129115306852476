import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onDeleteTeamworkFxTemplateTodoList = thunk(
  async ({ deleteTeamworkFxTemplateList }, { id, push }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'teamworkFx.templates.todoLists.list.deleteList';
    showLoader({ key: loader });
    try {
      await api.todoListsTemplate.remove(id);

      push('/templates/todos');

      deleteTeamworkFxTemplateList(id);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
