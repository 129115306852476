import { thunk } from 'easy-peasy';
import get from 'lodash/get';

import { overviewActivityItemsPerPage } from '../../../../../ui/constants/project/overview';
import api from '../../../../../services/api';

export const onGetActivitiesByPagination = thunk(
  async (
    { setActivities },
    { projectId, skipCount, previousPage = false },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const userTimezone = get(state, 'user.user.timezone', null);

    const loader = `project.overview.activities.getOverviewPaginationActivities.${
      !previousPage ? 'next' : 'previous'
    }`;
    showLoader({ key: loader });
    try {
      const data = await api.activities.find({
        query: { projectId, $skip: skipCount, $limit: overviewActivityItemsPerPage },
        for: 'additionalInfo',
      });
      setActivities({ res: data, userTimezone, previousPage });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
