import { action } from 'easy-peasy';
import sortBy from 'lodash/sortBy';

export const mergeSidebarIntoExisted = (categories, payload) => {
  payload.forEach((category) => {
    const { categoryId } = category;
    const isPresent = categories.list.some((item) => item === categoryId);
    const checked = categoryId in categories.map ? categories.map[categoryId].checked : false;

    if (!isPresent) {
      categories.list.push(categoryId);
    }
    categories.map[categoryId] = category;
    categories.map[categoryId].checked = checked;
  });

  return categories;
};

export const setTemplateListSidebarCategories = action((state, payload) => {
  const sortedCategories = sortBy(payload, ({ name }) => name.trim().toLowerCase());

  mergeSidebarIntoExisted(state.sidebar.categories, sortedCategories);
});
