const initStore = {
  archived: {
    list: [],
    map: {},
  },
  active: {
    list: [],
    map: {},
  },
  onHold: {
    list: [],
    map: {},
  },
  projects: {},
  $skip: 0,
  hasMoreData: false,
  $skipSidebarProjects: 0,
  hasSidebarMoreData: false,
  paginationProjectsType: 'active',
};

export default initStore;
