import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onCompleteTodo = thunk(
  async ({ completeTodo }, { id, todolistId, completed }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = `teamworkFx.todos.completed.${id}`;
    showLoader({ key: loader });
    try {
      const completedTodo = await api.todos.patch(
        id,
        { todolistId },
        { for: 'onToggleTodoCompletedStatus', completed }
      );
      completeTodo(completedTodo);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
