import classnames from 'classnames';
import { useFlags } from '@webfx/web-hooks';
import { Icon } from '@webfx/core-web';
import setGoalName from '@webfx/gameplan-ui/src/utils/set-goal-name.js';
import styles from './GoalDetail.module.css';

const EXCLUDED_TYPES = ['seo', 'ppc', 'social'];

const GoalDetail = ({ type, goal }) => {
  const { gameplanfxUpdates } = useFlags();

  if (EXCLUDED_TYPES.includes(type)) {
    return <div className="py-4" />;
  }

  const iconName = gameplanfxUpdates ? 'flag' : 'golf_course';
  const iconClassName = gameplanfxUpdates ? 'text-gray-550' : classnames('mr-1', styles.goalIcon);

  return (
    <div className={classnames('d-flex', { 'mt-3 mb-n2': !gameplanfxUpdates })}>
      <Icon className={iconClassName}>{iconName}</Icon>
      <p className={styles.goalName}>
        <span className="bold" data-fx-name="objective">
          Objective:
        </span>{' '}
        {setGoalName({ type, goal })}
      </p>
    </div>
  );
};

export default GoalDetail;
