import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const attachments = feathersClient.service('tw/attachments');
export const comments = feathersClient.service('tw/comments-deprecated');

/**
 *
 * @param payload
 */
export const create = (payload) => attachments.create(payload);

/**
 *
 * @param uploadId
 */
export const get = (uploadId) => attachments.get(uploadId);

/**
 *
 * @param payload
 */
export const find = (payload) => attachments.find(paramsForServer(payload));

/**
 *
 * @param root0
 * @param root0.uploadId
 */
export const remove = ({ uploadId }) => attachments.remove(uploadId);
/**
 *
 * @param commentId
 * @param comment
 * @param params
 */
export const patch = (commentId, comment, params) =>
  comments.patch(commentId, comment, paramsForServer(params));
