const initStore = {
  recentDiscussion: [],
  allMessages: [],
  total: 0,
  filter: {
    showAll: true,
    categories: {
      selected: [],
      list: [],
      map: {},
    },
  },
};

export default initStore;