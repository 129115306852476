import { action } from 'easy-peasy';

import { toBool } from '../../../../../../utils-deprecated/toBool';

export const wsDeleteTodoList = action((state, { result: { todolistId, completed } }) => {
  const status = toBool(completed) ? 'completed' : 'active';
  state.sidebarLists[status] = state.sidebarLists[status].filter(
    (list) => list.todolistId !== todolistId
  );
});
