import api from '../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProject = fetchWithLoaderAndRedirect({
  loaderKey: 'Project',
  useGlobalLoader: false,
  action: async (actions, { projectId }) => {
    const { loadProject } = actions.project;
    await loadProject(projectId);
  },
});
