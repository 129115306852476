import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { Spinner, Svg } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';

import { getLastDayOfTheMonthByDate } from '@webfx/web-ui/src/QuarterSelector/utils';

import addOneTimeClientRequestGoals from '../../utils/addOneTimeClientRequestGoals.js';
import useGoals from '../../hooks/useGoals.js';
import useProjectsOptimizations from '../../hooks/useProjectsOptimizations.js';
import useGamePlanIsEnabled from '../../hooks/useGamePlanIsEnabled.js';

import Box from '../../assets/images/box.svg';

import GoalsChart from '../GoalsChartV1';
import GoalsHeaderV1 from '../GoalsHeaderV1';
import GoalList from '../GoalList';
import ActionsNeeded from '../ActionsNeeded';

const GoalsV1 = ({ companyId, quarter, hideGoals = false }) => {
  const flags = useFlags();
  const months = quarter?.monthRange;
  const lastMonthOfTheQuarterDay = getLastDayOfTheMonthByDate(months[1]);

  const { data: goals, isLoading } = useGoals({
    query: {
      companyId,
      date: { $gte: months[0], $lte: months[1] },
      $join: ['optimizations'],
      $limit: -1,
    },
  });

  const { enabled: isGamePlanEnabled } = useGamePlanIsEnabled(companyId);

  const { data, isFetching } = useProjectsOptimizations({
    query: {
      dueDate: { $gte: months[0], $lte: lastMonthOfTheQuarterDay },
      companyId,
    },
  });

  addOneTimeClientRequestGoals(goals, data, months);

  if (isFetching) {
    return <Spinner className="my-5" />;
  }

  return (
    <>
      {!hideGoals && isGamePlanEnabled && (
        <div className="border-bottom">
          {/* Sub Header */}
          <GoalsHeaderV1 companyId={companyId} />
          {!isLoading && isEmpty(goals) ? (
            <div className="d-flex flex-column align-items-center justify-content-center p-5">
              <Svg svgStyle={{ width: '130px' }} src={Box} />

              <p className="pt-3">No objectives configured in this quarter</p>
            </div>
          ) : (
            <div className="d-flex flex-wrap">
              <div className="col-xl-7 col-lg-5 col-12 align-items-center d-flex">
                <GoalsChart goals={goals} />
              </div>
              <div
                className={classnames('col-xl-5 col-lg-7 col-12 border-left p-0', {
                  'd-flex justify-content-between': flags.gameplanfxUpdates,
                })}
              >
                <GoalList goals={goals} />
                {flags.gameplanfxUpdates && <ActionsNeeded months={months} companyId={companyId} />}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GoalsV1;
