import {action} from 'easy-peasy';
import initStore from './initStore';

// ws-thunks
const message = {
  ...initStore,
  reset: action((state) => {
    Object.keys(initStore).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      state[key] = initStore[key];
    });
  }),
};

export default message;