import { setTemplateTodoListsIntoStore } from './actions/setTemplateTodoListsIntoStore';
import { addProjectTemplateList } from './actions/addProjectTemplateList';
import { setProjectTemplateSidebarLists } from './actions/setProjectTemplateSidebarLists';
import { changeSidebarListChecked } from './actions/changeSidebarListChecked';
import { setTodoListTemplateFilteringParams } from './actions/setTodoListTemplateFilteringParams';
import { addTemplateTodo } from './actions/addTemplateTodo';
import { addTemplateMultipleTodo } from './actions/addTemplateMultipleTodo';
import { deleteTemplateList } from './actions/deleteTemplateList';
import { updateTemplateTodoList } from './actions/updateTemplateTodoList';
import { deleteTemplateTodo } from './actions/deleteTemplateTodo';
import { updateTemplateTodo } from './actions/updateTemplateTodo';
import { changeSidebarAllChecked } from './actions/changeSidebarAllChecked';
import { dragAndDropTemplateTodo } from './actions/dragAndDropTemplateTodo';
import { mountEditCreateTemplateTodoListForm } from './actions/mountEditCreateTemplateTodoListForm';
import { clearTemplatePageState } from './actions/clearTemplatePageState';
import { updateTemplateTodoAssignee } from './actions/updateTemplateTodoAssignee';
import { setReorderedListIntoStore } from './actions/setReorderedListIntoStore';

import { onMountProjectTemplateTodoLists } from './thunks/onMountProjectTemplateTodoLists';
import { onCreateProjectTemplateTodoList } from './thunks/onCreateProjectTemplateTodoList';
import { onShowAllTodoLists } from './thunks/onShowAllTodoLists';
import { onFilterByList } from './thunks/onFilterByList';
import { onChangeFilteringParams } from './thunks/onChangeFilteringParams';
import { onCreateTemplateTodo } from './thunks/onCreateTemplateTodo';
import { onDeleteTemplateTodoList } from './thunks/onDeleteTemplateTodoList';
import { onUpdateTemplateTodoList } from './thunks/onUpdateTemplateTodoList';
import { onDeleteTemplateTodo } from './thunks/onDeleteTemplateTodo';
import { onUpdateTemplateTodo } from './thunks/onUpdateTemplateTodo';
import { onUpdateTemplateListOrder } from './thunks/onUpdateTemplateListOrder';
import { onUpdateTemplateTodoDragAndDrop } from './thunks/onUpdateTemplateTodoDragAndDrop';
import { onMountEditCreateTemplateTodoListForm } from './thunks/onMountEditCreateTemplateTodoListForm';
import { onMountProjectTemplateSingleTodoLists } from './thunks/onMountProjectTemplateSingleTodoLists';
import { onUpdateTemplateTodoAssignee } from './thunks/onUpdateTemplateTodoAssignee';

import initStore from './initStore';

const todoLists = {
  // store
  ...initStore,

  // actions
  setTemplateTodoListsIntoStore,
  addProjectTemplateList,
  setProjectTemplateSidebarLists,
  changeSidebarListChecked,
  setTodoListTemplateFilteringParams,
  addTemplateTodo,
  addTemplateMultipleTodo,
  deleteTemplateList,
  updateTemplateTodoList,
  deleteTemplateTodo,
  updateTemplateTodo,
  changeSidebarAllChecked,
  dragAndDropTemplateTodo,
  mountEditCreateTemplateTodoListForm,
  clearTemplatePageState,
  updateTemplateTodoAssignee,
  setReorderedListIntoStore,

  // thunks
  onMountProjectTemplateTodoLists,
  onCreateProjectTemplateTodoList,
  onShowAllTodoLists,
  onFilterByList,
  onChangeFilteringParams,
  onCreateTemplateTodo,
  onDeleteTemplateTodoList,
  onUpdateTemplateTodoList,
  onUpdateTemplateListOrder,
  onDeleteTemplateTodo,
  onUpdateTemplateTodo,
  onUpdateTemplateTodoDragAndDrop,
  onMountEditCreateTemplateTodoListForm,
  onMountProjectTemplateSingleTodoLists,
  onUpdateTemplateTodoAssignee,
};

export default todoLists;
