import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onMoveTodo = thunk(async (_, { todoId, target, onClose }, { getStoreActions }) => {
  const actions = getStoreActions();
  const showError = actions.common.showError;
  const showLoader = actions.common.showLoader;
  const hideLoader = actions.common.hideLoader;
  const moveTodo = actions.project.todos.singleTodoList.moveTodo;

  const loader = 'project.todos.singleTodoList.moveTodoForm.isTodoMoving';
  showLoader({ key: loader });

  try {
    const movedTodo = await api.todos.patch(todoId, target, { for: 'onMoveTodo' });
    moveTodo({ todoId, movedTodo });
    onClose();
  } catch (e) {
    showError(e);
  }

  hideLoader({ key: loader });
});
