import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { getRequestInfo } from './helpers/getTodoData';

export const onEditTodo = thunk(
  async (
    { editTodo },
    { values, setSubmitting = () => null, onCloseForm = () => null, todoId },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      const todo = await api.todos.patch(todoId, getRequestInfo(values), { for: 'onEditTodo' });
      editTodo({ todoId, todo });
      onCloseForm();
    } catch (e) {
      showError(e);
    }

    setSubmitting(false);
  }
);
