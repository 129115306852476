import { thunk } from 'easy-peasy';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import { filterTodos } from '../helpers/filterTodos';

/* eslint-disable prefer-destructuring */
export const wsOnEditTodo = thunk(
  async (_, { todolistId, result, result: { todoId } }, { getStoreActions, getState }) => {
    const a = getStoreActions();
    const createTodo = a.project.todos.singleTodoList.createTodo;
    const editTodo = a.project.todos.singleTodoList.editTodo;
    const deleteActiveTodo = a.project.todos.singleTodoList.deleteActiveTodo;

    const localState = getState();
    const { filters } = localState;
    const { todolistId: _todolistId } = localState.todoList;

    if (todolistId !== _todolistId) {
      return;
    }

    const isTodoExist = toBool(localState.todoList.todos.map[todoId]);
    const isFiltersPassed = filterTodos({ data: result, filters }).length > 0;

    if (isFiltersPassed && isTodoExist) {
      const todo = localState.todoList.todos.map[todoId];
      editTodo({ todolistId, todoId, todo: { ...result, hasNewComments: todo.hasNewComments } });
    }
    if (isFiltersPassed && !isTodoExist) {
      createTodo({ todolistId, todos: [result] });
    }
    if (!isFiltersPassed && isTodoExist) {
      deleteActiveTodo({ todo: result });
    }
  }
);
