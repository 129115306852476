import { computed, debug } from 'easy-peasy';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';

export const defaultFilters = {
  assignee: {
    label: 'Anyone',
    selectedValueLabel: 'Anyone',
    value: {
      responsibleUserId: undefined,
      responsibleCompanyId: undefined,
    },
    avatarUrl: null,
  },
  dueDate: {
    label: 'Anytime',
    value: 'anytime',
    dueDate: undefined,
  },
  search: '',
};

// TODO: Todolist / todos structuring is far to complicated and needs to use more derived state with computed
// TODO: simplify structures
// TODO: use core mixins for data calls.
const initState = {
  todoLists: {
    active: [], // TODO should be computed
    completed: [], // TODO should be computed
    current: computed(
      [
        (state) => state,
        (state, storeState) => storeState.project.todos.todoLists.hasActiveFilters,
      ],
      (state, hasActiveFilters) => {
        const currentlyLoadedLists = intersection(state.active, state.loadedTodoLists); //state.active.slice(0, state.loadedTodoLists.length);
        return currentlyLoadedLists.reduce((todolists, todolistId) => {
          // remove non checked lists
          if (!state.map[todolistId].checked) {
            return todolists;
          }

          // remove empty lists on filters
          if (hasActiveFilters) {
            const {
              todos: { active, completed },
            } = state.map[todolistId];
            if (!active.length && !completed.length) {
              return todolists;
            }
          }

          todolists.push(state.map[todolistId]);
          return todolists;
        }, []);
      }
    ),
    map: {},
    $skip: 0,
    hasMore: computed(
      [(state) => state, (state, storeState) => storeState.project.todos.todoLists.filters],
      (state, filters) => {
        // filtering automatically searches all checked lists
        if (
          filters.assignee.label !== 'Anyone' ||
          filters.dueDate.label !== 'Anytime' ||
          filters.search !== ''
        ) {
          return;
        }

        const checked = state.active.filter(
          (todolistId) => state.map[todolistId].checked === true && !state.map[todolistId].movedTo
        );
        if (state.loadedTodoLists.length >= checked.length) {
          return false;
        }

        return state.loadedTodoLists.length < checked.length;
      }
    ),
    lastLoadedTodoListId: null,
    loadedTodoLists: [],
  },
  todoListForm: {
    templates: [],
    milestones: [],
  },
  filters: {
    ...defaultFilters,
  },
  hasActiveFilters: computed(
    [(state) => state.filters],
    (filters) => !isEqual(filters, defaultFilters)
  ),
  assignees: [],
  // TODO: this data should be able to be computed from store vs fetched and saved again.
  moveTodoForm: {
    projects: [],
    todoLists: [],
  },

  // Needed to fix implemantation of clicking outside the Set Assignee form.
  openedAssigneeForm: null,
};

export default initState;
