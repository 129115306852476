import { thunk } from 'easy-peasy';

export const wsReorderTodo = thunk((_, { todolistId }, { getStoreActions, getState }) => {
  if (!getState().todoLists.map[todolistId]) {
    return;
  }

  const showReloadPageMessage = getStoreActions().common.showReloadPageMessage;
  const onMountProjectTodoLists = getStoreActions().project.todos.todoLists.onMountProjectTodoLists;

  showReloadPageMessage({
    reloadHandler: () => {
      onMountProjectTodoLists();
    },
  });
});
