import { action } from 'easy-peasy';

export const removeCompanyFromProject = action((state, payload) => {
  const index = state.project.projectCompanies.findIndex((c) => c.companyId === payload.companyId);
  state.project.projectCompanies.splice(index, 1);

  // state.other.companies.map[payload.companyId].users.forEach(id => delete state.other.users[id]);
  // delete state.other.companies.map[payload.companyId];
  // state.other.companies.list = state.other.companies.list.filter(id => id !== payload.companyId);
});
