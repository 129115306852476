import { action } from 'easy-peasy';
import { isOverdueMilestone } from '../../../../../utils-deprecated/calendar';
import { sortOverdueMilestones } from '../../../helpers/sortOverdueMilestones';

export const addMilestone = action((state, { milestone: createdMilestone, timezone }) => {
  state.milestones.items.push(createdMilestone);

  if (!isOverdueMilestone(createdMilestone.dueAt, timezone)) {
    return;
  }

  const isOverdueMilestoneInState = state.milestones.overdue.some(
    ({ milestoneId }) => milestoneId === createdMilestone.milestoneId
  );

  if (isOverdueMilestoneInState) {
    return;
  }

  state.milestones.overdue.push(createdMilestone);
  state.milestones.overdue = sortOverdueMilestones(state.milestones.overdue);
});
