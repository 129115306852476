import { DateTime } from 'luxon'
import api from '../../../../../services/api'
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect'

export const onClickProjectActivityEventTitle = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectActivity',
  useGlobalLoader: false,
  action: async (actions, { id, projectId }) => {
    const { startAt } = await api.events.get(id);

    const startOf = startAt
      ? startAt.replace(/-\d{2}$/, '-01')
      : DateTime.local().toFormat('yyyy-LL-01');

    return `/projects/${projectId}/milestones?from_date=${startOf}`;
  },
});
