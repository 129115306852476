const defaultUser = {
  userId: null,
  firstName: '',
  lastName: '',
  title: '',
  email: null,
  emails: [{ userId: null, email: '', type: 'work' }],
  companies: [],
  products: [],
  roles: [],
  sites: [],
  officeNumber: '',
  ext: '',
  homeNumber: '',
  mobileNumber: '',
  avatarUrl: null,
  tokens: [],
};

export default defaultUser;
