import { action } from 'easy-peasy';

export const updateProjectAccess = action((state, payload) => {
  if (payload.removedProjectsAccess) {
    payload.removedProjectsAccess.forEach(({ projectId }) => {
      state.sidebar.companies.list.find((companyId) => {
        const company = state.sidebar.companies.map[companyId];
        const projectIsInCompany = company.projects.list.includes(projectId);

        if (projectIsInCompany) {
          company.projects.map[projectId].checked = false;
          company.checked = false;
          return true;
        }
        return false;
      });
    });
  }
});
