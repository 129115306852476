import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { allPeopleAndCompaniesLimit } from '../../../../../ui/constants/limits';

// thunks
export const onCreateCompany = thunk(
  async (
    { resetCompanies, setCompanies },
    { name, setSubmitting, resetForm, companyId },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showNotification, showLoader, hideLoader, showError } = actions.common;

    const loader = 'showAllPeopleCompaniesLoader';
    showLoader({ key: loader });
    try {
      await api.companies.create({ name });
      // get updated, sorted list without userCompany
      const foundedCompanies = await api.companies.find({
        query: {
          $sort: { name: 1 },
          companyId: { $ne: companyId },
          $skip: 0,
          $limit: allPeopleAndCompaniesLimit,
        },
        for: 'allPeople',
      });
      resetCompanies();
      setCompanies(foundedCompanies);
      showNotification({ message: 'Company has been successfully created' });
      resetForm();
    } catch (error) {
      showError(error);
    }
    setSubmitting(false);
    hideLoader({ key: loader });
  }
);
