import allPeople from './allPeople';
import peopleAndPermissions from './peopleAndPermissions';
import deletedProjects from './deletedProjects';
import changePermissions from './changePermissions';
import archivedProjects from './archivedProjects';
import { onMountPageNotFound } from './notFound/thunks/onMountPageNotFound';

const pages = {
  allPeople,
  peopleAndPermissions,
  deletedProjects,
  changePermissions,
  onMountPageNotFound,
  archivedProjects,
};

export default pages;
