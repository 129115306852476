export const getResponsibleUser = ({ responsibleUser, responsibleCompany }) => {
  if (!responsibleUser && !responsibleCompany) {
    return 'Anyone';
  }

  if (responsibleUser) {
    return `${responsibleUser.firstName} ${responsibleUser.lastName.charAt(0).toUpperCase()}.`;
  }

  if (responsibleCompany) {
    return responsibleCompany.name;
  }
};

export const formatingActivity = ({
  activityId,
  type,
  action: activityAction,
  projectId,
  actionDate,
  ...res
}) => {
  const formatedActivity = {
    activityId,
    type,
    private: res.private,
    action: activityAction,
    projectId,
    actionDate,
    responsibleUser: getResponsibleUser(res),
    responsibleUserData: res.responsibleUser,
  };

  switch (type) {
    case 'todo': {
      formatedActivity.todo = {
        id: res.todoId,
        title: res.todo?.content || '',
      };
      formatedActivity.todoList = {
        id: res.todolistId,
        title: res.todoList?.name || '',
      };
      break;
    }
    case 'file': {
      if (res.attachment) {
        formatedActivity.file = {
          ...res.attachment,
          title: res.attachment.name,
        };
      } else {
        formatedActivity.file = {};
      }
      break;
    }
    case 'comment': {
      const { todo, message, milestone, todoId, messageId, milestoneId, commentId } = res;
      const id = todoId || messageId || milestoneId;

      let path = 'todo';
      if (messageId) {
        path = 'post';
      }
      if (milestoneId) {
        path = 'milestone';
      }

      let title;
      if (todoId) {
        title = todo?.content || '';
      }
      if (messageId) {
        title = message?.title || res.title;
      }
      if (milestoneId) {
        title = milestone?.title || res.title;
      }

      formatedActivity.comment = {
        id,
        path,
        title,
        commentId,
      };
      break;
    }
    case 'message': {
      formatedActivity.message = {
        id: res.messageId,
        title: res?.message?.title || res.title,
      };
      break;
    }
    case 'milestone': {
      formatedActivity.milestone = {
        id: res.milestoneId,
        title: res?.milestone?.title || res.title,
      };
      break;
    }
    case 'event': {
      formatedActivity.event = {
        id: res.eventId,
        title: res?.event?.title || res.title,
      };
      break;
    }
    default: {
      break;
    }
  }

  return formatedActivity;
};
