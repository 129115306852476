const initStore = {
  items: {
    list: [],
    map: {},
    total: 0,
  },
  filters: {
    isFiltered: false,
    search: '',
    // Need for saving searched value for scrolling
    lastSearched: '',
    pageIndex: 0,
    sidebar: {
      showAll: true,
      selected: [],
      categories: [
        { title: 'Messages', value: 'message', checked: false },
        { title: 'Comments', value: 'comment', checked: false },
        { title: 'To-dos', value: 'todo', checked: false },
        { title: 'Files', value: 'attachment', checked: false },
        { title: 'Milestones and Events', value: 'event', checked: false },
        { title: 'Projects', value: 'project', checked: false },
      ],
    },
  },
  scrollParams: {
    skipCount: 0,
    hasMoreData: true,
  },
};

export default initStore;
