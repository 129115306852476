import { action } from 'easy-peasy';
import actions from './actions';
import thunks from './thunks';

const peopleAndPermissions = {
  companies: [],

  reset: action((state, payload) => {
    state.companies = [];
  }),
  // actions
  ...actions,
  // thunks
  ...thunks,
};

export default peopleAndPermissions;
