import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';

// this wrapper function used for showing, hiding global loader,
// breaking call mount thunk id data was preloaded and redirect

/**
 *
 * @param {string} loaderKey key for loader what will be used
 * @param {string} pathname where will redirect. You can pass link into pushParams.link or return path from action
 * @param {function} action main thunk which will be wrapped
 * @param {boolean} use if we have chunk for this page
 * @returns wrapped thunk
 */

const fetchWithLoaderAndRedirect = ({ loaderKey, pathname, action, useGlobalLoader = true }) =>
  thunk(
    async (
      _,
      { pushParams, isPrefetchedData, ...payload } = {},
      { getStoreActions, getStoreState }
    ) => {
      if (isPrefetchedData) {
        return;
      }

      let link;
      let loadChunk = true;
      let isError = false;
      const actions = getStoreActions();
      const { showChunkLoader, showLoader, hideLoader, showError } = actions.common;

      const onMountLoaderKey =
        pushParams && pushParams.softLoader
          ? 'globalLoader.softLoader'
          : `globalLoader.onMount${loaderKey}`;

      showLoader({ key: onMountLoaderKey });

      try {
        link = await action(actions, { pushParams, ...payload }, getStoreState);
      } catch (e) {
        isError = true;
        if (e.code === 404) {
          if (payload.handleError) {
            payload.handleError(e);
            return;
          }
          getStoreActions().common.setAppError(e);
          return;
        }

        if (e.code === 408) {
          if (payload.handleError) {
            payload.handleError(e);
            return;
          }
        }

        console.error(e);
        showError(e);
        // TODO FX: disable redirect on 404 e.code === 404 ||
        if (e.code === 403 && pushParams) {
          // redirect if page not found or we have no access
          loadChunk = false;
          return pushParams.push(store.auth.startPage());
        }
      } finally {
        if (loadChunk && useGlobalLoader) {
          showChunkLoader({ key: loaderKey });
        }
        hideLoader({ key: onMountLoaderKey });
      }

      if (pushParams && !pushParams.doNotPush && !isError) {
        pushParams.push({
          pathname: pushParams.link || link || pathname,
          ...(pushParams.pageNumber && { search: `?page=${pushParams.pageNumber}` }),
          ...(pushParams.queryString && { search: pushParams.queryString }),
          popstate: {
            prefetchedData: true,
            ...(pushParams.openCreateModal && { openCreateModal: true }),
            ...(pushParams.openUpdateModal && { openUpdateModal: true }),
          },
        });
      }
    }
  );

export default fetchWithLoaderAndRedirect;
