import { onCreateProject } from './onCreateProject';
import { onGetAssignee } from './onGetAssignee';
import { onGetCompaniesWithProjects } from './onGetCompaniesWithProjects';
import onWSCreatedProjectUser from './onWSCreatedProjectUser';
import onWSPatchedProjectUser from './onWSPatchedProjectUser';
import onWSRemovedProjectUser from './onWSRemovedProjectUser';

export default {
  onCreateProject,
  onGetAssignee,
  onGetCompaniesWithProjects,
  onWSCreatedProjectUser,
  onWSPatchedProjectUser,
  onWSRemovedProjectUser,
};
