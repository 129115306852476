import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';
import { getCheckedLists } from '../helpers/getCheckedLists';

export const onLoadMoreTodos = thunk(
  async ({ loadMoreTodos }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const { filters, todoLists } = state.project.todos.todoLists;
    const { loadedTodoLists, hasMore } = state.project.todos.todoLists.todoLists;
    const checkedLists = getCheckedLists({ todoLists });
    const nextListToLoad = checkedLists.filter((t) => !loadedTodoLists.includes(t))[0];

    // TODO: offset fetching completed todos for faster responses

    try {
      const [activeTodos, completedTodos] = await Promise.all([
        api.todos.find({
          query: {
            todolistId: { $in: [nextListToLoad] },
            completedAt: 'NULL',
            // $skip,
            $limit: -1,
          },
          filter: getFilterParams(filters),
          for: 'onFindProjectTodos',
        }),
        api.todos.find({
          query: {
            todolistId: { $in: [nextListToLoad] },
            completedAt: { $ne: 'NULL' },
            $sort: { completedAt: -1 },
            // $skip,
            $limit: 4,
          },
          filter: getFilterParams(filters),
          for: 'onFindProjectTodos',
        }),
      ]);

      // TODO set Completed total
      const todosBag = [...activeTodos, ...completedTodos.data];

      loadMoreTodos({ todosBag, todolistId: nextListToLoad });
    } catch (e) {
      showError(e);
    }
  }
);
