import { paramsForServer } from 'feathers-hooks-common';
import { feathers } from '@webfx/core-web';
import pathsConst from '../../../../../ui/constants/paths';
import { companiesProjectsLimit } from '../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

const { ARCHIVED_PROJECTS } = pathsConst;

export const onMountTeamworkFxArchivedProjectsPage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxArchivedProjectsPage',
  pathname: ARCHIVED_PROJECTS,
  action: async (actions) => {
    const { mountTeamworkFxArchivedProjectsPage } = actions.ui.archivedProjects;

    const [companiesProjects, companiesArchivedProjects] = await Promise.all([
      feathers()
        .service('tw/sidebar')
        .find(
          paramsForServer({
            query: { $cache: false, $skip: 0, $limit: companiesProjectsLimit, status: 'active' },
            for: 'projectsSidebar',
          })
        ),
      feathers()
        .service('tw/sidebar')
        .find(
          paramsForServer({
            query: { $cache: false, $skip: 0, $limit: companiesProjectsLimit, status: 'archived' },
            for: 'projectsSidebar',
          })
        ),
    ]);
    mountTeamworkFxArchivedProjectsPage({ companiesProjects, companiesArchivedProjects });
  },
});
