import { action } from 'easy-peasy';

export const wsPatchProjectOverviewMilestone = action((state, { milestone, milestoneIndex }) => {
  // Update milestone due date and resposible
  const updatedMilestone = {
    dueAt: milestone.dueAt,
    responsibleUser: milestone.responsibleUser,
    responsibleCompany: milestone.responsibleCompany,
    completedAt: milestone.completedAt,
    completerUserId: milestone.completerUserId,
  };

  state.milestones.items[milestoneIndex] = {
    ...state.milestones.items[milestoneIndex],
    ...updatedMilestone,
  };
});
