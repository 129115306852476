import { thunk } from 'easy-peasy';
import { DateTime } from 'luxon';
import { feathers } from '@webfx/core-web';

export const onRestoreDeletedProject = thunk(
  async ({ setDeletedProjectList }, { values, param, setRestore }, { getStoreActions }) => {
    const { common } = getStoreActions();
    try {
      await Promise.all(
        values.map((id) =>
          feathers()
            .service('tw/projects')
            .patch(
              Number(id),
              {
                deletedAt: null,
                deleterUserId: null,
                deletedReason: null,
              },
              {
                query: {
                  $ignoreDeletedAt: true,
                },
              }
            )
        )
      );

      const res = await feathers()
        .service('tw/projects')
        .find({
          query: {
            deletedAt: { $gte: DateTime.utc().minus({ days: 30 }).toJSDate() },
            $ignoreDeletedAt: true,
            $select: ['projectId', 'name', 'deletedAt', 'encrypted', 'deleterUserId'],
            $sort: { deletedAt: -1 },
            $join: {
              deleter: {
                $select: ['userId', 'firstName', 'lastName'],
              },
            },
            ...param,
          },
        });

      setRestore({});
      setDeletedProjectList(res);
    } catch (error) {
      common.showError(error);
    }
  }
);
