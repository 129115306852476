import { useFlags } from '@webfx/web-hooks';
import classnames from 'classnames';

import QuarterlyGrowthPlan from '../QuarterlyGrowthPlan';
import OptimizationKanban from '../OptimizationKanban';
import ActivityLog from '../ActivityLog';
import LatestReports from '../LatestReports';
import ExpertTeam from '../ExpertTeam';

const GamePlan = ({
  project,
  companyId,
  companyUsers,
  forPlaybook,
  forUserProjects,
  pbShowGoals,
  pbShowKanban,
}) => {
  const flags = useFlags();

  const showQuarterlyGrowthPlan =
    flags.teamworkGoals &&
    ((project && project.quarterlyGrowthGamePlan) ||
      (!project && forUserProjects) ||
      (forPlaybook && pbShowGoals));

  const showOptimizationKanban =
    flags.teamworkOptimizations &&
    ((project && project?.upcomingGoalProgressingActionItemsKanban) ||
      (!project && forUserProjects) ||
      (forPlaybook && pbShowKanban));

  return (
    <>
      {showQuarterlyGrowthPlan && (
        <QuarterlyGrowthPlan
          companyId={companyId}
          companyUsers={companyUsers}
          forPlaybook={forPlaybook}
        />
      )}
      {showOptimizationKanban && (
        <OptimizationKanban
          companyId={companyId}
          forUserProjects={forUserProjects}
          companyUsers={companyUsers}
          forPlaybook={forPlaybook}
        />
      )}
      {flags.gameplanfxUpdates && (
        <div
          className={classnames('d-flex flex-column', {
            'bg-white px-3': forPlaybook,
          })}
        >
          <LatestReports companyId={companyId} />
          <ActivityLog companyId={companyId} />
          <ExpertTeam companyId={companyId} />
        </div>
      )}
    </>
  );
};
export default GamePlan;
