import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const timezones = feathersClient.service('tw/timezones');

/**
 *
 * @param params
 */
export const find = (params) => timezones.find(paramsForServer(params));
