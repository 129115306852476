import { api } from '@webfx/core-web';

const handleRemove = ({ _id, previousData, queryClient, queryKey }) => {
  const { data, ...rest } = previousData;
  const filteredData = data.filter((data) => {
    if (data.uploadId != _id) {
      return data;
    }
  });

  queryClient.setQueryData(queryKey, () => {
    return { data: filteredData, ...rest };
  });
};

const handleUpdate = ({ _id, upload, queryClient, queryKey }) => {
  queryClient.setQueryData(queryKey, (uploadsData) => {
    const { data } = uploadsData;
    delete uploadsData.data;
    const index = data.findIndex((item) => item.uploadId == _id);

    if (index === 0 || index) {
      data[index] = {
        ...data[index],
        ...upload,
      };
    }

    return {
      ...uploadsData,
      data,
    };
  });
};

const handleOnMutate = ({ upload, queryClient, queryKey, previousData }) => {
  const { _method, _id } = upload;
  if (_method == 'DELETE' && _id) {
    handleRemove({ previousData, _id, queryClient, queryKey });
  }
  if (_method == 'PATCH' && _id) {
    handleUpdate({ upload, _id, queryClient, queryKey, previousData });
  }
};
export default (options = null) => {
  return api.useMutation(['uploads'], {
    onMutate: async (data) => {
      if (options) {
        const { queryKey, queryClient } = options;
        await queryClient.cancelQueries(queryKey);
        const previousData = queryClient.getQueryData(queryKey);

        handleOnMutate({ upload: data, queryClient, queryKey, previousData });
        return { previousData };
      }
    },
  });
};
