import { api } from '@webfx/core-web';

const useProjects = (companyId) => {
  return api.useQuery(
    [
      'tw/projects',
      {
        query: {
          companyId,
          status: 'active',
          $limit: -1,
        },
      },
    ],
    {
      select: (data) => data.data ?? data ?? [],
      placeholderData: [],
      enabled: !!companyId,
    }
  );
};

export default useProjects;
