import { thunk } from 'easy-peasy';
import { feathers } from '@webfx/core-web';

export const onUpdateFile = thunk(
  async (
    { updateFile },
    { values, uploadId, dirty, setSubmitting, onClosePopup },
    { getStoreActions }
  ) => {
    const { common } = getStoreActions();
    try {
      if (dirty) {
        const file = await feathers()
          .service('tw/attachments')
          .patch(uploadId, values, { for: 'projectFiles' });
        updateFile(file);
      }

      onClosePopup();
    } catch (e) {
      common.showError(e);
    }
    setSubmitting(false);
  }
);
