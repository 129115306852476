import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onDeleteMilestone = thunk(
  async (_, { milestoneId, closeModal }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'project.overview.calendar.deleteMilestone';
    showLoader({ key: loader });
    try {
      await api.milestones.remove(milestoneId);
      closeModal();
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
