import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const projectsCompanies = feathersClient.service('tw/projects-companies');

/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) =>
  projectsCompanies.create(payload, paramsForServer(params));
/**
 *
 * @param id
 */
export const remove = (id) => projectsCompanies.remove(id);
