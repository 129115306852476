import { onAddCompanyToProject } from './onAddCompanyToProject';
import { onGiveAccess } from './onGiveAccess';
import { onRemoveAccess } from './onRemoveAccess';
import { onRemoveAccessForAll } from './onRemoveAccessForAll';
import { onRemoveCompanyFromProject } from './onRemoveCompanyFromProject';
import { onUpdateUserPermission } from './onUpdateUserPermission';
import { onCreateUserWithParams } from './onCreateUserWithParams';
import { onGiveAccessYourCompany } from './onGiveAccessYourCompany';
import { onRemoveAccessYourCompany } from './onRemoveAccessYourCompany';
import { onRemoveAccessForAllYourCompany } from './onRemoveAccessForAllYourCompany';
import { onMountChangePermissions } from './onMountChangePermissions';
import { onGiveAccessForAllYourCompany } from './onGiveAccessForAllYourCompany';
import { onGiveAccessForAll } from './onGiveAccessForAll';

export default {
  onAddCompanyToProject,
  onGiveAccess,
  onRemoveAccess,
  onRemoveAccessForAll,
  onRemoveCompanyFromProject,
  onUpdateUserPermission,
  onCreateUserWithParams,
  onGiveAccessYourCompany,
  onRemoveAccessYourCompany,
  onRemoveAccessForAllYourCompany,
  onMountChangePermissions,
  onGiveAccessForAll,
  onGiveAccessForAllYourCompany,
};
