import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

export const projectTemplateTodoLists = feathersClient.service('tw/project-template-todolists');

/**
 *
 * @param payload
 */
export const find = (payload) => projectTemplateTodoLists.find(paramsForServer(payload));
/**
 *
 * @param payload
 * @param params
 */
export const create = (payload, params) =>
  projectTemplateTodoLists.create(payload, paramsForServer(params));
/**
 *
 * @param id
 */
export const remove = (id) => projectTemplateTodoLists.remove(id);
/**
 *
 * @param id
 * @param body
 * @param params
 */
export const patch = (id, body, params) =>
  projectTemplateTodoLists.patch(id, body, paramsForServer(params));
