import { action } from 'easy-peasy';

import { toBool } from '../../../../../../utils-deprecated/toBool';
import { filterTodos } from '../helpers/filterTodos';
import { transformTodo } from '../helpers/transformTodo';
import { moveSidebarListToCompleted } from '../helpers/moveSidebarListToCompleted';
import { moveSidebarListToActive } from '../helpers/moveSidebarListToActive';

export const wsDragTodoToAnotherList = action(
  (
    state,
    { result: { todoId, sourceList = {}, targetList = {}, reorderedTodos }, todo: updatedTodo }
  ) => {
    const { todos } = state.todoList;

    if (sourceList.todolistId === state.todoList.todolistId) {
      state.sidebarLists = moveSidebarListToCompleted(state.todoList, state.sidebarLists, {
        todoList: sourceList,
      });
      todos.active = todos.active.filter((id) => id !== todoId);
      return delete todos.map[todoId];
    }

    if (targetList.todolistId === state.todoList.todolistId) {
      const newReorderedTodos = reorderedTodos.map((todo) => {
        // Need to change moved todo info (because we will get incorrect values from backend)
        if (todo.todoId === todoId) {
          return updatedTodo;
        }

        // Here we return value for not reordered item
        return todos.map[todo.todoId];
      });

      state.sidebarLists = moveSidebarListToActive(state.todoList, state.sidebarLists, {
        todoList: targetList,
      });
      const _todos = filterTodos({ data: newReorderedTodos, filters: state.filters }).map((todo) =>
        transformTodo(todo)
      );
      todos.active = _todos.filter((todo) => !toBool(todo.completedAt)).map((todo) => todo.todoId);
      todos.map = _todos.reduce((acc, todo) => ({ ...acc, [todo.todoId]: todo }), {});
    }
  }
);
