import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onPingTodoCreator = thunk(async (_, { todoId }, { getStoreActions }) => {
  const actions = getStoreActions();
  const { showError, showNotification } = actions.common;

  try {
    await api.pingTodoCreator.create({ todoId }, { for: 'sendNotification' });
    showNotification({ message: 'The email was sent' });
  } catch (e) {
    showError(e);
  }
});
