import formatSearchHit from '../../../../../utils-deprecated/formatSearchHit';

const getTeamworkFxSearchParams = ({ state, searchedValue: { hits, total }, clearItems }) => {
  const searchedParams = {
    items: {
      list: [...state.items.list],
      map: { ...state.items.map },
    },
    scrollParams: {
      ...state.scrollParams,
    },
  };

  if (clearItems || !hits.length) {
    searchedParams.items = {
      list: [],
      map: {},
    };

    searchedParams.scrollParams.skipCount = 0;
  }

  hits.forEach((hit) => {
    const formattedHit = formatSearchHit(hit);

    searchedParams.items.list.push(formattedHit.id);
    searchedParams.items.map[formattedHit.id] = formattedHit;
  });

  searchedParams.scrollParams.skipCount += hits.length;
  searchedParams.scrollParams.hasMoreData = total.value > searchedParams.scrollParams.skipCount;

  return searchedParams;
};

export default getTeamworkFxSearchParams;
