import { action } from 'easy-peasy';

import { moveSidebarListToActive } from '../helpers/moveSidebarListToActive';

export const deleteCompletedTodo = action((state, { todo, todo: { todoId } }) => {
  const { todos } = state.todoList;

  if (todos.map[todoId]) {
    todos.completed = todos.completed.filter((id) => id !== todoId);
    delete todos.map[todoId];
  }

  state.sidebarLists = moveSidebarListToActive(state.todoList, state.sidebarLists, todo);
});
