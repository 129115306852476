import { store } from '@webfx/core-web';
import { action } from 'easy-peasy';

import projects from './projects';
import todolists from './todolists';
import todos from './todos';
import messages from './messages';
import milestones from './milestones';

import legacyCommon from './legacy/common';
import legacyProjects from './legacy/projects';
import legacyFiles from './legacy/files';
import legacyCategories from './legacy/categories';
import legacyProjectTemplates from './legacy/projectTemplates';
import legacyPages from './legacy/pages';
import legacyPermissions from './legacy/permissions';
import legacyProject from './legacy/project';
import legacyTeamworkFx from './legacy/teamworkFx';
import legacyProjectTemplate from './legacy/projectTemplate';

// legacy stores to replace
store.addModel('common', legacyCommon);
store.addModel('projects', legacyProjects);
store.addModel('project', legacyProject);
store.addModel('projectTemplate', legacyProjectTemplate);
store.addModel('projectTemplates', legacyProjectTemplates);
store.addModel('permissions', legacyPermissions);
store.addModel('files', legacyFiles);
store.addModel('ui', legacyPages);
store.addModel('categories', legacyCategories);
store.addModel('teamworkFx', legacyTeamworkFx);

// Namespaced Store
store.addModel('tw', {
  // pass query client to old store for cache invalidation
  queryClient: null,
  setQueryClient: action((state, queryClient) => {
    state.queryClient = queryClient;
  }),

  projects,
  todolists,
  todos,
  messages,
  milestones,
});

export default store;
