import { action } from 'easy-peasy';

export const projectGiveAccessToCompany = action((state, { companyId, checked }) => {
  const company = state.sidebar.companies.map[companyId];

  company.checked = checked;
  company.projects.list.forEach((projectId) => {
    company.projects.map[projectId].checked = checked;
  });
});
