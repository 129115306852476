import { action } from 'easy-peasy';
import { getUserAndOtherCompanies } from '../helpers/getUserAndOtherCompanies';

export const setAllPeopleSearchedValueByText = action(
  (state, { companies, searchText, currentUserCompanyId }) => {
    const companiesInfo = getUserAndOtherCompanies(companies, currentUserCompanyId);

    state.companies = companiesInfo.companies;
    state.userCompany = companiesInfo.userCompany;

    state.skipCount = companies.length;
    state.hasMoreData = false;
    state.searchText = searchText;
  }
);
