import { action } from 'easy-peasy';

export const wsViewTodo = action((state, { todoId }) => {
  const { active, map } = state.todoLists;
  if (!todoId) {
    return;
  }

  const todolistId = active.find((_todolistId) => {
    const { todos } = map[_todolistId];
    return todos.active.some((id) => id === todoId) || todos.completed.some((id) => id === todoId);
  });
  if (!todolistId) {
    return;
  }

  const todo = map[todolistId].todos.map[todoId];
  if (todo) {
    todo.hasNewComments = false;
  }
});
