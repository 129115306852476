import { thunk } from 'easy-peasy';

import { getProjectTemplates } from './getProjectTemplates';

const updateCategories = (categories) => {
  categories.list.forEach((categoryId) => {
    categories.map[categoryId].checked = false;
  });
  return categories;
};

export const onShowAllCategories = thunk(
  async (
    { changeSidebarCategoryChecked, setTemplateProjectsItemsToStore },
    _,
    { getStoreState, getStoreActions }
  ) => {
    const { showLoader, hideLoader, showError } = getStoreActions().common;
    showLoader({ key: 'teamworkFx.templates.projects.categories.showAll' });

    const state = getStoreState();
    const categories = state.teamworkFx.templates.projects.sidebar;

    const updatedCategories = updateCategories(categories);

    try {
      changeSidebarCategoryChecked({ categories: updatedCategories, showAll: true });

      const stateAfterSettingSidebar = getStoreState();
      const { sidebar } = stateAfterSettingSidebar.teamworkFx.templates.projects;
      await getProjectTemplates(sidebar, setTemplateProjectsItemsToStore);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: 'teamworkFx.templates.projects.categories.showAll' });
  }
);
