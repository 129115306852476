import { toBool } from '../../../../../utils-deprecated/toBool';

export const getUserAndOtherCompanies = (companies, currentUserCompanyId) => {
  const userCompany = companies.find(
    (currentCompany) => currentCompany.companyId === currentUserCompanyId
  );

  if (toBool(userCompany)) {
    return {
      userCompany,
      companies: companies.filter(
        (currentCompany) => currentCompany.companyId !== currentUserCompanyId
      ),
    };
  }

  return {
    userCompany: null,
    companies,
  };
};
