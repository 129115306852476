import { thunk } from 'easy-peasy';
import { store } from '@webfx/teamwork-web/src/contexts/ProjectContext';

import api from '../../../../../services/api';

export const onSetAssigneeList = thunk(
  async ({ setAssigneeList, setAssignee }, _, { getStoreState }) => {
    const storeState = getStoreState();
    const projectId = store.get.projectId();
    const { searchAllProjects } = storeState.project.search.filters;

    if (searchAllProjects) {
      await api.projects
        .find({
          for: 'calendarAssigneesDropdownMainTab',
        })
        .then(({ data }) => {
          setAssigneeList({ assignees: data });
        });
    } else {
      setAssignee({ value: '' });
      return api.projects
        .get({
          selectedProjectId: projectId,
          projectId,
          params: { for: 'assigneesDropdown' },
        })
        .then(({ assignees }) => {
          setAssigneeList({ assignees });
        });
    }
  }
);
