import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';

export const onMountTeamworkFx = thunk(
  async (_, { fetchDataWithLogin, doNotShowLoaders } = {}, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader } = actions.common;
    const state = getStoreState();
    const { isFetchedData } = state.common.loader;
    const user = store.auth.user();

    if (isFetchedData) {
      hideLoader({ key: 'isFetchedData' });
      return;
    }

    // query a users project list
    actions.tw.projects.fetch({
      query: {
        status: 'active',
        $sort: { name: 1 },
        $join: ['owner', 'counts'],
        $limit: -1,
      },
    });

    if (!user.companies) {
      actions.users.get({
        userId: user.userId,
        query: {
          $select: ['userId'],
          $join: ['companies'],
        },
      });
    }

    showLoader({ key: 'globalLoader.onMountTeamworkFx' });

    if (!doNotShowLoaders) {
      showLoader({ key: 'onMountTeamworkFx' });
    }
    if (fetchDataWithLogin) {
      showLoader({ key: 'isFetchedData' });
    }

    if (!doNotShowLoaders) {
      hideLoader({ key: 'onMountTeamworkFx' });
    }
  }
);
