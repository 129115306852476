import api from '../../../../../services/api';
import getQueryForFiltering from '../helpers/getQueryForFiltering';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectTemplateSingleTodoLists = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectTemplateSingleTodoLists',
  action: async (
    actions,
    { projectTemplateId, projectTemplateTodoListId, pushParams },
    getStoreState
  ) => {
    const {
      setTemplateTodoListsIntoStore,
      setProjectTemplateSidebarLists,
      onMountProjectTemplateTodoLists,
    } = actions.projectTemplate.todoLists;

    const todoLists = await api.projectTemplateTodoLists.find({
      query: { projectTemplateId },
      for: 'onFindProjectTemplateTodolistsSidebar',
    });
    setProjectTemplateSidebarLists({
      data: todoLists,
      currentTemplateTodoListId: projectTemplateTodoListId,
    });

    if (todoLists.length) {
      const state = getStoreState();
      const query = getQueryForFiltering(
        state.projectTemplate.todoLists,
        projectTemplateId,
        projectTemplateTodoListId
      );

      const data = await api.projectTemplateTodoLists.find({
        query,
        for: 'projectTemplateTodoList',
      });

      if (!data.length) {
        onMountProjectTemplateTodoLists({ projectTemplateId, pushParams });
      }
      setTemplateTodoListsIntoStore(data);
    }

    return `/templates/projects/${projectTemplateId}/todo-lists/${projectTemplateTodoListId}`;
  },
});
