import { action } from 'easy-peasy';

export const wsRemoveProjectOverviewMilestone = action((state, { milestone, isOverdue }) => {
  state.milestones.items = state.milestones.items.filter(
    (item) => item.milestoneId !== milestone.milestoneId
  );
  state.milestones.overdue = state.milestones.overdue.filter(
    (item) => item.milestoneId !== milestone.milestoneId
  );
  if (isOverdue) {
    state.milestones.overdueCount -= 1;
  }
});
