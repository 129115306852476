import { toBool } from '../../../../../../utils-deprecated/toBool';

export const transformTodoListFields = ({ completed, private: _private, ...todoList }) => ({
  ...todoList,
  _private: toBool(_private),
  completed: toBool(completed),
});

export const transformTodoList = (todoList) => ({
  ...transformTodoListFields(todoList),
  $skip: 0,
  hasMore: false,
  isColorCoding: false,
  colorCodes: null,
  movedTo: null,
  todos: {
    active: [],
    completed: [],
    map: {},
  },
});
