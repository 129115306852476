import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onCheckConfirmation = thunk(
  async (
    { onCompleteTodo, checkConfirmation },
    { todolistId, todoId, toggleShowConfirmation },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = 'project.todos.todo.completeTodo';
    showLoader({ key: loader });

    try {
      const { firstComment } = await api.todos.get(todoId, {
        for: 'onGetCompleteTodoConfirmation',
      });

      if (firstComment.hasWarning) {
        checkConfirmation({ firstComment });
        toggleShowConfirmation(true);
        return hideLoader({ key: loader });
      }

      onCompleteTodo({ todolistId, todoId });
    } catch (e) {
      showError(e);
    }
  }
);
