import { useAuth, useFlags, useRouter } from '@webfx/web-hooks';
import { Icon, Link } from '@webfx/core-web';
import { useCompany } from '@webfx/web-hooks';

import styles from './GoalsHeader.module.css';

const GoalsHeader = ({ companyId }) => {
  const isAdmin = useAuth().isAdmin;
  const { url } = useRouter();
  const flags = useFlags();
  const { data: company } = useCompany(companyId);

  return (
    <>
      <div className="d-flex p-3 justify-content-between border-bottom">
        <div className="d-flex align-items-center">
          <span className={styles.headerSubtitle}>Digital Marketing Objectives</span>
          {!isAdmin && company && (
            <span>
              : <span className={styles.companyName}>{company?.name}</span>
            </span>
          )}
          <a
            href={
              flags?.helpcenterUpdates
                ? url({
                    route: 'helpCenterArticle',
                    categoryId: 'id4X6jpT',
                    categoryName: 'PlaybookFX',
                    articleId: 'T6RE7bac',
                    articleName: 'Current-Playbook',
                  })
                : `${url({ route: 'helpCenter' })}/tl1mtl1wd3-current-playbook`
            }
            className="d-flex align-items-center ml-4 font-14 text-blue-vivid-400 text-hover-blue-vivid-300 bold"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
            <Icon className="ml-1">arrow_forward</Icon>
          </a>
        </div>

        {isAdmin && (
          <div className="d-flex align-items-center">
            <Icon className={styles.lockIcon}>lock</Icon>
            <span className={styles.configText}>
              {`FXers: enable/disable this PlaybookFX feature or configure client settings in `}
              <Link
                className="bold"
                to={`/client-permissions/companies/edit/${companyId}`}
                plain={true}
              >
                Global Permissions
              </Link>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default GoalsHeader;
