import { action } from 'easy-peasy';

export const setProjectsForSwitch = action((state, { projectsForSwitch, selectedProjectId }) => {
  state.projectsForSwitch = projectsForSwitch.reduce(
    (prev, { name: projectName, projectId, company: { name: companyName } }) => {
      if (projectId !== Number(selectedProjectId)) {
        prev.push({ label: projectName, value: projectId, companyName });
      }

      return prev;
    },
    []
  );
});
