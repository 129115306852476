import { action } from 'easy-peasy';

export const moveTodo = action((state, { todoId, movedTodo: { todoList } }) => {
  state.todoList.todos.map[todoId].movedTo = {
    project: {
      newProjectId: todoList.project.projectId,
      newProjectName: todoList.project.name,
    },
    todoList: {
      newTodolistId: todoList.todolistId,
      newTodoListName: todoList.name,
    },
  };
});
