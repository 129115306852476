import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { useActiveSite } from '@webfx/web-hooks';
import IntegrationSetupModal from './IntegrationSetupModal';

const useIntegrationSetupModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { crmIntegrated, isFetched } = useActiveSite({ $join: ['crmIntegrated'] });
  const filterDate = DateTime.local().minus({ days: 90 }).toISODate();

  useEffect(() => {
    if (
      isFetched &&
      (!crmIntegrated?.isIntegrated || crmIntegrated?.latestIntegrationDate < filterDate)
    ) {
      setShowModal(true);
    }
  }, [crmIntegrated, isFetched]);

  return {
    showModal,
    setShowModal,
    IntegrationSetupModal,
  };
};

export default useIntegrationSetupModal;
