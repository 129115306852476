import { action } from 'easy-peasy';
import getTeamworkFxSearchParams from '../helpers/getTeamworkFxSearchParams';

export const setTeamworkFxSearchedValueByText = action(
  (
    state,
    {
      searchedValue: {
        body: { hits },
      },
      search,
    }
  ) => {
    state.filters.lastSearched = search;
    state.filters.isFiltered = true;
    state.filters.pageIndex = 0;

    const searchedParams = getTeamworkFxSearchParams({
      state,
      searchedValue: hits,
      clearItems: true,
    });
    state.items.list = searchedParams.items.list;
    state.items.map = searchedParams.items.map;
    state.items.total = hits.total.value;

    state.scrollParams.skipCount = searchedParams.scrollParams.skipCount;
    state.scrollParams.hasMoreData = searchedParams.scrollParams.hasMoreData;
  }
);
