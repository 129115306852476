import { thunk, debug } from 'easy-peasy';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';

export const onReorderTodoLists = thunk(
  async (
    { reorderTodoList, onLoadMoreTodos }, // actions
    { todoListsOrder, toggleReorderMode, setTodoListsOrder }, // payload
    { getStoreActions, getStoreState } // helpers
  ) => {
    // Don't call reorder thunk if we didn't change lists order
    if (todoListsOrder.length === 0) {
      return toggleReorderMode(false);
    }

    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();

    const { todoLists, filters } = state.project.todos.todoLists;
    const { data: project } = useProject();
    const projectId = project?.projectId;

    const loader = 'project.todos.reorderTodoLists';
    showLoader({ key: loader });

    const reorderedTodoLists = todoListsOrder.map((todolist, index) => ({
      todolistId: todolist.todolistId,
      position: index,
      checked: todolist.checked,
      projectId: todolist.projectId || projectId,
    }));

    try {
      await api.todoLists.patch(null, reorderedTodoLists, {
        for: 'onReorderTodoLists',
        $skipSafeMulti: true,
      });

      reorderTodoList({ reorderedTodoLists });

      await onLoadMoreTodos(); // call load next

      toggleReorderMode(false);
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
