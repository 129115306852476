import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onDeleteTeamworkFxTemplateTodo = thunk(
  async (
    { deleteTeamworkFxTemplateTodo },
    { todolistTemplateTodoId, queryClient },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'teamworkFx.templates.todoLists.list.deleteTodo';
    showLoader({ key: loader });
    try {
      const data = await api.todoListTemplateTodos.remove(todolistTemplateTodoId);
      queryClient?.invalidateQueries('tw/todolist-templates');
      deleteTeamworkFxTemplateTodo(data);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
