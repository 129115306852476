import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { transformTodoListFields } from '../helpers/transformTodoList';

export const editTodoList = action((state, { todoList }) => {
  const { todoLists } = state;
  const { todolistId } = todoList;

  todoLists.map[todolistId] = merge(todoLists.map[todolistId], transformTodoListFields(todoList));
});
