import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'react-bootstrap';

const TypeFiltersV1 = ({ setFilter, filters }) => {
  const [types, setTypes] = useState(filters?.type?.$in ?? []);

  const setTypesToFilter = (kanbanTypes) => {
    for (const type of kanbanTypes) {
      const index = types.indexOf(type);
      if (index !== -1) {
        types.splice(index, 1);
      } else {
        types.push(type);
      }
    }
    setTypes([...types]);
  };

  return (
    <>
      <div className="d-flex flex-column mt-3">
        <span className="font-14 bold mb-1">By Type</span>
        <div className="d-flex flex-column border rounded p-2">
          <Form.Check
            type="checkbox"
            id="asset"
            name="asset"
            label="Assets"
            checked={isEmpty(types) || types.includes('asset')}
            onClick={() => {
              setTypesToFilter(['asset']);
              setFilter({ type: { $in: types } });
            }}
            onChange={() => {}}
            className="font-14 text-dark-gray"
            custom
          />

          <Form.Check
            type="checkbox"
            id="todo"
            name="todo"
            label="Optimizations"
            checked={isEmpty(types) || types.includes('todo')}
            onClick={() => {
              setTypesToFilter(['todo', 'milestone']);
              setFilter({ type: { $in: types } });
            }}
            onChange={() => {}}
            className="font-14 text-dark-gray"
            custom
          />
        </div>
      </div>
    </>
  );
};

export default TypeFiltersV1;
