import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onShowTodosColorCodes = thunk(
  async ({ showTodosColorCodes }, { todolistId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError, showLoader, hideLoader } = actions.common;

    const loader = `project.todos.todoLists.colorCoding.${todolistId}`;
    showLoader({ key: loader });

    try {
      const todos = await api.todos.find({
        query: {
          todolistId,
          $select: ['todoId'],
          $limit: -1,
        },
      });

      const todoIds = todos.map(({ todoId }) => todoId);
      const colorCodes = await api.colorCodes.colorCodes(todoIds);

      showTodosColorCodes({ todolistId, colorCodes });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
