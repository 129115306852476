import { thunk } from 'easy-peasy';
// import api from '../../../../../services/api';

export const onUnMountProject = thunk(
  async ({ removeSelectedProject }, _, { getStoreState, getStoreActions }) => {
    // const state = getStoreState();
    // const projectId = state.tw.projects.viewingId;

    const actions = getStoreActions();
    const { showError } = actions.common;
    // const { resetMessagesStore } = actions.project.messages;
    // const { resetProjectTodos } = actions.project.todos;

    try {
      // if (projectId) {
      //   api.channels.patch({ projectId, subscribe: false }, { for: 'subscribeToProject' });
      // }
      removeSelectedProject();
      // resetMessagesStore();
      // resetProjectTodos();
    } catch (e) {
      showError(e);
    }
  }
);
