import { action } from 'easy-peasy';

import {
  deleteItem,
  deleteFromTodoList,
  deleteFromProject,
  deleteFromCompany,
} from '../helpers/completeItem';

export const completeTodo = action((state, { todoId }) => {
  const todo = state.todoItems[todoId];

  const { todolistId, projectId, project } = todo.todolist;
  const { companyId } = project.company;

  const functionsForCompleting = [
    deleteItem,
    deleteFromTodoList,
    deleteFromProject,
    deleteFromCompany,
  ];

  return functionsForCompleting.reduce((values, currentFunction) => {
    return currentFunction({ state: values, todoId, todolistId, projectId, project, companyId });
  }, state);
});
