import { store } from '@webfx/web-state';
import { computed } from 'easy-peasy';
import { setAccessLevel } from '../../../../utils-deprecated/changePermissions';
import changePermissionsActions from './actions';
import changePermissionsThunks from './thunks';

// TODO: refactor as user based and all computed to create company clusters
// TODO: deep embeded states here make updates challenging.

const changePermissions = {
  project: { projectCompanies: [] },

  // This builds a mapping to each user id inside of the embbed companies
  // used inside of permission updates.
  userMap: computed([(state) => state], (state) => {
    const userCompanies = store.auth.user()?.companies || [];
    return state.project.projectCompanies
      .filter((id) => userCompanies.findIndex((i) => i === id.companyId) === -1)
      .reduce((userMap, company, companyIndex) => {
        company.users.forEach((u, userIndex) => {
          if (!userMap[u.userId]) {
            userMap[u.userId] = [];
          }
          userMap[u.userId].push({ company: companyIndex, user: userIndex });
        });
        return userMap;
      }, {});
  }),

  other: computed([(state) => state], (state) => {
    const userCompanies = store.auth.user()?.companies || [];

    return state.project.projectCompanies
      .filter((id) => userCompanies.findIndex((i) => i === id.companyId) === -1)
      .reduce(
        (accumulator, current) => {
          const usersIds = current.users.reduce((acc, cur) => {
            accumulator.users[cur.userId] = {
              firstName: cur.firstName,
              lastName: cur.lastName,
              avatarUrl: cur.avatarUrl,
              type: cur.type,
              projectUserId: cur.projects?.length
                ? cur.projects[0].projectsUsers.projectUserId
                : null,
              accessLevel: setAccessLevel(
                cur.projects?.length ? cur.projects[0].projectsUsers.accessLevel : ''
              ),
            };
            acc.push(cur.userId);
            return acc;
          }, []);

          if (!accumulator.companies.list.includes(current.companyId)) {
            accumulator.companies.list.push(current.companyId);
          }

          accumulator.companies.map[current.companyId] = {
            name: current.name,
            users: usersIds,
            projectCompanyId:
              current.projectCompanyId || current.projectsCompanies.projectCompanyId,
          };
          return accumulator;
        },
        { companies: { list: [], map: {} }, users: {} }
      );
  }),
  yourCompany: computed([(state) => state], (state) => {
    const userCompanies = store.auth.user()?.companies || [];

    return state.project.projectCompanies
      .filter((id) => userCompanies.findIndex((i) => i === id.companyId) !== -1)
      .reduce(
        (accumulator, current) => {
          const usersIds = current.users.reduce((acc, cur) => {
            accumulator.users[cur.userId] = {
              name: `${cur.firstName} ${cur.lastName}`,
              projectUserId: cur.projectsAccess.length
                ? cur.projects[0].projectsUsers.projectUserId
                : null,
              hasAccess: !!cur.projects[0].projectsUsers.length,
              ...cur,
            };
            acc.push(cur.userId);
            return acc;
          }, []);

          accumulator.company = {
            companyId: current.companyId,
            name: current.name,
            usersIds,
            projectCompanyId: current.projectsCompanies.projectCompanyId,
          };
          return { ...accumulator };
        },
        { company: {}, users: {} }
      );
  }),

  // actions
  ...changePermissionsActions,
  // thunks
  ...changePermissionsThunks,
};

export default changePermissions;
