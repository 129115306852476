import get from 'lodash/get';
import { DateTime } from 'luxon';

// filter event which dueAt startAt, endAt dates are correspond to from|to date interval
const filterCalendarEventByDates = ({ startAt, endAt }, dates) => {
  const from = get(dates, 'from', null);
  const to = get(dates, 'to', null);
  let eventStartAt = DateTime.fromISO(startAt);
  let eventEndAt = DateTime.fromISO(endAt);

  if (!eventStartAt.isValid || !eventEndAt.isValid) {
    return false;
  }

  eventStartAt = eventStartAt.toFormat('yyyy-LL-dd');
  eventEndAt = eventEndAt.toFormat('yyyy-LL-dd');
  return (from <= eventStartAt && eventStartAt <= to) || (from <= eventEndAt && eventEndAt <= to);
};

export default filterCalendarEventByDates;
