import { action } from 'easy-peasy';

export const setTemplateProjectsItemsToStore = action((state, { data, hasMore, nextPage }) => {
  state.items.list = [];
  state.items.map = {};

  data.forEach((currentTemplate) => {
    state.items.list.push(currentTemplate.projectTemplateId);
    state.items.map[currentTemplate.projectTemplateId] = currentTemplate;
  });

  state.pagination.currentPage = nextPage;
  state.pagination.hasMore = hasMore;
});
