import set from 'lodash/set';
import { toBool } from '../../../../../../utils-deprecated/toBool';
import { transformTodoList } from './transformTodoList';

const setTodoList = (acc, todoList) => {
  const { todolistId, completed } = todoList;

  toBool(completed) ? acc.completed.push(todolistId) : acc.active.push(todolistId);
  acc.map[todolistId] = transformTodoList({ todoList });

  return acc;
};

export const setTodoLists = (todoLists, checkedLists) => {
  const _todoLists = todoLists.reduce(setTodoList, {
    active: [],
    completed: [],
    map: {},
  });

  checkedLists.forEach((todolistId) => {
    set(_todoLists, ['map', todolistId, 'checked'], true);
  });

  return _todoLists;
};
