import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getCheckedLists } from '../helpers/getCheckedLists';
import { getFilterParams } from '../helpers/getFilterParams';
import { hasFiltersSet } from '../helpers/hasFiltersSet';

export const onFilterTodosByAssignee = thunk(
  async (
    { filterTodosByAssignee, setLoadedTodoLists },
    { assignee },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { todoLists, filters } = state.project.todos.todoLists;

    const loaders = [
      'project.todos.todoLists.sidebar.assignee',
      'project.todos.todoLists.sidebar.hasAnyLoading',
    ];
    showLoader({ key: loaders });

    const filter = getFilterParams({ ...filters, assignee });
    const checkedLists = getCheckedLists({ todoLists });
    const hasFilters = hasFiltersSet(filter);

    // if we are going back to no filters we reset our loaded list
    // to perform lazy loading
    if (!hasFilters) {
      setLoadedTodoLists([]);
    }

    try {
      const todosBag = await api.todos.find({
        query: {
          todolistId: { $in: hasFilters ? checkedLists : checkedLists.slice(0, 2) },
          completedAt: 'NULL',
          $limit: projectCurrentTodosLimit,
        },
        filter,
        for: 'onFindProjectTodos',
      });

      filterTodosByAssignee({ todosBag, assignee });
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loaders });
  }
);
