import { action } from 'easy-peasy';

export const clearEventsMilestonesState = action((state) => {
  state.events = {
    items: [],
    select: null,
  };

  state.milestones = {
    items: [],
    select: null,
    overdue: [],
    hasMoreData: false,
    skipCount: 0,
    overdueCount: 0,
  };
});
