import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { getRequestInfo } from '../helpers/getTodoData';

/* eslint-disable prefer-destructuring */
export const onEditTodo = thunk(
  async (
    _,
    { values, setSubmitting = () => null, onCloseForm = () => null, todoId, todolistId },
    { getStoreActions }
  ) => {
    const a = getStoreActions();
    const showError = a.common.showError;
    const editTodo = a.project.todos.todoLists.editTodo;

    try {
      const todo = await api.todos.patch(todoId, getRequestInfo(values), { for: 'onEditTodo' });
      editTodo({ todolistId, todoId, todo });
      onCloseForm();
    } catch (e) {
      showError(e);
    }

    setSubmitting(false);
  }
);
