import { action } from 'easy-peasy';
import { setSidebarForMultiples, setSidebarForSingle } from '../helpers/merge';

export const setProjectTemplateSidebarLists = action(
  (state, { data, currentTemplateTodoListId }) => {
    currentTemplateTodoListId
      ? setSidebarForSingle(state, data, Number(currentTemplateTodoListId))
      : setSidebarForMultiples(state, data);
  }
);
