const useFilterOptimizationsByCompany = (queries, companyIds) => {
  const filteredQueries = queries;

  for (let j = 0; j < filteredQueries.data.length; j++) {
    if (
      companyIds.includes(filteredQueries.data[j].companyId) &&
      filteredQueries.data[j].type !== 'asset'
    ) {
      filteredQueries.data.splice(j, 1);
      j--;
    }
  }

  return filteredQueries;
};

export default useFilterOptimizationsByCompany;
