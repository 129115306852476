import { action } from 'easy-peasy';

export const addProjectTemplateList = action((state, payload) => {
  const { projectTemplateTodoListId } = payload;

  state.lists.list.unshift(projectTemplateTodoListId);
  state.lists.map[projectTemplateTodoListId] = payload;
  state.sidebarLists.list.unshift(projectTemplateTodoListId);
  state.sidebarLists.map[projectTemplateTodoListId] = { ...payload, checked: true };

  if (payload.projectTemplateTodos) {
    const idsOfTodos = payload.projectTemplateTodos.map((item) => {
      const { projectTemplateTodoId } = item;

      state.todoItems[projectTemplateTodoId] = item;
      return projectTemplateTodoId;
    });
    state.lists.map[projectTemplateTodoListId].projectTemplateTodos = idsOfTodos;
  }
});
