import classnames from 'classnames';

import { Icon, Link, Svg } from '@webfx/core-web';
import { useAuth, useRouter, useCan } from '@webfx/web-hooks';

import boltSVG from '@webfx/teamwork-web/src/assets/images/bolt_orange.svg';
import directorsTopPick from '@webfx/teamwork-web/src/assets/images/directorsTopPick.svg';
import WriteIcon from '@webfx/teamwork-web/src/assets/images/write-icon.svg';

import GoalDetail from '../../GoalDetail';

import styles from './OptimizationCard.module.css';

const priorityList = {
  'director’s-top-pick': {
    icon: directorsTopPick,
    backgroundColor: 'orange',
  },
  'sme-top-pick': {
    icon: boltSVG,
    backgroundColor: '#AF3FAC',
  },
};

let isPrivateOptimization;
let canReadProject = true;

/**
 *
 * @param root0
 * @param root0.projectOptimization
 */
const OptimizationCardV1 = ({ projectOptimization }) => {
  const { siteId, objectId, projectId, type, private: isPrivate } = projectOptimization;
  const buildUrl = (type) => {
    const urls = {
      asset: {
        route: 'cgfxContentPlanner',
        page: 'content-planner',
        siteId,
        assetId: objectId,
      },
      milestone: {
        route: 'milestone',
        milestoneId: `${objectId}`,
      },
      todo: {
        route: 'todo',
        todoId: `${objectId}`,
      },
    };

    return urls[type];
  };

  const { url } = useRouter();
  const userType = useAuth().user.type;

  canReadProject = useCan('read', 'project', {
    projectId: projectOptimization?.project?.projectId,
    companyId: projectOptimization?.project?.companyId,
  });
  isPrivateOptimization = userType === 'other' && isPrivate === '1';

  if ((projectId && !canReadProject) || isPrivateOptimization || type === 'undefined') {
    return <Card projectOptimization={projectOptimization} />;
  }

  return (
    <>
      <Link to={url(buildUrl(type))} plain={true}>
        <Card projectOptimization={projectOptimization} />
      </Link>
    </>
  );
};

const Card = ({ projectOptimization }) => {
  return (
    <div
      className={classnames(
        'bg-white card mb-2',
        projectOptimization.completedAt ? styles.completedCard : styles.card,
        { [styles.cardAsset]: projectOptimization.asset },
        {
          [styles.pointer]: canReadProject && !isPrivateOptimization,
        }
      )}
    >
      {projectOptimization.completedAt ? (
        <div
          className={classnames(
            'd-flex justify-content-center align-items-center',
            styles.completedSection
          )}
        >
          <div className={classnames('d-flex', styles.completedText)}>
            <Icon>check_circle</Icon> Completed!
          </div>
        </div>
      ) : null}

      <div className="p-3">
        {projectOptimization.asset ? (
          <div className={classnames('d-flex', styles.assetType)}>
            <Svg src={WriteIcon} />
            <p className="text-uppercase ml-1">{projectOptimization.asset.assetType}</p>
          </div>
        ) : null}
        <div className="d-flex">
          <span className={styles.optimizationName}>
            {projectOptimization?.name?.split(' > ').splice(-1)?.[0]}
          </span>
        </div>

        {projectOptimization?.project ? (
          <div className="d-flex mt-2">
            <span className={styles.projectName}>{projectOptimization?.project?.name}</span>
          </div>
        ) : null}

        <div className="d-flex mt-2">
          {projectOptimization.directorPick === 1 ? (
            <span
              className="badge badge-secondary font-12 mr-2"
              style={{
                color: '#FFFFFF',
                background: priorityList['director’s-top-pick'].backgroundColor,
              }}
            >
              <Svg
                className="mr-1"
                svgStyle={{ width: '10px' }}
                component={'span'}
                src={priorityList['director’s-top-pick'].icon}
              />
              Director’s Top Pick
            </span>
          ) : null}

          {projectOptimization.smePick === 1 ? (
            <span
              className="badge badge-secondary font-12"
              style={{
                color: '#FFFFFF',
                background: priorityList['sme-top-pick'].backgroundColor,
              }}
            >
              <Svg
                className="mr-1"
                svgStyle={{ width: '10px' }}
                component={'span'}
                src={priorityList['sme-top-pick'].icon}
              />
              SME Top Pick
            </span>
          ) : null}
        </div>
        <div className="d-flex">{<GoalDetail goal={projectOptimization.goal} />}</div>
      </div>
    </div>
  );
};

export default OptimizationCardV1;
