import { Icon } from '@webfx/core-web';
import { useFlags, useCompany } from '@webfx/web-hooks';

import OptimizationFilters from '../../OptimizationFilters';
import KanbanQuarterSelector from '../../KanbanQuarterSelector';

const Header = ({ companyId, setQuarter, year, quarter, filters, setFilter, resetFilters }) => {
  const flags = useFlags();
  const isGameplanfxUpdates = flags.gameplanfxUpdates;
  const { data: company } = useCompany(companyId);

  return (
    <div className="d-flex justify-content-between align-items-center bg-blue-100 px-3 py-4">
      {isGameplanfxUpdates && company ? (
        <h2 className="font-20 text-gray-700 bold" data-fx-name="label">
          Strategy for {company.name}
        </h2>
      ) : (
        <>
          <h2 className="font-20 text-gray-700 bold">
            This Quarter&apos;s Evergreen Assets and Tactics
          </h2>
          <div className="d-flex align-items-center font-12 font-weight-semibold text-gray-550">
            <Icon className="font-16 text-blue-200">help</Icon>
            <span className="ml-1">
              To provide feedback on items below, double click a card to open the Comment view.
            </span>
          </div>
        </>
      )}

      <div className="d-flex">
        <OptimizationFilters
          companyId={companyId}
          quarter={quarter}
          filters={filters}
          setFilter={setFilter}
          resetFilters={resetFilters}
        />
        <div className="ml-3">
          <KanbanQuarterSelector
            setQuarter={setQuarter}
            quarter={quarter}
            year={year}
            companyId={companyId}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
