const getUserAssignee = (assigneeInfo) => {
  const userId = assigneeInfo.value.responsibleUserId;
  return {
    user: {
      userId,
      firstName: assigneeInfo.firstName,
      lastName: assigneeInfo.lastName,
      avatarUrl: assigneeInfo.avatarUrl,
    },
    company: undefined,
    responsibleCompanyId: undefined,
    responsibleUserId: userId,
    assignee: {
      avatarUrl: assigneeInfo.avatarUrl,
      name: `${assigneeInfo.firstName} ${assigneeInfo.lastName}`,
    },
  };
};

const getCompanyAssignee = (assigneeInfo) => {
  const companyId = assigneeInfo.value.responsibleCompanyId;
  return {
    user: undefined,
    company: {
      companyId,
      name: assigneeInfo.label,
      logoUrl: assigneeInfo.avatarUrl,
    },
    responsibleCompanyId: companyId,
    responsibleUserId: null,
    assignee: {
      avatarUrl: assigneeInfo.avatarUrl,
      name: assigneeInfo.label,
    },
  };
};

export const getAssigneeInfo = (assigneeInfo) => {
  const { responsibleCompanyId, responsibleUserId } = assigneeInfo.value;

  if (responsibleUserId !== undefined) {
    return getUserAssignee(assigneeInfo);
  }
  if (responsibleCompanyId !== undefined) {
    return getCompanyAssignee(assigneeInfo);
  }

  return {
    user: undefined,
    company: undefined,
    responsibleCompanyId: undefined,
    responsibleUserId: undefined,
    assignee: undefined,
  };
};
