import initState from './initState';
// actions - general
import { mountProjectTodoLists } from './actions/mountProjectTodoLists';
import { loadMoreTodos } from './actions/loadMoreTodos';
import { resetState } from './actions/resetState';
import { resetTodosFilters } from './actions/resetTodosFilters';
// actions - todolist
import { mountCreateTodoListForm } from './actions/mountCreateTodoListForm';
import { createTodoList } from './actions/createTodoList';
import { createTodoListByTemplate } from './actions/createTodoListByTemplate';
import { editTodoList } from './actions/editTodoList';
import { moveTodoList } from './actions/moveTodoList';
import { moveTodoToList } from './actions/moveTodoToList';
import { deleteTodoList } from './actions/deleteTodoList';
import { deleteMovedTodoList } from './actions/deleteMovedTodoList';
import { showTodosColorCodes } from './actions/showTodosColorCodes';
import { hideTodosColorCodes } from './actions/hideTodosColorCodes';
import { reorderTodoList } from './actions/reorderTodoList';
import { setOpenedAssigneeForm } from './actions/setOpenedAssigneeForm';
// actions - todos
import { createTodo } from './actions/createTodo';
import { editTodo } from './actions/editTodo';
import { editTodoAssignee } from './actions/editTodoAssignee';
import { editTodoPriority } from './actions/editTodoPriority';
import { completeTodo } from './actions/completeTodo';
import { unCompleteTodo } from './actions/unCompleteTodo';
import { reorderTodo } from './actions/reorderTodo';
import { dragTodoToAnotherList } from './actions/dragTodoToAnotherList';
import { updateTodosPositions } from './actions/updateTodosPositions';
import { deleteActiveTodo } from './actions/deleteActiveTodo';
import { deleteCompletedTodo } from './actions/deleteCompletedTodo';
import { deleteMovedTodo } from './actions/deleteMovedTodo';
import { setLoadedTodoLists } from './actions/setLoadedTodoLists';
// actions - sidebar
import { filterTodosByAssignee } from './actions/filterTodosByAssignee';
import { filterTodosByDueDate } from './actions/filterTodosByDueDate';
import { filterTodosBySearch } from './actions/filterTodosBySearch';
import { showSidebarAllTodoLists } from './actions/showSidebarAllTodoLists';
import { hideSidebarAllTodoLists } from './actions/hideSidebarAllTodoLists';
import { toggleSidebarTodoList } from './actions/toggleSidebarTodoList';
import { moveSidebarList } from './actions/moveSidebarList';
// actions - move todo
import { setMoveTodoProjects } from './actions/setMoveTodoProjects';
import { setMoveTodoTodoLists } from './actions/setMoveTodoTodoLists';
import { moveTodo } from './actions/moveTodo';
import { resetMoveTodoTodoLists } from './actions/resetMoveTodoTodoLists';
// actions - ws - to-do list
import { wsReorderTodoLists } from './ws/wsReorderTodoLists';
// actions - ws - todos
import { wsReorderTodo } from './ws/wsReorderTodo';
import { wsDragTodoToAnotherList } from './ws/wsDragTodoToAnotherList';
import { wsCreateComment } from './ws/wsCreateComment';
import { wsDeleteComment } from './ws/wsDeleteComment';
import { wsViewTodo } from './ws/wsViewTodo';

/* ************************************************** THUNKS ******************************************************** */

// thunks - general
import { onMountProjectTodoLists } from './thunks/onMountProjectTodoLists';
import { onLoadMoreTodos } from './thunks/onLoadMoreTodos';

// thunks - todolist
import { onMountCreateTodoListForm } from './thunks/onMountCreateTodoListForm';
import { onCreateTodoList } from './thunks/onCreateTodoList';
import { onCreateTodoListByTemplate } from './thunks/onCreateTodoListByTemplate';
import { onEditTodoList } from './thunks/onEditTodoList';
import { onMoveTodoList } from './thunks/onMoveTodoList';
import { onDeleteTodoList } from './thunks/onDeleteTodoList';
import { onShowTodosColorCodes } from './thunks/onShowTodosColorCodes';
import { onReorderTodoLists } from './thunks/onReorderTodoLists';
// thunks - todos
import { onCreateTodo } from './thunks/onCreateTodo';
import { onEditTodo } from './thunks/onEditTodo';
import { onEditTodoAssignee } from './thunks/onEditTodoAssignee';
import { onEditTodoPriority } from './thunks/onEditTodoPriority';
import { onCompleteTodo } from './thunks/onCompleteTodo';
import { onUnCompleteTodo } from './thunks/onUnCompleteTodo';
import { onReorderTodo } from './thunks/onReorderTodo';
import { onDragTodoToAnotherList } from './thunks/onDragTodoToAnotherList';
import { onMoveTodo } from './thunks/onMoveTodo';
import { onDeleteTodo } from './thunks/onDeleteTodo';
import { onPingTodoCreator } from './thunks/onPingTodoCreator';
// thunks - sidebar
import { onFilterTodosByAssignee } from './thunks/onFilterTodosByAssignee';
import { onFilterTodosByDueDate } from './thunks/onFilterTodosByDueDate';
import { onFilterTodosBySearch } from './thunks/onFilterTodosBySearch';
import { onShowSidebarAllTodoLists } from './thunks/onShowSidebarAllTodoLists';
import { onToggleSidebarTodoList } from './thunks/onToggleSidebarTodoList';
// thunks - move to-do
import { onMountMoveTodo } from './thunks/onMountMoveTodo';
import { onFindTodoListsForMoveDropdown } from './thunks/onFindTodoListsForMoveDropdown';
// thunks - ws - to-do lists
import { wsOnCreateTodoList } from './ws/wsOnCreateTodoList';
import { wsOnCreateTodoListByTemplate } from './ws/wsOnCreateTodoListByTemplate';
import { wsOnEditTodoList } from './ws/wsOnEditTodoList';
import { wsOnReorderTodoLists } from './ws/wsOnReorderTodoLists';
import { wsOnDeleteTodoList } from './ws/wsOnDeleteTodoList';
// thunks - ws - todos
import { wsOnCreateTodo } from './ws/wsOnCreateTodo';
import { wsOnEditTodo } from './ws/wsOnEditTodo';
import { wsOnToggleTodoCompletedStatus } from './ws/wsOnToggleTodoCompletedStatus';
import { wsOnDeleteTodo } from './ws/wsOnDeleteTodo';
import { wsOnDeleteComment } from './ws/wsOnDeleteComment';
import { wsOnDragTodoToAnotherList } from './ws/wsOnDragTodoToAnotherList';

const todoLists = {
  ...initState,
  /* ********************************* ACTIONS ******************************** */
  // actions - general
  mountProjectTodoLists,
  loadMoreTodos,
  resetState,
  // actions - todolist
  mountCreateTodoListForm,
  createTodoList,
  createTodoListByTemplate,
  editTodoList,
  moveTodoList,
  deleteTodoList,
  deleteMovedTodoList,
  showTodosColorCodes,
  hideTodosColorCodes,
  reorderTodoList,
  setLoadedTodoLists,
  resetTodosFilters,
  setOpenedAssigneeForm,
  // actions - todos
  createTodo,
  editTodo,
  editTodoAssignee,
  editTodoPriority,
  completeTodo,
  unCompleteTodo,
  reorderTodo,
  dragTodoToAnotherList,
  updateTodosPositions,
  deleteActiveTodo,
  deleteCompletedTodo,
  deleteMovedTodo,
  // actions - sidebar
  filterTodosByAssignee,
  filterTodosByDueDate,
  filterTodosBySearch,
  showSidebarAllTodoLists,
  hideSidebarAllTodoLists,
  toggleSidebarTodoList,
  moveSidebarList,
  // actions - move to-do
  setMoveTodoProjects,
  setMoveTodoTodoLists,
  moveTodo,
  moveTodoToList,
  resetMoveTodoTodoLists,
  // actions - ws - to-do list
  wsReorderTodoLists,
  // actions - ws - todos
  wsReorderTodo,
  wsDragTodoToAnotherList,
  wsCreateComment,
  wsDeleteComment,
  wsViewTodo,

  /* ************************************************** THUNKS ****************************************************** */

  // thunks - general
  onMountProjectTodoLists,
  onLoadMoreTodos,
  // thunks - todolist
  onMountCreateTodoListForm,
  onCreateTodoList,
  onCreateTodoListByTemplate,
  onEditTodoList,
  onMoveTodoList,
  onDeleteTodoList,
  onShowTodosColorCodes,
  onReorderTodoLists,
  // thunks - todos
  onCreateTodo,
  onEditTodo,
  onEditTodoAssignee,
  onEditTodoPriority,
  onCompleteTodo,
  onUnCompleteTodo,
  onReorderTodo,
  onDragTodoToAnotherList,
  onMoveTodo,
  onDeleteTodo,
  onPingTodoCreator,
  // thunks - sidebar
  onFilterTodosByAssignee,
  onFilterTodosByDueDate,
  onFilterTodosBySearch,
  onShowSidebarAllTodoLists,
  onToggleSidebarTodoList,
  // thunks - moveTodo
  onMountMoveTodo,
  onFindTodoListsForMoveDropdown,
  // thunks - ws - to-do lists
  wsOnCreateTodoList,
  wsOnCreateTodoListByTemplate,
  wsOnEditTodoList,
  wsOnReorderTodoLists,
  wsOnDeleteTodoList,
  // thunks - ws - to-dos
  wsOnCreateTodo,
  wsOnEditTodo,
  wsOnToggleTodoCompletedStatus,
  wsOnDeleteTodo,
  wsOnDeleteComment,
  wsOnDragTodoToAnotherList,
};

export default todoLists;
