const initStore = {
  lists: {
    list: [],
    map: {},
  },
  todoItems: {},
  listTemplates: [],
  milestoneTemplates: [],
  filters: {
    assigneeId: null,
    search: '',
  },
  sidebarLists: {
    showAll: true,
    notActiveList: [],
    list: [],
    map: {},
  },
};

export default initStore;
