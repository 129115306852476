import { setTimeToZero } from '../../../../../../utils-deprecated/calendar';

export const getRequestInfo = (todoBag) => {
  if (todoBag.dueDate) {
    todoBag.dueDate = setTimeToZero(todoBag.dueDate);
  }

  if (todoBag.assignee) {
    const { responsibleCompanyId = null, responsibleUserId = null } = todoBag.assignee.value;
    return {
      ...todoBag,
      responsibleCompanyId,
      responsibleUserId,
    };
  }

  return todoBag;
};

export const getTodoData = (isMultiple, values) => {
  const { multipleItem, ...itemInfo } = getRequestInfo(values);

  if (isMultiple) {
    const contentForAllItems = values.content.split('\n').filter((item) => item);
    if (!contentForAllItems.length) {
      return null;
    }

    return contentForAllItems.map((currentItem) => ({
      ...itemInfo,
      content: currentItem,
    }));
  }

  return itemInfo;
};
