import { thunk } from 'easy-peasy';

import { updateMilestone } from './updateMilestone';
import { moveMilestone } from './moveMilestone';

export const onUpdateMilestone = thunk(
  async (
    _,
    { id, values, closeModal = () => null, resetForm = () => null },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const showLoader = actions.common.showLoader;
    const hideLoader = actions.common.hideLoader;
    const showError = actions.common.showError;

    const state = getStoreState();

    const loader = 'project.overview.calendar.editMilestone';
    showLoader({ key: loader });
    try {
      values.projectId
        ? await moveMilestone({ actions, state, form: { id, values } })
        : await updateMilestone({ actions, state, form: { id, values } });

      resetForm();
      closeModal();
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
