import { action } from 'easy-peasy';

import { sortOverdueMilestones } from '../../../../helpers/sortOverdueMilestones';

export const uncompleteOverdueMilestone = action((state, updatedItem) => {
  state.overdueMilestones.map = sortOverdueMilestones([
    ...state.overdueMilestones.map,
    updatedItem,
  ]);
  state.overdueMilestones.list[updatedItem.milestoneId] = updatedItem;
  state.overdueMilestones.overdueCount += 1;
});
