import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

export const onGetFilesSidebarUsers = thunk(
  async (
    { setSidebarUsers },
    { projectId, skipCountUsers, limit = 10, afterDeletingFile = false, clearState = false },
    { getStoreActions }
  ) => {
    const { common } = getStoreActions();
    common.showLoader({ key: 'getUsersOfFiles' });
    try {
      const users = await api.attachments.find({
        query: {
          projectId,
          $skip: skipCountUsers,
          $limit: limit,
        },
        for: 'uploadedBy',
      });

      setSidebarUsers({ ...users, afterDeletingFile, clearState });
    } catch (e) {
      common.showError(e);
    }
    common.hideLoader({ key: 'getUsersOfFiles' });
  }
);
