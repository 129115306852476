/**
 * REFACTOR THIS
 *  - add in useGoals hooks.
 * @param goals
 * @param data
 * @param months
 */
const addOneTimeClientRequestGoals = (goals, data, months) => {
  const hasClientRequestGoal = goals?.some((goal) => goal.isClientRequest);

  //Don't add client requests again to goal list, if it's already added.
  if (hasClientRequestGoal) {
    return;
  }

  // change this to check for strategy builder id
  const validTypes = ['asset', 'todo', 'milestone'];

  const optimizationsWithoutGoals = data.filter(
    (optimization) => !optimization.goal && validTypes.includes(optimization.type)
  );
  const clientRequests = {
    name: 'One-Time Client Requests',
    isClientRequest: true,
    date: months[0],
    optimizations: [
      {
        total: optimizationsWithoutGoals.length,
        assets: optimizationsWithoutGoals.filter((optimization) => optimization.type === 'asset')
          .length,
        optimizations: optimizationsWithoutGoals.filter(
          (optimization) => optimization.type === 'todo' || optimization.type === 'milestone'
        ).length,
      },
    ],
  };

  //Add client requests to goal list
  if (goals && clientRequests.optimizations[0].total > 0) {
    goals.push(clientRequests);
  }
};

export default addOneTimeClientRequestGoals;
