/* eslint-env browser */
/* eslint-disable global-require,import/first */

import './config/routes';
import './store';

require('./assets/css/style.css');
require('./assets/css/colors.css');

export { default } from './App';
