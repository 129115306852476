import { action } from 'easy-peasy';
import isInternalCompany from '../../../../../utils-deprecated/isInternalCompany';

export const setProjectForPeopleAndPermissions = action((state, payload) => {
  const companies = payload.projectCompanies.filter((company) => !isInternalCompany(company.name));
  const internalCompany = payload.projectCompanies.filter((company) =>
    isInternalCompany(company.name)
  )[0];
  if (internalCompany) {
    companies.push(internalCompany);
  }
  state.companies = companies;
});
