import { api } from '@webfx/core-web';

/**
 * Get the activity log for a project.
 *
 * @param {*} companyId - The company ID
 * @param queryParams
 * @returns A list of activity logs (messages and calls)
 */
const useActivityLog = (companyId, queryParams = {}, reactQueryParams = {}) => {
  return api.useQuery(
    [
      'tw/activity-log',
      {
        query: {
          companyId,
          ...queryParams,
          ...reactQueryParams,
        },
      },
    ],
    {
      select: (data) => data.data ?? data ?? [],
      placeholderData: [],
      enabled: !!companyId,
    }
  );
};

export default useActivityLog;
