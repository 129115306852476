import { thunk } from 'easy-peasy';

import api from '../../../../services/api';
// TODO:Refactor - remove this coz we should use companies and projects from globals
export const onGetCompaniesWithProjects = thunk(
  async ({ setCompaniesWithProjects }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    showLoader({ key: 'getCompaniesWithProjects' });
    try {
      const res = await api.companies.find({ for: 'projectsDropdown', query: { $limit: -1 } });
      setCompaniesWithProjects(res);
    } catch (error) {
      showError(error);
    }
    hideLoader({ key: 'getCompaniesWithProjects' });
  }
);
