import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import getSelectedCategories from '../helpers/getSelectedCategories';

export const onSearchTeamworkFxMore = thunk(
  async (
    { setMoreTeamworkFxSearchedValueToStore },
    { pageIndex = 0, limit = 30 },
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    const offset = limit * pageIndex;

    const state = getStoreState();
    const { lastSearched, sidebar } = state.teamworkFx.search.filters;
    // const { skipCount } = state.teamworkFx.search.scrollParams;
    const selectedCategories = getSelectedCategories(sidebar);

    if (!lastSearched) {
      return;
    }

    const loaders = [
      'teamworkFx.search.teamworkFxSearchGetMore',
      'teamworkFx.search.hasAnyLoading',
    ];
    showLoader({ key: loaders });
    try {
      const searchedValue = await api.search.find({
        query: {
          searchTerm: lastSearched,
          resourceType: selectedCategories,
          projectId: undefined,
          from: offset || 0,
        },
      });

      setMoreTeamworkFxSearchedValueToStore({ searchedValue, limit, pageIndex });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
