import { action } from 'easy-peasy';

import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';

export const deleteMovedTodo = action((state, { todolistId, todoId }) => {
  const todoList = state.todoLists.map[todolistId];
  if (!todoList) {
    return;
  }

  const { todos } = todoList;
  todos.active = todos.active.filter((id) => id !== todoId);
  delete todos.map[todoId];

  moveTodoListToCompleted(state.todoLists, { todolistId });
});
