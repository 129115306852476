import { action } from 'easy-peasy';

export const removeMilestone = action((state, { milestone, isOverdue }) => {
  const { milestoneId } = milestone;
  state.milestones.items = state.milestones.items.filter(
    (item) => item.milestoneId !== milestoneId
  );
  state.overdueMilestones.map = state.overdueMilestones.map.filter(
    (item) => item.milestoneId !== milestoneId
  );

  if (isOverdue) {
    state.overdueMilestones.overdueCount -= 1;
  }

  if (milestoneId in state.overdueMilestones.list) {
    state.overdueMilestones.skipCount -= 1;
  }

  delete state.overdueMilestones.list[milestoneId];
});
