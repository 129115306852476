import { useAuth, useRouter, useFlags } from '@webfx/web-hooks';
import { Icon, Link } from '@webfx/core-web';
import { useCompany } from '@webfx/web-hooks';

import styles from './GoalsHeader.module.css';

const GoalsHeader = ({ companyId }) => {
  const isAdmin = useAuth().isAdmin;
  const flags = useFlags();
  const { url } = useRouter();
  const { data: company } = useCompany(companyId);

  return (
    <>
      <div className="d-flex p-3 justify-content-between">
        <div className="d-flex align-items-center">
          <span className={styles.headerSubtitle}>Current Priority Areas</span>
          {!isAdmin && company && (
            <span>
              : <span className={styles.companyName}>{company?.name}</span>
            </span>
          )}
          <a
            href={
              flags?.helpcenterUpdates
                ? url({
                    route: 'helpCenterArticle',
                    categoryId: 'id4X6jpT',
                    categoryName: 'PlaybookFX',
                    articleId: 'T6RE7bac',
                    articleName: 'Current-Playbook',
                  })
                : `${url({ route: 'helpCenter' })}/tl1mtl1wd3-current-playbook`
            }
            className="d-flex align-items-center ml-4 font-14 text-gray-550 text-hover-gray-600 bold"
            target="_blank"
            rel="noreferrer"
            data-fx-name="learnMoreLink"
          >
            Learn more
            <Icon className="ml-1">arrow_forward</Icon>
          </a>
        </div>

        {isAdmin && (
          <Link
            className="bold"
            to={url({
              route: 'editCompany',
              id: `${company?.companyId}`,
              page: 'objectives',
            })}
            plain
          >
            <div
              className="d-flex align-items-center bold bg-blue-vivid-100 text-hover-blue-vivid-600 p-1"
              style={{ borderRadius: '30px', color: '#0C57AD' }}
            >
              <span className="font-12 mx-1">Configure Objectives</span>
              <Icon className="font-16" outlined>
                settings
              </Icon>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default GoalsHeader;
