import { toBool } from '../../../../../../utils-deprecated/toBool';

export const moveTodoListToActive = (todoLists, todo) => {
  const todolistId = todo.todolistId || todo.todoList.todolistId;

  if (!todolistId) {
    return;
  }
  if (!toBool(todoLists.map[todolistId]?.completed)) {
    return;
  }
  if (!todoLists.map[todolistId].todos.active.length) {
    return;
  }

  todoLists.active.unshift(todolistId);
  todoLists.completed = todoLists.completed.filter((id) => id !== Number(todolistId));
  todoLists.map[todolistId].checked = false;
  todoLists.map[todolistId].completed = false;
};
