import * as projects from './projects';
import * as companies from './companies';
import * as messages from './messages';
import * as comments from './comments';
import * as user from './user';
import * as users from './users';
import * as categories from './categories';
import * as countries from './countries';
import * as timezones from './timezones';
import * as resetPassword from './resetPassword';
import * as todoLists from './todoLists';
import * as milestones from './milestones';
import * as events from './events';
import * as todos from './todos';
import * as subscribers from './subscribers';
import * as projectTemplates from './projectTemplates';
import * as calendar from './calendar';
import * as attachments from './attachments';
import * as pastebinTemplates from './pastebinTemplates';
import * as projectsCompanies from './projectsCompanies';
import * as activities from './activities';
import * as projectsUsers from './projectsUsers';
import * as channels from './channels';
import * as deletions from './deletions';
import * as sidebar from './sidebar';
// import * as deletedProjects from './deletedProjects';
import * as pingTodoCreator from './pingTodoCreator';
import * as permissions from './permissions';
import * as search from './search';
import * as projectTemplateTodoLists from './projectTemplateTodoLists';
import * as projectTemplateTodos from './projectTemplateTodos';
import * as todoListsTemplate from './todoListsTemplate';
import * as todoListTemplateTodos from './todoListTemplateTodos';
import * as views from './views';
import * as projectTemplateMilestones from './projectTemplateMilestones';
import * as colorCodes from './colorCodes';
import * as userFileTokens from './userFileTokens';

const api = {
  projects,
  companies,
  messages,
  comments,
  user,
  users,
  categories,
  countries,
  timezones,
  resetPassword,
  todoLists,
  milestones,
  events,
  todos,
  subscribers,
  projectTemplates,
  calendar,
  attachments,
  pastebinTemplates,
  projectsCompanies,
  activities,
  projectsUsers,
  channels,
  deletions,
  sidebar,
  // deletedProjects,
  pingTodoCreator,
  permissions,
  search,
  projectTemplateTodoLists,
  projectTemplateTodos,
  todoListsTemplate,
  todoListTemplateTodos,
  views,
  projectTemplateMilestones,
  colorCodes,
  userFileTokens,
};

export default api;
