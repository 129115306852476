import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const companies = feathersClient.service('companies');

export const find = (params) => companies.find(paramsForServer(params));

export const get = (companyId) => companies.get(companyId, paramsForServer({ for: 'users' }));

export const create = (payload) => companies.create(payload);

export const patch = ({ companyId, values }) => companies.patch(companyId, values);

export const remove = ({ companyId }) => companies.remove(companyId);
