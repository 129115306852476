import { initState } from './initState';

import { mountProjectEditUserPage } from './actions/mountProjectEditUserPage';
import { updateProjectAccess } from './actions/updateProjectAccess';
import { projectGiveAccessToCompany } from './actions/projectGiveAccessToCompany';
import { projectGiveAccessToProject } from './actions/projectGiveAccessToProject';
import { projectToggleSelectAllAccess } from './actions/projectToggleSelectAllAccess';

import { onDeleteProjectUser } from './thunks/onDeleteProjectUser';
import { onMountProjectEditUserPage } from './thunks/onMountProjectEditUserPage';
import { onSendProjectPasswordInstruction } from './thunks/onSendProjectPasswordInstruction';
import { onUpdateProjectUser } from './thunks/onUpdateProjectUser';
import { onGiveAccessProjectUser } from './thunks/onGiveAccessProjectUser';

const editUser = {
  ...initState,

  // actions
  mountProjectEditUserPage,
  updateProjectAccess,
  projectGiveAccessToCompany,
  projectGiveAccessToProject,
  projectToggleSelectAllAccess,

  // thunks
  onDeleteProjectUser,
  onMountProjectEditUserPage,
  onSendProjectPasswordInstruction,
  onUpdateProjectUser,
  onGiveAccessProjectUser,
};

export default editUser;
