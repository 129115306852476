import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onDeleteMilestoneTemplate = thunk(
  async (
    { deleteMilestoneTemplate },
    { projectTemplateMilestoneId, weekDue, dowDue },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'projectTemplate.milestones.deleteMilestone';
    showLoader({ key: loader });
    try {
      await api.projectTemplateMilestones.remove(projectTemplateMilestoneId);

      deleteMilestoneTemplate({ projectTemplateMilestoneId, weekDue, dowDue });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
