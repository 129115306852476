import React from 'react';
import Checkbox from '@webfx/teamwork-web/src/components/Checkbox';

const PrivateCheckbox = ({ privateFile, handlePrivate, privateFileLoading }) => {
  return (
    <div>
      <div style={{ float: 'left' }}>
        <Checkbox
          field={{
            name: 'private',
            value: privateFile,
          }}
          form={{
            setFieldValue: handlePrivate,
          }}
          isLoading={privateFileLoading}
          uncheckedIcon="check_circle"
        />
      </div>
      <div style={{ fontSize: '13px', float: 'left', padding: ' 3px 10px' }}>
        Private: (Visible only to your company)
      </div>
    </div>
  );
};

export default PrivateCheckbox;
