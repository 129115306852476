import deleteProjectsActions from './actions';
import deleteProjectsThunks from './thunks';

const deleteProjects = {
  list: [],
  total: 0,
  limit: null,
  skip: null,

  // actions
  ...deleteProjectsActions,

  // thunks
  ...deleteProjectsThunks,
};

export default deleteProjects;
