import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

export const onFindPermissions = thunk(
  async (actions, payload, { getStoreActions, getStoreState }) => {
    const { showLoader, hideLoader, showError } = getStoreActions().common;
    const { permissions } = getStoreState();
    const { projectId = null, forceLoader = false } = payload;

    // TODO: permissions are being refetched when they shouldnt need to be.
    if (projectId === null && permissions.fetched && !forceLoader) {
      return;
    }
    if (
      permissions.fetched &&
      projectId !== null &&
      projectId === permissions.data.project.projectId
    ) {
      return;
    }

    if (permissions.fetched === false || forceLoader) {
      showLoader({ key: 'getUserPermissions' });
    }

    try {
      const data = await api.permissions.find({ query: { projectId } });

      actions.setUserPermissions({ data, projectId });
    } catch (e) {
      showError(e);
    }
    if (permissions.fetched === false || forceLoader) {
      hideLoader({ key: 'getUserPermissions' });
    }
  }
);
