import { action } from 'easy-peasy';

import { toBool } from '../../../../../../utils-deprecated/toBool';
import { filterTodos } from '../helpers/filterTodos';
import { moveTodoListToActive } from '../helpers/moveTodoListToActive';
import { moveTodoListToCompleted } from '../helpers/moveTodoListToCompleted';
import { transformTodo } from '../helpers/transformTodo';
import { getInitializedTodoLists } from '../helpers/getInitializedTodoLists';

export const wsDragTodoToAnotherList = action(
  (
    state,
    { result: { todoId, sourceList = {}, targetList = {}, reorderedTodos }, todo: updatedTodo }
  ) => {
    const { active, lastLoadedTodoListId } = state.todoLists;
    const sourceTodoList = state.todoLists.map[sourceList.todolistId];
    const targetTodoList = state.todoLists.map[targetList.todolistId];

    const initializedTodoLists = getInitializedTodoLists(active, lastLoadedTodoListId);
    const isSourceInitialised = initializedTodoLists.includes(sourceList.todolistId);
    const isTargetInitialised = initializedTodoLists.includes(targetList.todolistId);

    if (isSourceInitialised) {
      sourceTodoList.todos.active = sourceTodoList.todos.active.filter((id) => id !== todoId);
      delete sourceTodoList.todos.map[todoId];

      state.todoLists.$skip -= 1;
      moveTodoListToCompleted(state.todoLists, { todoList: sourceList });
    }

    if (isTargetInitialised) {
      const newReorderedTodos = reorderedTodos.map((todo) => {
        // Need to change moved todo info (because we will get incorrect values from backend)
        if (todo.todoId === todoId) {
          return updatedTodo;
        }

        // Here we return value for not reordered item
        return targetTodoList.todos.map[todo.todoId];
      });

      const todos = filterTodos({ data: newReorderedTodos, filters: state.filters }).map((todo) =>
        transformTodo(todo)
      );
      targetTodoList.todos.active = todos
        .filter((todo) => !toBool(todo.completedAt))
        .map((todo) => todo.todoId);
      targetTodoList.todos.map = todos.reduce((acc, todo) => ({ ...acc, [todo.todoId]: todo }), {});

      state.todoLists.$skip += 1;
      moveTodoListToActive(state.todoLists, { todoList: targetList });
    }
  }
);
