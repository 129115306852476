import { action } from 'easy-peasy';
import { getSubscribers } from '../../../../../../utils-deprecated/subscribePeople';
import { getUserSubscribeId } from '../../../helpers/getUserSubscribeId';
import { getPastebinTemplatesOptions } from '../../../helpers/dropdownOptions';
import { transformTodo } from '../helpers/transformTodo';

export const mountTodo = action(
  (
    state,
    { todo, firstCommentId, comments, assignees, subscribers, currentUserId, pastebinTemplates }
  ) => {
    const { total, data: commentsList } = comments || {};
    const { users: commentators, ..._todo } = todo || {}; // TODO: Remove props renamign from API

    if (todo) {
      state.todo = transformTodo(_todo);
    }

    if (comments) {
      state.comments = {
        total,
        list: commentsList
          .map(({ commentId }) => commentId)
          .filter((commentId) => commentId !== firstCommentId),
        map: commentsList.reduce((acc, comment) => ({ ...acc, [comment.commentId]: comment }), {}),
        firstCommentId,
      };
    }

    if (assignees) {
      state.assignees = assignees.assignees || assignees;
      const people = state.assignees.map((group) => {
        group.users.forEach((u) => {
          u.subscribed = -1 !== subscribers.findIndex((s) => s.userId === u.userId);
        });
        return group;
      });

      state.people = getSubscribers(people);
    }

    if (subscribers) {
      state.sidebar = {
        subscribers,
        userSubscribeId: getUserSubscribeId(subscribers, currentUserId),
        commentators,
      };
    }

    if (pastebinTemplates) {
      state.pastebinTemplates = getPastebinTemplatesOptions(pastebinTemplates);
    }
  }
);
