import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onDeleteComment = thunk(async (_, { commentId }, { getStoreActions }) => {
  const actions = getStoreActions();
  const showError = actions.common.showError;
  const showLoader = actions.common.showLoader;
  const hideLoader = actions.common.hideLoader;
  const deleteComment = actions.project.todos.todo.deleteComment;

  const loader = 'project.comments.deleteComment';
  showLoader({ key: loader });
  try {
    const comment = await api.comments.remove(commentId);
    deleteComment({ comment });
  } catch (e) {
    showError(e);
  }
  hideLoader({ key: loader });
});
