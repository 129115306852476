import todoLists from './todoLists';
import singleTodoList from './singleTodoList';
import todo from './todo';
// actions
import { resetProjectTodos } from './actions/resetProjectTodos';

// TODO: deprecate/Refactor into flatter store vs layers
const todos = {
  todoLists,
  singleTodoList,
  todo,
  // actions
  resetProjectTodos,
};

export default todos;
