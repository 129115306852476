import { action } from 'easy-peasy';

export const projectToggleSelectAllAccess = action((state, payload) => {
  state.sidebar.companies.list.forEach((companyId) => {
    const company = state.sidebar.companies.map[companyId];

    company.checked = payload;
    company.projects.list.forEach((projectId) => {
      company.projects.map[projectId].checked = payload;
    });
  });
});
