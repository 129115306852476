import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';
import { backendStartPages } from '../../../../../ui/constants/project/settings';

export const onSwitchProject = thunk(
  async ({ selectProject }, { projectId, push }, { getStoreActions, getStoreState }) => {
    const { showLoader, showError, hideLoader } = getStoreActions().common;
    const url = getStoreState().router.url;
    showLoader({ key: 'switchProject' });
    try {
      const project = await api.projects.get({ projectId, params: { for: 'projectCompanies' } });
      selectProject(project);
      push(url({ route: 'project', projectId, tab: backendStartPages[project.startPage] }));
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: 'switchProject' });
  }
);
