export default {
  items: [],
  itemsIds: [],
  skipCount: 0,
  hasMoreData: true,
  sortingParam: 'createdAt',
  sidebarUsers: {
    listId: [],
    items: {},
    // need for understanding do we need to get
    // files by request to a server after deleting
    userWasDeleted: false,
  },
  hasMoreUsers: true,
  skipCountUsers: 0,
};
