import { action } from 'easy-peasy';

export const addTemplateTodo = action((state, [item]) => {
  const { projectTemplateTodoListId, projectTemplateTodoId } = item;

  const list = state.lists.map[projectTemplateTodoListId];

  if (list) {
    list.projectTemplateTodos.push(projectTemplateTodoId);
    state.todoItems[projectTemplateTodoId] = item;
  }
});
