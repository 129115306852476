import initStore from './initStore';

import { setLoading } from './actions/setLoading';
import { setLoadingMore } from './actions/setLoadingMore';
import { setSearchText } from './actions/setSearchText';
import { setAssignee } from './actions/setAssignee';
import { setDate } from './actions/setDate';
import { setSort } from './actions/setSort';
import { clearSearchItems } from './actions/clearSearchItems';
import { setAssigneeList } from './actions/setAssigneeList';
import { setLastSearchText } from './actions/setLastSearchText';
import { setSearchAllProjects } from './actions/setSearchAllProjects';
import { setSearchCategories } from './actions/setSearchCategories';
import { setSearchItems } from './actions/setSearchItems';
import { unMountProjectSearch } from './actions/unMountProjectSearch';

import { onSearch } from './thunks/onSearch';
import { onUnMountProjectSearch } from './thunks/onUnMountProjectSearch';
import { onMountProjectSearch } from './thunks/onMountProjectSearch';
import { onSetAssigneeList } from './thunks/onSetAssigneeList';

const search = {
  // store
  ...initStore,

  // actions
  setLoading,
  setLoadingMore,
  setSearchText,
  setLastSearchText,
  setDate,
  setSort,
  clearSearchItems,
  setSearchItems,
  setSearchAllProjects,
  setSearchCategories,
  setAssigneeList,
  setAssignee,
  unMountProjectSearch,

  // thunks
  onSearch,
  onSetAssigneeList,
  onMountProjectSearch,
  onUnMountProjectSearch,
};

export default search;
