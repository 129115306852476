import { action } from 'easy-peasy';
import { getSelectedProjects } from '../helpers/getSelectedProjects';

export const projectGiveAccessToProject = action((state, { companyId, projectId, checked }) => {
  const company = state.sidebar.companies.map[companyId];
  company.projects.map[projectId].checked = checked;

  const selectedProjects = getSelectedProjects(company);
  if (selectedProjects.length === company.projects.list.length) {
    company.checked = true;
  }

  if (checked === false) {
    company.checked = false;
  }
});
