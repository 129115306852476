import { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Icon } from '@webfx/core-web';

import classnames from 'classnames';
import styles from './GoalProgressBar.module.css';

const GoalProgressBar = ({ total, completed, percentage, isLoading }) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  const style = {
    width: `${percentage}%`,
    backgroundColor: '#207DE9',
    position: 'absolute',
  };

  return (
    <>
      <div
        className={styles.progressBar}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        ref={popoverRef}
      >
        <div className={classnames({ 'shimmer-overlay': isLoading })}>
          <div
            className={classnames(styles.progress)}
            style={style}
            aria-valuenow={0}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>

      <Overlay show={showPopover} placement="top" target={popoverRef.current}>
        {({ ...props }) => {
          return (
            <Popover {...props} className={styles.popover}>
              <Popover.Content>
                <div className="position-relative p-1">
                  <div className="my-1 d-flex align-items-center">
                    <Icon className={classnames([styles.f13, 'text-primary'])}>
                      insert_drive_file
                    </Icon>
                    <span className="pl-1">{total ?? 'No'} Action Items Planned</span>
                  </div>
                  {total && (
                    <div className="my-1 d-flex align-items-center">
                      <Icon className={classnames([styles.f13, 'text-success'])}>check_circle</Icon>
                      <span className="pl-1">{completed} Action Items Completed</span>
                    </div>
                  )}
                </div>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
};

export default GoalProgressBar;
