import { api } from '@webfx/core-web';

export default function useSitesCurrentPBEnabled(siteIds) {
  const siteSettingsQuery = api.useQuery(
    [
      'site-settings',
      {
        query: {
          siteId: { $in: siteIds },
          field: { $in: ['playbookShowGoals', 'playbookShowActionItemsKanban'] },
          value: 1,
        },
      },
    ],
    {
      enabled: !!siteIds,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  return { ...siteSettingsQuery };
}
