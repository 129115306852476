import { api } from '@webfx/core-web';

const useHistory = (companyId, options) => {
  return api.useQuery(
    [
      'tw/history',
      {
        companyId,
        $sort: {
          date: -1,
          createdAt: -1,
        },
        $limit: 30,
        ...options,
      },
    ],
    {
      enabled: !!companyId,
      select: (data) => data || [],
    }
  );
};

export default useHistory;
