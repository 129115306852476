import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { projectCurrentTodosLimit } from '../../../../../../ui/constants/limits';
import { getFilterParams } from '../helpers/getFilterParams';

export const onLoadMoreTodos = thunk(
  async ({ loadMoreTodos }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const state = getStoreState();
    const { filters } = state.project.todos.singleTodoList;
    const { todolistId, $skip } = state.project.todos.singleTodoList.todoList;

    try {
      const todosBag = await api.todos.find({
        query: {
          todolistId: { $in: [todolistId] },
          $skip,
          $limit: projectCurrentTodosLimit,
        },
        filter: getFilterParams(filters),
        for: 'onFindProjectTodos',
      });

      loadMoreTodos({ todosBag });
    } catch (e) {
      showError(e);
    }
  }
);
