import { useCan } from '@webfx/core-web'
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect'

export const onClickTeamworkFxSearchMilestoneTitle = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxSearchPage',
  useGlobalLoader: false,
  action: async (actions, { milestoneId, projectId, pushParams }, getStoreState) => {
    const { onGetMilestone, clearSelectedMilestone } = actions.project.calendar.calendar;
    const { onMountProject } = actions.project;

    await Promise.all([
      onMountProject({ projectId, pushParams: { ...pushParams, doNotPush: true } }),
      onGetMilestone({ milestoneId }),
    ]);

    const state = getStoreState();
    const { dueAt, creatorUserId } = state.project.calendar.calendar.milestones.select;
    const editOtherEventOrMilestone = useCan(
      'update',
      'milestone',
      { projectId, creatorUserId },
      'title'
    );

    const startOf = dueAt.replace(/-\d{2}$/, '-01');

    // eslint-disable-next-line no-param-reassign
    pushParams.openUpdateModal = editOtherEventOrMilestone;
    // eslint-disable-next-line no-param-reassign
    pushParams.queryString = `?from_date=${startOf}`;
    if (!editOtherEventOrMilestone) {
      clearSelectedMilestone();
    }

    return `/projects/${projectId}/milestones?from_date=${startOf}`;
  },
});
