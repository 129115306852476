import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import getQueryForFiltering from '../helpers/getQueryForFiltering';

const updateLists = ({ lists, projectTemplateTodoListId, checked }) => {
  lists.map[projectTemplateTodoListId].checked = checked;
  return lists;
};

const getSelectedLists = (updatedCategories) => {
  const selected = updatedCategories.list.filter(
    (categoryId) => updatedCategories.map[categoryId].checked
  );

  return selected.length ? selected : undefined;
};

export const onFilterByList = thunk(
  async (
    { setTemplateTodoListsIntoStore, changeSidebarListChecked },
    { projectTemplateTodoListId, checked, templateId },
    { getStoreState, getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loaders = [
      `projectTemplate.todoLists.sidebar.categories.${projectTemplateTodoListId}`,
      'projectTemplate.todoLists.hasAnyLoading',
    ];
    showLoader({ key: loaders });

    const state = getStoreState();
    const lists = state.projectTemplate.todoLists.sidebarLists;

    const updatedLists = updateLists({ lists, projectTemplateTodoListId, checked });
    const selectedLists = getSelectedLists(updatedLists);

    try {
      const query = getQueryForFiltering(state.projectTemplate.todoLists, templateId);
      const data = await api.projectTemplateTodoLists.find({
        query,
        for: 'projectTemplateTodoList',
      });
      changeSidebarListChecked({ lists: updatedLists, showAll: !selectedLists });
      setTemplateTodoListsIntoStore(data);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loaders });
  }
);
