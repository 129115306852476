import { action } from 'easy-peasy';

import { getMilestonesOptions } from '../helpers/createTodoListDropdownOptions';
import { setTodosToLists } from '../helpers/setTodosToLists';

export const mountProjectTodoLists = action((state, payload) => {
  const { todoListForm } = state;
  const { todoLists, todosBag, assignees, milestones, todolistId } = payload;

  // TODO: data needs cleaner seperation
  if (todoLists) {
    const _todoLists = setTodosToLists({
      todoLists: {
        ...todoLists, // this spreads the current state object in
        $skip: 0,
      },
      todosBag,
      todolistId,
    });

    state.todoLists = _todoLists;
  }
  if (assignees) {
    state.assignees = assignees;
  }
  if (milestones) {
    todoListForm.milestones = getMilestonesOptions(milestones);
  }
});
