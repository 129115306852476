import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

export const onCreateCategory = thunk(async (actions, data, { getStoreActions }) => {
  const { common } = getStoreActions();
  common.showLoader({ key: 'getCreateCategories' });
  try {
    const res = await api.categories.create(data);
    actions.put(res);
  } catch (e) {
    common.showError(e);
  }
  common.hideLoader({ key: 'getCreateCategories' });
});
