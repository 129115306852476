import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

const getTodosList = (parentId, items) =>
  items.map((todolistTemplateTodoId, index) => ({
    todolistTemplateTodoId,
    todolistTemplateId: parentId,
    position: index,
  }));

export const onUpdateTeamworkFxTemplateDragAndDropTodo = thunk(
  async (
    { teamworkFxDragAndDropTemplateTodo },
    { targetId, sourceId, itemId, items },
    { getStoreActions }
  ) => {
    const { showError } = getStoreActions().common;
    const todosList = getTodosList(targetId, items);
    try {
      teamworkFxDragAndDropTemplateTodo({ targetId, sourceId, itemId, items });
      await api.todoListTemplateTodos.updateDragAndDrop(todosList, {
        for: 'reorder',
        $skipSafeMulti: true,
      });
    } catch (e) {
      showError(e);
    }
  }
);
