import { thunk } from 'easy-peasy';
import { store } from '@webfx/teamwork-web/src/contexts/ProjectContext';
import api from '../../../../../services/api';
import getSelectedCategories from '../helpers/getSelectedCategories';

export const onSearch = thunk(
  async (
    { setSearchItems },
    { loadMore = false, updateSearchString = false, resultSize = 20, currentPage = 0 } = {},
    { getStoreActions, getStoreState }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    const {
      setLoading: setLoadingInitial,
      setLoadingMore,
      setLastSearchText,
      clearSearchItems,
    } = actions.project.search;
    const setLoading = loadMore ? setLoadingMore : setLoadingInitial;

    if (!loadMore) {
      clearSearchItems();
    }
    const state = getStoreState();
    const projectId = store.get.projectId();
    const {
      loading,
      filters: {
        lastSearch,
        search: currentSearch,
        searchAllProjects,
        selectedCategories,
        date: { to, from },
        assignee,
        sort: {
          selected: { value: sortBy },
        },
      },
    } = state.project.search;

    const search = updateSearchString ? currentSearch : lastSearch;
    if (loading) {
      return;
    }

    const categories = getSelectedCategories(selectedCategories);

    if (!search || search === '') {
      return;
    }

    setLoading({ value: true });
    if (updateSearchString) {
      setLastSearchText();
    }

    api.search
      .find({
        query: {
          searchTerm: search,
          resourceType: categories,
          projectId: searchAllProjects ? undefined : projectId,
          from: currentPage * resultSize,
          assignee,
          toDate: to,
          fromDate: from,
          sortBy,
          resultSize,
        },
      })
      .then(
        (results) => {
          setSearchItems({ results, loading });
          setLoading({ value: false });
        },
        (e) => {
          showError(e);
          setLoading({ value: false });
        }
      );
  }
);
