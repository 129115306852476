// eslint-disable-next-line import/no-unresolved
import { store, feathers } from '@webfx/core-web';
import { DateTime } from 'luxon';
import api from '../../../../../services/api';
import { deletedProjectsLimit } from '../../../../../ui/constants/limits';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountTeamworkFxDeletedProjectsPage = fetchWithLoaderAndRedirect({
  loaderKey: 'TeamworkFxDeletedProjectsPage',
  pathname: store.getState().router.url('deletedProjects'),
  action: async (actions, { skip }, getStoreState) => {
    const state = getStoreState();
    const limit = state.ui.deletedProjects.limit || deletedProjectsLimit;
    const { setDeletedProjectList } = actions.ui.deletedProjects;

    const res = await feathers()
      .service('tw/projects')
      .find({
        query: {
          deletedAt: { $gte: DateTime.utc().minus({ days: 30 }).toJSDate() },
          $ignoreDeletedAt: true,
          $select: ['projectId', 'name', 'deletedAt', 'encrypted', 'deleterUserId'],
          $sort: { deletedAt: -1 },
          $limit: limit || 50,
          $skip: skip || 0,
          $join: {
            deleter: {
              $select: ['userId', 'firstName', 'lastName'],
            },
          },
        },
      });
    setDeletedProjectList(res);
  },
});
