import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';
import { getResponsibleInfo } from '../helpers/getResponsibleInfo';

export const onUpdateTemplateTodo = thunk(
  async (
    { updateTemplateTodo },
    { values, id, setSubmitting, onCloseForm },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;
    try {
      const changedTodo = await api.projectTemplateTodos.patch(id, getResponsibleInfo(values));
      updateTemplateTodo(changedTodo[0]);
      onCloseForm();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
