import { api } from '@webfx/core-web';
import { isEmpty } from 'lodash';

function useCompaniesOptimizationsHidden(companyUsers) {
  let companyIds = [];
  for (let i = 0; i < companyUsers?.length; i++) {
    companyIds.push(companyUsers[i].companyId);
  }

  const { data: settings, isFetching } = api.useQuery(
    [
      'company-settings',
      {
        query: {
          field: 'hideOptimizations',
          companyId: { $in: companyIds },
          $sort: { updatedAt: -1 },
        },
      },
    ],
    {
      enabled: !!companyUsers,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );

  if (companyIds && !isFetching && !isEmpty(settings)) {
    let optimizationsHidden = [];
    let encountered = [];

    settings.data.forEach((s) => {
      if (!encountered.includes(s.companyId)) {
        encountered.push(s.companyId);
        if (s.value === '1') {
          optimizationsHidden.push(s.companyId);
        }
      }
    });
    return optimizationsHidden;
  }
  return [];
}

export default useCompaniesOptimizationsHidden;
