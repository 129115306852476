import { action } from 'easy-peasy';

export const wsCreateComment = action((state, { todoId }) => {
  if (!todoId) {
    return;
  }
  const todo = state.todoList.todos.map[todoId];

  if (todo) {
    todo.comments += 1;
    todo.hasNewComments = true;
  }
});
