import { setHasArchivedProjects } from './setHasArchivedProjects';
import { setCompaniesProjects } from './setCompaniesProjects';
import { setNameFilter } from './setNameFilter';

import { setActivities } from './setActivities';
import { clearDashboardState } from './clearDashboardState';

import {
  setCreateProjectCompanies,
  toggleIsFetchingCompaniesProjects,
  resetCompanyProjects,
} from './dashboard';

import { setEvent } from './event/setEvent';
import { removeEvent } from './event/removeEvent';
import { updateEvent } from './event/updateEvent';

import { setOverdueMilestones } from './milestone/setOverdueMilestones';
import { loadMoreOverdueMilestones } from './milestone/loadMoreOverdueMilestones';
import { setMilestones } from './milestone/setMilestones';
import { removeMilestone } from './milestone/removeMilestones';
import { updateMilestone } from './milestone/updateMilestone';
import { updateOverdueMilestone } from './milestone/updateOverdueMilestone';
import { removeOverdueMilestone } from './milestone/removeOverdueMilestone';
import { setSelectMilestone } from './milestone/setSelectMilestone';
import { toggleMilestone } from './milestone/toggleMilestone';
import { completeOverdueMilestone } from './milestone/completeOverdueMilestone';
import { uncompleteOverdueMilestone } from './milestone/uncompleteOverdueMilestone';
import { setTeamworkFxDashboardMilestone } from './milestone/setTeamworkFxDashboardMilestone';
import { updateMilestones } from './milestone/updateMilestones';

export default {
  setNameFilter,
  setHasArchivedProjects,
  setCompaniesProjects,
  setActivities,
  setCreateProjectCompanies,
  toggleIsFetchingCompaniesProjects,
  resetCompanyProjects,
  clearDashboardState,
  setEvent,
  removeEvent,
  updateEvent,
  setOverdueMilestones,
  loadMoreOverdueMilestones,
  setMilestones,
  setSelectMilestone,
  removeMilestone,
  updateMilestone,
  updateOverdueMilestone,
  removeOverdueMilestone,
  completeOverdueMilestone,
  uncompleteOverdueMilestone,
  toggleMilestone,
  setTeamworkFxDashboardMilestone,
  updateMilestones,
};
