/* eslint-disable camelcase */
import { backendStartPages } from '../ui/constants/project/settings';

/*
const getFileNameAndExtension = ({ google_url }) => {
  const fullNameWithExtension = google_url.substring(google_url.lastIndexOf('/') + 1);

  const firstDotIndex = fullNameWithExtension.indexOf('.');
  const fullExtension = fullNameWithExtension.substring(firstDotIndex + 1);
  const querySymbol = fullExtension.match(/[?|#]/);
  const extension = querySymbol ? fullExtension.substring(0, querySymbol.index) : fullExtension;

  return {
    extension,
    name: fullNameWithExtension.substring(0, firstDotIndex),
  };
};
*/

const getResponsibleUser = (author) => {
  if (!author) {
    return 'Anyone';
  }

  const firstSpaceIndex = author.indexOf(' ');

  if (firstSpaceIndex !== -1) {
    return `${author.substring(0, firstSpaceIndex)} ${author
      .substring(firstSpaceIndex + 1)
      .charAt(0)
      .toUpperCase()}.`;
  }

  return author;
};

const getAction = (sourceType) => {
  if (sourceType === 'attachment') {
    return 'Uploaded by';
  }

  if (sourceType === 'message' || sourceType === 'comment') {
    return 'Posted by';
  }

  if (sourceType === 'milestone' || sourceType === 'todo') {
    return 'Created By';
  }

  return null;
};

const formatSearchHit = ({ _id, _source }) => {
  const formattedHit = {
    id: _id,
    projectId: _source.project_id || 0,
    type: _source.type,
    action: getAction(_source.type),
    responsibleUser: getResponsibleUser(_source.author || _source.author_name), // TODO: fix this
    companyName: _source.company_name,
    projectName: _source.project_name,
    createdDate: _source.creation_date || undefined,
  };

  switch (_source.type) {
    case 'todo': {
      formattedHit.todo = {
        id: _source.todo_id,
        title: _source.todo_content,
      };
      formattedHit.todolist = {
        id: _source.todolist_id,
        title: _source.todolist_name,
      };
      break;
    }
    case 'todolist': {
      formattedHit.todolist = {
        id: _source.todolist_id,
        title: _source.todolist_name,
        description: _source.todolist_description,
      };
      break;
    }
    case 'attachment': {
      //const fileNameWithExtension = getFileNameAndExtension(_source);

      formattedHit.attachment = {
        id: _source.upload_id,
        name: _source.attachment_name,
        extension: _source.attachment_extension, //|| fileNameWithExtension.extension,
      };
      break;
    }
    case 'comment': {
      const { comment_content, todo_id, message_id, milestone_id } = _source;

      const id = todo_id || message_id || milestone_id;

      let path = 'todo';
      if (message_id) {
        path = 'messages';
      }
      if (milestone_id) {
        path = 'milestone';
      }

      formattedHit.comment = {
        id,
        path,
        title: comment_content,
      };
      break;
    }
    case 'message': {
      formattedHit.message = {
        id: _source.message_id,
        title: _source.message_title,
      };
      break;
    }
    case 'milestone': {
      formattedHit.milestone = {
        id: _source.milestone_id,
        title: _source.milestone_title,
      };
      break;
    }
    case 'event': {
      formattedHit.event = {
        id: _source.event_id,
        title: _source.event_title,
      };
      break;
    }
    case 'project': {
      formattedHit.project = {
        id: _source.project_id,
        title: _source.project_name,
        startPage: backendStartPages[_source.start_page],
      };
      break;
    }
    default: {
      break;
    }
  }

  return formattedHit;
};

export default formatSearchHit;
