const unitedStates = [
  'abilene, texas',
  'akron, ohio',
  'albany, georgia',
  'albany, new york',
  'albany, oregon',
  'albuquerque, new mexico',
  'alexandria, louisiana',
  'allentown, pennsylvania',
  'altoona, pennsylvania',
  'amarillo, texas',
  'ames, iowa',
  'anchorage, alaska',
  'ann arbor, michigan',
  'anniston, alabama',
  'appleton, wisconsin',
  'asheville, north carolina',
  'athens, georgia',
  'atlanta, georgia',
  'atlantic city, new jersey',
  'auburn, alabama',
  'augusta, georgia',
  'austin, texas',
  'bakersfield, california',
  'baltimore, maryland',
  'bangor, maine',
  'barnstable town, massachusetts',
  'baton rouge, louisiana',
  'battle creek, michigan',
  'bay city, michigan',
  'beaumont, texas',
  'beckley, west virginia',
  'bellingham, washington',
  'bend, oregon',
  'billings, montana',
  'binghamton, new york',
  'birmingham, alabama',
  'bismarck, north dakota',
  'blacksburg, virginia',
  'bloomington, illinois',
  'bloomington, indiana',
  'bloomsburg, pennsylvania',
  'boise city, idaho',
  'boston, massachusetts',
  'boulder, colorado',
  'bowling green, kentucky',
  'bremerton, washington',
  'bridgeport, connecticut',
  'brownsville, texas',
  'brunswick, georgia',
  'buffalo, new york',
  'burlington, north carolina',
  'burlington, vermont',
  'california, maryland',
  'canton, ohio',
  'cape coral, florida',
  'cape girardeau, missouri',
  'carbondale, illinois',
  'carson city, nevada',
  'casper, wyoming',
  'cedar rapids, iowa',
  'chambersburg, pennsylvania',
  'champaign, illinois',
  'charleston, south carolina',
  'charleston, west virginia',
  'charlotte, north carolina',
  'charlottesville, virginia',
  'chattanooga, tennessee',
  'cheyenne, wyoming',
  'chicago, illinois',
  'chico, california',
  'cincinnati, ohio',
  'clarksville, tennessee',
  'cleveland, ohio',
  'cleveland, tennessee',
  "coeur d'alene, idaho",
  'college station, texas',
  'colorado springs, colorado',
  'columbia, missouri',
  'columbia, south carolina',
  'columbus, georgia',
  'columbus, indiana',
  'columbus, ohio',
  'corpus christi, texas',
  'corvallis, oregon',
  'crestview, florida',
  'cumberland, maryland',
  'dallas, texas',
  'dalton, georgia',
  'danville, illinois',
  'daphne, alabama',
  'davenport, iowa',
  'dayton, ohio',
  'decatur, alabama',
  'decatur, illinois',
  'deltona, florida',
  'denver, colorado',
  'des moines, iowa',
  'detroit, michigan',
  'district of columbia',
  'dothan, alabama',
  'dover, delaware',
  'dubuque, iowa',
  'duluth, minnesota',
  'durham, north carolina',
  'east stroudsburg, pennsylvania',
  'eau claire, wisconsin',
  'el centro, california',
  'el paso, texas',
  'elizabethtown, kentucky',
  'elkhart, indiana',
  'elmira, new york',
  'enid, oklahoma',
  'erie, pennsylvania',
  'eugene, oregon',
  'evansville, indiana',
  'fairbanks, alaska',
  'fargo, north dakota',
  'farmington, new mexico',
  'fayetteville, arkansas',
  'fayetteville, north carolina',
  'flagstaff, arizona',
  'flint, michigan',
  'florence, alabama',
  'florence, south carolina',
  'fond du lac, wisconsin',
  'fort collins, colorado',
  'fort smith, arkansas',
  'fort wayne, indiana',
  'fresno, california',
  'gadsden, alabama',
  'gainesville, florida',
  'gainesville, georgia',
  'gettysburg, pennsylvania',
  'glens falls, new york',
  'goldsboro, north carolina',
  'grand forks, north dakota',
  'grand island, nebraska',
  'grand junction, colorado',
  'grand rapids, michigan',
  'grants pass, oregon',
  'great falls, montana',
  'greeley, colorado',
  'green bay, wisconsin',
  'greensboro, north carolina',
  'greenville, north carolina',
  'greenville, south carolina',
  'gulfport, mississippi',
  'hagerstown, maryland',
  'hammond, louisiana',
  'hanford, california',
  'harrisburg, pennsylvania',
  'harrisonburg, virginia',
  'hartford, connecticut',
  'hattiesburg, mississippi',
  'hickory, north carolina',
  'hilton head island, south carolina',
  'hinesville, georgia',
  'homosassa springs, florida',
  'hot springs, arkansas',
  'houma, louisiana',
  'houston, texas',
  'huntington, west virginia',
  'huntsville, alabama',
  'idaho falls, idaho',
  'indianapolis, indiana',
  'iowa city, iowa',
  'ithaca, new york',
  'jackson, michigan',
  'jackson, mississippi',
  'jackson, tennessee',
  'jacksonville, florida',
  'jacksonville, north carolina',
  'janesville, wisconsin',
  'jefferson city, missouri',
  'johnson city, tennessee',
  'johnstown, pennsylvania',
  'jonesboro, arkansas',
  'joplin, missouri',
  'kahului, hawaii',
  'kalamazoo, michigan',
  'kankakee, illinois',
  'kansas city, missouri',
  'kennewick, washington',
  'killeen, texas',
  'kingsport, tennessee',
  'kingston, new york',
  'knoxville, tennessee',
  'kokomo, indiana',
  'la crosse, wisconsin',
  'lafayette, indiana',
  'lafayette, louisiana',
  'lake charles, louisiana',
  'lake havasu city, arizona',
  'lakeland, florida',
  'lancaster, pennsylvania',
  'lansing, michigan',
  'laredo, texas',
  'las cruces, new mexico',
  'las vegas, nevada',
  'lawrence, kansas',
  'lawton, oklahoma',
  'lebanon, pennsylvania',
  'lewiston, idaho',
  'lewiston, maine',
  'lexington, kentucky',
  'lima, ohio',
  'lincoln, nebraska',
  'little rock, arkansas',
  'logan, utah',
  'longview, texas',
  'longview, washington',
  'los angeles, california',
  'louisville, kentucky',
  'lubbock, texas',
  'lynchburg, virginia',
  'macon, georgia',
  'madera, california',
  'madison, wisconsin',
  'manchester, new hampshire',
  'manhattan, kansas',
  'mankato, minnesota',
  'mansfield, ohio',
  'mcallen, texas',
  'medford, oregon',
  'memphis, tennessee',
  'merced, california',
  'miami, florida',
  'michigan city, indiana',
  'midland, michigan',
  'midland, texas',
  'milwaukee, wisconsin',
  'minneapolis, minnesota',
  'missoula, montana',
  'mobile, alabama',
  'modesto, california',
  'monroe, louisiana',
  'monroe, michigan',
  'montgomery, alabama',
  'morgantown, west virginia',
  'morristown, tennessee',
  'mount vernon, washington',
  'muncie, indiana',
  'muskegon, michigan',
  'myrtle beach, south carolina',
  'napa, california',
  'naples, florida',
  'nashville, tennessee',
  'new bern, north carolina',
  'new haven, connecticut',
  'new orleans, louisiana',
  'new york, new york',
  'niles, michigan',
  'north port, florida',
  'norwich, connecticut',
  'ocala, florida',
  'ocean city, new jersey',
  'odessa, texas',
  'ogden, utah',
  'oklahoma city, oklahoma',
  'olympia, washington',
  'omaha, nebraska',
  'orlando, florida',
  'oshkosh, wisconsin',
  'owensboro, kentucky',
  'oxnard, california',
  'palm bay, florida',
  'panama city, florida',
  'parkersburg, west virginia',
  'pensacola, florida',
  'peoria, illinois',
  'philadelphia, pennsylvania',
  'phoenix, arizona',
  'pine bluff, arkansas',
  'pittsburgh, pennsylvania',
  'pittsfield, massachusetts',
  'pocatello, idaho',
  'port st. lucie, florida',
  'portland, maine',
  'portland, oregon',
  'poughkeepsie, new york',
  'prescott valley, arizona',
  'providence, rhode island',
  'provo, utah',
  'pueblo, colorado',
  'punta gorda, florida',
  'racine, wisconsin',
  'raleigh, north carolina',
  'rapid city, south dakota',
  'reading, pennsylvania',
  'redding, california',
  'reno, nevada',
  'richmond, virginia',
  'riverside, california',
  'roanoke, virginia',
  'rochester, minnesota',
  'rochester, new york',
  'rockford, illinois',
  'rocky mount, north carolina',
  'rome, georgia',
  'sacramento, california',
  'saginaw, michigan',
  'salem, oregon',
  'salinas, california',
  'salisbury, maryland',
  'salt lake city, utah',
  'san angelo, texas',
  'san antonio, texas',
  'san diego, california',
  'san francisco, california',
  'san jose, california',
  'san luis obispo, california',
  'santa cruz, california',
  'santa fe, new mexico',
  'santa maria, california',
  'santa rosa, california',
  'savannah, georgia',
  'scranton, pennsylvania',
  'seattle, washington',
  'sebastian, florida',
  'sebring, florida',
  'sheboygan, wisconsin',
  'sherman, texas',
  'shreveport, louisiana',
  'sierra vista, arizona',
  'sioux city, iowa',
  'sioux falls, south dakota',
  'south bend, indiana',
  'spartanburg, south carolina',
  'spokane, washington',
  'springfield, illinois',
  'springfield, massachusetts',
  'springfield, missouri',
  'springfield, ohio',
  'st. cloud, minnesota',
  'st. george, utah',
  'st. joseph, missouri',
  'st. louis, missouri',
  'state college, pennsylvania',
  'staunton, virginia',
  'stockton, california',
  'sumter, south carolina',
  'syracuse, new york',
  'tallahassee, florida',
  'tampa, florida',
  'terre haute, indiana',
  'texarkana, texas',
  'the villages, florida',
  'toledo, ohio',
  'topeka, kansas',
  'trenton, new jersey',
  'tucson, arizona',
  'tulsa, oklahoma',
  'tuscaloosa, alabama',
  'twin falls, idaho',
  'tyler, texas',
  'urban honolulu, hawaii',
  'utica, new york',
  'valdosta, georgia',
  'vallejo, california',
  'victoria, texas',
  'vineland, new jersey',
  'virginia beach, virginia',
  'visalia, california',
  'waco, texas',
  'walla walla, washington',
  'warner robins, georgia',
  'waterloo, iowa',
  'watertown, new york',
  'wausau, wisconsin',
  'weirton, west virginia',
  'wenatchee, washington',
  'wheeling, west virginia',
  'wichita falls, texas',
  'wichita, kansas',
  'williamsport, pennsylvania',
  'wilmington, north carolina',
  'winchester, virginia',
  'winston, north carolina',
  'worcester, massachusetts',
  'yakima, washington',
  'york, pennsylvania',
  'youngstown, ohio',
  'yuba city, california',
  'yuma, arizona',
];

export default { 'united states': unitedStates.map((metro) => ({ label: metro, value: metro })) };
