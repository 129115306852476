const initStore = {
  sidebar: {
    companiesProjects: {
      active: {
        list: [],
        map: {},
      },
      onHold: {
        list: [],
        map: {},
      },
      projects: {},
      $skip: 0,
      hasMoreData: false,
      paginationProjectsType: 'active',
      filters: {
        params: { name: '' },
      },
      isFetching: false,
    },
    hasArchivedProjects: false,
  },
  activities: [],

  events: {
    items: [],
    select: null,
  },

  milestones: {
    items: [],
    select: null,
  },

  overdueMilestones: {
    map: [],
    list: {},
    skipCount: 0,
    hasMoreData: false,
    isLoading: false,
    overdueCount: 0,
  },

  assignees: [],
  privateAssignees: [],

  companies: [],
};

export default initStore;
