import { action } from 'easy-peasy';
import { getTodoIndex } from '../helpers/list';

export const teamworkFxDragAndDropTemplateTodo = action(
  (state, { targetId, sourceId, itemId, items }) => {
    const list = state.lists.map[sourceId];
    const todoIndex = getTodoIndex(list, itemId);

    if (todoIndex === -1) {
      return;
    }

    // remove todo from source todo-list
    state.lists.map[sourceId].todos.splice(todoIndex, 1);

    // add todo to target todo-list
    state.lists.map[targetId].todos.splice(items.indexOf(itemId), 0, Number(itemId));
  }
);
