const initStore = {
  milestones: {
    weeks: {
      list: [],
      map: {},
    },
  },
};

export default initStore;
