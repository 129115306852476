import { action } from 'easy-peasy';

export const loadMoreOverdueMilestones = action((state, { data, hasMore, count }) => {
  const newList = {
    ...state.overdueMilestones.list,
    ...data.reduce((acc, milestone) => ({ ...acc, [milestone.milestoneId]: milestone }), {}),
  };

  state.overdueMilestones = {
    map: [...state.overdueMilestones.map, ...data],
    list: newList,
    skipCount: state.overdueMilestones.skipCount + data.length,
    hasMoreData: hasMore,
    overdueCount: count,
  };
});
