import { thunk } from 'easy-peasy';
import omit from 'lodash/omit';
import api from '../../../../../../services/api';

export const onUpdateProjectUser = thunk(
  async ({ updateProjectAccess }, { values, userId, setSubmitting }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError, showNotification } = actions.common;
    try {
      const sanitized = omit(values, ['companyId']);
      const user = await api.users.patch(userId, sanitized);
      updateProjectAccess(user);
      showNotification({ message: 'The settings for this person have been saved successfully' });
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
