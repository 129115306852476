import { initState } from './initState';

import { mountTeamworkFxEditUserPage } from './actions/mountTeamworkFxEditUserPage';
import { updateProjectAccess } from './actions/updateProjectAccess';
import { giveAccessToCompany } from './actions/giveAccessToCompany';
import { giveAccessToProject } from './actions/giveAccessToProject';
import { toggleSelectAllAccess } from './actions/toggleSelectAllAccess';

import { onDeleteTeamworkFxUser } from './thunks/onDeleteTeamworkFxUser';
import { onMountTeamworkFxEditUserPage } from './thunks/onMountTeamworkFxEditUserPage';
import { onSendTeamworkFxPasswordInstruction } from './thunks/onSendTeamworkFxPasswordInstruction';
import { onUpdateTeamworkFxUser } from './thunks/onUpdateTeamworkFxUser';
import { onGiveAccessTeamworkFxUser } from './thunks/onGiveAccessTeamworkFxUser';

const editUser = {
  ...initState,

  // actions
  mountTeamworkFxEditUserPage,
  updateProjectAccess,
  giveAccessToCompany,
  giveAccessToProject,
  toggleSelectAllAccess,

  // thunks
  onDeleteTeamworkFxUser,
  onMountTeamworkFxEditUserPage,
  onSendTeamworkFxPasswordInstruction,
  onUpdateTeamworkFxUser,
  onGiveAccessTeamworkFxUser,
};

export default editUser;
