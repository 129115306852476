import classnames from 'classnames';

import styles from './Icon.module.css';

const Icon = ({
  className,
  component: Component = 'span',
  children,
  onClick,
  outlined = false,
  ...passThroughProps
}) => (
  <Component
    className={classnames(styles.materialIcons, { [styles.outlined]: outlined }, className)}
    onClick={onClick}
    aria-hidden
    {...passThroughProps}
  >
    {children}
  </Component>
);

export default Icon;
