import { action } from 'easy-peasy';

import { initState } from '../initState';

export const unMountProjectSingleTodoList = action((state) => {
  state.todoList = initState.todoList;
  state.todoListForm = initState.todoListForm;
  state.sidebarLists = initState.sidebarLists;
  state.filters = initState.filters;
  state.assignees = initState.assignees;
});
