import { action } from 'easy-peasy';

export const decreaseSkipCount = action((state, payload) => {
  const { uploadId } = payload;
  const isPresentInStore = state.items.some(({ files }) =>
    files.some((item) => item.uploadId === uploadId)
  );
  if (isPresentInStore) {
    state.skipCount -= 1;
  }
});
