import { action } from 'easy-peasy';
import merge from 'lodash/merge';

import { toBool } from '../../../../../../utils-deprecated/toBool';
import { transformTodoListFields } from '../helpers/transformTodoList';

export const wsEditTodoList = action((state, { result }) => {
  if (state.todoList.todolistId === result.todolistId) {
    state.todoList = merge(state.todoList, transformTodoListFields(result));
  }

  const status = toBool(result.completed) ? 'completed' : 'active';
  state.sidebarLists[status] = state.sidebarLists[status].map((list) =>
    list.todolistId === result.todolistId ? { ...list, name: result.name } : list
  );
});
