import { action } from 'easy-peasy';
import { getListIndex } from '../helpers/list';

export const deleteTemplateList = action((state, listId) => {
  const listIndex = getListIndex(state.lists.list, listId);

  if (listIndex === -1) {
    return;
  }

  const list = state.lists.map[listId];
  list.projectTemplateTodos.forEach((id) => delete state.todoItems[id]);

  state.lists.list.splice(listIndex, 1);
  delete state.lists.map[listId];

  const listIndexInSidebar = state.sidebarLists.list.indexOf(listId);
  if (listIndexInSidebar !== -1 && state.sidebarLists.list.length === 1) {
    state.sidebarLists.showAll = true;
  }
  state.sidebarLists.list.splice(listIndexInSidebar, 1);
  delete state.sidebarLists.map[listId];
});
