import { thunk } from 'easy-peasy';

import api from '../../../../services/api';

export const onDeleteCategory = thunk(async (actions, { categoryId }, { getStoreActions }) => {
  const { common } = getStoreActions();
  try {
    await api.categories.remove(categoryId);
    actions.remove(categoryId);
  } catch (e) {
    common.showError(e);
  }
});
