import { thunk } from 'easy-peasy';
import useProject from '@webfx/teamwork-web/src/hooks/useProject';

import api from '../../../../../services/api';

export const onRemoveCompanyFromProject = thunk(
  async ({ removeCompanyFromProject }, id, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    const { selectProject } = actions.project;

    const { data: project } = useProject();
    const projectId = project?.projectId;

    const loader = 'project.peopleAndPermissions.removeCompanyFromProject';
    showLoader({ key: loader });
    try {
      const removedCompany = await api.projectsCompanies.remove(id);
      const selectedProject = await api.projects.get({
        projectId,
        params: { for: 'projectCompanies' },
      });
      removeCompanyFromProject(removedCompany);
      selectProject(selectedProject);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
