import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onCreateTodoList = thunk(
  async (_, { values, setSubmitting, push }, { getStoreActions, getStoreState }) => {
    const actions = getStoreActions();
    const showError = actions.common.showError;
    const onMountProjectTodoLists = actions.project.todos.todoLists.onMountProjectTodoLists;

    try {
      let todoList = await api.todoLists.create(values, { for: 'onCreateTodoList' });

      if (Array.isArray(todoList)) {
        todoList = todoList[0];
      }
      push(
        getStoreState().router.url({ route: 'projectTodolists', projectId: todoList.projectId })
      );
    } catch (e) {
      showError(e);
      setSubmitting(false);
    }
  }
);
