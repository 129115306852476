import { action } from 'easy-peasy';

export const moveTodo = action((state, { movedTodo: { todoList } }) => {
  state.todo.movedTo = {
    project: {
      newProjectId: todoList.project.projectId,
      newProjectName: todoList.project.name,
    },
    todoList: {
      newTodoListName: todoList.name,
    },
  };
});
