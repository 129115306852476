import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onUpdateTemplateTodoList = thunk(
  async (
    { updateTemplateTodoList },
    { values, setSubmitting, onClosePopup, id },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showError } = actions.common;
    try {
      const updatedMilestone = await api.projectTemplateTodoLists.patch(id, values, {
        for: 'onUpdateProjectTemplateTodoList',
      });
      updateTemplateTodoList(updatedMilestone);
      onClosePopup();
    } catch (e) {
      showError(e);
    }
    setSubmitting(false);
  }
);
