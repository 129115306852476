import { api } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';

/**
 *
 * @param query
 * @returns A React query
 */
export default function useSiteSettings(query = {}) {
  const { siteId: activeId } = useActiveSite();
  const siteId = query?.siteId || activeId;

  const siteSettingsQuery = api.useQuery(
    [
      'site-settings',
      {
        query: {
          siteId,
          $limit: -1,
          ...query,
        },
      },
    ],
    { enabled: !!siteId }
  );

  const mutator = api.useMutation(['site-settings', siteId]);
  return { ...siteSettingsQuery, mutator };
}
