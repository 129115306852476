import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';

export const onMoveTodoList = thunk(
  async ({ moveTodoList }, { todolistId, target }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'project.todos.singleTodoList.moveTodoList';
    showLoader({ key: loader });
    try {
      const movedTodoList = await api.todoLists.patch(todolistId, target, {
        for: 'onMoveTodoList',
      });
      moveTodoList({ todolistId, movedTodoList });
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
