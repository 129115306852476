import { paramsForServer } from 'feathers-hooks-common';
import { feathersClient } from './feathersClient';

const categories = feathersClient.service('tw/categories');

/**
 *
 * @param params
 */
export const find = (params) => categories.find(paramsForServer(params));
/**
 *
 * @param payload
 */
export const create = (payload) => categories.create(payload);
/**
 *
 * @param id
 * @param body
 * @param params
 */
export const patch = (id, body, params = {}) => categories.patch(id, body, paramsForServer(params));
/**
 *
 * @param id
 */
export const remove = (id) => categories.remove(id);
