import { action } from 'easy-peasy';

export const removeAccessYourCompany = action((state, payload) => {
  if (!payload.length) {
    state.yourCompany.users[payload] = {
      ...state.yourCompany.users[payload],
      projectUserId: null,
      hasAccess: false,
    };
    return;
  }
  payload.forEach((id) => {
    state.yourCompany.users[id] = {
      ...state.yourCompany.users[id],
      projectUserId: null,
      hasAccess: false,
    };
  });
});
