import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const onSubscribeCurrentUser = thunk(
  async ({ subscribeCurrentUser }, { todoId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      const [user] = await api.subscribers.create({ todoId }, { for: 'users' });
      if (user) {
        subscribeCurrentUser({ user });
      }
    } catch (e) {
      showError(e);
    }
  }
);
