import { thunk } from 'easy-peasy';

export const onUnMountProjectSearch = thunk(
  async ({ unMountProjectSearch }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      unMountProjectSearch();
    } catch (e) {
      showError(e);
    }
  }
);
