import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';

export const onRemoveAccessForAll = thunk(
  async ({ removeAccess }, { users, list, projectId }, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    const currentUser = store.auth.user();

    if (!list || !Array.isArray(list)) {
      return;
    }

    const usersIds = list.reduce((acc, cur) => {
      if (currentUser.userId !== cur && users[cur].projectUserId) {
        acc.push({ userId: cur, projectId, projectUserId: users[cur].projectUserId });
      }
      return acc;
    }, []);

    const loader = 'project.peopleAndPermissions.unSubscribe';
    showLoader({ key: loader });
    try {
      const batchSize = 100;
      const totalRequests = usersIds.length / batchSize;

      for (let i = 0; i < totalRequests; i++) {
        await api.projectsUsers.remove(null, {
          query: { $multi: true, $or: usersIds.slice(i * batchSize, (i + 1) * batchSize) },
          for: 'changePeopleAndPermissions',
        });
      }
      removeAccess(list);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
