const getTodoPlace = (reorderedTodoIds, todoId) => {
  const index = reorderedTodoIds.findIndex((id) => id === todoId);
  return index === 0
    ? {
        targetTodoId: reorderedTodoIds[index + 1],
        place: 'before',
      }
    : {
        targetTodoId: reorderedTodoIds[index - 1],
        place: 'after',
      };
};

const setTodoToPlace = ({ allTodos, todoPlace: { targetTodoId, place }, todoId }) => {
  const _allTodos = [...allTodos];
  const targetTodoIndex = allTodos.findIndex(({ todoId: id }) => id === targetTodoId);

  const placeIndex = place === 'before' ? targetTodoIndex : targetTodoIndex + 1;
  _allTodos.splice(placeIndex, 0, { todoId, position: null });

  return _allTodos;
};

// TODO: Probably we have a bug with moved todo
export const getActualTodosPositions = (allTodos, reorderedTodoIds, targetId, todoId) => {
  if (allTodos.length === 0) {
    return [
      {
        todoId,
        position: 1,
        todolistId: Number(targetId),
      },
    ];
  }

  const todoPlace = getTodoPlace(reorderedTodoIds, todoId);

  return setTodoToPlace({ allTodos, todoPlace, todoId }).map(({ todoId: id }, index) => ({
    todoId: id,
    position: index + 1,
    todolistId: Number(targetId),
  }));
};
