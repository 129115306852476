import { paramsForServer } from 'feathers-hooks-common';

import { feathersClient } from './feathersClient';

export const projectTemplates = feathersClient.service('tw/project-templates');

export const find = (payload) => projectTemplates.find(payload);
export const create = (body) => projectTemplates.create(body);
export const patch = (id, body, params) => projectTemplates.patch(id, body, params);
export const remove = (id) => projectTemplates.remove(id);
export const get = (projectTemplateId, params) =>
  projectTemplates.get(projectTemplateId, paramsForServer(params));
