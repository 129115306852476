import { useRouter } from '@webfx/web-hooks';
import { Button } from '@webfx/core-web';
import useProjects from '../../hooks/useProjects';
import useActivityLog from '../../hooks/useActivityLog';
import useHistory from '../../hooks/useHistory';

import ActivitySection from './ActivitySection';
import styles from './ActivityLog.module.css';

const ActivityLog = ({ companyId }) => {
  const { params, url } = useRouter();

  const { data: historyItems } = useHistory(companyId);
  const { data: logs } = useActivityLog(companyId);
  const { data: projects } = useProjects(companyId);
  const projectId = params.projectId ?? projects?.[0]?.projectId; // We only need one project to redirect to History page

  const items = [
    {
      type: 'messages',
      data: logs?.messages,
      iconOutlined: true,
      icon: 'chat_bubble',
      title: 'Messages',
    },
    { type: 'calls', data: logs?.calls, iconOutlined: true, icon: 'phone_in_talk', title: 'Calls' },
    {
      type: 'actionItems',
      data: historyItems?.data.filter((h) => h.type !== 'Message'),
      icon: 'bolt',
      title: 'Action Items',
    },
  ];

  return (
    <div className="d-flex flex-column mb-3" data-fx-name="label">
      <h3 className="font-20 text-gray-700 bold">Activity Log</h3>
      <p className="semibold font-14 text-gray-700">
        Activities Supporting Planned Deliverables and Driving Results
      </p>

      <div className={styles.activitiesRow}>
        {items.map((item) => (
          <ActivitySection
            key={item.type}
            type={item.type}
            title={item.title}
            icon={item.icon}
            items={item.data}
            iconOutlined={item.iconOutlined}
          />
        ))}
      </div>
      <div className="d-flex justify-content-end">
        {projectId ? (
          <Button
            variant="link"
            className="font-13 p-0 border-none shadow-none semibold text-blue-vivid-300 mt-2"
            to={url({
              route: 'projectHistory',
              projectId: projectId.toString(),
              page: 'timeline',
            })}
            text="See Full History"
            icon="navigate_next"
            iconPosition="right"
            iconTrailing
          />
        ) : null}
      </div>
    </div>
  );
};

export default ActivityLog;
