import { action } from 'easy-peasy';

export const removeFile = action((state, { uploadId }) => {
  const itemsCopy = [...state.items];

  itemsCopy.forEach(({ files: currentFiles }, groupIndex) => {
    const index = currentFiles.findIndex((file) => file.uploadId === uploadId);

    if (index !== -1) {
      currentFiles.splice(index, 1);
      state.skipCount -= 1;
    }

    if (index !== -1 && !currentFiles.length) {
      itemsCopy.splice(groupIndex, 1);
    }
  });

  state.items = itemsCopy;
});
