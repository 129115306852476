import { isEmpty } from 'lodash';

import { Icon } from '@webfx/core-web';
import { ProductLogo } from '@webfx/web-ui';
import { useFlags } from '@webfx/web-hooks';
import useCompanySites from '@webfx/teamwork-web/src/hooks/useCompanySites';

import accelerateGrowthBackgroundImage from '@webfx/teamwork-web/src/assets/images/accelerate-growth-background-image.png';
import { Link } from 'react-router-dom';
import styles from './PlayBookFXCTABanner.module.css';

const PlayBookFXCTABanner = ({ companyId }) => {
  const flags = useFlags();
  const backgroundImage = `url(${accelerateGrowthBackgroundImage})`;

  const { data: company } = useCompanySites(companyId);

  return (
    <div
      className={`row p-3 m-0 pt-4 text-light rounded-bottom ${
        !flags.gameplanfxUpdates ? 'mb-5' : ''
      }`}
      style={{ backgroundColor: '#083A74' }}
    >
      <div className="col-3">
        <h3 className={styles.playBookTextLogo}>
          <ProductLogo product="PlaybookFX" height="44px" />
        </h3>
      </div>
      <div
        className="col"
        style={{ backgroundImage, backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}
      >
        Find the most effective path for revenue growth with PlaybookFX big data insights
      </div>
      <div className="col-3">
        <Link
          to={isEmpty(company?.sites) ? '/playbookfx' : `/playbookfx/${company?.sites[0]?.siteId}`}
          style={{ backgroundColor: '#207DE9' }}
          className="btn btn-primary pb-2 float-right"
        >
          Accelerate Growth
          <Icon size={10} className="ml-1" style={{ lineHeight: 'unset' }}>
            arrow_forward
          </Icon>
        </Link>
      </div>
    </div>
  );
};

export default PlayBookFXCTABanner;
