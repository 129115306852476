import isInternalCompany from '../../../../../utils-deprecated/isInternalCompany';

const getValuesForStoreByStatus = (items, type) => {
  const valuesForStore = {
    list: [],
    map: {},
    projects: {},
  };

  items.forEach((company) => {
    if (type === 'archived') {
      // we do not need to put `WebFx` to a top of archived projects list
      valuesForStore.list.push(company.companyId);
    } else {
      // check for putting `WebFx` to a top of list
      isInternalCompany(company.name)
        ? valuesForStore.list.unshift(company.companyId)
        : valuesForStore.list.push(company.companyId);
    }

    valuesForStore.map[company.companyId] = { ...company, projects: [] };

    company.projects.forEach((currentProject) => {
      valuesForStore.projects[currentProject.projectId] = currentProject;
      valuesForStore.map[company.companyId].projects.push(currentProject.projectId);
    });
  });

  return valuesForStore;
};

export default getValuesForStoreByStatus;
