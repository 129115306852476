import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onUpdateEvent = thunk(
  async (
    { updateEvent },
    { id, values, closeModal = () => null, resetForm = () => null },
    { getStoreActions }
  ) => {
    const { common } = getStoreActions();
    try {
      const data = await api.events.update(id, values);
      updateEvent(data);
      resetForm();
      closeModal();
    } catch (e) {
      common.showError(e);
    }
  }
);
