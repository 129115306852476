import { action } from 'easy-peasy';

export const setTemplateTodoListsIntoStore = action((state, payload) => {
  state.lists.list = [];
  state.lists.map = {};
  state.todoItems = {};

  payload.forEach((currentList) => {
    const idsOfTodos = currentList.projectTemplateTodos.map((item) => {
      const { projectTemplateTodoId } = item;

      state.todoItems[projectTemplateTodoId] = item;
      return projectTemplateTodoId;
    });

    state.lists.list.push(currentList.projectTemplateTodoListId);
    state.lists.map[currentList.projectTemplateTodoListId] = {
      ...currentList,
      projectTemplateTodos: idsOfTodos,
    };
  });
});
