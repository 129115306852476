import { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import { Svg, Button, Icon, FormikTextarea } from '@webfx/core-web';
import useActionTemplate from '@webfx/marketingcloud-web/src/hooks/useActionTemplate';
import useIntegrationRequests from '@webfx/marketingcloud-web/src/hooks/analytics/useIntegrationRequests';

import { useSitePlan, useActiveSite, useNotifications, useAuth } from '@webfx/web-hooks';
import ContactBanner from '@webfx/marketingcloud-web/src/assets/images/analytics/contact-form-banner.svg';
import DataArchitect from './DataArchitect';

import styles from './IntegrationSetupModal.module.css';

const TODO_ASSIGNEE = 12569355; // emartz@webfx.com
const INTEGRATIONS_TODOLIST = 32032677;

const IntegrationSetupModal = ({ product, setShowModal }) => {
  const [onSetup, setOnSetup] = useState(false);
  const site = useActiveSite();
  const { toast } = useNotifications();
  const plan = useSitePlan() || {};
  const { user: currentUser } = useAuth();
  const actionTemplateMutator = useActionTemplate();
  const { data, mutator: integrationsMutator } = useIntegrationRequests();

  const isRequestSent = data?.some((integration) => integration.connector === product);

  const dataArchitect = site.fxTeam?.find(
    (user) => user?.role_name === 'MarketingCloudFX Solutions'
  );

  const products = {
    'closed-revenue': 'Closed Revenue',
    pipeline: 'Pipeline',
  };

  const handleSubmit = (values) => {
    const payload = {
      action: 'analytics_upgrade',
      siteId: site.siteId,
      todolistId: INTEGRATIONS_TODOLIST,
      message: values?.message || '',
      dueDate: DateTime.local().plus({ days: 7 }),
      defaultUser: TODO_ASSIGNEE, // todo assignee
      commentAssignee: TODO_ASSIGNEE,
      planLevel: plan?.name || '',
      clientName: site.name,
      requesterEmail: currentUser?.email,
      requesterName: `${currentUser?.firstName} ${currentUser?.lastName}`,
      product: products[product],
    };
    actionTemplateMutator.mutate(
      {
        ...payload,
      },
      {
        onSuccess: () => {
          toast.success(`Request sent successfully!`);
          setOnSetup(false);

          // Save the integration request
          saveRequest();
        },
      },
      {
        onError: () => {
          toast.error('An error occurred while sending the request. Please try again later.');
        },
      }
    );
  };

  // Save the integration request
  const saveRequest = () => {
    integrationsMutator.mutate({
      _method: 'create',
      connector: product,
      siteId: site.siteId,
      requestedBy: currentUser?.userId,
    });
  };

  return (
    <div
      className={classnames(styles.modal, 'bg-white shadow rounded')}
      data-fx-name="integrationEmailModal"
    >
      {onSetup ? (
        <>
          <div className="d-flex border-bottom align-items-center justify-content-between p-3 m-0">
            <div className="d-flex align-items-center">
              <Icon outlined className="text-blue-vivid-200 font-24">
                construction
              </Icon>
              <span className="font-18 text-gray-700 bold ml-2">Request Setup</span>
            </div>
            <Icon
              outlined
              className="text-gray-600 font-24 cursor-pointer"
              onClick={() => setOnSetup(false)}
            >
              close
            </Icon>
          </div>
          <div className="d-flex justify-content-between align-items-start p-5">
            <div className="d-flex flex-column">
              <div className="d-flex flex-column">
                <span>You’re requesting an integration to be setup on your account.</span>

                <span className="my-3">Add a message below (optional):</span>
              </div>

              <Formik
                initialValues={{
                  message: '',
                }}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      name="message"
                      component={FormikTextarea}
                      placeholder="Write your message here"
                      rows={5}
                      className="mt-2"
                    />
                    {/* Action buttons */}
                    <div className="d-flex mt-3">
                      <Button
                        type="submit"
                        className="btn-primary-vivid px-4 py-2 mr-2 semibold"
                        text="Send Message"
                        icon="arrow_forward"
                        iconTrailing
                        iconPosition="right"
                        isLoading={isSubmitting}
                      />
                      <Button
                        data-fx-name="cancelSetupRequest"
                        className="ml-2 btn-outline-primary-vivid px-4 semibold"
                        onClick={() => setOnSetup(false)}
                        text="Cancel"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* Data Architect */}
            {dataArchitect ? <DataArchitect dataArchitect={dataArchitect} /> : null}
          </div>
        </>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          data-fx-name="integrationComingSoonModal"
        >
          <div
            className={styles.close}
            onClick={() => {
              setShowModal(false);
            }}
            data-fx-name="closeModalButton"
          >
            <Icon>close</Icon>
          </div>
          <div className="d-flex justify-content-between">
            <div className={classnames(styles.modalContent, 'd-flex flex-column p-3 w-50')}>
              <p className={classnames(styles.modalHeading, 'text-gray-700 bold m-0 mt-3')}>
                Get full insights on your lead-to-revenue pipeline!
              </p>
              <p className={classnames(styles.modalSubheading, 'text-gray-600 bold mt-3')}>
                Including revenue forecasts for future months based on your existing pipeline!
              </p>
            </div>
            <Svg className={styles.logo} /*svgStyle={{ width: 500 }}*/ src={ContactBanner} />
          </div>
          <div className={classnames(styles.modalContentBorder, 'mx-3 border-top w-100')}></div>
          <div className="d-flex flex-column align-items-center mb-5 w-75">
            <p className="font-20 text-gray-800 bold">Make data your competitive advantage.</p>
            <p className="font-14 text-gray-600">
              Connect sales data from CRMs, ecommerce stores, and other MarTech tools to enable
              closed-loop reporting, and drive a lift in performance.
            </p>

            {!isRequestSent ? (
              <Button
                icon="arrow_forward"
                iconTrailing={true}
                iconPosition="right"
                className="bg-green-04 bold text-white p-3 mt-3"
                text="Speak to a DataTech Architect"
                onClick={() => setOnSetup(true)}
              />
            ) : (
              <div className="d-flex flex-column align-items-center">
                <Icon className="text-green-03 font-32 mb-2">check_circle</Icon>
                <p className="font-14 text-gray-800 bold m-1">A message has been sent!</p>
                <p className="font-14 text-gray-700">
                  A DataTech Architect will be in touch with you.
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationSetupModal;
