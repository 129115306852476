import { mountTeamworkFxArchivedProjectsPage } from './mountTeamworkFxArchivedProjectsPage';
import { setArchivedProjectList } from './setArchivedProjectList';
import { setCompaniesProjects } from './setCompaniesProjects';
import { onUnmountTeamworkFxArchivedProjectsPage } from './onUnmountTeamworkFxArchivedProjectsPage';

export default {
  mountTeamworkFxArchivedProjectsPage,
  setArchivedProjectList,
  setCompaniesProjects,
  onUnmountTeamworkFxArchivedProjectsPage,
};
