import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../../../services/api';

export const wsOnCreateComment = thunk(async (_, comment, { getStoreActions, getState }) => {
  const a = getStoreActions();
  const showError = a.common.showError;
  const createComment = a.project.todos.todo.createComment;

  const currentUserId = store.auth.user()?.userId;

  const localState = getState();
  const { todoId } = localState.todo;
  if (comment.todoId !== todoId) {
    return;
  }

  try {
    await api.views.create({ todoId });

    // TODO: this is a bad relience hack, if user had two windows open only 1 will get the comment
    // TODO: we need to fix overall content population to
    if (comment.creatorUserId === currentUserId) {
      return;
    }

    createComment({ comment, currentUserId });
  } catch (e) {
    showError(e);
  }
});
