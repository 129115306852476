import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';
import api from '../../../../../../services/api';
import { overdueMilestonesLimit } from '../../../../../../ui/constants/limits';
import { getOverdueDate } from '../../../../../../utils-deprecated/calendar';

export const onLoadMoreOverdueMilestones = thunk(
  async ({ loadMoreOverdueMilestones }, _, { getStoreState, getStoreActions }) => {
    const state = getStoreState();
    const { skipCount } = state.teamworkFx.dashboard.overdueMilestones;
    const { timezone } = store.auth.user();

    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const overdueDate = getOverdueDate(timezone);
    const query = {
      dueAt: { $lte: overdueDate },
      $client: { for: 'overdueCalendar' },
      $sort: { dueAt: -1 },
      $limit: overdueMilestonesLimit,
      $skip: skipCount,
    };

    const loader = 'teamworkFx.dashboard.overdueMilestones.getMore';
    showLoader({ key: loader });

    try {
      const milestones = await api.milestones.find({ query });
      loadMoreOverdueMilestones(milestones);
    } catch (e) {
      showError(e);
    }

    hideLoader({ key: loader });
  }
);
