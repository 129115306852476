import { store } from '@webfx/web-state';
import { thunk } from 'easy-peasy';

export const onWsCreateProjectOverviewMilestone = thunk(
  async ({ wsAddProjectOverviewMilestone }, milestone) => {
    const timezone = store.auth.user().timezone;

    wsAddProjectOverviewMilestone({ milestone, timezone });
  }
);
