import { thunk } from 'easy-peasy';

/* eslint-disable prefer-destructuring */
export const wsOnDeleteComment = thunk(async (_, comment, { getStoreActions, getState }) => {
  const a = getStoreActions();
  const deleteComment = a.project.todos.todo.deleteComment;

  const localState = getState();
  if (!localState.comments.list.includes(comment.commentId)) {
    return;
  }

  deleteComment({ comment });
});
