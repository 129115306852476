import api from '../../../../../../services/api';

export const getTodosColorCodes = async (todos, isColorCoding) => {
  if (!isColorCoding) {
    return;
  }

  const todoIds = todos.map(({ todoId }) => todoId);
  return api.colorCodes.colorCodes(todoIds);
};
