import { useState, useRef } from 'react';
import classnames from 'classnames';
import { Overlay, Popover } from 'react-bootstrap';

import { Icon } from '@webfx/core-web';

import MoveOptimization from '../MoveOptimization';

import styles from './ActionButton.module.css';

const ActionButton = ({ projectOptimization, companyId, quarter }) => {
  const [show, setShow] = useState(false);
  const popoverRef = useRef(null);

  const handleMoreActionClick = (event, show) => {
    event.stopPropagation();
    event.preventDefault();
    setShow(show);
  };

  return (
    <>
      <div
        className={classnames(
          'd-flex align-items-center justify-content-center text-gray-500 text-hover-gray-600 font-24 cursor-pointer',
          styles.actionBtn
        )}
        onClick={() => handleMoreActionClick(event, !show)}
        ref={popoverRef}
      >
        <Icon>more_vert</Icon>
      </div>

      <Actions
        quarter={quarter}
        companyId={companyId}
        projectOptimization={projectOptimization}
        target={popoverRef.current}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

const Actions = ({ projectOptimization, companyId, quarter, target, show, setShow }) => {
  const [showMove, setShowMove] = useState(false);

  return (
    <>
      <Overlay
        show={show}
        placement="bottom"
        target={target}
        rootClose
        onHide={() => setShow(false)}
      >
        {({ ...props }) => {
          return (
            <Popover {...props} className={styles.popover}>
              <Popover.Content className="px-3 py-2">
                <div className="d-flex flex-column" data-fx-name="actionMenu">
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => setShowMove(true)}
                  >
                    <div className="d-flex align-items-center mt-2 cursor-pointer text-gray-700 text-hover-blue-400">
                      <Icon className="font-20 mr-2">calendar_month</Icon> Move to...
                    </div>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
      <MoveOptimization
        open={showMove}
        onClose={() => setShowMove(false)}
        projectOptimization={projectOptimization}
        companyId={companyId}
        quarter={quarter}
      />
    </>
  );
};

export default ActionButton;
