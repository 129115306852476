import { feathersClient } from './feathersClient';

export const events = feathersClient.service('tw/events');

/**
 *
 * @param id
 */
export const get = (id) => events.get(id);
/**
 *
 * @param payload
 */
export const create = (payload) => events.create(payload);
/**
 *
 * @param eventId
 */
export const remove = (eventId) => events.remove(eventId);
/**
 *
 * @param id
 * @param body
 */
export const update = (id, body) => events.patch(id, body);
