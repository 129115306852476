import { thunk } from 'easy-peasy';

export const onUnMountTeamworkFxSearchState = thunk(
  async ({ unMountTeamworkFxSearchState }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showError } = actions.common;

    try {
      unMountTeamworkFxSearchState();
    } catch (e) {
      showError(e);
    }
  }
);
