import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onGetMilestone = thunk(
  async (
    { setSelectMilestone, setCurrentAssignees, setCurrentPrivateAssignees },
    { milestoneId, params },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;
    const { setCompaniesWithProjects } = actions.projects;

    const loader = 'getMilestoneSingle';
    showLoader({ key: loader });
    try {
      const milestone = await api.milestones.get({ id: milestoneId, params });
      const [assignee, privateAssignee, companiesWithProjects] = await Promise.all([
        api.projects.get({ projectId: milestone.projectId, params: { for: 'assigneesDropdown' } }),
        api.projects.get({
          projectId: milestone.projectId,
          params: {
            for: 'assigneesDropdown',
            private: true,
          },
        }),
        api.companies.find({ for: 'projectsDropdown', query: { $limit: -1 } }),
      ]);
      setCurrentAssignees(assignee);
      setCurrentPrivateAssignees(privateAssignee);
      setSelectMilestone(milestone);
      setCompaniesWithProjects(companiesWithProjects);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
