import permissionsStore from './store';
import { setUserPermissions } from './actions/setUserPermissions';
import { resetUserPermissions } from './actions/resetUserPermissions';
import { onFindPermissions } from './thunks/onFindPermissions';

// roles = ['owner', 'admin', 'user_messages', 'user_todos', 'user_calendar']

// TODO:  Move Role Permissions into user Store
// TODO:  Move Project Permissions Refactored project store

const permissions = {
  // store
  ...permissionsStore,
  // actions
  setUserPermissions,
  resetUserPermissions,
  // thunks
  onFindPermissions,
};

export default permissions;
