import { thunk } from 'easy-peasy';
import api from '../../../../../services/api';

/* eslint-disable prefer-destructuring */
export const onWsDeleteMilestoneComment = thunk(async (_, { milestoneId }, { getStoreActions }) => {
  // Handle action only for milestone comments
  if (!milestoneId) {
    return;
  }

  const actions = getStoreActions();
  const showError = actions.common.showError;
  const wsRemoveMilestoneComment = actions.project.overview.wsRemoveMilestoneComment;

  try {
    const milestone = await api.milestones.get({
      id: milestoneId,
      params: { for: 'milestoneComments' },
    });
    wsRemoveMilestoneComment(milestone);
  } catch (e) {
    showError(e);
  }
});
