import { thunk } from 'easy-peasy';

import api from '../../../../../services/api';

export const onGiveAccessForAllYourCompany = thunk(
  async (
    { giveAccessYourCompany },
    { users, list, projectId, curentUserId },
    { getStoreActions }
  ) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const newListIds = list.filter((id) => {
      return users[id].type !== 'owner' && id !== curentUserId;
    });

    const usersIds = newListIds.reduce((acc, cur) => {
      acc.push({ userId: cur, projectId });
      return acc;
    }, []);

    const loader = 'project.peopleAndPermissions.subscribe';
    showLoader({ key: loader });
    try {
      const res = await api.projectsUsers.create(usersIds, {
        for: 'changePeopleAndPermissions',
      });

      giveAccessYourCompany(res);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key: loader });
  }
);
