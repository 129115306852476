import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../../services/api';
import { getFromTo, getSelectedMonthDate } from '../../../../../utils-deprecated/calendar';

export const onMountCalendar = thunk(
  async ({ setMilestones, setEvent }, _, { getStoreActions }) => {
    const { timezone } = store.auth.user();

    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const currDate = getSelectedMonthDate(null, timezone);
    const { from, to } = getFromTo(currDate, true, 2);

    const key = 'teamworkFx.dashboard.mountDashboardCalendar';
    showLoader({ key });

    try {
      const calendarData = await api.calendar.find({ query: { from, to, dayLimit: 10 } });
      const { milestones, events } = calendarData;

      setMilestones(milestones);
      setEvent(events);
    } catch (e) {
      showError(e);
    }
    hideLoader({ key });
  }
);
