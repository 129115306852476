import { action } from 'easy-peasy';

export const deleteMilestoneTemplate = action((state, payload) => {
  const { weeks } = state.milestones;

  if (
    weeks.map[payload.weekDue].days.list.length === 1 &&
    weeks.map[payload.weekDue].days[payload.dowDue].list.length === 1
  ) {
    const deleteWeekIndex = weeks.list.findIndex((item) => item === payload.weekDue);

    delete weeks.map[payload.weekDue];
    weeks.list.splice(deleteWeekIndex, 1);
    return;
  }

  if (weeks.map[payload.weekDue].days[payload.dowDue].list.length === 1) {
    const deleteDayIndex = weeks.map[payload.weekDue].days.list.findIndex(
      (item) => item === payload.dowDue
    );

    delete weeks.map[payload.weekDue].days[payload.dowDue];
    weeks.map[payload.weekDue].days.list.splice(deleteDayIndex, 1);
    return;
  }

  const deleteMilestoneIndex = weeks.map[payload.weekDue].days[payload.dowDue].list.findIndex(
    (item) => item === payload.projectTemplateMilestoneId
  );

  delete weeks.map[payload.weekDue].days[payload.dowDue].map[payload.projectTemplateMilestoneId];
  weeks.map[payload.weekDue].days[payload.dowDue].list.splice(deleteMilestoneIndex, 1);
});
