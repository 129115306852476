import { action } from 'easy-peasy';

export const setSearchCategories = action((state, { key, value }) => {
  if (key === 'all' && value) {
    state.filters.categories.forEach((category) => {
      category.checked = false;
    });
    return;
  }
  const category = state.filters.categories.find((c) => c.value === key);
  category.checked = !category.checked;
  return;
});
