import api from '../../../../../../services/api';
import fetchWithLoaderAndRedirect from '../../../../fetchWithLoaderAndRedirect';

export const onMountProjectEditUserPage = fetchWithLoaderAndRedirect({
  loaderKey: 'PeopleAndPermissionEditUserPage',
  action: async (actions, { userId }) => {
    const { mountProjectEditUserPage } = actions.project.peopleAndPermissions.editUser;

    const [selectedUser, companies] = await Promise.all([
      api.users.get(userId, { for: 'projectsAccess' }),
      api.companies.find({
        query: { $sort: { name: 1 }, $limit: -1 },
        for: 'users',
      }),
    ]);

    mountProjectEditUserPage({ selectedUser, companies });
  },
});
