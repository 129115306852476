import api from '../../../../../services/api';
import getQueryForFiltering from '../helpers/getQueryForFiltering';
import fetchWithLoaderAndRedirect from '../../../fetchWithLoaderAndRedirect';

export const onMountProjectTemplateTodoLists = fetchWithLoaderAndRedirect({
  loaderKey: 'ProjectTemplateTodoLists',
  action: async (actions, { projectTemplateId }, getStoreState) => {
    const { setTemplateTodoListsIntoStore, setProjectTemplateSidebarLists } =
      actions.projectTemplate.todoLists;
    const todoLists = await api.projectTemplateTodoLists.find({
      query: { projectTemplateId },
      for: 'onFindProjectTemplateTodolistsSidebar',
    });
    setProjectTemplateSidebarLists({ data: todoLists });

    if (todoLists.length) {
      const state = getStoreState();
      const query = getQueryForFiltering(state.projectTemplate.todoLists, projectTemplateId);

      const data = await api.projectTemplateTodoLists.find({
        query,
        for: 'projectTemplateTodoList',
      });

      setTemplateTodoListsIntoStore(data);
    }

    return `/templates/projects/${projectTemplateId}/todo-lists`;
  },
});
