import { action } from 'easy-peasy';

export const setTeamworkFxDashboardMilestone = action((state, updatedItem) => {
  const { milestoneId } = updatedItem;

  const milestoneInStateIndex = state.milestones.items.findIndex(
    (item) => item.milestoneId === milestoneId
  );
  milestoneInStateIndex !== -1
    ? (state.milestones.items[milestoneInStateIndex] = { ...updatedItem })
    : state.milestones.items.push(updatedItem);
});
