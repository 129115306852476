import React, { Suspense, useEffect } from 'react';
import { api } from '@webfx/core-web';

import { useStoreActions } from 'easy-peasy';

const Routes = React.lazy(() => import('./ui/Routes'));

export default function App() {
  const queryClient = api.useQueryClient();
  const setQueryClient = useStoreActions((actions) => actions.tw.setQueryClient);

  useEffect(() => setQueryClient(queryClient), []);

  return (
    <Suspense fallback={<div style={{ display: 'none' }} />}>
      <Routes />
    </Suspense>
  );
}
