import { thunk } from 'easy-peasy';
import { paramsForServer } from 'feathers-hooks-common';
import { feathers } from '@webfx/core-web';
import { companiesProjectsLimit } from '../../../../../ui/constants/limits';

export const onLoadMoreArchivedProjects = thunk(
  async ({ setCompaniesProjects }, _, { getStoreState, getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const state = getStoreState();
    const { $skip } = state.ui.archivedProjects;

    try {
      showLoader({ key: 'getArchivedProjects' });
      const companiesProjects = await feathers()
        .service('tw/sidebar')
        .find(
          paramsForServer({
            query: {
              $cache: false,
              $skip,
              $limit: companiesProjectsLimit,
              status: 'archived',
            },
            for: 'projectsSidebar',
          })
        );
      setCompaniesProjects({ companiesProjects, type: 'archived' });
      hideLoader({ key: 'getArchivedProjects' });
    } catch (e) {
      showError(e);
    }
  }
);
