import { action } from 'easy-peasy';

export const removeMilestone = action((state, { milestoneId }) => {
  state.milestones.items = state.milestones.items.filter(
    (item) => item.milestoneId !== milestoneId
  );
  state.milestones.overdue = state.milestones.overdue.filter(
    (item) => item.milestoneId !== milestoneId
  );
});
