export const setItem = (key, val) => {
  try {
    if (!key) {
      return null;
    }
    localStorage.setItem(key, val);
  } catch (error) {
    console.warn(error);
  }
};

export const getItem = (key) => {
  try {
    if (!key) {
      return null;
    }
    return localStorage.getItem(key);
  } catch (error) {
    console.warn(error);
  }
};

export const removeItem = (key) => {
  try {
    if (!key) {
      return null;
    }
    localStorage.removeItem(key);
  } catch (error) {
    console.warn(error);
  }
};
