import { thunk } from 'easy-peasy';

import api from '../../../../../../services/api';

export const onFindCreateProjectCompanies = thunk(
  async ({ setCreateProjectCompanies }, _, { getStoreActions }) => {
    const actions = getStoreActions();
    const { showLoader, hideLoader, showError } = actions.common;

    const loader = 'teamworkFx.dashboard.findCompanies';
    showLoader({ key: loader });
    try {
      const companies = await api.companies.find({
        query: { $sort: { name: 1 }, $limit: -1, $select: ['companyId', 'name'] },
      });
      setCreateProjectCompanies(companies);
    } catch (error) {
      showError(error);
    }
    hideLoader({ key: loader });
  }
);
