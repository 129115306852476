import { thunk } from 'easy-peasy';

import { store } from '@webfx/web-state';
import api from '../../../../services/api';

export const onGetFiles = thunk(
  async (
    { set },
    { projectId, skipCount = 0, creatorUserId = [], limit = 10, clearState = false },
    { getStoreActions, getState, getStoreState }
  ) => {
    const { common } = getStoreActions();
    const { sortingParam: sort } = getState();
    const state = getStoreState();

    const { timezone: userTimezone } = store.auth.user();
    const { startAttachmentId } = state.files;

    if (!clearState) {
      common.showLoader({ key: 'getAllFiles' });
    }
    try {
      const query = {
        projectId,
        $skip: skipCount,
        $limit: limit,
        $sort: sort === 'name' ? { [sort]: 1 } : { [sort]: -1 },
      };

      if (creatorUserId.length) {
        query.creatorUserId = { $in: creatorUserId };
      }

      const item = await api.attachments.find({
        query,
        for: 'projectFiles',
        startAttachmentId,
      });
      set({ item, sort, creatorUserId, userTimezone, clearState });
    } catch (e) {
      common.showError(e);
    }
    common.hideLoader({ key: 'getAllFiles' });
  }
);
